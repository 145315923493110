import { FunctionComponent, useContext, useState } from "react";
import { useTranslation } from 'react-i18next';
import Button from '@mui/material/Button';
import PersonAddIcon from '@mui/icons-material/PersonAdd';

import { followUser, unfollowUser } from "../../utils/api/api";
import { AuthContext } from '../authentification/AuthContext';

const Header: FunctionComponent<any> = ({user:pageUser}) => {
  const { t } = useTranslation();
  const authContext = useContext(AuthContext);
  const { user } = authContext || {};
  const [isFollowing, setIsFollowing] = useState(false);


  const handleFollow = async () => {
    if (!pageUser?.id) {
      console.error("User ID is missing.");
      return;
    }

    try {
      await followUser(pageUser.id);
    } catch (error) {
      console.error("Error following user:", error);
      const customEvent = new CustomEvent("apiError", { detail: error });
      window.dispatchEvent(customEvent);
    }
  };

  return (
    <div className="self-stretch flex flex-col items-center justify-start max-w-full">
          <div className="self-stretch rounded-xl bg-lightolivegreen flex flex-col items-center justify-center p-12 box-border max-w-full mq900:pl-6 mq900:pr-6 mq900:box-border">
            <div className="w-[690px] flex flex-col items-center justify-start gap-[24px] max-w-full">
            {pageUser && (
              <div className="flex flex-row items-center justify-start gap-[16px]">


                <img
                  className="h-16 w-16 relative rounded-61xl object-cover"
                  loading="lazy"
                  alt=""
                  src={pageUser?.profile_picture? `data:image/jpeg;base64,${pageUser.profile_picture}` : "/profile-picture@3x.png"}
                />
                <div className="flex flex-col items-start justify-center">
                  <h2 className="m-0 relative text-inherit leading-[28px] font-medium font-inherit mq450:text-base mq450:leading-[22px] text-whitesmoke-100 tracking-wide	">
                    {pageUser?.first_name} {pageUser?.last_name}
                  </h2>
                  <div className="relative text-sm font-normal leading-[20px] text-whitesmoke-300 tracking-wider">{`Traveller`}</div>
                </div>

              </div>
              )}
              <h3 className="m-0 self-stretch relative text-lg leading-[26px] font-normal font-inherit text-whitesmoke-600 text-center">
                {pageUser?.bio}
              </h3>
              {/* <div className="flex flex-row items-start justify-start gap-[8px]">
                <div className="rounded-md bg-secondary-500 overflow-hidden flex flex-row items-center justify-center p-2 cursor-pointer">
                  <img
                    className="h-4 w-4 relative overflow-hidden shrink-0"
                    loading="lazy"
                    alt=""
                    src="/logofacebook.svg"
                  />
                </div>
                <div className="rounded-md bg-secondary-500 overflow-hidden flex flex-row items-center justify-center p-2 cursor-pointer">
                  <img
                    className="h-4 w-4 relative overflow-hidden shrink-0"
                    loading="lazy"
                    alt=""
                    src="/logotwitter.svg"
                  />
                </div>
                <div className="rounded-md bg-secondary-500 overflow-hidden flex flex-row items-center justify-center p-2 cursor-pointer">
                  <img
                    className="h-4 w-4 relative overflow-hidden shrink-0"
                    loading="lazy"
                    alt=""
                    src="/logoinstagram.svg"
                  />
                </div>
                <div className="rounded-md bg-secondary-500 overflow-hidden flex flex-row items-center justify-center p-2 cursor-pointer">
                  <img
                    className="h-4 w-4 relative overflow-hidden shrink-0"
                    loading="lazy"
                    alt=""
                    src="/logoyoutube.svg"
                  />
                </div>
              </div> */}
              {user.id !== pageUser?.id ?
              isFollowing ?
              (
                <Button variant="outlined" startIcon={<PersonAddIcon />} onClick={() => handleFollow()}>
                {t('user-unfollow')}
              </Button>
              ) :
              (
                      <Button variant="outlined" startIcon={<PersonAddIcon />} onClick={() => handleFollow()}>
                      {t('user-follow')}
                    </Button>
              ) : null
              }



            </div>
          </div>
        </div>
  );
};

export default Header;
