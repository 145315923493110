import { FunctionComponent, useState, useEffect, useContext, useRef } from "react";
import { useTranslation } from "react-i18next";
import { Button } from "@mui/material";
import EmailIcon from '@mui/icons-material/Email';
import InstagramIcon from '@mui/icons-material/Instagram';
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import { IconButton } from '@mui/material';
import { motion } from 'framer-motion'
import { Heart, Lightbulb, Calendar, ChevronLeft, ChevronRight, CircleHelp } from 'lucide-react'

import { useNavigate, useLocation } from "react-router-dom";
import { FaAngleDown } from 'react-icons/fa';

import Navbar from "../components/main/Navbar";
// import Header from "../components/blog/Header";
import KoFiButton from "../components/landing/KoFi";
import HorizontalBanner from "../components/landing/HorizontalBanner";
import LazyShow from "../components/landing/LazyShow";
import VideoPlayerList from "../components/landing/Interviews";
import FAQ from "../components/landing/FAQ";
import Footer from "../components/main/Footer";

import { AuthContext } from '../components/authentification/AuthContext';





const PillarItem = ({ title, description, icon, index, pillar }) => {

  const isEven = index % 2 === 0

  return (
    <motion.div
      initial={{ opacity: 0 }}
      whileInView={{ opacity: 1 }}
      transition={{ duration: 0.5 }}
      className={`flex flex-col md:flex-row ${isEven ? '' : 'md:flex-row-reverse'} items-center justify-center pt-4`}
    >
      <motion.div
        className="w-full md:w-1/2 p-4 md:p-8"
        initial={{ x: isEven ? -50 : 50, opacity: 0 }}
        whileInView={{ x: 0, opacity: 1 }}
        transition={{ duration: 0.5, delay: 0.2 }}
      >
        <div className="flex items-center mb-4">
          <div className="bg-gray-100 p-2 rounded-full mr-4 mt-[15px]">
            {icon}
          </div>
          <h2 className="text-2xl md:text-3xl font-bold text-primary">{title}</h2>
        </div>
        <p className="text-gray-600 text-base md:text-lg">{description}</p>
        <div className="w-full max-w-md relative mt-4 border-1 border-primary rounded-[10px]">
        <div className="flex flex-row gap-2 justify-start items-center p-5">
<a href="https://apps.apple.com/be/app/slostr/id6590637281?itscg=30200&itsct=apps_box_badge&mttnsubad=6590637281" style={{display: "inline-block"}}>
    <img src="https://toolbox.marketingtools.apple.com/api/v2/badges/download-on-the-app-store/white/en-us?releaseDate=1722902400" alt="Download on the App Store" style={{width: '160px', height: '46px', verticalAlign: 'middle', objectFit: 'contain'}} />
    </a>
    <a href="https://play.google.com/store/apps/details?id=com.slostr.Slostr&hl=en" style={{display: "inline-block"}}>
    <img src="./GetItOnGooglePlay_Badge_Web_color_English.png" alt="Download on the Play Store" style={{width: '143px', height: '41px', verticalAlign: 'middle', objectFit: 'contain'}} />
    </a>
</div>
<div className="absolute top-0 right-0 bg-primary text-white font-bold py-1 px-3 rounded-bl-lg rounded-tr-lg">
              BETA
            </div>
          </div>
      </motion.div>
      <motion.div
        className="w-full md:w-1/2"
        initial={{ scale: 0.9, opacity: 0 }}
        whileInView={{ scale: 1, opacity: 1 }}
        transition={{ duration: 0.5, delay: 0.4 }}
      >
        <PillarCarousel pillar={pillar} />
      </motion.div>
    </motion.div>
  )
}



export const PillarCarousel = ({ pillar }) => {
  const { t } = useTranslation();




  const pillarData = {
    "1": [
      {
        id: "01",
        videoSrc:
          "https://slostr.s3.fr-par.scw.cloud/slostr-imgs/slostr/inspire-friends.mov",
        thumbnail:
          "https://slostr.s3.fr-par.scw.cloud/slostr-imgs/slostr/inspire-friends.jpg",
        description: t("pillar-inspire-1"),
      },
      {
        id: "02",
        videoSrc:
          "https://slostr.s3.fr-par.scw.cloud/slostr-imgs/slostr/inspire-map.mov",
        thumbnail:
          "https://slostr.s3.fr-par.scw.cloud/slostr-imgs/slostr/inspire-map.jpg",
        description: t("pillar-inspire-2"),
      },
      {
        id: "03",
        videoSrc:
          "https://slostr.s3.fr-par.scw.cloud/slostr-imgs/slostr/inspire-profile.mov",
        thumbnail:
          "https://slostr.s3.fr-par.scw.cloud/slostr-imgs/slostr/inspire-profile.jpg",
        description: t("pillar-inspire-3"),
      },
    ],
    "2": [
      {
        id: "01",
        videoSrc:
          "https://slostr.s3.fr-par.scw.cloud/slostr-imgs/slostr/practical-trains.mov",
        thumbnail:
          "https://slostr.s3.fr-par.scw.cloud/slostr-imgs/slostr/practical-trains.jpg",
        description: t("pillar-practical-1"),
      },
      {
        id: "02",
        videoSrc:
          "https://slostr.s3.fr-par.scw.cloud/slostr-imgs/slostr/practical-climbing.mov",
        thumbnail:
          "https://slostr.s3.fr-par.scw.cloud/slostr-imgs/slostr/practical-climbing.jpg",
        description: t("pillar-practical-2"),
      },
      {
        id: "03",
        videoSrc:
          "https://slostr.s3.fr-par.scw.cloud/slostr-imgs/slostr/practical-pois.mov",
        thumbnail:
          "https://slostr.s3.fr-par.scw.cloud/slostr-imgs/slostr/practical-pois.jpg",
        description: t("pillar-practical-3"),
      },
    ],
    "3": [
      { id: "01", videoSrc: null, description: null },
      { id: "02", videoSrc: null, description: null },
      { id: "03", videoSrc: null, description: null },
    ],
  };

  const [currentIndex, setCurrentIndex] = useState(0)
  const contents = pillarData[pillar]
  const videoRefs = useRef([])

  useEffect(() => {
    try {
      videoRefs.current.forEach((video, index) => {
        if (video) {
          if (index === currentIndex) {
            video.play().catch(() => {}); // Catch and ignore play errors
          } else {
            video.pause();
            video.currentTime = 0;
          }
        }
      });
    } catch (error) {
      // Ignore errors silently
    }
  }, [currentIndex]);


  const handleCardClick = (index: number) => {
    setCurrentIndex(index)
  }

  return (
    <section className="w-full max-w-xs mx-auto md:max-w-sm relative pb-[90px] md:pb-0">
      <div className="relative">
        {contents.map((content, index) => (
          <motion.div
            key={content.id}
            className={`
              h-full max-h-[300px] md:max-h-[500px] w-[50%] md:w-3/5 absolute inset-0 transition-all duration-500 ease-[cubic-bezier(0.25,1,0.5,1)]
              ${getPositionClasses(index, currentIndex, contents.length)}
            `}
            style={{
              zIndex: getZIndex(index, currentIndex, contents.length),
            }}
            onClick={() => handleCardClick(index)}
            initial={false}
            animate={{
              scale: index === currentIndex ? 1 : 0.9,
              x: getTranslateX(index, currentIndex, contents.length),
            }}
            transition={{ duration: 0.5 }}
          >
            {content.videoSrc ? (
            <div className="bg-white rounded-lg shadow-2xl cursor-pointer transition-transform hover:scale-105  flex flex-col  overflow-hidden">
            <div className="relative flex-grow">
            <video
    ref={(el) => (videoRefs.current[index] = el)}
    className="w-full h-full object-cover"
    src={content.videoSrc}
    loop
    muted
    playsInline
    poster={content.thumbnail}
  />

              <div className="bg-white relative bottom-[10px] md:bottom-[14px] h-4"></div>
            </div>
            <div className="p-4 mt-[-5px] md:mt-[-15px] pt-0">
              <p className="text-sm text-gray-600 text-center">{content.description}</p>
            </div>
          </div>
            ) : (
              <div className="bg-white rounded-lg shadow-2xl cursor-pointer transition-transform hover:scale-105  flex flex-col  overflow-hidden w-36 h-36">
              <div className="relative flex-grow ">
              <div
                className="w-full h-full object-cover"
              />
            </div>
            </div>
            )}

          </motion.div>
        ))}
      </div>
    </section>
  )
}

function getPositionClasses(index: number, currentIndex: number, totalItems: number): string {
  const diff = (index - currentIndex + totalItems) % totalItems
  return diff === 0 ? 'relative' : 'absolute'
}

function getZIndex(index: number, currentIndex: number, totalItems: number): number {
  const diff = (index - currentIndex + totalItems) % totalItems
  return totalItems - Math.abs(diff)
}

function getTranslateX(index: number, currentIndex: number, totalItems: number): string {
  const diff = (index - currentIndex + totalItems) % totalItems

  switch (diff) {
    case 0:
      return '50%'
    case 1:
    case -2:
      return '100%'
    case -1:
    case 2:
      return '0%'
    default:
      return '0%'
  }
}




const LandingPage: FunctionComponent = () => {
  const { t } = useTranslation();

  const authContext = useContext(AuthContext);
  const { user, logout } = authContext || {};

  const [isActive, setIsActive] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();
  const [isLoading, setIsLoading] = useState(true);
  const [bgImage, setBgImage] = useState('');

  const updateBackgroundImage = () => {
    if (window.innerWidth <= 768) {
      setBgImage('url("https://slostr.s3.fr-par.scw.cloud/slostr-imgs/slostr/about-us-phone.jpeg")');
    } else {
      setBgImage('url("https://slostr.s3.fr-par.scw.cloud/slostr-imgs/slostr/about-us.jpeg")');
    }
  };

  useEffect(() => {
    updateBackgroundImage(); // Set initial background image
    window.addEventListener('resize', updateBackgroundImage); // Update background image on resize

    return () => {
      window.removeEventListener('resize', updateBackgroundImage); // Cleanup event listener
    };
  }, []);
  useEffect(() => {
    const hash = location.hash;

    if (hash) {
      // Set a delay before scrolling
      setTimeout(() => {
        const element = document.getElementById(hash.substring(1));
        if (element) {
          element.scrollIntoView({ behavior: 'smooth' });
        }
        setIsLoading(false); // Set loading to false after scrolling
      }, 250); // 2 seconds delay
    } else {
      setIsLoading(false); // Set loading to false if no hash
    }
  }, [location]);

  useEffect(() => {
    // Add the active class after a delay to trigger the animation
    const timer = setTimeout(() => {
      setIsActive(true);
    }, 10); // Delay to ensure the component is fully mounted

    return () => clearTimeout(timer); // Clean up the timer on unmount
  }, []);


  const scrollToNextSection = (id: any) => {
    const nextSection = document.getElementById(id);
    if (nextSection) {
        nextSection.scrollIntoView({ behavior: 'smooth' });
    }
};

const whatsappNumber = "+33 7 66 52 21 63";




const pillars = [
  {
    title: t("slostr-inspire"),
    description: t("slostr-inspire-info"),
    icon: <Heart className="w-8 h-8 text-red-500" />,
    pillar: '1' as const,
  },
  {
    title: t("slostr-practical"),
    description: t("slostr-practical-info"),
    icon: <Lightbulb className="w-8 h-8 text-yellow-500" />,
    pillar: '2' as const,
  },
  // {
  //   title: t("slostr-to-come"),
  //   description: t("slostr-to-come-info"),
  //   icon: <CircleHelp className="w-8 h-8 text-blue-500" />,
  //   pillar: '3' as const,
  // },
  // {
  //   title: t("slostr-events"),
  //   description: t("slostr-events-info"),
  //   icon: <Calendar className="w-8 h-8 text-blue-500" />,
  //   pillar: '3' as const,
  // },
];



  return (
    <>
            <Navbar scrollToNextSection={scrollToNextSection}/>
            <div className="w-full relative overflow-hidden gap-[30px]"
      style={{backgroundImage: 'linear-gradient(120deg, #fffff 0%, #fffff 100%)'}}>

        {/* Hero */}
        <div className="relative w-full h-[calc(100vh)] flex items-center justify-center">
          <>
                <video className="absolute top-0 left-0 w-full h-full object-cover" autoPlay loop muted>
                <source src="https://slostr.s3.fr-par.scw.cloud/slostr-imgs/slostr/0728.webm" type="video/webm" />
                </video>
            </>
            <div className="absolute top-0 left-0 w-full h-full bg-black bg-opacity-40"></div>
            <div className="relative z-10 text-center text-white flex flex-col">

            <div className="w-full max-w-[400px] self-center">
  <svg version="1.0" xmlns="http://www.w3.org/2000/svg" width="100%" height="100%" viewBox="0 0 600.000000 283.000000" preserveAspectRatio="xMidYMid meet"
        className={isActive ? 'active' : ''}
        >

<g transform="translate(0.000000,283.000000) scale(0.100000,-0.100000)" fill="#FFFFFF" stroke="none">
<path d="M1500 1665 l0 -725 85 0 85 0 0 725 0 725 -85 0 -85 0 0 -725z" className="svg-elem-1"></path>
<path d="M4467 2204 c-4 -4 -7 -61 -7 -126 l0 -118 -90 0 -90 0 0 -65 0 -65
89 0 89 0 5 -357 c5 -393 8 -417 63 -482 49 -56 103 -75 207 -69 45 2 88 8 95
12 7 4 12 32 12 73 l0 65 -52 -6 c-62 -8 -115 14 -135 54 -9 19 -13 114 -13
368 l0 342 95 0 95 0 0 65 0 65 -95 0 -94 0 -3 123 -3 122 -80 3 c-45 1 -84 0
-88 -4z" className="svg-elem-2"></path>
<path d="M2425 2184 c-379 -61 -664 -380 -669 -749 -1 -131 21 -220 90 -361
89 -180 265 -396 472 -581 141 -125 198 -146 280 -104 94 48 358 316 484 493
235 327 270 636 104 913 -109 182 -284 317 -481 370 -61 16 -226 27 -280 19z
m281 -102 c216 -67 402 -247 471 -456 37 -111 38 -274 4 -386 -53 -175 -194
-382 -396 -585 -215 -216 -250 -235 -336 -174 -68 48 -327 314 -396 407 -113
149 -181 284 -204 403 -17 87 -7 270 20 344 87 243 301 422 561 469 51 9 217
-4 276 -22z" className="svg-elem-3"></path>
<path d="M2395 2015 c-245 -54 -433 -254 -472 -502 -14 -88 -14 -104 0 -177
42 -209 190 -376 397 -447 89 -30 260 -38 350 -15 114 28 221 89 297 170 80
84 114 143 142 246 36 128 21 267 -43 400 -41 85 -175 223 -263 268 -125 65
-275 86 -408 57z m25 -200 c19 -9 51 -33 71 -51 l35 -34 21 29 c57 76 191 103
288 57 75 -35 156 -128 180 -206 7 -23 6 -23 -30 9 -81 71 -172 89 -251 50
-73 -35 -104 -112 -70 -177 23 -45 59 -71 144 -106 105 -44 136 -67 162 -121
30 -63 22 -85 -63 -170 -83 -82 -164 -127 -272 -150 -88 -18 -132 -18 -220 0
-139 29 -299 136 -349 233 -16 31 -16 35 2 75 27 59 68 93 153 128 131 53 169
92 169 172 0 54 -27 92 -84 118 -81 37 -169 19 -238 -49 -32 -30 -38 -33 -38
-17 0 61 114 190 193 219 53 20 150 15 197 -9z m-130 -245 c33 -33 26 -76 -17
-99 -43 -22 -101 24 -85 69 17 50 67 65 102 30z m550 0 c36 -36 21 -89 -30
-105 -41 -13 -86 34 -74 80 12 46 69 60 104 25z" className="svg-elem-4"></path>
<path d="M2483 1485 c-18 -8 -38 -26 -45 -40 -10 -22 -8 -30 8 -52 37 -49 103
-54 144 -13 26 26 25 62 -2 88 -31 28 -67 34 -105 17z" className="svg-elem-5"></path>
<path d="M2317 1343 c-32 -31 46 -94 141 -114 114 -23 267 29 267 91 0 32 -30
38 -58 12 -33 -31 -71 -42 -145 -42 -66 0 -141 24 -156 49 -8 12 -39 15 -49 4z" className="svg-elem-6"></path>
<path d="M728 1961 c-245 -64 -337 -324 -165 -465 56 -46 93 -61 259 -106 205
-56 246 -84 255 -171 12 -118 -142 -191 -312 -148 -63 16 -120 68 -140 129
l-17 50 -85 0 -85 0 7 -47 c19 -126 126 -231 275 -269 91 -23 253 -15 334 16
77 29 169 117 185 175 17 62 13 154 -8 200 -41 88 -151 152 -346 199 -182 45
-235 81 -235 159 0 91 75 147 198 147 116 0 195 -51 217 -142 7 -28 8 -28 91
-28 l84 0 0 36 c0 51 -45 138 -93 180 -57 49 -107 73 -186 90 -86 18 -151 17
-233 -5z" className="svg-elem-7"></path>
<path d="M3657 1964 c-183 -44 -292 -187 -258 -340 25 -112 122 -179 341 -235
203 -52 255 -88 255 -179 0 -90 -66 -140 -196 -148 -136 -8 -232 47 -259 147
l-11 41 -86 0 -86 0 6 -45 c24 -171 191 -285 417 -285 100 1 156 12 225 46 99
50 153 125 162 226 16 171 -80 258 -366 333 -195 52 -231 77 -231 163 0 52 32
99 83 122 53 24 162 26 215 4 52 -21 101 -72 113 -116 l10 -38 84 0 85 0 0 28
c0 86 -76 194 -168 241 -94 48 -225 62 -335 35z" className="svg-elem-8"></path>
<path d="M5372 1965 c-53 -16 -115 -58 -139 -94 l-18 -26 -5 55 -5 55 -82 3
-83 3 0 -511 0 -510 85 0 85 0 0 360 c0 391 0 388 57 441 52 49 92 63 180 63
l83 1 0 81 c0 76 -2 82 -22 88 -36 9 -89 6 -136 -9z" className="svg-elem-9"></path>
</g>
</svg>
  </div>

                {/* <h1 className="text-4xl md:text-6xl lg:text-7xl font-bold">{t('slostr-title')}</h1> */}
                <p className="mt-4 text-xl pl-2 pr-2 md:text-2xl font-[100]">{t('slostr-heading')}</p>

            </div>
            <div className="absolute bottom-8 left-1/2 transform -translate-x-1/2 cursor-pointer" onClick={()=> scrollToNextSection('product-showcase')}>
                    <div className="text-[20px] animate-bounce text-white">
                        <FaAngleDown />
                    </div>
                    <div className="text-[20px] animate-bounce text-white mt-[-5px]">
                        <FaAngleDown />
                    </div>
                </div>
        </div>


        <div className="py-12 px-4 sm:px-6 lg:px-8">
        <div id="product-showcase" className="max-w-7xl mx-auto flex flex-col gap-0">
          <h1 className="text-4xl font-bold text-center text-primary mb-4">{t('slostr-explained')}</h1>
          <h3 className="max-w-[650px] self-center text-xl text-center text-slate-400 italic font-normal mb-12">{t('slostr-explained-info-1')} <span className="font-bold">slo</span>w <span className="font-bold">s</span>ustainable <span className="font-bold">tr</span>{t('slostr-explained-info-2')}</h3>
          <div className="space-y-24">
            {pillars.map((pillar, index) => (
          <PillarItem
          key={index}
          title={pillar.title}
          description={pillar.description}
          icon={pillar.icon}
          index={index}
          pillar={pillar.pillar}
        />
                    ))}
          </div>
        </div>
      </div>


        {/* Produtc */}

        {/* <LazyShow>
        <div id="product-showcase" className="">
          <div className="w-full bg-gradient-to-b from-white to bg-white py-16">
          <div className="max-w-4xl w-full mx-auto">
            <h2 className="text-center font-display text-xl font-bold tracking-tight text-primary md:text-3xl">
                {t('slostr-explained')}
            </h2>
            <p className="text-center font-display text-lg font-light tracking-tight text-slate-500 md:text-xl mb-8">
                {t('slostr-explained-info')}
            </p>
            <ul className="mt-8 grid grid-cols-1 gap-6 text-center text-slate-700 md:grid-cols-2 lg:grid-cols-2 xl:grid-cols-2 list-none p-3">
                {features.map((feature, index) => (
                    <li
                        key={index}
                        className="relative rounded-xl px-3 py-6 shadow-md hover:shadow-lg hover:scale-105 transition duration-300 bg-cover bg-center text-slate-700"
                        // style={{backgroundImage: `url(${feature.backgroundImage})`}}
                    >
                        <div className="absolute inset-0 rounded-xl"></div>
                        <div className="relative z-10 flex flex-col items-center justify-center">
                            <img src={feature.icon} alt={feature.title} className="h-10 w-10 mb-3"
                            style={{filter: 'invert(24%) sepia(9%) saturate(1649%) hue-rotate(175deg) brightness(94%) contrast(92%)'}}
                            />
                            <h3 className="font-display font-medium text-xl">{feature.title}</h3>
                            <p className="mt-1.5 text-base leading-6">{feature.description}</p>
                        </div>
                    </li>
                ))}
            </ul>
            </div>
            </div>
            <div className="pt-6 text-center justify-center flex flex-col items-center bg-gradient-to-b from-white to bg-white">
        <h3 className="font-display text-xl font-light text-secondary md:text-2xl">{t('slostr-not-title')}</h3>
        <p className="text-slate-500 text-lg mt-4 max-w-[600px]">
        {t('slostr-not-description')}
        </p>

    </div>
            <h2 className="text-center font-display text-xl font-bold tracking-tight text-primary md:text-2xl mt-20">
                {t('slostr-examples')}
            </h2>
        <HorizontalBanner />
<div className="flex justify-center items-center">
<Button variant="contained" size="large" color="secondary"
style={{borderRadius: '25px'}}
            //  endIcon={<OpenInNewIcon />}
            //  onClick={handleClickOpen}
            onClick={()=>navigate('/map')}
>
            {t("subscribe-long")}
                </Button>
</div>

        </div>


        </LazyShow> */}
    <motion.div
      initial={{ opacity: 0 }}
      whileInView={{ opacity: 1 }}
      transition={{ duration: 0.5 }}
      id="interviews"
    >
            <motion.div
        initial={{ x: -50, opacity: 0 }}
        whileInView={{ x: 0, opacity: 1 }}
        transition={{ duration: 0.5, delay: 0.2 }}
        className="flex align-center flex-col"
      >
      <VideoPlayerList />
      </motion.div>
    </motion.div>

        <LazyShow>
        <div id="about-us" className="relative bg-cover bg-center h-screen mt-10" style={{ backgroundImage: bgImage }}>
        <div className="absolute inset-0 bg-black bg-opacity-50 flex items-start pt-24 justify-center">
        <h2 className="text-4xl md:text-5xl lg:text-6xl font-extrabold text-white text-center">
        {t('slostr-who-are-we')}
                </h2>
    </div>
    <div className="absolute bottom-0 left-0 right-0 mb-8 mx-auto max-w-3xl p-4 text-white text-center">
        <p className="text-base md:text-lg leading-6 md:leading-7">
{t('slostr-who-are-we-info')}
        </p>
    </div>
</div>


        </LazyShow>


        <LazyShow>
        <div id="next-steps" className="bg-gray-100 py-16">
    <div className="mx-auto max-w-6xl px-4 sm:px-6 lg:px-8">
        <h2 className="text-center font-display text-3xl font-bold tracking-tight text-primary md:text-4xl mb-8">
            {t('slostr-next')}
        </h2>
        <p className="text-center text-lg leading-7 text-gray-700 mb-8">
        {t('slostr-next-header')}
        </p>
        <div className="grid grid-cols-1 md:grid-cols-3 gap-8 text-center">
            <div className="bg-white px-6 py-8 rounded-lg shadow-lg">
                <h3 className="text-xl font-semibold mb-2 text-slate-700">{t('slostr-next-features')}</h3>
                <p className="text-base leading-6 text-gray-600">
                {t('slostr-next-features-info')}
                </p>
            </div>
            <div className="bg-white px-6 py-8 rounded-lg shadow-2xl relative flex justify-start items-center flex-col border-2 border-solid border-[#f88378]">
                <h3 className="text-xl font-semibold mb-2 text-slate-700">{t('slostr-next-donations')}</h3>
                <p className="text-base leading-6 text-gray-600">
                {t('slostr-next-donations-info')}
                </p>
                <div className="flex justify-center space-x-4 mt-4">
                {/* <KoFiButton /> */}
                {/* <Button onClicke={r}>
                  {t('feedback-board')}
                </Button> */}
                </div>
            </div>
            <div className="bg-white px-6 py-8 rounded-lg shadow-lg">
                <h3 className="text-xl font-semibold mb-2 text-slate-700">{t('slostr-next-feedback')}</h3>
                <p className="text-base leading-6 text-gray-600">
                {t('slostr-next-feedback-info')}
                </p>
                <div className="flex justify-center space-x-4 mt-4">
        <IconButton href={`https://wa.me/${whatsappNumber}`} target="_blank" color="primary">
          <WhatsAppIcon />
        </IconButton>
        <IconButton href="mailto:vladimir.fayt@slostr.com" color="primary">
          <EmailIcon />
        </IconButton>
        <IconButton href="https://www.instagram.com/goslostr" target="_blank" color="primary">
          <InstagramIcon />
        </IconButton>
        <IconButton href="https://www.linkedin.com/in/vladimir-fayt-6507a91a1/" target="_blank" color="primary">
          <LinkedInIcon />
        </IconButton>
      </div>
            </div>
        </div>
    </div>
    {/* <div className="flex justify-center items-center p-10 w-full">
    <img src="https://slostr.s3.fr-par.scw.cloud/slostr-imgs/slostr/app-showcase.png" alt="" className="sm:max-w-[400px]"/>
    </div> */}

<div className="flex flex-row gap-2 justify-center items-center p-10">
<a href="https://apps.apple.com/be/app/slostr/id6590637281?itscg=30200&itsct=apps_box_badge&mttnsubad=6590637281" style={{display: "inline-block"}}>
    <img src="https://toolbox.marketingtools.apple.com/api/v2/badges/download-on-the-app-store/white/en-us?releaseDate=1722902400" alt="Download on the App Store" style={{width: '160px', height: '46px', verticalAlign: 'middle', objectFit: 'contain'}} />
    </a>
    <a href="https://play.google.com/store/apps/details?id=com.slostr.Slostr&hl=en" style={{display: "inline-block"}}>
    <img src="./GetItOnGooglePlay_Badge_Web_color_English.png" alt="Download on the Play Store" style={{width: '143px', height: '41px', verticalAlign: 'middle', objectFit: 'contain'}} />
    </a>
</div>

</div>
        </LazyShow>

        <motion.div
      initial={{ opacity: 0 }}
      whileInView={{ opacity: 1 }}
      transition={{ duration: 0.5 }}
      id="faq"
    >
            <motion.div
        initial={{ x: -50, opacity: 0 }}
        whileInView={{ x: 0, opacity: 1 }}
        transition={{ duration: 0.5, delay: 0.2 }}
      >
      <FAQ />
      </motion.div>
    </motion.div>


        <Footer />
        {/* Inline styles for keyframes animation */}
        <style>
          {`
            @keyframes scroll-down {
              0%, 100% { transform: translate(-50%, -5px) rotate(45deg); opacity: 0; }
              50% { transform: translate(-50%, 0) rotate(45deg); opacity: 1; }
            }
          `}
        </style>
      </div>
    </>


    )
};

export default LandingPage;
