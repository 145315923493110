import * as React from 'react';
import Avatar from '@mui/material/Avatar';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import LinearProgress from '@mui/material/LinearProgress';
import Stack from '@mui/material/Stack';
import type { SxProps } from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import Co2Icon from '@mui/icons-material/Co2';
import { useTranslation } from 'react-i18next';

export interface TasksProgressProps {
  sx?: SxProps;
  value: number;
  title: string;
}

export function TasksProgress({ value, sx, title }: TasksProgressProps): React.JSX.Element {
  const { t } = useTranslation();

  return (
    <Card sx={sx}>
      <CardContent>
        <Stack spacing={2}>
          <Stack direction="row" sx={{ alignItems: 'flex-start', justifyContent: 'space-between' }} spacing={3}>
            <Stack spacing={1}>
              <Typography color="text.secondary" gutterBottom variant="overline">
                {title}
              </Typography>
              <Typography variant="h4">{value.toFixed(0)} / 2000 kgCO2eq</Typography>
            </Stack>
            <Stack spacing={1}>

            <Avatar className='!bg-primary ' sx={{ height: '56px', width: '56px' }}>
              <Co2Icon className='!text-[2.5rem]'/>
            </Avatar>
            </Stack>

          </Stack>
          <div>
            <LinearProgress value={value/2000 * 100} variant="determinate" />
          </div>
        </Stack>
        <a href="https://www.lowtrip.fr" target='_blank'>
        <div className='w-full mt-5 bg-whitesmoke-400 rounded-lg flex flex-row gap-2 justify-center items-end p-2 text-white shadow-lg text-base hover:scale-[0.99] transition'>
          {t('estimation-made')} <img src="/lowtrip.png" alt="" className='w-[55px]'/>
        </div>
        </a>
      </CardContent>
    </Card>
  );
}
