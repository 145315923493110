import React, { FunctionComponent, useState, useEffect, useContext } from "react";
import CircularProgress from '@mui/material/CircularProgress';
import { useTranslation } from 'react-i18next';
import PersonAddIcon from '@mui/icons-material/PersonAdd';
import { useLocation, useNavigate } from 'react-router-dom';
import { updateStravaUserAPI, updateWahooUserAPI } from '../utils/api/api';
import { AuthContext } from "../components/authentification/AuthContext";
import Navbar from "../components/main/Navbar";

const Divider = ({ width = "w-64" }) => {
  return (
    <div className={`w-full mb-4`}>
      <div
        className={`h-1 mx-auto bg-orange ${width} opacity-25 my-0 py-0 rounded-t mb-10`}
      ></div>
    </div>
  );
};

const TestUser: FunctionComponent<any> = ({ app }) => {
  const { t } = useTranslation();
  const authContext = useContext(AuthContext);
  const { user, isAuthenticated, fetchCurrentUser } = authContext || {};

  const [isUpdating, setIsUpdating] = useState(true);
  const [updateError, setUpdateError] = useState(true);
  const [callMade, setCallMade] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    const token = localStorage.getItem('token');
    if (!token) {
      setIsUpdating(false);
      navigate(`/login?redirect=${encodeURIComponent(location.pathname + location.search)}`);
      return;
    }

    const fetchUserAndUpdate = async () => {
      await fetchCurrentUser();
    };

    if (!user) {
      fetchUserAndUpdate();
    }
  }, []);

  const accountLinkUpdate = async () => {
    const params = new URLSearchParams(location.search);
    const code = params.get('code');

    if (user && code && !callMade) {
      setIsUpdating(true);

      const updateUserAPI = app !== 'wahoo' ? updateStravaUserAPI : updateWahooUserAPI;

      updateUserAPI(code)
        .then((response) => {
          console.log('User updated successfully:', response);
          setCallMade(true);
          setUpdateError(false);
        })
        .catch((error) => {
          console.error('Error updating user:', error);
          setUpdateError(true);
        })
        .finally(() => {
          setIsUpdating(false);
        });
    }
  };

  useEffect(() => {
    const delayUserCheck = setTimeout(() => {
      if (user && !callMade) {
        accountLinkUpdate();
      }
    }, 500); // Adjust the delay as needed

    return () => clearTimeout(delayUserCheck);
  }, [user]);

  if (isUpdating) {
    return (
      <div className="w-full relative bg-primary-contrast overflow-hidden flex flex-col items-center justify-center">
        <CircularProgress />
      </div>
    );
  }

  return (
    <div className="w-full relative bg-primary-contrast overflow-hidden flex flex-col items-center justify-start">
      <Navbar />
      <div className="w-full relative bg-primary-contrast flex flex-col gap-4 items-center justify-center text-center pt-[80px] h-[calc(100vh-80px)] max-w-[400px] font-roboto text-lg">
        <PersonAddIcon color="primary" className="!text-[80px]" />
        {updateError || !callMade ? (
          <>
            <h1>{t('account-link-failed')}</h1>
            <Divider />
            <p>{t('account-link-failed-description')}</p>
          </>
        ) : (
          <div>
            <h1>{t('account-linked-thanks')}</h1>
            <Divider />
            <p>{t('account-linked-description')}</p>
          </div>
        )}
      </div>
    </div>
  );
};

export default TestUser;
