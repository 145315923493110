import React, { FunctionComponent, useContext, useEffect, useState } from 'react';
import UserSettings from "../components/settings/UserSettings";
import Navbar from "../components/main/Navbar";
import Footer from "../components/main/Footer";
import { AuthContext } from '../components/authentification/AuthContext';

import { fetchFollowView } from '../utils/api/api';



const UserSettingsPage: FunctionComponent = () => {
  const authContext = useContext(AuthContext);
  const { user } = authContext || {};
  // const [community, setCommunity] = useState<CommunityType[]>([]);

  // useEffect(() => {
  //   const fetchFollowUserData = async () => {
  //     try {
  //       const data = await fetchFollowView();
  //       setCommunity(data)
  //     } catch (error) {
  //       console.error("Error fetching blog data:", error);
  //     }
  //   };

  //   fetchFollowUserData();
  // }, [user]);

  return (
    <div className="w-full relative bg-primary-contrast overflow-hidden flex flex-col items-center justify-start gap-[30px]">
    <Navbar />
    <div className="w-full relative bg-gray-25 flex flex-col items-center justify-start pt-[80px] px-0 pb-24 box-border gap-[20px] tracking-[normal] mq750:gap-[15px]">
      <UserSettings user={user}
      // community={community}
      />
    </div>
    <Footer />
    </div>
  );
};

export default UserSettingsPage;
