import React, { useState } from 'react';
import { Bar } from 'react-chartjs-2';
import { useTranslation } from 'react-i18next';

const transformDataToStack = (categoryData) => {
  return categoryData.map((item) => ({
    value: item.kgCO2eq,
    color: item.colors || '#000',
  }));
};

const prepareChartData = (co2_stats) => {
  const categoryOrder = ['trip', 'car', 'plane'];
  return categoryOrder
    .filter((category) => co2_stats[category])
    .map((category) => ({
      label: category.charAt(0).toUpperCase() + category.slice(1),
      stacks: calculateTotal(co2_stats[category.toLowerCase()]),
    }));
};

const calculateTotal = (data) => {
  return data.reduce((sum, item) => sum + item.kgCO2eq, 0).toFixed(1);
};

const StackedBarCO2 = ({ co2_stats }) => {
  const { t } = useTranslation();
  const stackData = prepareChartData(co2_stats);
  const totalTripCO2 = calculateTotal(co2_stats.trip || []);
  const averageEuropeanCO2 = 7250;

  const [selectedBar, setSelectedBar] = useState(null);

  const chartData = {
    labels: ['Trip'],
    datasets: [{
      data: [calculateTotal(co2_stats.trip)],
    }],
  };

  // Dynamically add "Car" if it exists
  if (co2_stats.car && co2_stats.car.length > 0) {
    chartData.labels.push('Car');
    chartData.datasets[0].data.push(calculateTotal(co2_stats.car));
  }

  // Dynamically add "Plane" if it exists
  if (co2_stats.plane && co2_stats.plane.length > 0) {
    chartData.labels.push('Plane');
    chartData.datasets[0].data.push(calculateTotal(co2_stats.plane));
  }

  console.log('chartData', chartData)

  const options = {
    responsive: true,
    scales: {
      x: { stacked: true },
      y: {
        stacked: true,
        ticks: {
          callback: (value) => `${value} kg`,
        },
      },
    },
    plugins: {
      tooltip: {
        callbacks: {
          label: (tooltipItem) => `${tooltipItem.raw} kgCO2eq`,
        },
      },
      legend: {
        display: false
    },
    },
    onClick: function(evt, element) {
      if (element.length > 0) {
        const index = element[0].index;
        setSelectedBar(stackData[index]);
      }
    }}

  return (
    <div style={{ padding: '30px', textAlign: 'center' }}>
      <h2>{t('co2-trip')}</h2>
      <Bar
        data={chartData}
        options={options}
        width={400}
        height={250}
      />
      <div style={{ marginTop: '20px', textAlign: 'left' }}>
        {Object.keys(co2_stats).map((category) => {
          const totalCO2 = calculateTotal(co2_stats[category]);
          const timesX = (totalCO2 / totalTripCO2).toFixed(1);
          return (
            <p key={category}>
              <strong>
                {category.charAt(0).toUpperCase() + category.slice(1)}:
              </strong>{' '}
              {totalCO2} kgCO2eq ({timesX}x)
            </p>
          );
        })}
      </div>
      <p style={{ color: 'green', marginTop: '10px' }}>
        {t('co2_savings', {
          savings: (
            ((calculateTotal(co2_stats['car'] || []) -
              totalTripCO2) /
              averageEuropeanCO2) *
            100
          ).toFixed(1),
        })}
      </p>
      {selectedBar && (
        <div style={{ marginTop: '20px', textAlign: 'left' }}>
          <h3>{selectedBar.label}</h3>
          {selectedBar.label.toLowerCase() === 'trip' && (
            <p>{t('co2_trip_explanation', 'This represents the CO2 emissions for your trip.')}</p>
          )}
          {selectedBar.label.toLowerCase() === 'car' && (
            <p>{t('co2_car_explanation', 'This represents the emissions if the same distance was done as a road trip with 2 people.')}</p>
          )}
          {selectedBar.label.toLowerCase() === 'plane' && (
            <p>{t('co2_plane_explanation', 'This represents the emissions if the same distance was covered by plane.')}</p>
          )}
          {co2_stats[selectedBar.label.toLowerCase()].map((entry, idx) => (
            <div key={idx} style={{ display: 'flex', alignItems: 'center', margin: '5px 0' }}>
              <div
                style={{
                  width: `${(entry.kgCO2eq /
                    calculateTotal(co2_stats[selectedBar.label.toLowerCase()])) *
                    60}%`,
                  height: '20px',
                  backgroundColor: entry.colors,
                  borderRadius: '5px',
                }}
              ></div>
              <span style={{ marginLeft: '10px' }}>{entry.NAME} - {entry['Mean of Transport']}</span>
              <span style={{ marginLeft: '10px', color: 'gray' }}>
                {entry.kgCO2eq.toFixed(1)} kgCO2eq
              </span>
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

export default StackedBarCO2;
