import { FunctionComponent, useState, useEffect, useContext } from "react";
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import Skeleton from '@mui/material/Skeleton';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import AddIcon from '@mui/icons-material/Add';
import PersonIcon from '@mui/icons-material/Person';
import ExploreIcon from '@mui/icons-material/Explore';
import { useNavigate } from 'react-router-dom';

import Navbar from "../components/main/Navbar";
import Header from "../components/blog/Header";
import Content from "../components/blog/ContentProfile";
import Footer from "../components/main/Footer";
import Dashboard from "../components/dashboard/page";

import { fetchBlogByAuthor, fetchUser } from '../utils/api/api';
import { AuthContext } from '../components/authentification/AuthContext';



const MunicipalPage: FunctionComponent = () => {
  const { t } = useTranslation();
  const authContext = useContext(AuthContext);
  const { user } = authContext || {};
  const navigate = useNavigate();

    const [blogs, setBlogs] = useState([]);
    const [pageUser, setPageUser] = useState<any>(null);
    const { authorId } = useParams();
    const [statsOpen, setStatsOpen] = useState<any>(false);

    const [statCO2, setStatCO2] = useState<any>(null);
    const [statTransport, setStatTransport] = useState<any>(null);
    const [statCO2All, setStatCO2All] = useState<any>(null);


    useEffect(() => {
      const fetchBlogData = async () => {
        try {
          const data = await fetchBlogByAuthor(authorId);
          const sortedData = data.sort((a: any, b: any) => b.id - a.id);
          setBlogs(sortedData);
        } catch (error) {
          console.error('Error fetching blog data:', error);
        }
        try {
          const data = await fetchUser(authorId);
          setPageUser(data);
        } catch (error) {
          console.error('Error fetching blog data:', error);
        }
      };

      fetchBlogData();
    }, [authorId]);

useEffect(() => {
  if (!blogs || !pageUser || !authorId) return
  if (pageUser.id === user.id && blogs.length > 0) {

    const initialStatsSum = {
      bus: { distance: 0 },
      car: { distance: 0 },
      run: { ascent: 0, descent: 0, distance: 0 },
      bike: { ascent: 0, descent: 0, distance: 0 },
      boat: { distance: 0 },
      sail: { distance: 0 },
      hike: { ascent: 0, descent: 0, distance: 0 },
      moto: { distance: 0 },
      hitchhike: { distance: 0 },
      train: { distance: 0 }
    };

    let statsSum: any = {...initialStatsSum};
    let co2Sum = 0;
    let co2Details: any = {};

    blogs.forEach((item: any) => {
      let blogCO2 = 0
      // Sum up stats
      if (item.stats) {
        Object.keys(item.stats).forEach(key => {
          if (statsSum[key]) {
            Object.keys(item.stats[key]).forEach(subKey => {
              statsSum[key][subKey] += item.stats[key][subKey];
            });
          }
        });
      }

      // Sum up co2_stats
      if (item.co2_stats) {
        console.log(item.co2_stats)
        console.log(item.co2_stats.trip)
        const co2Stats = item.co2_stats.trip;
        co2Stats.forEach((stat: any) => {
          // const transport = stat['Mean of Transport'];
          // const name = stat['NAME'];
          // const key = `${transport}-${name}`;

          // if (!co2Details[key]) {
          //   co2Details[key] = {
          //     transport: transport,
          //     name: name,
          //     kgCO2eq: 0
          //   };
          // }
          // co2Details[key].kgCO2eq += stat.kgCO2eq;
          co2Sum += stat.kgCO2eq;
          blogCO2 += stat.kgCO2eq;

        });
        co2Details[item.title] = {kgCO2eq: blogCO2}
      }
    });
    setStatCO2(co2Sum)
    setStatTransport(statsSum)
    setStatCO2All(co2Details)

}

}, [authorId, blogs, pageUser]);

  return (
    <div className="w-full relative bg-primary-contrast overflow-hidden min-h-screen flex flex-col justify-between">
      <Navbar />
      <section className="w-full min-h-screen flex flex-col items-center justify-start pt-[90px] px-5 box-border gap-[48px] max-w-full text-left text-xl text-secondary-800 "
      style={{backgroundImage: 'linear-gradient(120deg, #fdfbfb 0%, #ebedee 100%)'}}>
        {(blogs && pageUser) ? (
<>
<Header
      user={pageUser}
      setStatsOpen={setStatsOpen}
      statsOpen={statsOpen}
      />
      {(statsOpen && blogs.length > 0) ? (
          <Dashboard
          statsSum={statTransport}
          statCO2={statCO2}
          statCO2All={statCO2All}
          />
      ) : (
  blogs.length > 0 ? (
    <div className="flex flex-col items-start justify-center max-w-full text-2xl">
<div className="flex flex-row items-start justify-start max-w-full">
            <h1 className="text-start relative text-inherit leading-[28px] font-bold font-inherit inline-block max-w-full ">
            {t('author-latest')}
            </h1>
          </div>
          <Content
          blogs={blogs}
          />
        </div>
  ) : (
    pageUser.id === user.id ? (
<div className="w-full flex flex-col gap-10 items-center justify-center">

<h1 className="text-center relative text-inherit leading-[28px] font-bold font-inherit inline-block max-w-full ">
            {t('author-empty')}
            </h1>

<Button variant="outlined" startIcon={<AddIcon />} size="large" sx={{width: '200px'}} onClick={()=>navigate('/create-blog-post')}>
        {t("add-new-trip")}
      </Button>

      <Button variant="outlined" startIcon={<PersonIcon />} size="large" sx={{width: '200px'}} onClick={()=>navigate('/settings')}>
        {t("update-account")}
      </Button>

      <Button variant="outlined" startIcon={<ExploreIcon />} size="large" sx={{width: '200px'}} onClick={()=>navigate('/explore')}>
        {t("explore-latest-trips")}
      </Button>

</div>
    ) : null

  )

      )}
        </>
        ) : (
<>
<Skeleton className="w-full md:w-2/3" variant="rounded" height={300} />
<div className="w-full flex flex-col md:flex-row items-center justify-center gap-4">
{/* <Grid className="w-full flex items-center" container> */}
      {Array.from(new Array(3)).map((item, index: any) => (
        <Box key={index} sx={{ width: 250, my: 5 }}>

            <Skeleton variant="rectangular" width={250} height={200} />
            <Box sx={{ pt: 0.5 }}>
              <Skeleton />
              <Skeleton width="60%" />
            </Box>
        </Box>
      ))}
    {/* </Grid> */}
    </div>

</>
        )}

      </section>
      <Footer />
    </div>
  );
};

export default MunicipalPage;
