// BlogWritingPage.tsx
import React, { FunctionComponent, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import CircularProgress from "@mui/material/CircularProgress";
import { useTranslation } from 'react-i18next';


import WritingContent from "../components/municipality/WritingContent";
import { fetchMunicipalityRouteById } from "../utils/api/api";
import Navbar from "../components/main/Navbar";
import Footer from "../components/main/Footer";




const MunicipalWritingPage: FunctionComponent = () => {
  const { t } = useTranslation();

  const { id } = useParams<{ id: string }>();
  const [municipalityRoute, setMunicipalityRoute] = useState<any>(null);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  useEffect(() => {
    if (id) {
      setIsLoading(true); // Start loading
      fetchMunicipalityRouteById(id)
        .then((fetchedRoute) => {
          setMunicipalityRoute(fetchedRoute);
        })
        .finally(() => {
          setIsLoading(false); // Finish loading
        });
    }
  }, [id]);

  return (
    <div className="w-full relative bg-primary-contrast overflow-hidden flex flex-col items-center justify-start">
      <Navbar />
      <div className="w-full relative bg-white overflow-auto flex flex-col items-center justify-center pt-[80px] pb-[120px]">
        <div className="w-[736px] flex flex-col items-center justify-start py-0 px-5 box-border max-w-full mq450:gap-[25px] mq725:gap-[50px]">
          {isLoading ? (
            // Display a loader or a placeholder component while data is being fetched
            <div className="spinner">
            <CircularProgress />
            {t('loading')}
          </div>
          ) : (
            // Once data is fetched or if creating a new blog (id is undefined), render the BlogWritingContent component
            <WritingContent route={municipalityRoute} />
          )}
        </div>
      </div>
      <Footer/>
    </div>
  );
};

export default MunicipalWritingPage;
