import React, {
  FunctionComponent,
  useState,
  useEffect,
  useContext,
  useRef,
} from "react";
import Button from "@mui/material/Button";
import { styled } from "@mui/material/styles";
import Tooltip from "@mui/material/Tooltip";
import { useNavigate } from "react-router-dom";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import TextField from "@mui/material/TextField";
// @ts-ignore
import * as toGeoJSON from "@mapbox/togeojson";
// @ts-ignore
import simplify from "simplify-geojson";
import Slider from "@mui/material/Slider";
import CircularProgress from "@mui/material/CircularProgress";
import { useTranslation } from 'react-i18next';

import MultiInput from "../inputs/Multiinput";
import TextEditor from "../blog/TextEditor";
// import MapboxMapEdit from "../map/MapEdit";

import { updateMunicipalityRoute, createMunicipalityRoute, fetchMunicipalActivities } from "../../utils/api/api";
import { ActivityOptionType } from "../../types";
import {
  Feature,
  FeatureCollection,
  GeoJsonProperties,
  Geometry,
  LineString,
} from "geojson";
import { AuthContext } from "../authentification/AuthContext";

const Input = styled("input")({
  display: "none",
});

const WritingContent: FunctionComponent<any> = ({ route }) => {
  const { t } = useTranslation();
  const authContext = useContext(AuthContext);
  const { user } = authContext || {};
  const [blogContent, setBlogContent] = useState(route?.content || "");
  const [title, setTitle] = useState(route?.title || "");
  const [imageURL, setImageURL] = useState(route?.header_image_url || "");
  const [imageCredit, setImageCredit] = useState(route?.header_image_attrib || "");
  const [isFormValid, setIsFormValid] = useState<boolean>(false);
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState<boolean | null>(false);
  const [activityOptions, setActivityOptions] = useState<ActivityOptionType[]>(
    []
  );
  const [selectedActivities, setSelectedActivities] = useState<number[]>([]);
  const [lineStringFeature, setLineStringFeature] = useState<Geometry | null>(
    null
  );
  const [mapFeatures, setMapFeatures] = useState<FeatureCollection | null>(
    null
  );


  const handleTitleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setTitle(event.target.value);
  };
  const handleImageURLChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setImageURL(event.target.value);
  };
  const handleImageCreditChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setImageCredit(event.target.value);
  };

  useEffect(() => {
    fetchMunicipalActivities()
      .then((fetchedActivities) => {
        setActivityOptions(fetchedActivities);
      })
      .catch((error) => {
        console.error("Error fetching activities:", error);
        const customEvent = new CustomEvent("apiError", {
          detail: error.response,
        });
        window.dispatchEvent(customEvent);
      });

  }, []);



  useEffect(() => {
    if (route) {
      setTitle(route.title || "");
      setBlogContent(route.content || "");
      if (route.geometry) {
        setLineStringFeature(route.geometry);

        const feature: Feature<Geometry, GeoJsonProperties> = {
          type: "Feature",
          properties: {},
          geometry: route.geometry,
        };
        setMapFeatures({
          type: "FeatureCollection",
          features: [feature],
        });
      }
    }
  }, [route, user]);

  useEffect(() => {
    const isTitleValid = title.length > 0 && title.length <= 255;
    const isRouteValid = lineStringFeature ? true : false;
    const isActivityValid = selectedActivities.length > 0;
    setIsFormValid(
      isTitleValid && isRouteValid && isActivityValid
    );
  }, [title, lineStringFeature, selectedActivities]);

  const handleActivitiesChange = (activities: number[]) => {
    setSelectedActivities(activities);
  };

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setIsLoading(true);

    const selectedActivityValues = selectedActivities.map((id) => id);

    try {
      const routeData = {
        title: title,
        content: blogContent,
        activities: selectedActivityValues,
        imageURL: imageURL,
        imageCredit: imageCredit,
        geometry: lineStringFeature,
      };
      if (route?.id) {
        await updateMunicipalityRoute(route.id, routeData);
      } else {
        await createMunicipalityRoute(routeData);
      }

      setIsLoading(false);
      navigate("/");


    } catch (error) {
      console.error("Error saving blog:", error);
      const customEvent = new CustomEvent("apiError", { detail: error });
      window.dispatchEvent(customEvent);
      setIsLoading(false)
    }
  };

  const convertToLineStringFeature = (
    geoJsonData: FeatureCollection<Geometry, GeoJsonProperties>
  ): Feature<Geometry, GeoJsonProperties> => {
    let allCoordinates: number[][] = [];

    // Iterate through each feature in the FeatureCollection
    geoJsonData.features.forEach((feature) => {
      if (feature.geometry.type === "LineString") {
        //@ts-ignore
        allCoordinates = allCoordinates.concat(
          feature.geometry.coordinates.map((coord) => [coord[0], coord[1]])
        ); // Also strips elevation if present
      }
    });

    // Create a new LineString feature using the merged coordinates
    const mergedLineStringFeature: Feature<LineString, GeoJsonProperties> = {
      type: "Feature",
      properties: {}, // Add any relevant properties here
      geometry: {
        type: "LineString",
        coordinates: allCoordinates,
      },
    };

    return mergedLineStringFeature;
  };

  const simplifyLineString = (
    feature: Feature<Geometry, GeoJsonProperties>,
    tolerance: number = 0.0001
  ): Feature<Geometry, GeoJsonProperties> => {
    // Simplify the feature using the simplify-geojson library
    return simplify(feature, tolerance) as Feature<Geometry, GeoJsonProperties>;
  };

  const handleGPXFileUpload = (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files ? event.target.files[0] : null;
    if (file) {
      const reader = new FileReader();
      reader.onload = async (e: ProgressEvent<FileReader>) => {
        const result = e.target?.result;
        if (typeof result === "string") {
          const gpx = new DOMParser().parseFromString(result, "text/xml");
          const convertedData: FeatureCollection<Geometry, GeoJsonProperties> =
            toGeoJSON.gpx(gpx) as FeatureCollection<
              Geometry,
              GeoJsonProperties
            >;

          // Convert the uploaded GPX to LineString and simplify it
          let newLineStringFeature = convertToLineStringFeature(convertedData);
          // newLineStringFeature = simplifyLineString(newLineStringFeature);

          // Merge with existing LineString if it exists
          // @ts-ignore
          let mergedCoordinates = newLineStringFeature.geometry.coordinates;
          if (lineStringFeature && lineStringFeature.type === "LineString") {
            // Append new coordinates to existing ones
            mergedCoordinates = (lineStringFeature.coordinates as any[]).concat(
              mergedCoordinates
            );
          }

          // Update the LineString feature with merged coordinates
          const updatedLineStringFeature: Geometry = {
            type: "LineString",
            coordinates: mergedCoordinates,
          };

          // Update state
          setLineStringFeature(updatedLineStringFeature);
          setMapFeatures({
            type: "FeatureCollection",
            features: [
              {
                type: "Feature",
                properties: {},
                geometry: updatedLineStringFeature,
              },
            ],
          });
        }
      };
      reader.readAsText(file);
    }
  };


  return (
    <div className={`w-full`}>
      {isLoading && (
        <div className="spinner-background white-bg-spinner">
            <CircularProgress />
            <span>{t('create-saving')}</span>
            </div>
      )
        }

    <form
      className="self-stretch flex flex-col items-center justify-start gap-[48px] max-w-full text-left text-sm text-dark-gray font-text-small mq725:gap-[24px]"
      onSubmit={handleSubmit}
    >

      <div className="flex flex-row w-full justify-between">
        <TextField
          id="standard-multiline-flexible"
          label={t('create-title')}
          multiline
          maxRows={4}
          variant="standard"
          className="w-[80%]"
          value={title}
          onChange={handleTitleChange}
          helperText={`${title.length}/255`}
          inputProps={{ maxLength: 255 }}
        />
      </div>

      {/* <DropImage
        setHeaderImage={setNewHeaderImage}
        initialImageUrl={headerImagePreview}
      /> */}
       <TextField
        id="standard-multiline-flexible"
        label='create-image-url'
        variant="standard"
        className="w-full"
        value={imageURL}
        onChange={handleImageURLChange}
      />

      <MultiInput
        activityOptions={activityOptions}
        onActivitiesChange={handleActivitiesChange}
        defaultOptions={route?.activities}
        placeholder={t('create-activities')}
      />


      <div className="flex flex-col w-full gap-[5px] items-center justify-center">
        {/* <MapboxMapEdit
          mapFeatures={mapFeatures}
          onFeatureUpdate={setLineStringFeature}
        /> */}

        <div>
          <label htmlFor="contained-button-file">
            <Input
              accept=".gpx"
              id="contained-button-file"
              multiple
              type="file"
              onChange={handleGPXFileUpload}
            />
            <Tooltip title={t('create-gpx-info')}>
              <Button
                variant="contained"
                component="span"
                startIcon={<CloudUploadIcon />}
              >
                {t('create-gpx')}
              </Button>
            </Tooltip>
          </label>
        </div>
      </div>

      <div className="self-stretch flex flex-col items-start justify-start gap-[6px] shrink-0">
        <div className="self-stretch relative text-center leading-[22px]">
        {t('create-content')}
        </div>
        <TextEditor blogContent={blogContent} setBlogContent={setBlogContent} />
        {/* <div className="self-stretch relative text-xs leading-[20px] text-gray">
          This is a hint text for the user
        </div> */}
      </div>
      <Button type="submit" variant="contained"
      disabled={!isFormValid}
      >
      {t('create-save')}
      </Button>
    </form>
    </div>
)}

export default WritingContent;
