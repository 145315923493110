import { useState, useEffect } from "react";
import Navbar from "../components/main/Navbar";
import Footer from "../components/main/Footer";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

import { Button } from "@mui/material";


const Divider = ({ width = "w-64", mx= "mx-0" }) => {
  return (
    <div className={`w-full mb-4`}>
      <div
        className={`h-1 mx-auto md:${mx} bg-orange ${width} opacity-25 my-0 py-0 rounded-t mb-10`}
      ></div>
    </div>
  );
};
const HowWorks = () => {
  const { t, i18n } = useTranslation();
  const [open, setOpen] = useState(false);

  const navigate = useNavigate();


  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const itemData = [
    {
      img: 'https://storage.googleapis.com/slostr-imgs/slostr/1_en.png',
      img_fr: 'https://storage.googleapis.com/slostr-imgs/slostr/1_fr.png',
    },
    {
      img: 'https://storage.googleapis.com/slostr-imgs/slostr/2_en.png',
      img_fr: 'https://storage.googleapis.com/slostr-imgs/slostr/2_fr.png',
    },
    {
      img: 'https://storage.googleapis.com/slostr-imgs/slostr/3_en.png',
      img_fr: 'https://storage.googleapis.com/slostr-imgs/slostr/3_fr.png',
    },
    {
      img: 'https://storage.googleapis.com/slostr-imgs/slostr/4_en.png',
      img_fr: 'https://storage.googleapis.com/slostr-imgs/slostr/4_fr.png',
    },

  ];



  return (
    <div className="w-full relative bg-primary-contrast overflow-hidden gap-[30px]">
      <Navbar />
      <div className="relative w-full">
        <div className="transition-all duration-500 ease-in-out pt-[80px] flex flex-col justify-center items-center p-5">
        <h1 className="text-17xl md:text-[3rem] flex flex-row font-roboto tracking-tight font-bold text-secondary-600 md:pb-5 gap-2">
                  <span className={`block text-primary xl:inline`}>
                  slostr,
                  </span>
                  {" "}{t("slostr-what-it-is")}
                </h1>
                <Divider/>
          <div className="grid grid-cols-1 sm:grid-cols-2 gap-4 w-full h-full">
            {itemData.map((item) => (
              <div key={item.img} className="w-full h-full">
                <img
                  className="w-full h-full object-cover"
                  srcSet={`${i18n.language === 'fr' ? item.img_fr : item.img}?w=164&h=164&fit=crop&auto=format&dpr=2 2x`}
                  src={`${i18n.language === 'fr' ? item.img_fr : item.img}?w=164&h=164&fit=crop&auto=format`}
                  loading="lazy"
                />
              </div>
            ))}
          </div>
          <div className="w-full p-3 pt-20">
          <Divider/>
<div className="w-full flex flex-col items-center justify-center">
<h1 className="text-17xl md:text-[2.5rem] flex flex-row font-roboto tracking-tight font-bold text-secondary-600 md:pb-5 gap-2 text-center">
{t("slostr-get-account")}
</h1>

<div className="flex flex-col mx-auto mt-6 space-y-3 md:space-y-0 md:flex-row">


<Button
                    variant="contained"
                    size="large"
                    // endIcon={<OpenInNewIcon />}
                    onClick={()=>navigate('/signup')}
                  >
                    {t("subscribe")}
                  </Button>
</div>
</div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default HowWorks;
