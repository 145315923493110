import React, { useState, useEffect, useContext } from "react";
// @ts-ignore
import isEqual from "lodash/isEqual";
import Slider from "@mui/material/Slider";
import Button from "@mui/material/Button";
import {Chip} from "@nextui-org/react";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import Fab from "@mui/material/Fab";
import TuneIcon from "@mui/icons-material/Tune";
import { IconButton, Paper, Zoom } from "@mui/material";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import FormLabel from "@mui/material/FormLabel";
import FormControl from "@mui/material/FormControl";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormHelperText from "@mui/material/FormHelperText";
import Switch from "@mui/material/Switch";
import InfoIcon from "@mui/icons-material/Info";
import {
  Popover,
  PopoverTrigger,
  PopoverContent,
  ScrollShadow,
  Spinner,
  Skeleton,
  Card as CardUI,
  CardBody,
  CardHeader,
  Select,
  SelectItem, Modal, ModalContent, ModalHeader, ModalBody, ModalFooter, useDisclosure
} from "@nextui-org/react";
import { CiSearch } from "react-icons/ci";
import { IoMdClose } from "react-icons/io";
import { GiSloth, GiTurtle, GiHorseHead, GiElephant } from "react-icons/gi";
import { MdDirectionsBike } from "react-icons/md";
import { FaTrain, FaHiking, FaCarAlt } from "react-icons/fa";
import { FaFerry, FaSailboat, FaBusSimple, FaThumbsUp } from "react-icons/fa6";


import {
  TextField,
  List,
  ListItem,
  ListItemAvatar,
  Avatar,
  InputAdornment,
} from "@mui/material";

import Card from "../blog/CardHorizontal";
import MultiInput from "../inputs/Multiinput";
import {
  timeToSliderValue,
  sliderValueToTime,
  calculateLabel,
  getMarks,
  getRandomLocation,
  createConcatenatedRoutesForBlogs,
} from "../../utils/utils";
import { transformBlogsToFeatures } from "../../utils/geoHelpers";
import { transportationColorsFaded } from "../../utils/mapStyles";
import { ActivityOptionType } from "../../types";
import {
  fetchActivities,
  fetchCommunityLocations,
  updateCurrentLocation,
  fetchBlogs,
  searchUsers,
} from "../../utils/api/api";
import VisibilityTooltip from "../blog/VisibilityTooltip";
import { AuthContext } from "../authentification/AuthContext";
import { CustomTabPanel, a11yProps } from "../settings/CustomTabPanel";
import { Typography } from "@mui/material";
import { useNotification } from "../NotificationContext";
import CreateAccountForm from "../authentification/CreateAccountForm";

const MAPBOX_API_KEY =  process.env.REACT_APP_MAPBOX_API_TOKEN as string;


const Sidebar: React.FC<any> = ({
  blogs,
  setFetchedBlogs,
  map,
  focusedBlogId,
  valueMainTab,
  changeMainTab,
  selectedMunicipality,
  selectedMunicipalityRoute,
  setSelectedMunicipalityRoute,
  hoveredCard,
  setHoveredCard,
  setCommunityLocations,
  showCommunityLocations,
  setShowCommunityLocations,
  setCommunityCurrentTrips,
  setCommunityCurrentTripFeatures,
  setCurrentLocation,
  activeChips,
  setActiveChips
}) => {
  const { t } = useTranslation();
  const authContext = useContext(AuthContext);
  const { user } = authContext || {};
  const { notificationCount, community } = useNotification();

  const [isLoading, setIsLoading] = useState(false);
  const [isPopOpen, setIsPopOpen] = useState(true);
  const {isOpen, onOpen, onOpenChange} = useDisclosure();

  const [activityOptions, setActivityOptions] = useState<ActivityOptionType[]>(
    []
  );
  const [selectedRange, setSelectedRange] = useState<[number, number]>([
    0, 100,
  ]);
  const [currentPage, setCurrentPage] = useState(1);
  const [routesPerPage] = useState(5);
  const [followingApprovedIds, setFollowingApprovedIds] = useState<any>([]);

  const [showCard, setShowCard] = useState(false);
  const [locationState, setLocationState] = useState<any>(null);
  const [showHelpLocation, setShowHelpLocation] = useState(false);
  const [searchQuery, setSearchQuery] = useState("");
  const [searchResults, setSearchResults] = useState<any[]>([]);
  const [selectedAuthor, setSelectedAuthor] = useState<any>(null);
  const [selectedDaysRange, setSelectedDaysRange] = useState<[number, number]>([
    0, 365,
  ]);
  const [selectedActivities, setSelectedActivities] = useState<number[]>([]);
  const [visibility, setVisibility] = useState<string>("public");
  const [selectedLocation, setSelectedLocation] = useState(null);
  const [selectedDistance, setSelectedDistance] = useState(10);
  const [startSearchQuery, setStartSearchQuery] = useState("");

  const marks = getMarks(t)



  useEffect(() => {
    if (map) {
      fetchFilteredBlogs();
    }
  }, [map]);

  const handleLocationStateChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setLocationState(event.target.checked);
    setShowCard(false);
    if (event.target.checked) {
      if (navigator.geolocation) {
        navigator.geolocation.getCurrentPosition(
          (position) => {
            const { latitude, longitude } = position.coords;
            const [newLat, newLon] = getRandomLocation(
              latitude,
              longitude,
              3000
            );
            setCurrentLocation({ lat: newLat, lon: newLon });
            updateCurrentLocation(newLat, newLon);
          },
          (error) => {
            console.error("Error getting location:", error);
          }
        );
      } else {
        console.error("Geolocation is not supported by this browser.");
      }
    } else {
      setCurrentLocation({ lat: null, lon: null });
      updateCurrentLocation(0, 0);
    }
  };

  const handleSearch = async (query: string) => {
    console.log(`Searching ${query}`);
    setSearchQuery(query);
    if (query.length > 2) {
      try {
        const results = await searchUsers(query);
        setSearchResults(results);
      } catch (error) {
        console.error("Error searching users:", error);
      }
    } else {
      setSearchResults([]);
    }
  };

  const handleDaysChange = (daysRange: [number, number]) => {
    setSelectedDaysRange(daysRange);
  };

  const handleActivitiesChange = (activities: any[]) => {
    // Only update if activities have actually changed
    if (!isEqual(activities, selectedActivities)) {
      setSelectedActivities(activities.map((activity) => activity.id));
    }
  };

  const handleBlogVisibility = (event: any) => {
    if (event !== null) {
      setVisibility(event);
    }
  };

  useEffect(() => {
    if (showCard) {
      const timer = setTimeout(() => {
        setShowCard(false);
      }, 6000);

      return () => clearTimeout(timer);
    }
  }, [showCard]);

  const handleUpdateLocation = () => {
    setShowCard(false);
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          const { latitude, longitude } = position.coords;
          const [newLat, newLon] = getRandomLocation(latitude, longitude, 3000);
          setCurrentLocation({ lat: newLat, lon: newLon });
          updateCurrentLocation(newLat, newLon);
        },
        (error) => {
          console.error("Error getting location:", error);
        }
      );
    } else {
      console.error("Geolocation is not supported by this browser.");
    }
  };

  useEffect(() => {
    fetchActivities()
      .then((fetchedActivities) => {
        setActivityOptions(fetchedActivities);
      })
      .catch((error) => {
        console.error("Error fetching activities:", error);
      });
  }, []);

  const handleOpenSidebar = (value: boolean) => {
    if (!value) {
      setIsPopOpen(true);
    }
  };

  useEffect(() => {
    // Remove border from previously focused element
    const previouslyFocusedElement = document.querySelector(".focused-blog");
    if (previouslyFocusedElement) {
      previouslyFocusedElement.classList.remove("focused-blog");
    }

    if (focusedBlogId) {
      const element = document.getElementById(`blog-${focusedBlogId}`);
      if (element) {
        element.scrollIntoView({ behavior: "smooth", block: "nearest" });
        element.classList.add("focused-blog"); // Add a class for focused blog
      }
    }
  }, [focusedBlogId]);

  // Calculate the part of blogs to display
  const indexOfLastRoute = currentPage * routesPerPage;
  const indexOfFirstRoute = indexOfLastRoute - routesPerPage;
  // const currentRoutes = blogs.slice(0, indexOfLastRoute);
  const currentRoutes = blogs;

  // Increment page to show more routes
  const handleSeeMoreClick = () => {
    setCurrentPage((prevPage) => prevPage + 1);
  };

  // useEffect(() => {
  //   if (!user) return;
  //   const followingData =
  //     community?.filter(
  //       (relation: any) =>
  //         relation.request_approved && relation.follower?.id === user?.id
  //     ) ?? [];
  //   const followingApprovedIds = followingData.map(
  //     (relation: any) => relation.followed.id
  //   );
  //   setFollowingApprovedIds([...followingApprovedIds, user.id]);
  // }, [community]);

  // useEffect(() => {
  //   if (followingApprovedIds.length > 0) {
  //     fetchCommunityLocations().then((data) => {
  //       const userId = user.id;

  //       const userLocation = data.locations.find(
  //         (relation: any) => relation.user === user.id
  //       );
  //       setTimeout(() => {
  //         setLocationState(userLocation ? userLocation.is_location_on : false);
  //         setShowCard(true);
  //       }, 500);

  //       const geoJsonData = {
  //         type: "FeatureCollection",
  //         features: data.locations.map((location: any) => {
  //           const relatedUser = community.find(
  //             (relation: any) => relation.followed.id === location.user
  //           )?.followed;
  //           return {
  //             type: "Feature",
  //             properties: {
  //               user: relatedUser.id,
  //               imageId: relatedUser.profile_picture,
  //               iconSize: relatedUser.id === userId ? [25, 25] : [45, 45],
  //               isUser: relatedUser.id === userId,
  //             },
  //             geometry: {
  //               type: "Point",
  //               coordinates: [
  //                 location.location.coordinates[0],
  //                 location.location.coordinates[1],
  //               ],
  //             },
  //           };
  //         }),
  //       };
  //       setCommunityLocations(geoJsonData);

  //       const geoJSONFeatureCollection = createConcatenatedRoutesForBlogs(
  //         data.current_trips
  //       );
  //       setCommunityCurrentTrips(geoJSONFeatureCollection);
  //       const blogFeatures = transformBlogsToFeatures(geoJSONFeatureCollection);
  //       setCommunityCurrentTripFeatures(blogFeatures);
  //     });
  //   }
  // }, [followingApprovedIds]);

  const handleGetPerson = (item) => {
    setSearchQuery(null);
    setSelectedAuthor(item);
  };

  const searchPlaces = async (query, setSearchQuery, startChange) => {
    if (query.length < 3) {
      setSearchQuery(query);
    }

    setSearchQuery(query);

    const url = `https://api.mapbox.com/geocoding/v5/mapbox.places/${query}.json?access_token=${MAPBOX_API_KEY}&limit=5`;

    try {
      const response = await fetch(url);
      const data = await response.json();
      setSearchResults(data.features);
    } catch (error) {
      console.error(error);
    } finally {
      // setIsSearching(false);
    }
  };

  const handleClearInput = () => {
    setStartSearchQuery("");
    setSearchResults([]);
    setSelectedLocation(null);
  };
  const handleSelectResult = (location) => {
    console.log("location", location);
    setStartSearchQuery(location.place_name);
    setSelectedLocation([location.center[0], location.center[1]]);
    map.easeTo({
      center: [location.center[0], location.center[1]],
      zoom: 8,
      duration: 500, // 500ms for animation duration
    });
    setSearchResults([]);
  };

  const handleDistanceChange = (input) => {
    setSelectedDistance(parseInt(input.currentKey));
  };

  const fetchFilteredBlogs = async () => {
          try {
      // Assume fetchBlogs is updated to accept filters
      setIsLoading(true);
      const fetchedBlogs = await fetchBlogs({
        daysRange: selectedDaysRange,
        activities: selectedActivities,
        visibility: visibility,
        location: selectedLocation,
        distance: selectedDistance,
        from_author: selectedAuthor ? selectedAuthor.id : null,
      });
      setIsLoading(false);

      setFetchedBlogs(fetchedBlogs);
    } catch (error) {
      console.error("Error fetching blogs:", error);
    }

  };

  useEffect(() => {
    if (visibility !== 'public' && !user) {
      setIsPopOpen(false)
      onOpenChange()
      setVisibility('public')
    }


  }, [visibility, isOpen])

  const handleChipClick = (index: number) => {
    setActiveChips((prev) =>
      prev.includes(index)
        ? prev.filter((chip) => chip !== index) // Remove chip from active
        : [...prev, index] // Add chip to active
    );
    console.log(`Chip ${index + 1} clicked!`); // Perform your action here
  };

  const chips = [t('trip-micro-adventure'), t('trip-exploration'), t('trip-expedition'), t('trip-odyssey')];
  const icons = [<GiSloth size={22}/>, <GiTurtle size={22}/>, <GiHorseHead size={22}/>, <GiElephant size={22}/>];

  const transportationIcons: any = {
    hike: <FaHiking color="white" />,
    bike: <MdDirectionsBike color="white" />,
    train: <FaTrain color="white" />,
    car: <FaCarAlt color="white" />,
    bus: <FaBusSimple color="white" />,
    boat: <FaFerry color="white" />,
    sail: <FaSailboat color="white" />,
    hitchhike: <FaThumbsUp color="white" />,
  };


  return (
    <div>
      {/* Sidebar toggle and container */}
      {!showCommunityLocations && (
        <div className="bg-gradient-to-l from-slate-300 to-zinc-200 absolute right-0 z-10  top-[66px] w-[380px] h-[calc(100%)] overflow-auto">
          <ScrollShadow className="p-6 mb-14 w-full h-[calc(100%-66px)] top-[66px]">
            <div className="flex flex-col gap-10">
              <div className="flex flex-col gap-6">
                {isLoading ? (
                  // <div className="spinner">
                  //   <Spinner
                  //     label={t("loading")}
                  //     color="warning"
                  //     labelColor="foreground"
                  //   />
                  // </div>
                  <CardUI className="w-full space-y-5 p-4 bg-[#ffffff9c]" radius="lg">
                  <Skeleton className="rounded-lg">
                    <div className="h-40 rounded-lg bg-gray-300/50"></div>
                  </Skeleton>
                </CardUI>

                ) : (
                  currentRoutes &&
                  currentRoutes.map((blog: any, index: any) => (
                    <Link
                      to={`/${blog.author.username}/${blog.id}`}
                      key={index}
                      onMouseEnter={() => setHoveredCard(blog.id)}
                      // onMouseLeave={() => setHoveredCard(null)}
                    >
                      <Card blog={blog} focus={hoveredCard === blog.id} />
                    </Link>
                  ))
                )}
              </div>
            </div>
          </ScrollShadow>
        </div>
      )}
          <div className="z-10 fixed inset-0 flex items-end left-auto right-[400px] pb-8 h-[200px] top-auto bottom-0">
          <div className="flex flex-col gap-3">
      {chips.map((chip, index) => (
          <Chip
            key={index}
            radius="md"
            size="lg"
            startContent={icons[index]}
            onClick={() => handleChipClick(index)}
            className={`hover:scale-105 transition-all hover:cursor-pointer !w-40 !max-w-40 !justify-center !text-center ${activeChips.includes(index) ? "" : "!shadow-lg"}`}
            color="secondary"
            variant={
              activeChips.includes(index) ? "shadow" : "faded"
            }
          >{chip}</Chip>
        ))}

      </div>
      </div>
      <div className="z-10 absolute flex right-[390px] flex-col gap-1 top-[calc(50vh)] bottom-auto -translate-y-1/2">
      {Object.keys(transportationIcons).map((transport, index) => (
        <Chip
          key={index}
          size="sm"
          radius="sm"
          className="flex items-center justify-between !p-1 !w-8 !h-auto"
          style={{
            backgroundColor: `${transportationColorsFaded[transport]}`,
            border: `1px solid ${transportationColorsFaded[transport]}`,
          }}
        >
          <div className="flex items-center gap-3">
            {transportationIcons[transport]}
          </div>
        </Chip>
      ))}
    </div>
      <div
        className={`z-[10] flex flex-col gap-2 absolute top-[90px] left-[20px] cursor-pointer`}
      >
        {/* <Tooltip title={t("friends-location")}>
          <Fab
            color="primary"
            aria-label="add"
            onClick={() => {
              setShowCommunityLocations(!showCommunityLocations);
              // handleButtonClick()
            }}
          >
            {showCommunityLocations ? <PublicIcon /> : <GroupIcon />}
          </Fab>
        </Tooltip>
        <Tooltip title={t("nav-create")}>
          <Link to={`/create-blog-post`}>
            <Fab color="primary" aria-label="add">
              <AddIcon />
            </Fab>
          </Link>
        </Tooltip> */}
        <Popover placement="right-start" offset={10} defaultOpen={true} isOpen={isPopOpen} onOpenChange={(open) => setIsPopOpen(open)}>
          <PopoverTrigger>
            <Fab color="primary" aria-label="add">
              <TuneIcon />
            </Fab>
          </PopoverTrigger>
          <PopoverContent className="w-[400px]">
            {(titleProps) => (
              <div className="px-6 py-2 w-full">
                <p
                  className="text-small font-bold text-foreground"
                  {...titleProps}
                >
                  {t("side-filter")}
                </p>
                <div className="mt-2 flex flex-col gap-8 w-full">
                  <div>
                    {t("side-activities")}
                    <MultiInput
                      activityOptions={activityOptions}
                      onActivitiesChange={handleActivitiesChange}
                    />
                  </div>
                  <div className="flex flex-col">
                    {t("side-duration")}
                    <Slider
                      className="!w-[calc(100%-30px)] self-center flex"
                      getAriaLabel={() => "Duration range"}
                      defaultValue={[0, 100]}
                      valueLabelDisplay="auto"
                      valueLabelFormat={(e) => calculateLabel(e, t)}
                      marks={marks}
                      min={timeToSliderValue(0)}
                      max={timeToSliderValue(365)}
                      step={1}
                      onChange={(_, newValue) => {
                        const [start, end] = newValue as [number, number];
                        // Convert slider values back to actual days
                        const daysRange: [number, number] = [
                          Math.round(sliderValueToTime(start)),
                          Math.round(sliderValueToTime(end)),
                        ];
                        handleDaysChange(daysRange);
                      }}
                    />
                  </div>
                  <div>
                    {t("side-visibility-2")}
                    <VisibilityTooltip
                      value={visibility}
                      onChange={handleBlogVisibility}
                    />
                  </div>

                  <div className="relative flex-col h-10">
                    {!selectedAuthor && (
                      <TextField
                        value={searchQuery}
                        onChange={(e) => handleSearch(e.target.value)}
                        placeholder={t("search-users")}
                        variant="outlined"
                        fullWidth
                        size="small"
                        InputProps={{
                          startAdornment: (
                            <InputAdornment position="start">
                              <CiSearch />
                            </InputAdornment>
                          ),
                        }}
                      />
                    )}

                    {/* Render the search results as a list below the input field */}
                    {searchQuery && searchResults.length > 0 && (
                      <CardUI className="absolute top-12 z-10">
                        <CardBody>
                          <List>
                            {searchResults.map((option) => (
                              <ListItem
                                key={option.id}
                                onClick={() => handleGetPerson(option)}
                              >
                                <ListItemAvatar>
                                  <Avatar
                                    src={option.profile_picture}
                                    sx={{ width: 24, height: 24 }}
                                  />
                                </ListItemAvatar>
                                <p className="text-sm">{option.name}</p>
                              </ListItem>
                            ))}
                          </List>
                        </CardBody>
                      </CardUI>
                    )}

                    {selectedAuthor && (
                      <CardUI className="w-full mb-2">
                        <CardHeader className="justify-between">
                          <div className="flex gap-5">
                            <Avatar
                              src={selectedAuthor.profile_picture}
                              sx={{ width: 24, height: 24 }}
                            />
                            <div className="flex flex-col gap-1 items-start justify-center">
                              <h4 className="text-small font-semibold leading-none text-default-600">
                                {selectedAuthor.name}
                              </h4>
                            </div>
                          </div>
                          <IconButton
                            aria-label="delete"
                            onClick={() => setSelectedAuthor(null)}
                          >
                            <IoMdClose />
                          </IconButton>
                        </CardHeader>
                      </CardUI>
                    )}
                  </div>

                  <div className="">
      {t("filter-location")}

      <div className="relative flex flex-row gap-2">
        <TextField
          type="text"
          placeholder="Start Location"
          value={startSearchQuery}
          onChange={(e) => searchPlaces(e.target.value, setStartSearchQuery, true)}
          fullWidth
          size="small"
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <CiSearch />
              </InputAdornment>
            ),
            endAdornment: startSearchQuery ? (
              <IconButton
          aria-label="delete"
          onClick={handleClearInput}
        >
          <IoMdClose />
        </IconButton>
            ) : null
          }}
        />
      <Select
        defaultSelectedKeys={['10']}
        onSelectionChange={handleDistanceChange}
        // label="Distance"
        disableSelectorIconRotation
        className="max-w-[95px]"
      >
        <SelectItem key={'10'}>10km</SelectItem>
        <SelectItem key={'20'}>20km</SelectItem>
        <SelectItem key={'50'}>50km</SelectItem>
        <SelectItem key={'100'}>100km</SelectItem>
        <SelectItem key={'200'}>200km</SelectItem>
      </Select>
      </div>

      {searchResults.length > 0 && (
        <ul className="mt-2 h-48 overflow-auto rounded-md border border-gray-200 bg-white shadow-sm">
          {searchResults.map((item) => (
            <li key={item.id} className="border-b border-gray-100 last:border-b-0">
              <button
                className="w-full px-4 py-2 text-left hover:bg-gray-50"
                onClick={() => handleSelectResult(item)}
              >
                {item.place_name}
              </button>
            </li>
          ))}
        </ul>
      )}

    </div>

                  <Button
                    variant="contained"
                    color="primary"
                    onClick={async () => {
                      await fetchFilteredBlogs()
                      setIsPopOpen(false)
                    }}
                  >
                    {t("side-apply")}
                  </Button>
                </div>
              </div>
            )}
          </PopoverContent>
        </Popover>
      </div>
      <div
        className={`z-[999] flex flex-col gap-2 absolute bottom-7 md: ${
          showCommunityLocations
            ? "right-[calc(50%+10px)] translate-x-[50%]"
            : "hidden"
        }`}
      >
        <Zoom in={showCard}>
          <Paper
            className=""
            sx={{ p: 2, width: 250, textAlign: "center", borderRadius: "15px" }}
          >
            <div className="flex flex-col gap-2">
              <Button
                variant="contained"
                color="primary"
                startIcon={<LocationOnIcon />}
                onClick={handleUpdateLocation}
              >
                {t("update-location")}
              </Button>
              <FormControl component="fieldset" variant="standard">
                <FormGroup>
                  <FormControlLabel
                    className="!mr-0"
                    control={
                      <Switch
                        checked={locationState}
                        onChange={handleLocationStateChange}
                        name="location"
                      />
                    }
                    label={
                      <div className="flex flex-row gap-2">
                        {t("put-location")}
                        <IconButton
                          onClick={() => setShowHelpLocation(!showHelpLocation)}
                        >
                          <InfoIcon />
                        </IconButton>
                      </div>
                    }
                  />
                </FormGroup>

                <FormHelperText>
                  {showHelpLocation ? t("location-info") : ""}
                </FormHelperText>
              </FormControl>
            </div>
          </Paper>
        </Zoom>
      </div>
      <Modal isOpen={isOpen} onOpenChange={onOpenChange} size="xl">
        <ModalContent>
          {(onClose) => (
            <>
              <ModalHeader className="flex flex-col gap-1 text-xl font-bold font-msr">{t('test-user-thanks')}</ModalHeader>
              <ModalBody className="pb-4">
                <p>{t('test-user-description')}</p>
                <CreateAccountForm setIsLoading={setIsLoading} redirection={false}/>
              </ModalBody>
            </>
          )}
        </ModalContent>
      </Modal>

    </div>
  );
};

export default Sidebar;
