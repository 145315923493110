import React, { useState, useEffect, useContext } from "react";
import DialogTitle from "@mui/material/DialogTitle";
import Button from "@mui/material/Button";
import Dialog, { DialogProps } from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import { useTranslation } from "react-i18next";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import Tooltip from "@mui/material/Tooltip";
import { styled } from "@mui/material/styles";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import CloudDownloadIcon from "@mui/icons-material/CloudDownload";
// @ts-ignore
import * as toGeoJSON from "@mapbox/togeojson";
// @ts-ignore
import simplify from "simplify-geojson";
import {
  Feature,
  FeatureCollection,
  GeoJsonProperties,
  Geometry,
  LineString,
} from "geojson";
import * as turf from "@turf/turf";
import DirectionsCarIcon from "@mui/icons-material/DirectionsCar";
import DirectionsBusIcon from "@mui/icons-material/DirectionsBus";
import TrainIcon from "@mui/icons-material/Train";
import HikingIcon from "@mui/icons-material/Hiking";
import DirectionsBikeIcon from "@mui/icons-material/DirectionsBike";
import SailingIcon from "@mui/icons-material/Sailing";
import Divider from "@mui/material/Divider";
import ButtonGroup from "@mui/material/ButtonGroup";
import DirectionsRunIcon from '@mui/icons-material/DirectionsRun';
import TwoWheelerIcon from '@mui/icons-material/TwoWheeler';
import ThumbUpIcon from '@mui/icons-material/ThumbUp';
import DirectionsBoatIcon from '@mui/icons-material/DirectionsBoat';

import StravaActivities from "../inputs/StravaActivities";
import WahooWorkouts from "../inputs/WahooWorkouts";
import RouteSearcher from "../blog/GoogleDirection";
import RouteSearcherMapbox from "../blog/RouteSearcherMapbox";
import { AuthContext } from "../authentification/AuthContext";
import { fetchCurrentUser } from '../../utils/api/api';
import { smoothElevation,  calculateDistancesAndElevations} from '../../utils/utils';


import { use } from "i18next";

const Input = styled("input")({
  display: "none",
});

const RouteAdd: React.FC<any> = ({
  chapters,
  currentChapterIndex,
  setChapters,
  setMethodUsed,
  setRouteSearchOpen,
  methodUsed,
  mapInstance,
  newCoord,
  setNewCoord,
  routes,
  setRoutes,
  selectedRouteIndex,
  setSelectedRouteIndex,
  setStatsAlert,
}) => {
  const { t } = useTranslation();
  const authContext = useContext(AuthContext);
  const { user, setUser } = authContext || {};

  const [selectedTransportMode, setSelectedTransportMode] = React.useState("");
  const [tempMapFeature, setTempMapFeature] = useState<any | null>(null);
  const [routeMapbox, setRouteMapbox] = useState<any>(null);
  const [routeOpen, setRouteOpen] = useState(false);
  const [gpxUplaoded, setGpxUplaoded] = useState(false);
  const [stravaRoute, setStravaRoute] = useState<any>(null);
  const [wahooRoute, setWahooRoute] = useState<any>(null);
  const [showStrava, setShowStrava] = useState<any>(false);
  const [showWahoo, setShowWahoo] = useState<any>(false);
  const [origin, setOrigin] = useState(null);
  const [destination, setDestination] = useState(null);
  const [allWaypoints, setAllWaypoints] = useState<any[]>([]);


  const handleTransportChange = (event: SelectChangeEvent) => {
    setShowStrava(false)
    setShowWahoo(false)
    setSelectedTransportMode(event.target.value as string);
    setRouteOpen(true);
  };


  const handleClose = () => {
    setNewCoord(null)
    setRouteSearchOpen(false)
    setMethodUsed(null)
    setTempMapFeature(null);
    setRoutes(null);
    setOrigin(null);
    setDestination(null);
    setSelectedTransportMode("");
    allWaypoints.forEach((marker) => {
      marker.remove()
    }
      );
    setAllWaypoints([])
    if (mapInstance.getSource("route-mapbox")) {
      mapInstance.removeLayer("route-mapbox");
      mapInstance.removeSource("route-mapbox");
  }
  };


  const saveFeatureToChapter = () => {
    if (tempMapFeature) {
      const updatedChapters = [...chapters];
      const currentChapter = updatedChapters[currentChapterIndex];

      // Ensure the mapFeatures structure is initialized
      if (!currentChapter.route_segments) {
        currentChapter.route_segments = [];
      }

      // Append the temporary feature to the current chapter's mapFeatures
      currentChapter.route_segments.push(...tempMapFeature.features);
      currentChapter.is_updated = true;

      // Update the chapters state and clear the temporary feature state
      setChapters(updatedChapters);
      handleClose()
      setGpxUplaoded(false)
    }
  };

  useEffect(() => {
    if (gpxUplaoded) {
      saveFeatureToChapter()
      setShowStrava(false)
      setShowWahoo(false)
    }
}, [tempMapFeature])




  const convertToLineStringFeature = (
    geoJsonData: FeatureCollection<Geometry, GeoJsonProperties>
  ): Feature<Geometry, GeoJsonProperties> => {
    let allCoordinates: number[][] = [];

    // Iterate through each feature in the FeatureCollection
    geoJsonData.features.forEach((feature) => {
      if (feature.geometry.type === "LineString") {
        //@ts-ignore
        allCoordinates = allCoordinates.concat(
          feature.geometry.coordinates.map((coord) => [coord[0], coord[1]])
        ); // Also strips elevation if present
      }
    });

    // Create a new LineString feature using the merged coordinates
    const mergedLineStringFeature: Feature<LineString, GeoJsonProperties> = {
      type: "Feature",
      properties: {}, // Add any relevant properties here
      geometry: {
        type: "LineString",
        coordinates: allCoordinates,
      },
    };

    return mergedLineStringFeature;
  };

  const simplifyLineString = (
    feature: Feature<Geometry, GeoJsonProperties>,
    tolerance: number = 0.0001
  ): Feature<Geometry, GeoJsonProperties> => {
    // Simplify the feature using the simplify-geojson library
    return simplify(feature, tolerance) as Feature<Geometry, GeoJsonProperties>;
  };

  const saveFeatures = (
    data: any,
    selectedTransportMode: any
  ) => {
    let newLineStringFeature = convertToLineStringFeature(data);
          newLineStringFeature = simplifyLineString(newLineStringFeature);

          // Check if the original data has elevation information
          let preciseCoordinates: any[] = [];
          let hasElevationData = false;
          let pointSpacing = 0.025;

          if (selectedTransportMode === "hike" || selectedTransportMode === "run") {
            pointSpacing = 0.015;
          }

          // Iterate over the features to check for elevation data
          for (const feature of data.features) {
            if (
              feature.geometry.type === "LineString" &&
              feature.geometry.coordinates.some((coord: any) => coord.length > 2)
            ) {
              hasElevationData = true;
              break;
            }
          }

          if (hasElevationData) {
            for (const feature of data.features) {
              // Ensure that the feature is a LineString and has a valid geometry
              if (feature.geometry && feature.geometry.type === "LineString") {
                // Concatenate the coordinates to the main array
                preciseCoordinates = preciseCoordinates.concat(feature.geometry.coordinates);
              }
            }
          } else {
            // Interpolate points every 50 meters if no elevation data exists
            const lineLength = turf.length(newLineStringFeature, {
              units: "kilometers",
            });
            const numberOfPoints = Math.floor(
              (lineLength * 1000) / (pointSpacing * 1000)
            );
            for (let i = 0; i <= numberOfPoints; i++) {
              // @ts-ignore
              const point = turf.along(newLineStringFeature, i * pointSpacing, {
                units: "kilometers",
              }); // 0.05 km is 50 meters
              preciseCoordinates.push(point.geometry.coordinates);
            }
          }

          const preciseFeature = {
            type: "Feature",
            geometry: {
              type: "LineString",
              coordinates: preciseCoordinates,
            },
            properties: {},
          };
          const routesFeatureCollection = {
            type: "FeatureCollection",
            features: [
              {
                type: "Feature",
                properties: {
                  transportation: { name: selectedTransportMode },
                  elevation_profile: preciseFeature.geometry,
                },
                geometry: newLineStringFeature.geometry,
              },
            ],
          };

          if (hasElevationData) {
            // Smooth elevation data
            try {
              const smoothedElevations = smoothElevation(preciseCoordinates);

              // Calculate total distance and ascent/descent
              const stats = calculateDistancesAndElevations(smoothedElevations, selectedTransportMode);

              setStatsAlert(stats)
            }
            catch (error) {
              console.log('error getting feature stats', error)

          }
        }


          setTempMapFeature(routesFeatureCollection);
  };

  const handleGPXFileUpload = (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files ? event.target.files[0] : null;
    if (file) {
      const reader = new FileReader();
      reader.onload = async (e: ProgressEvent<FileReader>) => {
        try {
        const result = e.target?.result;
        if (typeof result === "string") {
          const gpx = new DOMParser().parseFromString(result, "text/xml");
          const convertedData: FeatureCollection<Geometry, GeoJsonProperties> =
            toGeoJSON.gpx(gpx) as FeatureCollection<
              Geometry,
              GeoJsonProperties
            >;
            saveFeatures(convertedData, selectedTransportMode)

          // setRoutes(routesFeatureCollection);
          setGpxUplaoded(true)

        }
      } catch (error) {
        alert(t("gpx-error"));
        console.error('Error processing GPX file:', error);
      }
    };
      reader.readAsText(file);
    }
  };

  useEffect(() => {
    if (stravaRoute) {
      saveFeatures(stravaRoute.geojson, selectedTransportMode)

      const stravaImages = stravaRoute.pictures.map((picture: any) => ({
        preview: picture,
        image_url: picture,
        caption: "",
        type: 'image'
      }));

      const updatedChapters = [...chapters];
      const currentChapter = updatedChapters[currentChapterIndex];
      currentChapter.is_updated = true;

      // Append the temporary feature to the current chapter's mapFeatures
      // currentChapter.images.push(...stravaImages);

      // Update the chapters state and clear the temporary feature state
      setChapters(updatedChapters);
      setGpxUplaoded(true)
    }
  }, [stravaRoute])

  useEffect(() => {
    if (wahooRoute) {
      saveFeatures(wahooRoute.geojson, selectedTransportMode)

      const updatedChapters = [...chapters];
      const currentChapter = updatedChapters[currentChapterIndex];
      currentChapter.is_updated = true;

      setChapters(updatedChapters);
      setGpxUplaoded(true)
    }
  }, [wahooRoute])

  function getPreciseCoordinates(
    selectedTransportMode: any,
    selectedRoute: any
  ) {
    let preciseCoordinates = [];
    let preciseFeature = null;
    let pointSpacing = 0.025; // default spacing

    if (selectedTransportMode === "hike" || selectedTransportMode === "run") {
      pointSpacing = 0.015; // smaller spacing for hiking
    }
    if (selectedTransportMode === "hike" || selectedTransportMode === "bike" || selectedTransportMode === "run") {
      const lineLength = turf.length(selectedRoute, { units: "kilometers" });
      const numberOfPoints = Math.floor(lineLength / pointSpacing);
      for (let i = 0; i <= numberOfPoints; i++) {
        const point = turf.along(selectedRoute, i * pointSpacing, {
          units: "kilometers",
        });
        preciseCoordinates.push(point.geometry.coordinates);
      }

      preciseFeature = {
        type: "Feature",
        geometry: {
          type: "LineString",
          coordinates: preciseCoordinates,
        },
        properties: {},
      };
    }

    return preciseFeature;
  }

  useEffect(() => {
    if (routes && routes.features.length > 0) {
      const selectedRoute: any =
        routes.features[selectedRouteIndex ? selectedRouteIndex : 0];

      const preciseFeature = getPreciseCoordinates(
        selectedTransportMode,
        selectedRoute
      );

      setTempMapFeature({
        type: "FeatureCollection",
        features: [
          {
            type: "Feature",
            properties: {
              transportation: { name: selectedTransportMode },
              elevation_profile: preciseFeature
                ? preciseFeature.geometry
                : null,
            },
            geometry: selectedRoute.geometry,
          },
        ],
      });
    }
  }, [selectedRouteIndex, routes]);

  useEffect(() => {
    if (routeMapbox) {
      const selectedRoute: any = routeMapbox.features[0];

      const preciseFeature = getPreciseCoordinates(
        selectedTransportMode,
        selectedRoute
      );

      setTempMapFeature({
        type: "FeatureCollection",
        features: [
          {
            type: "Feature",
            properties: {
              transportation: { name: selectedTransportMode },
              elevation_profile: preciseFeature
                ? preciseFeature.geometry
                : null,
            },
            geometry: selectedRoute.geometry,
          },
        ],
      });
    }
  }, [routeMapbox]);

  useEffect(() => {
    if (selectedTransportMode === "train" || selectedTransportMode === "boat" || selectedTransportMode === "sail") {
      setMethodUsed("google");
    } else {
      setMethodUsed("mapbox");
    }
  }, [selectedTransportMode]);

  useEffect(() => {
    if (methodUsed === "mapbox") {
      const clickHandler = (e: any) => {
        const coordinates = [e.lngLat.lng, e.lngLat.lat];
        setNewCoord(coordinates);
      };

      mapInstance.on('click', clickHandler);

      // Cleanup function to remove the event listener
      return () => {
        mapInstance.off('click', clickHandler);
      }
    } else {
      // Optionally handle other methods or cleanup specific to other methods
    }
  }, [methodUsed])

  const handleStrava = async () => {
    if (user && user.strava) {
      setShowStrava(true);
    } else {
      try {
        const userData = await fetchCurrentUser();
        if (userData && setUser) {
          setUser(userData);
        }

        if (userData && userData.strava) {
          setShowStrava(true);
        } else {
          window.open('http://www.strava.com/oauth/authorize?client_id=127024&response_type=code&redirect_uri=http://slostr.com/strava/exchange_token&approval_prompt=force&scope=activity:read_all', '_blank');
        }
      } catch (error) {
        console.error("Failed to fetch user:", error);
      }
    }
  };

  const handleWahoo = async () => {
    if (user && user.wahoo) {
      setShowWahoo(true);
    } else {
      try {
        const userData = await fetchCurrentUser();
        if (userData && setUser) {
          setUser(userData);
        }

        if (userData && userData.wahoo) {
          setShowWahoo(true);
        } else {
          window.open('https://api.wahooligan.com/oauth/authorize?client_id=wczAd63aYxL6JPnhMAuxU7kFgbzF90TNSGaf7yNaK1U&response_type=code&redirect_uri=https://slostr.com/wahoo/exchange_token&approval_prompt=force&scope=user_read%20workouts_read', '_blank');
        }
      } catch (error) {
        console.error("Failed to fetch user:", error);
      }
    }
  };

  return (
    <div className="w-full h-full p-3">
      <h2>{t("create-route")}</h2>


            <FormControl fullWidth>
              <InputLabel id="demo-simple-select-label">
                {t("create-transport")}
              </InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={selectedTransportMode}
                label="Transport"
                onChange={handleTransportChange}
              >
                <MenuItem value={"hike"} className="gap-3">
                  <HikingIcon /> {t("transport-hiking")}
                </MenuItem>
                <MenuItem value={"bike"} className="gap-3">
                  <DirectionsBikeIcon /> {t("transport-bike")}
                </MenuItem>
                <MenuItem value={"train"} className="gap-3">
                  <TrainIcon /> {t("transport-train")}
                </MenuItem>
                <MenuItem value={"bus"} className="gap-3">
                  <DirectionsBusIcon /> {t("transport-bus")}
                </MenuItem>
                <MenuItem value={"car"} className="gap-3">
                  <DirectionsCarIcon /> {t("transport-car")}
                </MenuItem>
                <MenuItem value={"hitchhike"} className="gap-3">
                  <ThumbUpIcon /> {t("transport-hitchhike")}
                </MenuItem>
                <MenuItem value={"boat"} className="gap-3">
                  <DirectionsBoatIcon /> {t("transport-boat")}
                </MenuItem>
                <MenuItem value={"sail"} className="gap-3">
                  <SailingIcon /> {t("transport-sail")}
                </MenuItem>
                <MenuItem value={"moto"} className="gap-3">
                  <TwoWheelerIcon /> {t("transport-moto")}
                </MenuItem>
                <MenuItem value={"run"} className="gap-3">
                  <DirectionsRunIcon /> {t("transport-run")}
                </MenuItem>
              </Select>
            </FormControl>
            {/* <div
              className={`w-full flex flex-col md:flex-row items-center justify-center md:gap-3 p-3 ${
                routeOpen ? "" : "hidden"
              }`}
            > */}
              {showStrava || showWahoo ? (


              showStrava ? (<StravaActivities setStravaRoute={setStravaRoute} setShowStrava={setShowStrava}/>) : (
              <div>
               <WahooWorkouts setWahooRoute={setWahooRoute} setShowWahoo={setShowWahoo}/>
                </div>
              )

            ) : (
              <div
              className={`w-full flex flex-col items-center justify-center md:gap-3 p-3 ${
                routeOpen ? "" : "hidden"
              }`}>
              {(selectedTransportMode == "hike" ||
                selectedTransportMode == "bike"||
                selectedTransportMode == "run") && (
                <div className="w-full h-full flex flex-col gap-2 items-center justify-center">
                  <div className="w-full flex flex-row gap-2">
                  <label htmlFor="contained-button-file">
                    <Input
                      accept=".gpx"
                      id="contained-button-file"
                      multiple
                      type="file"
                      onChange={handleGPXFileUpload}
                    />
                    <Tooltip title={t("create-gpx-info")}>
                      <Button
                        variant="contained"
                        className="w-[200px]"
                        component="span"
                        startIcon={<CloudUploadIcon />}
                      >
                        GPX
                      </Button>
                    </Tooltip>
                  </label>
                  <Button
                        variant="contained"
                        component="span"
                        className="w-[200px] !bg-orange"
                        startIcon={<CloudDownloadIcon />}
                        onClick={() => handleStrava()}
                      >
                        Strava
                      </Button>
                  <Button
                        variant="contained"
                        component="span"
                        className="w-[200px] !bg-black"

                        startIcon={<CloudDownloadIcon />}
                        onClick={() => handleWahoo()}
                      >
                       Wahoo
                      </Button>
                  </div>

                  <div className="py-3"><Divider>{t('or')}</Divider></div>


                </div>
              )}







            {selectedTransportMode && (
              <>
              {methodUsed === "google" && (
                <>
                <p className="p-2 text-secondary-500">{t('directions-help-google')}</p>
                <RouteSearcher
                  setRoutes={setRoutes}
                  setSelectedRouteIndex={setSelectedRouteIndex}
                  origin={origin}
                  setOrigin={setOrigin}
                  destination={destination}
                  setDestination={setDestination}
                  transportationMethod={selectedTransportMode}
                />
                </>
              )}

              {methodUsed === "mapbox" && (
                <>
                <p className="p-2 text-secondary-500">{t('directions-help-mapbox')}</p>
                <RouteSearcherMapbox
                  mapInstance={mapInstance}
                  transportationMethod={selectedTransportMode}
                  setRoute={setRouteMapbox}
                  newCoord={newCoord}
                  setAllWaypoints={setAllWaypoints}
                  allWaypoints={allWaypoints}
                />
                </>
              )}
              </>
            )}
                                  <div className="py-2 flex flex-row justify-between">
            <Button onClick={handleClose}>{t("close")}</Button>
            <Button onClick={saveFeatureToChapter} disabled={!routeMapbox && !routes}>
              {t("save")} {t("route")}
            </Button>
            </div>
                      </div>

          )}


    </div>
  );
};

export default RouteAdd;
