import React, { FunctionComponent, useState, useEffect, useContext } from "react";
import { useTranslation } from 'react-i18next';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Box from '@mui/material/Box';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import ListItemText from '@mui/material/ListItemText';
import Divider from '@mui/material/Divider';
import Avatar from '@mui/material/Avatar';
import { useNavigate } from 'react-router-dom';
import Stack from '@mui/material/Stack';
import Badge from '@mui/material/Badge';
import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';
import DoneIcon from '@mui/icons-material/Done';
import CloseIcon from '@mui/icons-material/Close';
// @ts-ignore
import { debounce } from "lodash";
import PersonAddIcon from '@mui/icons-material/PersonAdd';
import PersonRemoveIcon from '@mui/icons-material/PersonRemove';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import ListItemButton from '@mui/material/ListItemButton';
import DialogTitle from '@mui/material/DialogTitle';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';

import { CustomTabPanel, a11yProps } from './CustomTabPanel';
import { aproveFollowUser, followUser, unfollowUser, RemoveFollowUser, SetFolloSeenUser } from "../../utils/api/api"
import { useNotification } from '../NotificationContext';

export interface SimpleDialogProps {
  open: boolean;
  selectedValue: any;
  onClose: () => void;
}

function SimpleDialog(props: SimpleDialogProps) {
  const { onClose, selectedValue, open } = props;
  const { t, i18n } = useTranslation();


  const handleClose = () => {
    onClose();
  };

  const handleFollow = async (followId: any) => {
    try {
      // Call the API to approve the follow request
      await followUser(followId);
      onClose();

    } catch (error) {
      console.error('Error updating user:', error);
      // Handle error (e.g., show error message)
    }
  }

  return (
    <Dialog onClose={handleClose} open={open} fullWidth maxWidth={'sm'}>
      <DialogTitle>{t('follow-back')} {selectedValue ? selectedValue.follower.first_name : ''}?</DialogTitle>
      <DialogContent >
<div className="w-full flex justify-center items-center">
<Button fullWidth variant="outlined" color='info' startIcon={<PersonAddIcon />} onClick={() => handleFollow(selectedValue.follower.id)}>
                    {t('user-follow')}
                  </Button>
</div>

                  </DialogContent>

    </Dialog>
  );
}

const UserCommunity: FunctionComponent<any> = ({ community: initialCommunity, user }) => {
  const { t } = useTranslation();
  const { notificationCount, setNotificationCount } = useNotification();

  const [open, setOpen] = React.useState(false);

  const [value, setValue] = useState(0);
  const [selectedValue, setSelectedValue] = useState<any>(null);
  const [community, setCommunity] = useState(initialCommunity);
  const [friends, setFriends] = useState<any[]>([]);
  const [followers, setFollowers] = useState<any[]>([]);
  const [following, setFollowing] = useState<any[]>([]);
  const [pendingRequests, setPendingRequests] = useState<any[]>([]);
  const [width, setWidth] = useState<any>('vertical'); // State to handle the width in percentage of the map



  const navigate = useNavigate();


  const handleResize = debounce(() => {
    if (window.innerWidth <= 768) {
      setWidth('horizontal');
    } else {
      setWidth('vertical');
    }
  }, 100);

  useEffect(() => {
    handleResize();
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  useEffect(() => {
    setCommunity(initialCommunity);
  }, [initialCommunity]);

  useEffect(() => {
    const friendsData = community.filter((relation: any) => relation.request_approved && relation.follower?.id !== user?.id) ?? [];
    const followersData = community.filter((relation: any) => !relation.request_approved && relation.follower?.id !== user?.id) ?? [];
    const followingData = community?.filter((relation: any) => relation.follower?.id ===user?.id) ?? [];
    const pendingRequestsData = community?.filter((relation: any) => !relation.request_seen && relation.followed?.id === user?.id) ?? [];
    setPendingRequests(pendingRequestsData);
    setFriends(friendsData);
    setFollowers(followersData);
    setFollowing(followingData);
  }, [community]);




  const handleApproveFollow = async (followId: any) => {
    try {
      // Call the API to approve the follow request
      await aproveFollowUser(followId);
      // Update the community data to reflect the approval
      const updatedCommunity = community?.map((request: any) => {
        if (request.follower?.id === followId) {
          return { ...request, request_seen: true };
        }
        return request;
      });
      const isIdInCommunity = (id: number): boolean => {
        const isInFollowing = following.some((relation: any) => relation.followed?.id === id);
        const isInFollowers = followers.some((relation: any) => relation.follower?.id === id);
        return isInFollowing && !isInFollowers;
      };
      const findRelationById = (id: number): any => {
        return (
          community.find((relation: any) => relation.follower?.id === id)
        );
      };

      if (!isIdInCommunity(followId)) {
        setOpen(true)
        const relation = findRelationById(followId)
        setSelectedValue(relation)
      }

      setCommunity(updatedCommunity);
      setNotificationCount(notificationCount - 1);
    } catch (error) {
      console.error('Error updating user:', error);
      // Handle error (e.g., show error message)
    }
  }

  const handleCancelFollow = async (followId: any) => {
    try {
      const updatedCommunity = community.map(request => {
        if (request.follower?.id === followId) {
          return { ...request, request_seen: true };
        }
        return request;
      });
      const updatedRequests = pendingRequests.map(request => {
        if (request.follower?.id === followId) {
          return { ...request, request_seen: true };
        }
        return request;
      });
      setNotificationCount(notificationCount - 1)
      setCommunity(updatedCommunity);
      setPendingRequests(updatedRequests);
      await SetFolloSeenUser(followId);
    } catch (error) {
      console.error('Error updating user:', error);
      // Handle error (e.g., show error message)
    }
  }

  const handleFollow = async (followId: string | undefined) => {
    try {
      // Call the API to approve the follow request
      await followUser(followId);
      // setFollowing(prev => [...prev, { followed: { id: followId }, follower: { id: user?.id }, request_approved: true, display:false }]);
      setPendingRequests(prev => [...prev, { followed: { id: followId }, follower: { id: user?.id }, request_approved: false }]);


    } catch (error) {
      console.error('Error updating user:', error);
      // Handle error (e.g., show error message)
    }
  }

  const handleUnFollow = async (followId: string | undefined) => {
    try {
      await unfollowUser(followId);
      setFollowing(prev => prev.filter(follow => follow.followed?.id !== followId));

    } catch (error) {
      console.error('Error updating user:', error);
      // Handle error (e.g., show error message)
    }
  }

  const handleRemoveFollow = async (followId: string | undefined) => {
    try {
      await RemoveFollowUser(followId);
      setFollowers(prev => prev.filter(follow => follow.follower?.id !== followId));

    } catch (error) {
      console.error('Error updating user:', error);
      // Handle error (e.g., show error message)
    }
  }

  const shouldDisplayFollowButton = (followerId: any) => {
    return !following.some(({ followed }) => followed?.id === followerId);
  };


  const navigateToAuthorBlogs = (authorId: any) => {
    navigate(`/${authorId}`);
  };

  const handleClose = () => {
    setOpen(false);
    setSelectedValue(null);
  };


  return (
    <div className="w-full flex flex-col md:flex-row">
            <div className="bg-transparent flex md:h-[224px] w-full md:w-[250px] justify-center"
    >
      <Tabs value={value} onChange={handleChange} aria-label="community tabs" orientation={width}>

        <Tab label={<Badge badgeContent={notificationCount} variant="dot" color="primary">{`${t('user-follow-requests')}`}&nbsp;&nbsp;</Badge>} {...a11yProps(0)} className="!text-xs md:!text-md"/>

        <Tab label={t('user-following')} {...a11yProps(1)} className="!text-xs md:!text-md"/>
        <Tab label={t('user-followers')} {...a11yProps(2)} className="!text-xs md:!text-md"/>
      </Tabs>
      </div>
      <CustomTabPanel value={value} index={0}>
      <SimpleDialog
        selectedValue={selectedValue}
        open={open}
        onClose={handleClose}
      />

      <List className="w-full">
          {community?.map((request: any) => (
            <>
            {!request.request_seen && !(request.follower?.id === user?.id) && (
                <ListItem
                key={request.id}
                className="transition cursor-pointer hover:bg-neutral-100"
                secondaryAction={
                  !request.request_seen && (
                    <Stack spacing={0} direction="row">

                    {/* <IconButton edge="end" aria-label="approve">
                      <DeleteIcon />
                    </IconButton> */}
                          <IconButton onClick={() => handleApproveFollow(request.follower?.id)} className="!text-xs md:!text-md"><DoneIcon/></IconButton>
                          <IconButton color="error" className="!text-xs md:!text-md" onClick={() => handleCancelFollow(request.follower?.id)} ><CloseIcon/></IconButton>
    </Stack>


                  )
                }
              >
                <ListItemAvatar>
                  <Avatar src={`${request.follower?.profile_picture}`} />
                </ListItemAvatar>
                <ListItemText
                  primary={`${request.follower?.first_name} ${request.follower?.last_name}`}
                  onClick={() => navigateToAuthorBlogs(request.follower?.username)}
                />
              </ListItem>
            )}
            </>
          ))}
        </List>
      </CustomTabPanel>
      <CustomTabPanel value={value} index={1}>
      <List className="w-full">
          {following.map((request) => (
                        <>
            <ListItem key={request.id}
            className="transition cursor-pointer hover:bg-neutral-100"
            >
              <ListItemAvatar>
                <Avatar src={`${request.followed?.profile_picture}`} />
              </ListItemAvatar>
              <ListItemText
              primary={`${request.followed?.first_name} ${request.followed?.last_name}`}
              onClick={() => navigateToAuthorBlogs(request.followed?.username)}
              />
              <Stack spacing={0} direction="row">
              {pendingRequests.some(pending => pending.followed?.id === request.followed?.id) && (
              <IconButton  disabled className="!text-xs md:!text-md"><AccessTimeIcon/></IconButton> )}
                <IconButton
                onClick={() => handleUnFollow(request.followed?.id)}
                color="error"
                className="!text-xs md:!text-md"
                >
                    <PersonRemoveIcon/></IconButton>
                    </Stack>
                        </ListItem>

                        </>

                      ))}
                    </List>
      </CustomTabPanel>
      <CustomTabPanel value={value} index={2}>
      <List className="w-full">
      <div className="w-full pl-2 pb-2">
{t('friends')}
</div>
        <Divider variant="middle" />
          {friends.map((request) => (


                <ListItem key={request.id}
                className="transition hover:bg-neutral-100 cursor-pointer"
                >
                  <ListItemAvatar>
                    <Avatar src={`${request.follower?.profile_picture}`} />
                  </ListItemAvatar>
                  <ListItemText
                  primary={`${request.follower?.first_name} ${request.follower?.last_name}`}
                  onClick={() => navigateToAuthorBlogs(request.follower?.username)}
                  />
                <Stack spacing={0} direction="row">

                {shouldDisplayFollowButton(request.follower?.id) && (
                  pendingRequests.some(pending => pending.followed?.id === request.follower?.id) ?
                    <></>:
                    <IconButton  onClick={() => handleFollow(request.follower?.id)} className="!text-xs md:!text-md">{t('user-follow')}</IconButton>
                )}
                  <IconButton
                  color="error"
                  onClick={() => handleRemoveFollow(request.follower?.id)}
                  className="!text-xs md:!text-md"><PersonRemoveIcon/></IconButton>
                  </Stack>
                              </ListItem>
          ))}
        </List>

<div className="w-full pl-2 pb-2">
{t('following-public')}
</div>
        <Divider variant="middle" />

        <List className="w-full">
          {followers.map((request) => (


                <ListItem key={request.id}
                className="transition hover:bg-neutral-100 cursor-pointer"
                >
                  <ListItemAvatar>
                    <Avatar src={`${request.follower?.profile_picture}`} />
                  </ListItemAvatar>
                  <ListItemText
                  primary={`${request.follower?.first_name} ${request.follower?.last_name}`}
                  onClick={() => navigateToAuthorBlogs(request.follower?.username)}
                  />
                <Stack spacing={0} direction="row">

                {shouldDisplayFollowButton(request.follower?.id) && (
                  pendingRequests.some(pending => pending.followed?.id === request.follower?.id) ?
                    <></>:
                    <IconButton  onClick={() => handleFollow(request.follower?.id)} className="!text-xs md:!text-md">{t('user-follow')}</IconButton>
                )}
                  <IconButton
                  color="error"
                  onClick={() => handleRemoveFollow(request.follower?.id)}
                  className="!text-xs md:!text-md"><PersonRemoveIcon/></IconButton>
                  </Stack>
                              </ListItem>
          ))}
        </List>

      </CustomTabPanel>
    </div>
  );
};

export default UserCommunity;
