import React from "react"
import { useTranslation } from "react-i18next"
import {Button, Popover, PopoverTrigger, PopoverContent} from "@nextui-org/react";
import LayersIcon from '@mui/icons-material/Layers';
import HelpIcon from '@mui/icons-material/Help';
import DirectionsWalkIcon from '@mui/icons-material/DirectionsWalk';

export default function InfoButton() {
  const { t } = useTranslation()

  return (
    <Popover placement="left-start">
      <PopoverTrigger>
        <Button
          variant="solid"
          isIconOnly
          className="absolute top-16 right-2 h-10 w-10 rounded-full bg-background/80 backdrop-blur-sm"
        >
          <HelpIcon className="h-5 w-5"/>
          <span className="sr-only">Open information</span>
        </Button>
      </PopoverTrigger>
      <PopoverContent className="w-80">
        <div className="space-y-4">
          <div className="space-y-2">
            <div className="flex items-center">
              <div className="flex h-8 w-8 items-center justify-center rounded-full border">
                <DirectionsWalkIcon className="h-4 w-4" />
              </div>
              <h4 className="ml-2 font-semibold">{t("blog-icons")}</h4>
            </div>
            <p className="text-sm text-muted-foreground">
              {t("blog-icons-explanaition")}
            </p>
          </div>
          <div className="space-y-2">
            <div className="flex items-center">
              <div className="flex h-8 w-8 items-center justify-center rounded-full border">
                <LayersIcon className="h-4 w-4" />
              </div>
              <h4 className="ml-2 font-semibold">{t("blog-layers")}</h4>
            </div>
            <p className="text-sm text-muted-foreground">
              {t("blog-layers-explanaition")}
            </p>
          </div>
        </div>
      </PopoverContent>
    </Popover>
  )
}
