import { useLocation } from "react-router-dom";
import Chip from '@mui/material/Chip';
import Button from '@mui/material/Button';

import Navbar from "../components/main/Navbar";
import Footer from "../components/main/Footer";

const ErrorPage = () => {

  return (
    <div className="w-full h-[100vh] relative bg-primary-contrast overflow-hidden flex flex-col items-center justify-start">
    <Navbar />
    <div className="w-full pt-[70px] flex flex-row justify-center align-center">
    <div className="w-full h-screen flex flex-row justify-center items-center grid-cols-2">
      <div className="w-1/2 flex flex-col items-center justify-center py-20 px-10 box-border flex-wrap">
        <h1 className="font-roboto text-xl font-normal"> ! Something went wrong</h1>
        <div className="overflow-hidden flex flex-col items-start justify-center box-border">
            <h3 className="font-roboto font-[400]">You do not have access to this page.</h3>

            <h3 className="font-roboto font-light">If this is an error, could you please comment on <a className="font-semibold text-olivegreen" target="_blank" href="https://www.figma.com/file/4O4GxXv4HL4EE1AtaFw4Bd/slostr?type=whiteboard&node-id=0%3A1&t=inhybnTWEXllvyUl-1">this board</a> what you did when the error occured?</h3>
        {/* <Button variant="contained">Return to Homepage</Button> */}

        </div>
      </div>
      <img
          className="max-h-[60vh] w-1/2 overflow-hidden shrink-0 object-cover max-w-full m-10 lg:flex-1 rounded-[20px] shadow-xl"
          loading="lazy"
          alt=""
          src="/broken.png"
        />
        </div>
            </div>
      <Footer/>
    </div>
  );
};

export default ErrorPage;



