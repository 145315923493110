import React, { useEffect, useState } from "react";
import {
  Box,
  Chip,
  Drawer,
  IconButton,
  Typography,
  Divider,
} from "@mui/material";
import { LocationOn, FilterList, Timer, Close } from "@mui/icons-material";
import { useTranslation } from "react-i18next";

import { fetchTags, fetchStartCities } from "../../utils/api/api";

const FilterMenu = ({ onFilterChange, geoData, trips, setTripsShown }) => {
    const { t, i18n } = useTranslation();

  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const [filterTags, setFilterTags] = useState([]);
  const [startCities, setStartCities] = useState([]);
  const [selectedTags, setSelectedTags] = useState([]);
  const [selectedCity, setSelectedCity] = useState(null);
  const [selectedDurations, setSelectedDurations] = useState([]);

  const durationRanges = [
    { label: "< 1h", min: 0, max: 1 },
    { label: "1-3h", min: 1, max: 3 },
    { label: "3-5h", min: 3, max: 5 },
    { label: "5-10h", min: 5, max: 10 },
    { label: "> 10h", min: 10, max: 999 },
  ];

  useEffect(() => {
    const fetchFilterTags = async () => {
      try {
        const data = await fetchTags();
        setFilterTags(data);
      } catch (error) {
        console.error("Error fetching tags:", error);
      }
    };

    fetchFilterTags();
  }, [i18n]);

  useEffect(() => {
    const fetchCities = async () => {
      try {
        const data = await fetchStartCities();
        setStartCities(data);
      } catch (error) {
        console.error("Error fetching cities:", error);
      }
    };

    fetchCities();
  }, [i18n]);

  const filterGeodata = (data, trips, tags = [], city = null, durations = []) => {
    let filteredData = data;

    // Filter by tags
    if (tags.length > 0) {
      filteredData = filteredData.filter((item) =>
        tags.every((tag) =>
          item.tags && item.tags.some((itemTag) => itemTag.id === tag.id)
        )
      );
    }

    // Filter by city
    if (city) {
      const filteredTrips = trips.filter((trip) => trip.start_city === city.id);
      const endSpots = filteredTrips.map((trip) => trip.end_spot);
      setTripsShown(filteredTrips);
      filteredData = filteredData.filter((spot) => endSpots.includes(spot.id));
    }

    // Filter by duration ranges
    if (durations.length > 0) {
      const filteredTrips = trips.filter((trip) => {
        const [hours, minutes] = trip.total_time.split(":").map(Number);
        const totalMinutes = hours * 60 + minutes;
        return durations.some(
          (range) => totalMinutes >= range.min * 60 && totalMinutes <= range.max * 60
        );
      });

      const endSpots = filteredTrips.map((trip) => trip.end_spot);
      setTripsShown(filteredTrips);
      filteredData = filteredData.filter((spot) => endSpots.includes(spot.id));
    }

    return filteredData;
  };

  const applyFilters = () => {
    const filteredData = filterGeodata(
      geoData,
      trips,
      selectedTags,
      selectedCity,
      selectedDurations
    );
    onFilterChange(filteredData);
  };

  useEffect(() => {
    applyFilters();
  }, [selectedTags, selectedCity, selectedDurations]);

  const handleSelectTag = (tag) => {
    setSelectedTags((prev) =>
      prev.some((selected) => selected.id === tag.id)
        ? prev.filter((selected) => selected.id !== tag.id)
        : [...prev, tag]
    );
  };

  const handleSelectCity = (city) => {
    setSelectedCity(selectedCity?.id === city.id ? null : city);
  };

  const handleSelectDuration = (duration) => {
    setSelectedDurations((prev) =>
      prev.some((selected) => selected.label === duration.label)
        ? prev.filter((selected) => selected.label !== duration.label)
        : [...prev, duration]
    );
  };

  return (
    <Box sx={{ p: 2, position: "absolute", top: 80, left: 0, right: 0 }}>
      <Box display="flex" gap={2}>
        <Chip
          label={selectedCity ? selectedCity.name : t('spot.start-city')}
          icon={<LocationOn />}
          onClick={() => setIsDrawerOpen(true)}
          style={{
            color: "#555",
            backgroundColor: "white",
            borderColor: "#8ABFA3",
            borderWidth: 1,
            border: "solid",
            cursor: "pointer",
          }}
        />
        <Chip
          label={t('spot.tags')}
          icon={<FilterList />}
          onClick={() => setIsDrawerOpen(true)}
          style={{
            color: "#555",
            backgroundColor: "white",
            borderColor: "#8ABFA3",
            borderWidth: 1,
            border: "solid",
            cursor: "pointer",
          }}
        />
        {selectedCity && (
          <Chip
            label={t('spot.duration')}
            icon={<Timer />}
            onClick={() => setIsDrawerOpen(true)}
            style={{
              color: "#555",
              backgroundColor: "white",
              borderColor: "#8ABFA3",
              borderWidth: 1,
              border: "solid",
              cursor: "pointer",
            }}
          />
        )}
      </Box>

      <Drawer
        anchor="left"
        open={isDrawerOpen}
        onClose={() => setIsDrawerOpen(false)}
      >
        <Box
          sx={{
            width: 300,
            p: 2,
            display: "flex",
            flexDirection: "column",
            height: "100%",
          }}
        >
          <Box display="flex" justifyContent="space-between" alignItems="center">
            <Typography variant="h6">{t('spot.filters')}</Typography>
            <IconButton onClick={() => setIsDrawerOpen(false)}>
              <Close />
            </IconButton>
          </Box>
          <Divider sx={{ my: 2 }} />


          {/* Cities Filter */}
          <Typography variant="subtitle1">{t('spot.start-city')}</Typography>
          <Box sx={{ display: "flex", flexWrap: "wrap", gap: 1, mb: 4 }}>
            {startCities.map((city) => (
              <Chip
                key={city.id}
                label={city.name}
                onClick={() => handleSelectCity(city)}
                color={selectedCity?.id === city.id ? "primary" : "default"}
              />
            ))}
          </Box>

          {/* Tags Filter */}
          <Typography variant="subtitle1">{t('spot.tags')}</Typography>
          <Box sx={{ display: "flex", flexWrap: "wrap", gap: 1, mb: 4 }}>
            {filterTags.map((tag) => (
              <Chip
                key={tag.id}
                label={tag.name}
                onClick={() => handleSelectTag(tag)}
                color={
                  selectedTags.some((selected) => selected.id === tag.id)
                    ? "primary"
                    : "default"
                }
              />
            ))}
          </Box>

          {/* Duration Filter */}
          <Typography variant="subtitle1">{t('spot.duration')}</Typography>
          <Box sx={{ display: "flex", flexWrap: "wrap", gap: 1 }}>
            {durationRanges.map((range) => (
              <Chip
                key={range.label}
                label={range.label}
                onClick={() => handleSelectDuration(range)}
                color={
                  selectedDurations.some((selected) => selected.label === range.label)
                    ? "primary"
                    : "default"
                }
              />
            ))}
          </Box>
        </Box>
      </Drawer>
    </Box>
  );
};

export default FilterMenu;
