import { FunctionComponent, useState, useEffect } from "react";
import { useParams } from 'react-router-dom';
import CircularProgress from '@mui/material/CircularProgress';
import { useTranslation } from 'react-i18next';
import Skeleton from '@mui/material/Skeleton';
import Box from '@mui/material/Box';

import Navbar from "../components/main/Navbar";
import Header from "../components/blog/Header";
import Content from "../components/blog/Content";
import Footer from "../components/main/Footer";

import { fetchListBlogs, fetchPrioritizedUsers } from '../utils/api/api';

const activityHierarchy = [
  "surf",
  "climbing",
  "ski",
  "hiking",
  "bike",
  "train",
  "city_trip",
  "sailing",
];

const getBlogTheme = (activities: any) => {
  for (const theme of activityHierarchy) {
    if (activities.some((activity: any) => activity.name === theme)) {
      return theme;
    }
  }
  return "other";
};


const AllBlogsPage: FunctionComponent = () => {
  const { t, i18n } = useTranslation();

  const [groupedBlogs, setGroupedBlogs] = useState({});
  const [groupedUsers, setGroupedUsers] = useState({});

  useEffect(() => {
    fetchListBlogs({ daysRange: [0, 365], activities: [], visibility: "public" })
      .then((data) => {
        const sortedData = data.sort(
          (a, b) => new Date(b.date).getTime() - new Date(a.date).getTime()
        );
        const groupedByTheme: Record<string, any[]> = {};
        sortedData.forEach((blog) => {
          const theme = getBlogTheme(blog.activities);
          if (!groupedByTheme[theme]) groupedByTheme[theme] = [];
          groupedByTheme[theme].push(blog);
        });
        setGroupedBlogs(groupedByTheme);
      })
      .catch((error) => console.error("Error fetching blogs:", error));
  }, []);

  useEffect(() => {
    fetchPrioritizedUsers()
      .then((users) => {
        const groupedByTheme: Record<string, any[]> = {};
        users.forEach((user) => {
          const theme = user.theme.name; // Assuming API returns a theme
          if (!groupedByTheme[theme]) groupedByTheme[theme] = [];
          groupedByTheme[theme].push(user);
        });
        setGroupedUsers(groupedByTheme);
      })
      .catch((error) => console.error("Error fetching users:", error));
  }, []);



  return (
    <div className={`w-full relative bg-gradient-to-br from-slate-50 to-zinc-100 overflow-hidden gap-[30px] min-h-screen`}>
      <Navbar />
      <section className="w-full flex flex-col items-center justify-start pt-[90px] px-5 box-border gap-[48px] max-w-full text-left text-xl text-secondary-800 min-h-screen ">
      {/* <Header
      blogs={blogs}
      /> */}
        <div className="flex flex-col items-start justify-center gap-[32px] max-w-full text-2xl">

          {groupedBlogs ? (
<>
        <div className="flex flex-col items-start justify-center max-w-full text-2xl">
          {/* <div className="flex flex-row items-start justify-start max-w-full">
            <h1 className="text-start relative text-inherit leading-[28px] font-bold font-inherit inline-block max-w-full text-slate-700">
            {t('explore-latest')}
            </h1>
          </div> */}
          <Content groupedBlogs={groupedBlogs} groupedUsers={groupedUsers} />

        </div></>
        ) : (
<div className="w-full flex flex-col items-center justify-center gap-4">
<div className="w-full flex flex-col md:flex-row items-center justify-center gap-4">
{/* <Grid className="w-full flex items-center" container> */}
      {Array.from(new Array(3)).map((item, index: any) => (
        <Box key={index} sx={{ width: 250, my: 5 }}>

            <Skeleton variant="rectangular" width={250} height={200} />
            <Box sx={{ pt: 0.5 }}>
              <Skeleton />
              <Skeleton width="60%" />
            </Box>
        </Box>
      ))}
    {/* </Grid> */}
    </div>
    <div className="w-full flex flex-col md:flex-row items-center justify-center gap-4">
{/* <Grid className="w-full flex items-center" container> */}
      {Array.from(new Array(3)).map((item, index: any) => (
        <Box key={index} sx={{ width: 250, my: 5 }}>

            <Skeleton variant="rectangular" width={250} height={200} />
            <Box sx={{ pt: 0.5 }}>
              <Skeleton />
              <Skeleton width="60%" />
            </Box>
        </Box>
      ))}
    {/* </Grid> */}
    </div>

</div>
        )}
        </div>
      </section>
      <Footer />
    </div>
  );
};

export default AllBlogsPage;
