import React, { useState } from "react";
import {
  Box,
  Collapse,
  IconButton,
  Typography,
  Divider,
} from "@mui/material";
import { ExpandMore, AccessTime, AttachMoney, Hiking } from "@mui/icons-material";
import Timeline from "@mui/lab/Timeline";
import TimelineItem, { timelineItemClasses } from "@mui/lab/TimelineItem";
import TimelineSeparator from "@mui/lab/TimelineSeparator";
import TimelineConnector from "@mui/lab/TimelineConnector";
import TimelineContent from "@mui/lab/TimelineContent";
import TimelineDot from "@mui/lab/TimelineDot";
import { FaHiking, FaBicycle, FaTrain, FaBus } from "react-icons/fa";
import { useTranslation } from "react-i18next";
import { LocationOn } from "@mui/icons-material";

const transportationIcons = {
  hiking: <FaHiking />,
  hike: <FaHiking />,
  bike: <FaBicycle />,
  train: <FaTrain />,
  bus: <FaBus />,
};

const TripDetailsPanel = ({ mainPoiId, tripsShown }) => {
  const { t } = useTranslation();

  const [showContent, setShowContent] = useState(true);

  const toggleContent = () => {
    setShowContent(!showContent);
  };

  const tripData = tripsShown.filter((trip) => trip.end_spot === mainPoiId);

  const formatDuration = (duration) => {
    const [daysPart, timePart] = duration.includes(" ")
      ? duration.split(" ")
      : [null, duration];
    const [hours, minutes] = timePart.split(":").map((part) => parseInt(part, 10));
    const days = daysPart ? parseInt(daysPart, 10) : 0;

    let formattedDuration = "";
    if (days) formattedDuration += `${days}d `;
    if (hours) formattedDuration += `${hours}h `;
    if (minutes || !formattedDuration) formattedDuration += `${minutes}m`;

    return formattedDuration.trim();
  };

  return (
    <Box sx={{ border: "1px solid grey", borderRadius: 2, p: 2, mb: 2 }}>
      {/* Toggle Button */}
      <Box
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        onClick={toggleContent}
      >
        <Typography variant="h6">{t("spot.approach")}</Typography>
        <IconButton size="small">
          <ExpandMore
            sx={{ transform: showContent ? "rotate(180deg)" : "rotate(0deg)" }}
          />
        </IconButton>
      </Box>

      <Collapse in={showContent}>
        <Box mt={2}>
          {tripData.length > 0 ? (
            <Box>
              {/* Trip Summary */}
              <Box display="flex" justifyContent="space-between" mb={2}>
                <Box display="flex" alignItems="center" gap={1}>
                  <AccessTime fontSize="small" />
                  <Typography variant="body1">
                    ~{formatDuration(tripData[0].total_time)}
                  </Typography>
                </Box>
                <Box display="flex" alignItems="center" gap={1}>
                  <AttachMoney fontSize="small" />
                  <Typography variant="body1">
                    ~€{tripData[0].approximate_cost}
                  </Typography>
                </Box>
              </Box>

              {/* Timeline for Stages */}
              <Timeline
  sx={{
    [`& .${timelineItemClasses.root}:before`]: {
      flex: 0,
      padding: 0,
    },
  }}
>
  {tripData[0].stages.map((stage, index) => (
    <TimelineItem key={index}>
      <TimelineSeparator>
        <TimelineDot color="primary">
          {transportationIcons[stage.transportation_mode]}
        </TimelineDot>
        <TimelineConnector />
      </TimelineSeparator>
      <TimelineContent sx={{ px: 2 }}>
        {/* Start Location */}
        <Typography variant="body1" fontWeight="bold">
          {stage.start}
        </Typography>

        {/* Description */}
        {stage.description && (
          <Typography variant="body2" sx={{ mt: 0.5 }}>
            {stage.description}
          </Typography>
        )}

        {/* Operator */}
        {stage.operator && (
          <Typography variant="body2" color="text.secondary" sx={{ mt: 0.5 }}>
            {t("Operator")}: {stage.operator}
          </Typography>
        )}
      </TimelineContent>
    </TimelineItem>
  ))}

  {/* Last Stage with Map Pin */}
  <TimelineItem>
    <TimelineSeparator>
      <TimelineDot color="primary">
        <LocationOn sx={{fontSize: 17}}/>
      </TimelineDot>
    </TimelineSeparator>
    <TimelineContent sx={{ px: 2, display: 'flex', pt: 2}}>
      <Typography variant="body1" fontWeight="bold">
        {tripData[0].stages[tripData[0].stages.length - 1].end}
      </Typography>
    </TimelineContent>
  </TimelineItem>
</Timeline>
            </Box>
          ) : (
            <Typography>{t("spot.no_trip_data")}</Typography>
          )}
        </Box>
      </Collapse>
    </Box>
  );
};

export default TripDetailsPanel;
