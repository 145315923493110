import React, { useState, useEffect } from 'react';
import { Button, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Pagination, Typography } from '@mui/material';
import DirectionsRunIcon from '@mui/icons-material/DirectionsRun';
import DirectionsBikeIcon from '@mui/icons-material/DirectionsBike';
import { format } from 'date-fns';
import TableFooter from '@mui/material/TableFooter';
import TablePagination from '@mui/material/TablePagination';
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import FirstPageIcon from '@mui/icons-material/FirstPage';
import KeyboardArrowLeft from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRight from '@mui/icons-material/KeyboardArrowRight';
import LastPageIcon from '@mui/icons-material/LastPage';
import { useTheme } from '@mui/material/styles';
import CircularProgress from '@mui/material/CircularProgress';
import CloudDownloadIcon from '@mui/icons-material/CloudDownload';
import CloseIcon from '@mui/icons-material/Close';

// @ts-ignore
import DatePicker from "react-datepicker";

import "react-datepicker/dist/react-datepicker.css";


import { fetchStravaActivitiesAPI, fetchStravaActivityDetailAPI } from "../../utils/api/api";

const StravaActivities: React.FC<any>  = ({setStravaRoute, setShowStrava}) => {
  const [activities, setActivities] = useState([]);
  const [loading, setLoading] = useState(false);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(5);
  const [beforeDate, setBeforeDate] = useState(new Date());


  const fetchActivities = async (before = null) => {
    setLoading(true);
    try {
      const response = await fetchStravaActivitiesAPI(before);
      const filteredActivities = response
      setActivities(response);
    } catch (error) {
      console.error('Error fetching activities:', error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchActivities();
  }, []);

  const fetchActivityDetail = async (activityId: any) => {
    setLoading(true);
    try {
      const response = await fetchStravaActivityDetailAPI(activityId);
      setStravaRoute(response);
    } catch (error) {
      console.error('Error fetching activity detail:', error);
    } finally {
      setLoading(false);
    }
  };



  const formatDuration = (seconds: any) => {
    const hours = Math.floor(seconds / 3600);
    const minutes = Math.floor((seconds % 3600) / 60);
    return `${hours}h ${minutes}m`;
  };

  interface TablePaginationActionsProps {
    count: number;
    page: number;
    rowsPerPage: number;
    onPageChange: (
      event: React.MouseEvent<HTMLButtonElement>,
      newPage: number,
    ) => void;
  }

  function TablePaginationActions(props: TablePaginationActionsProps) {
    const theme = useTheme();
    const { count, page, rowsPerPage, onPageChange } = props;

    const handleFirstPageButtonClick = (
      event: React.MouseEvent<HTMLButtonElement>,
    ) => {
      onPageChange(event, 0);
    };

    const handleBackButtonClick = (event: React.MouseEvent<HTMLButtonElement>) => {
      onPageChange(event, page - 1);
    };

    const handleNextButtonClick = (event: React.MouseEvent<HTMLButtonElement>) => {
      onPageChange(event, page + 1);
    };

    const handleLastPageButtonClick = (event: React.MouseEvent<HTMLButtonElement>) => {
      onPageChange(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
    };

    return (
      <Box sx={{ flexShrink: 0, ml: 2.5 }}>
        <IconButton
          onClick={handleFirstPageButtonClick}
          disabled={page === 0}
          aria-label="first page"
        >
          {theme.direction === 'rtl' ? <LastPageIcon /> : <FirstPageIcon />}
        </IconButton>
        <IconButton
          onClick={handleBackButtonClick}
          disabled={page === 0}
          aria-label="previous page"
        >
          {theme.direction === 'rtl' ? <KeyboardArrowRight /> : <KeyboardArrowLeft />}
        </IconButton>
        <IconButton
          onClick={handleNextButtonClick}
          disabled={page >= Math.ceil(count / rowsPerPage) - 1}
          aria-label="next page"
        >
          {theme.direction === 'rtl' ? <KeyboardArrowLeft /> : <KeyboardArrowRight />}
        </IconButton>
        <IconButton
          onClick={handleLastPageButtonClick}
          disabled={page >= Math.ceil(count / rowsPerPage) - 1}
          aria-label="last page"
        >
          {theme.direction === 'rtl' ? <FirstPageIcon /> : <LastPageIcon />}
        </IconButton>
      </Box>
    );
  }

  const handleChangePage = (
    event: React.MouseEvent<HTMLButtonElement> | null,
    newPage: number,
  ) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
  ) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleDateChange = (date: any) => {
    setBeforeDate(date);
    const beforeTimestamp:any = Math.floor(date.getTime() / 1000); // Convert selected date to epoch timestamp
    fetchActivities(beforeTimestamp);
  };

  const emptyRows =
  page > 0 ? Math.max(0, (1 + page) * rowsPerPage - activities.length) : 0;

  return (
    <div className='w-full h-full pt-3'>

{loading ? (
      <CircularProgress />

) : (
    <div className='w-full flex justify-between items-center'>
        <div className='flex flex-row gap-2 items-center'>
        <p className='text-secondary-500 font-roboto'>Filter by activities before</p>

        <DatePicker
    showIcon
    icon="fa fa-calendar"
  selected={beforeDate}
  onSelect={handleDateChange} //when day is clicked
  onChange={handleDateChange} //only when value has changed
/>
        </div>

<IconButton onClick={() => setShowStrava(false)}>
                    <CloseIcon/>
                  </IconButton>
</div>
)}

      <TableContainer component={Paper} style={{ marginTop: 20 }}>
        <Table size="small">
          <TableHead>
            <TableRow>
            <TableCell>Select</TableCell>
              <TableCell>Name</TableCell>
              <TableCell>Type</TableCell>
              <TableCell>Distance (km)</TableCell>
              <TableCell>Duration</TableCell>
              <TableCell>Date</TableCell>

            </TableRow>
          </TableHead>

          <TableBody>
          {(rowsPerPage > 0
            ? activities.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
            : activities
          ).map((row: any) => (

            <TableRow key={row.id}>
                                <TableCell>
                  <IconButton onClick={() => fetchActivityDetail(row.id)}>
                    <CloudDownloadIcon/>
                  </IconButton>
                </TableCell>
                <TableCell component="th" scope="row" className='w-[150px]'>
              {row.name }
              </TableCell>

              <TableCell component="th" scope="row">
              {row.type === 'Run' ? <DirectionsRunIcon /> : row.type === 'Ride' ?<DirectionsBikeIcon /> : row.type}
              </TableCell>
              <TableCell>{(row.distance / 1000).toFixed(2)}</TableCell>
                <TableCell>{formatDuration(row.moving_time)}</TableCell>
                <TableCell>{format(new Date(row.start_date_local), 'dd-MM-yyyy')}</TableCell>
            </TableRow>
          ))}
          {emptyRows > 0 && (
            <TableRow style={{ height: 53 * emptyRows }}>
              <TableCell colSpan={6} />
            </TableRow>
          )}
        </TableBody>
          <TableFooter>
          <TableRow >
            <TablePagination
              rowsPerPageOptions={[5, 10, 25, { label: 'All', value: -1 }]}
              colSpan={3}
              count={activities.length}
              rowsPerPage={rowsPerPage}
              page={page}
              className='!overflow-visible'
              slotProps={{
                select: {
                  inputProps: {
                    'aria-label': 'rows per page',
                  },
                  native: true,
                },
              }}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
              ActionsComponent={TablePaginationActions}
            />
          </TableRow>
        </TableFooter>
        </Table>
      </TableContainer>
    </div>
  );
};

export default StravaActivities;
