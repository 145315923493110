// NewBlogWritingPage.tsx
import React, { FunctionComponent, useEffect, useState, useContext } from "react";
import { useParams } from "react-router-dom";
import CircularProgress from "@mui/material/CircularProgress";
import { useTranslation } from 'react-i18next';


import NewBlogWritingContent from "../components/blog/NewBlogWritingContent";
import { fetchNewBlogById } from "../utils/api/api";
import Navbar from "../components/main/Navbar";
import Footer from "../components/main/Footer";
import { AuthContext } from "../components/authentification/AuthContext";
import ErrorPage from "./ErrorPage";




const NewBlogWritingPage: FunctionComponent<any> = ({map, setMap, mapContainerRef,allLayers, setAllLayers, allMarkers, mapAnimation,
  setMapAnimation, setAllMarkers, startAtChapters = false}) => {
  const { t } = useTranslation();
  const authContext = useContext(AuthContext);
  const { user } = authContext || {};

  const { id } = useParams<{ id: string }>();
  const [blog, setBlog] = useState<any | null>(null);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isAllowed, setIsAllowed] = useState<boolean>(false);

  useEffect(() => {
    if (id) {
      setIsLoading(true); // Start loading
      fetchNewBlogById(id)
        .then((fetchedBlog) => {
          setBlog(fetchedBlog);
          if (user.id === fetchedBlog.author.id || fetchedBlog.contributors.some((contributor: any) => contributor.id === user.id)) {
            setIsAllowed(true)
          }
        })
        .finally(() => {
          setIsLoading(false); // Finish loading
        });
    }
  }, [id]);

  return (
    <>
          <Navbar />
          <div className="w-full h-screen relative bg-primary-contrast overflow-hidden flex flex-col items-center justify-start">
      <div className="w-full h-full relative bg-white flex flex-col items-center justify-center">

          {isLoading ? (
            // Display a loader or a placeholder component while data is being fetched
            <div className="spinner">
            <CircularProgress />
            {t('loading')}
          </div>
          ) : (
            // Once data is fetched or if creating a new blog (id is undefined), render the BlogWritingContent component
            isAllowed || !blog ? (
              <NewBlogWritingContent blog={blog} startAtChapters={startAtChapters} map={map}
              setMap={setMap}
              mapContainerRef={mapContainerRef}
              mapAnimation={mapAnimation}
                setMapAnimation={setMapAnimation}
              allLayers={allLayers}
              setAllLayers={setAllLayers}
              allMarkers={allMarkers}
              setAllMarkers={setAllMarkers}
/>
            ) : (
              <ErrorPage/>
            )

          )}
      </div>
      {/* <Footer/> */}
    </div>
    </>
  );
};

export default NewBlogWritingPage;
