import React, { useEffect, useState, useRef, useContext, lazy, Suspense } from "react";
import {
  Routes,
  Route,
  useNavigationType,
  useLocation,
  useMatch,
  createBrowserRouter,
} from "react-router-dom";
import ReactGA from "react-ga4";
import { useError } from "./components/errors/ErrorContext";
import { AuthContext } from './components/authentification/AuthContext';
import AuthenticatedRoute from './components/authentification/AuthenticatedRoute';
import MaintenanceBanner from "./components/main/MaintenanceBanner";
import ErrorModal from "./components/errors/ErrorModal";
import AppPopup from './components/main/AppPopup';


// const SignUp = lazy(() => import("./pages/SignUp"));
// const MapContainer = lazy(() => import("./components/map/MapContainer"));
// const NewBlogWritingPage = lazy(() => import("./pages/NewBlogWritingPage"));
// const NewBlogEditPage = lazy(() => import("./pages/NewBlogEditPage"));
// const MunicipalWritingPage = lazy(() => import("./pages/MunicipalityWritingPage"));
// const MunicipalPage = lazy(() => import("./pages/MunicipalPage"));
// const AuthorPage = lazy(() => import("./pages/AuthorBlogList"));
// const AllBlogsPage = lazy(() => import("./pages/AllBlogsList"));
// const MunicipalRouteView = lazy(() => import("./pages/MunicipalRouteView"));
// const UserSettingsPage = lazy(() => import("./pages/UserPage"));
// const GPXDownloadPage = lazy(() => import("./pages/GPXDownloadPage"));
// const ImprovedFeedbackBoard = lazy(() => import("./pages/Feedback"));
// const NewSpotPage = lazy(() => import("./pages/NewSpotPage"));

// import LoadingPage from "./pages/Loading";
// import LandingPage from "./pages/LandingPage";
// import {
//   TermsOfUse,
//   PrivacyPolicy,
//   CookiesPolicy,
// } from "./pages/policies/Policies";
// const MapboxMap = lazy(() => import("./pages/HomeMap"));
// const AboutUs = lazy(() => import("./pages/AboutUs"));
// const HowWorks = lazy(() => import("./pages/HowWorks"));
// const NewBlogView = lazy(() => import("./pages/NewBlogView"));
// const Login = lazy(() => import("./pages/Login"));
// const Welcome = lazy(() => import("./pages/Welcome"));
// const TestUser = lazy(() => import("./pages/TestUser"));
// const VerifyMail = lazy(() => import("./pages/VerifyMail"));
// const ActivateMail = lazy(() => import("./pages/ActivateMail"));
// const FeedPage = lazy(() => import("./pages/Feed"));

import SignUp from "./pages/SignUp";
import MapContainer from "./components/map/MapContainer";
import NewBlogWritingPage from "./pages/NewBlogWritingPage";
import NewBlogEditPage from "./pages/NewBlogEditPage";
import MunicipalWritingPage from "./pages/MunicipalityWritingPage";
import MunicipalPage from "./pages/MunicipalPage";
import AuthorPage from "./pages/AuthorBlogList";
import AllBlogsPage from "./pages/AllBlogsList";
import MunicipalRouteView from "./pages/MunicipalRouteView";
import UserSettingsPage from "./pages/UserPage";
import GPXDownloadPage from "./pages/GPXDownloadPage";
import ImprovedFeedbackBoard from "./pages/Feedback";
import NewSpotPage from "./pages/NewSpotPage";
import LoadingPage from "./pages/Loading";
import LandingPage from "./pages/LandingPage";
import { TermsOfUse, PrivacyPolicy, CookiesPolicy } from "./pages/policies/Policies";
import MapboxMap from "./pages/HomeMap";
import AboutUs from "./pages/AboutUs";
import HowWorks from "./pages/HowWorks";
import NewBlogView from "./pages/NewBlogView";
import Login from "./pages/Login";
import Welcome from "./pages/Welcome";
import TestUser from "./pages/TestUser";
import VerifyMail from "./pages/VerifyMail";
import ActivateMail from "./pages/ActivateMail";
import FeedPage from "./pages/Feed";
import ClimbingSpotsMap from "./pages/ClimbingSpots";


function usePageViews() {
  let location = useLocation();

  useEffect(() => {
    ReactGA.initialize("G-ZMZDWKCGCP");
    trackPage(location.pathname);
  }, [location]);

  const trackPage = (page: any) => {
    ReactGA.send({ hitType: "pageview", page: page });
  };
}

function App() {
  const action = useNavigationType();
  const location = useLocation();
  const pathname = location.pathname;
  const authContext = useContext(AuthContext);
  const isAuthenticated = authContext?.isAuthenticated || false;
  const { user, logout } = authContext || {};  const [isErrorModalOpen, setIsErrorModalOpen] = useState(false);
  const [errorDetails, setErrorDetails] = useState<any>({
    message: "",
    data: null,
  });
  const { showError, hideError } = useError();
  const [map, setMap] = useState<any>(null);
  const [mapAnimation, setMapAnimation] = useState<any>(null);
  const mapContainerRef = useRef(null);
  const [allLayers, setAllLayers] = useState<any[]>([]);
  const [allMarkers, setAllMarkers] = useState<any[]>([]);

  usePageViews();

  // useEffect(() => {
  //   if (action !== "POP") {
  //     window.scrollTo(0, 0);
  //   }
  // }, [action, pathname]);

  useEffect(() => {
    let title = "";
    let metaDescription = "";

    switch (pathname) {
      case "/":
        title = "";
        metaDescription = "";
        break;
    }

    if (title) {
      document.title = title;
    }

    if (metaDescription) {
      const metaDescriptionTag: HTMLMetaElement | null = document.querySelector(
        'head > meta[name="description"]'
      );
      if (metaDescriptionTag) {
        metaDescriptionTag.content = metaDescription;
      }
    }
  }, [pathname]);

  useEffect(() => {
    const handleError = (error: any) => {
      showError("An unexpected error occurred", { error });
    };

    window.addEventListener("error", handleError);
    window.addEventListener("unhandledrejection", handleError);

    return () => {
      window.removeEventListener("error", handleError);
      window.removeEventListener("unhandledrejection", handleError);
    };
  }, [showError]);

  useEffect(() => {
    const handleApiError = (event: any) => {
      setErrorDetails({
        message: event.detail.message || "An unknown error occurred",
        data: event.detail.response?.data,
      });
      setIsErrorModalOpen(true); // Open the modal when there's an API error
    };

    window.addEventListener("apiError", handleApiError);

    return () => {
      window.removeEventListener("apiError", handleApiError);
    };
  }, []);

  const closeModal = () => {
    setIsErrorModalOpen(false);
  };

  useEffect(() => {
    const handleWindowClose = () => {
      // Retrieve and store the values you want to keep
      const token = localStorage.getItem("token");
      const language = localStorage.getItem("language");

      // Clear all localStorage
      localStorage.clear();

      // Reset the stored values
      if (token) {
        localStorage.setItem("token", token);
      }
      if (language) {
        localStorage.setItem("language", language);
      }
    };

    window.addEventListener("beforeunload", handleWindowClose);

    // Cleanup function to remove the event listener
    return () => {
      window.removeEventListener("beforeunload", handleWindowClose);
    };
  }, []);

  const matchAuthorIdId = useMatch("/:authorId/:id");



  return (
    <>

<Suspense fallback={<LoadingPage/>}>
{(pathname === '/map'
|| pathname === "/add-adventure"
|| pathname === "/climbing-spots"
|| pathname === "/add-new-spot/climbing"
|| pathname === "/edit-chapters/:id"
|| matchAuthorIdId
|| map) && (
        <MapContainer
          map={map}
          setMap={setMap}
          mapContainerRef={mapContainerRef}
        />
      )}

      <Routes>
        <Route path="/climbing-spots" element={<ClimbingSpotsMap
        map={map}
        setMap={setMap}
        mapAnimation={mapAnimation}
        setMapAnimation={setMapAnimation}
        mapContainerRef={mapContainerRef}
        allLayers={allLayers}
        setAllLayers={setAllLayers}
        allMarkers={allMarkers}
        setAllMarkers={setAllMarkers}
        />} />
        <Route path="/signup" element={<SignUp />} />
        <Route path="/login" element={<Login />} />
        <Route path="/about" element={<AboutUs />} />
        <Route path="/how-it-works" element={<HowWorks />} />
        <Route path="/welcome" element={<Welcome />} />
        <Route path="/strava/exchange_token" element={<TestUser />} />
        <Route path="/wahoo/exchange_token" element={<TestUser app={'wahoo'}/>} />
        <Route path="/user-registration/verify-mail" element={<VerifyMail />} />
        <Route path="/user-registration/activate-mail" element={<ActivateMail />} />
        <Route path="/:type/route-segment/:segmentId" element={<GPXDownloadPage />} />        <Route
          path="/map"
          element={
            // <AuthenticatedRoute>
              <MapboxMap
                map={map}
                setMap={setMap}
                mapAnimation={mapAnimation}
                setMapAnimation={setMapAnimation}
                mapContainerRef={mapContainerRef}
                allLayers={allLayers}
                setAllLayers={setAllLayers}
                allMarkers={allMarkers}
                setAllMarkers={setAllMarkers}
              />

            // </AuthenticatedRoute>
          }
        />
        <Route
          path="/feedback"
          element={
            <AuthenticatedRoute>
              <ImprovedFeedbackBoard />
            </AuthenticatedRoute>
          }
        />
        <Route
          path="/explore"
          element={
            <AuthenticatedRoute>
              <AllBlogsPage />
            </AuthenticatedRoute>
          }
        />
        <Route
          path="/settings"
          element={
            <AuthenticatedRoute>
              <UserSettingsPage />
            </AuthenticatedRoute>
          }
        />
        <Route
          path="/add-spot/climbing"
          element={
            <AuthenticatedRoute>
              <NewSpotPage
              map={map}
                setMap={setMap}
                mapAnimation={mapAnimation}
                setMapAnimation={setMapAnimation}
                mapContainerRef={mapContainerRef}
                allLayers={allLayers}
                setAllLayers={setAllLayers}
                allMarkers={allMarkers}
                setAllMarkers={setAllMarkers}
              />
              </AuthenticatedRoute>
          }
        />
        <Route
          path="/add-adventure"
          element={
            <AuthenticatedRoute>
              <NewBlogWritingPage
              map={map}
                setMap={setMap}
                mapAnimation={mapAnimation}
                setMapAnimation={setMapAnimation}
                mapContainerRef={mapContainerRef}
                allLayers={allLayers}
                setAllLayers={setAllLayers}
                allMarkers={allMarkers}
                setAllMarkers={setAllMarkers}
              />
            </AuthenticatedRoute>
          }
        />
        <Route
          path="/edit-blog/:id"
          element={
            <AuthenticatedRoute>
              <NewBlogWritingPage
              map={map}
                setMap={setMap}
                mapContainerRef={mapContainerRef}
                mapAnimation={mapAnimation}
                setMapAnimation={setMapAnimation}
                allLayers={allLayers}
                setAllLayers={setAllLayers}
                allMarkers={allMarkers}
                setAllMarkers={setAllMarkers}
              />
            </AuthenticatedRoute>
          }
        />
        <Route
          path="/edit-chapters/:id"
          element={
            <AuthenticatedRoute>
              <NewBlogEditPage
              map={map}
                setMap={setMap}
                mapContainerRef={mapContainerRef}
                mapAnimation={mapAnimation}
                setMapAnimation={setMapAnimation}
                allLayers={allLayers}
                setAllLayers={setAllLayers}
                allMarkers={allMarkers}
                setAllMarkers={setAllMarkers}
                startAtChapters={true}
              />
            </AuthenticatedRoute>
          }
        />
        <Route
          path="/:authorId"
          element={
            // <AuthenticatedRoute>
            <AuthorPage />
            // </AuthenticatedRoute>
          }
        />
        <Route
          path="/:authorId/:id"
          element={
            // <AuthenticatedRoute>
            <NewBlogView
              map={map}
              setMap={setMap}
              mapAnimation={mapAnimation}
                setMapAnimation={setMapAnimation}
              mapContainerRef={mapContainerRef}
              allLayers={allLayers}
              setAllLayers={setAllLayers}
              allMarkers={allMarkers}
              setAllMarkers={setAllMarkers}
            />
            // </AuthenticatedRoute>
          }
        />
        <Route
          path="/create-municipal-post"
          element={
            <AuthenticatedRoute>
              <MunicipalWritingPage />
            </AuthenticatedRoute>
          }
        />
        <Route
          path="/edit-municipal-post/:id"
          element={
            <AuthenticatedRoute>
              <MunicipalWritingPage />
            </AuthenticatedRoute>
          }
        />
        <Route
          path="/municipal-content/:authorId"
          element={
            <AuthenticatedRoute>
              <MunicipalPage />
            </AuthenticatedRoute>
          }
        />
        <Route
          path="/municipal-route/:id"
          element={
            <AuthenticatedRoute>
              <MunicipalRouteView />
            </AuthenticatedRoute>
          }
        />
        <Route path="/policies/terms-of-use" element={<TermsOfUse />} />
        <Route path="/policies/privacy-policy" element={<PrivacyPolicy />} />
        <Route path="/policies/cookies" element={<CookiesPolicy />} />
        <Route path="/feed" element={<FeedPage />} />
        <Route path="/" element={<LandingPage />} />
      </Routes>
      </Suspense>

      <ErrorModal
        isOpen={isErrorModalOpen}
        onClose={closeModal}
        errorMessage={errorDetails.message}
        errorData={errorDetails.data || {}}
      />
      <MaintenanceBanner />
      {/* <AppPopup /> */}
    </>
  );
}
export default App;
