import { FunctionComponent } from "react";
import { Link } from "react-router-dom";
import {ScrollShadow} from "@nextui-org/scroll-shadow";

import Card from "./Card";


const activityThemes = {
  surf: {
    labels: { en: 'Surf', fr: 'Surf', nl: 'Surfen' },
    image: 'https://slostr.s3.fr-par.scw.cloud/slostr-imgs/icons/surfing.png'
  },
  climbing: {
    labels: { en: 'Climbing', fr: 'Escalade', nl: 'Klimmen' },
    image: 'https://slostr.s3.fr-par.scw.cloud/slostr-imgs/icons/climbing.png'
},
  ski: {
    labels: { en: 'Ski', fr: 'Ski', nl: 'Skiën' },
    image: 'https://slostr.s3.fr-par.scw.cloud/slostr-imgs/icons/skiing.png'
},
  hiking: {
    labels: { en: 'Hiking', fr: 'Randonnée', nl: 'Wandelen' },
    image: 'https://slostr.s3.fr-par.scw.cloud/slostr-imgs/icons/trekking.png'
},
  bike: {
    labels: { en: 'Cycling', fr: 'Vélo', nl: 'Fietsen' },
    image: 'https://slostr.s3.fr-par.scw.cloud/slostr-imgs/icons/cycling.png?X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Content-Sha256=UNSIGNED-PAYLOAD&X-Amz-Credential=SCWFS8DRAYK2CC042Y1T%2F20241119%2Ffr-par%2Fs3%2Faws4_request&X-Amz-Date=20241119T171209Z&X-Amz-Expires=3598&X-Amz-Signature=4d69584c5a6de4353d5464e977cadbb54239e0d0945095610291489e97be59b9&X-Amz-SignedHeaders=host&x-id=GetObject'
},
  train: {
    labels: { en: 'Train', fr: 'Train', nl: 'Trein' },
    image: 'https://slostr.s3.fr-par.scw.cloud/slostr-imgs/icons/train.png'
},
  sailing: {
    labels: { en: 'Sailing', fr: 'Voile', nl: 'Zeilen' },
    image: 'https://slostr.s3.fr-par.scw.cloud/slostr-imgs/icons/sailing.png'
},
};


const getThemeLabelAndIcon = (theme: any, language: any) => {
  const themeConfig = activityThemes[theme];
  const label = themeConfig ? themeConfig.labels[language] : theme;

  if (themeConfig?.image) {
    return {
      label,
      icon: <img
      className="h-6 w-6"
      loading="lazy"
      style={{filter: 'brightness(0) saturate(100%) invert(21%) sepia(25%) saturate(691%) hue-rotate(174deg) brightness(95%) contrast(87%)'}}
      src={themeConfig?.image}
  />,
    };
  }

  return {
    label,
    icon: themeConfig?.icon || null,
  };
};


const Content: FunctionComponent<any> = ({ groupedBlogs, groupedUsers }) => {
  return (
    <div className="w-full flex flex-col justify-center pb-3 mt-6">
      {Object.keys(groupedBlogs).map((theme) => {
        const blogs = groupedBlogs[theme];
        const users = groupedUsers[theme] || [];
        const { label, icon } = getThemeLabelAndIcon(theme, "en"); // Adjust language as needed

        return (
          <div key={theme} className="w-full pl-2 pr-2">
            <div className="pb-5">
            <div className="flex items-center gap-3 mb-4 pb-1 pl-6">
              {icon}
              <h2 className="text-2xl font-semibold text-slate-700 m-0 p-0">{label}</h2>
            </div>

            <ScrollShadow
                    orientation="horizontal"
                    className="z-10 relative bottom-2 left-2"
                  >
                    <>
            <div className="relative rounded-lg flex flex-row gap-4 w-fit pb-2">
              {blogs.map((blog) => (
                <Link to={`/${blog.author.username}/${blog.id}`} key={blog.id}>
                  <Card
            blog={blog}
            />
                </Link>
              ))}
            </div>
            </>
            </ScrollShadow>
            {users.length > 0 && (
              <div className="flex items-center gap-4 mt-4 overflow-x-auto pl-10">
                {users.map((user) => (
                  <Link to={`/${user.user.username}/`}>

                  <div key={user.user.id} className="flex flex-col items-center">
                    <img
                      src={user.user.profile_picture}
                      alt={user.user.first_name}
                      className="w-14 h-14 rounded-full"
                    />
                    <span className="text-sm mt-2">{user.user.first_name}</span>
                  </div>
                  </Link>
                ))}
              </div>
            )}
            </div>

          </div>
        );
      })}
    </div>
  );
};

export default Content;
