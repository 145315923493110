// Importing helper modules
import { useCallback, useMemo, useRef, useState } from "react";
import { useTranslation } from 'react-i18next';

// Importing core components
import QuillEditor from "react-quill";
import InfoModal from "./InfoModal";

// Importing styles
import "react-quill/dist/quill.snow.css";


function ImageUploadModal({ isOpen, onClose, onSubmit }) {
  const [imageUrl, setImageUrl] = useState('');
  const [authorCredit, setAuthorCredit] = useState('');

  if (!isOpen) return null;

  return (
    <div style={{ position: 'fixed', top: '20%', left: '50%', transform: 'translate(-50%, -50%)', backgroundColor: 'white', padding: '20px', zIndex: 1000 }}>
      <div>
        <input type="text" placeholder="Image URL" value={imageUrl} onChange={(e) => setImageUrl(e.target.value)} />
      </div>
      <div>
        <input type="text" placeholder="Author Credit" value={authorCredit} onChange={(e) => setAuthorCredit(e.target.value)} />
      </div>
      <div>
        <button onClick={() => onSubmit(imageUrl, authorCredit)}>Insert</button>
        <button onClick={onClose}>Cancel</button>
      </div>
    </div>
  );
}


const TextEditor = ({blogContent, setBlogContent}) => {
  const { t } = useTranslation();
  // Editor ref
  const quill = useRef();
  const [showModal, setShowModal] = useState(false);

  const openModal = () => setShowModal(true);
  const closeModal = () => setShowModal(false);

  // Handler to handle button clicked

  const imageHandler = useCallback(() => {
    // Ask the user for the upload method
    // const uploadMethod = window.prompt('Type "1" to upload an image or "2" to insert an image via URL.');

    // if (uploadMethod === "1") {
    //   // Handle direct image upload
    //   const input = document.createElement("input");
    //   input.setAttribute("type", "file");
    //   input.setAttribute("accept", "image/*");
    //   input.click();

    //   input.onchange = () => {
    //     const file = input.files[0];
    //     if (file) {
    //       // File uploading logic here...
    //       const reader = new FileReader();
    //       reader.onload = () => {
    //         const imageUrl = reader.result;
    //         insertImage(imageUrl);
    //       };
    //       reader.readAsDataURL(file);
    //     }
    //   };
    // } else if (uploadMethod === "2") {
      // Handle image insertion via URL
      openModal();

      // if (imageUrl && imageUrl!=='') {
      //   // Insert the image along with the citation
      //   insertImageWithCitation(imageUrl, authorCredit);
      // }
    // }
  }, []);

  const insertImage = useCallback((imageUrl) => {
    const quillEditor = quill.current.getEditor();
    const range = quillEditor.getSelection(true);
    quillEditor.insertText(range.index, "\n");
    quillEditor.insertEmbed(range.index + 1, "image", imageUrl);
    quillEditor.insertText(range.index + 2, "\n");
    quillEditor.setSelection(range.index + 3);
  }, []);

  const insertImageWithCitation = useCallback((imageUrl, authorCredit) => {
    const quillEditor = quill.current.getEditor();
    const range = quillEditor.getSelection(true);
    const html = `<div class="w-full h-full flex flex-col"><img src="${imageUrl}" alt="Image"/>` +
    `<div class="relative flex justify-end bottom-6 right-2"><p class="m-0 p-0 text-2xs"> ${authorCredit}</p></div></div>`;
    quillEditor.clipboard.dangerouslyPasteHTML(range.index, html);
  }, []);

  const handleImageSubmit = (imageUrl, authorCredit) => {
    closeModal(); // Close the modal
    insertImageWithCitation(imageUrl, authorCredit); // Proceed with inserting the image
  };



  const modules = useMemo(
    () => ({
      toolbar: {
        container: [
          [{ header: [1, 2, 3, 4, false] }],
          ["bold", "italic", "underline", "blockquote"],
          // [{ color: [] }],
          [
            { list: "ordered" },
            { list: "bullet" },
            // { indent: "-1" },
            // { indent: "+1" },
          ],
          // [{ 'color': [] }, { 'background': [] }],
          // [{align: ''}, {align: 'right'}, {align: 'center'}, {align: 'justify'}],
          // ["image"],
          ["link"],
          // ["link", "video"]
          // ["clean"],
        ],
        // handlers: {
        //   image: imageHandler,
        // },
      },
      clipboard: {
        matchVisual: true,
      },
    }),
    // [imageHandler]
  );

  const formats = [
    "header",
    "bold",
    "italic",
    "underline",
    "strike",
    "blockquote",
    "list",
    "bullet",
    "indent",
    "link",
    // "image",
    "color",
    // "clean",
  ];

  return (
    <div className="w-full border border-solid border-whitesmoke-400 rounded-lg min-h-[250px] relative">
      {/* <label>Blog post content</label> */}
      <InfoModal/>
      <QuillEditor
        ref={(el) => (quill.current = el)}
        theme="snow"
        value={blogContent}
        formats={formats}
        modules={modules}
        onChange={(value) => setBlogContent(value)}
        placeholder={t('create-content-placeholder')}
      />
            {/* <ImageUploadModal isOpen={showModal} onClose={closeModal} onSubmit={handleImageSubmit} /> */}

    </div>
  );
};

export default TextEditor;
