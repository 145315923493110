import React, { useEffect, useRef, useState } from "react";
import mapboxgl from "mapbox-gl";
import "mapbox-gl/dist/mapbox-gl.css";
import * as turf from "@turf/turf";
import "@mapbox/mapbox-gl-draw/dist/mapbox-gl-draw.css";
import Button from "@mui/material/Button";
import { getLinePaintTransport, getLinePaintDirection } from "../../utils/mapStyles";
import ReactDOM from "react-dom";
import { BiSolidCommentDetail } from "react-icons/bi";
import {  IconButton } from "@mui/material";
import MapStyleSelector from "./MapStyleSelector";
import MapAnimation from "./MapAnimation";
import InfoView from "./InfoView";
import { getLocalizedField } from "../../utils/utils";
import { fetchRouteElevation } from "../../utils/api/api";
import { useTranslation } from "react-i18next";
import MapContainer from "./MapContainer";


import Chart from 'chart.js/auto';



import {
  densifyLineString,
  transformChapterImages,
  MyGallery,
  fitBoundsToFeatures,
  hideUnrelatedMarkersAndLayers,
  showMarkersAndLayers,
  showAllMarkersAndLayers,
  createGeoJSONLine
} from "./utils";

const emptyFeatureCollection = {
  type: "FeatureCollection",
  features: []
};

const MapboxMapView: React.FC<any> = ({ mapFeatures, chapterIndex, chartRef, setElevationSummary, setChapterIndex, setGpxData, userIsAuthor, transports, co2, map, mapContainerRef, allLayers, setAllLayers, allMarkers, setAllMarkers,mapResize,setMapResize, setShowChart, setTripPostImg, tripPostImg,showElevationRef, mapAnimation, setMapAnimation, qaOpen, setQaOpen
 }) => {

  const { t, i18n } = useTranslation();

  // const { map, mapContainerRef } = useMap();


  const [mapInstance, setMapInstance] = useState<mapboxgl.Map | null>(null);

  const markersRef = useRef([]);
  const markersdistanceRef = useRef([]);
  const [poiMarkers, setPoiMarkers] = useState<mapboxgl.Marker[]>([]);
  const encoderRef = useRef(null);
  const [dynamicMarker, setDynamicMarker] = useState<any>(null);
  const mediaRecorderRef = useRef<any>(null);
  const [recording, setRecording] = useState<any>(false);
  const [recordedChunks, setRecordedChunks] = useState<any>([]);
  const [encoder, setEncoder] = useState(null);
  const [loading, setLoading] = useState(true);
  const chartInstanceRef = useRef(null);
  const [currentFeature, setCurrentFeature] = useState<any>(null);
  const currentFeatureRef = useRef<any>(null);
  const [transportDistances, setTransportDistances] = useState({
    hike: 0,
    bike: 0,
    car: 0,
    train: 0,
    boat: 0,
    sail: 0,
    bus: 0,
    moto: 0,
    hitchhike: 0,
    run: 0,
});
  const currentTransportDistanceRef = useRef(transportDistances);
  const overlayImageRef = useRef<any>(null);
  const overlayImageLowTripRef = useRef<any>(null);
  const imageBoatRef = useRef<any>(null);
  const imageSailRef = useRef<any>(null);
  const imageBikeRef = useRef<any>(null);
  const imageTrainRef = useRef<any>(null);
  const imageCarRef = useRef<any>(null);
  const imageHikeRef = useRef<any>(null);
  const imageBusRef = useRef<any>(null);
  const imageMotoRef = useRef<any>(null);
  const imageHitchhikeRef = useRef<any>(null);
  const imageRunRef = useRef<any>(null);
  const [styleInitialized, setStyleInitialized] = useState<any>(false);
  const [isPhoneWidth, setIsPhoneWidth] = useState(false);
  const [elevationData, setElevationData] = useState<any>(null);
  const animationFrameId = useRef<any>(null);



  // async function loadDependencies() {

  //   const { default: loadEncoder } = await import(
  //     // @ts-ignore
  //     "https://unpkg.com/mp4-h264@1.0.7/build/mp4-encoder.js"
  //   );

  //   const { simd } = await import(
  //     // @ts-ignore
  //     "https://unpkg.com/wasm-feature-detect?module"
  //   );

  //   const supportsSIMD = await simd();
  //   const Encoder = await loadEncoder({ simd: supportsSIMD });
  //   setEncoder(Encoder);
  //   setLoading(false);
  // }

  useEffect(() => {
    // loadDependencies().catch(console.error);
    mapboxgl.accessToken = process.env.REACT_APP_MAPBOX_API_TOKEN as string;
  }, [])


  const dashArraySequence = [
    [0, 21, 0.3],
    [0.1, 21, 0.2],
    [0.2, 21, 0.1],
    // [0.75, 21, 0.25],
    [0.3, 21, 0],
    // [1.25, 21, 0.75],
    // [1.5, 21, 0.5],
    // [1.75, 21, 0.25],
    // [2, 21, 0],
    [0, 0.1, 0.3, 20.9],
    [0, 0.2, 0.3, 20.8],
    [0, 0.3, 0.3, 20.7],
    [0, 0.4, 0.3, 20.6],

    [0, 1, 0.3, 20],
    [0, 2, 0.3, 19],
    [0, 3, 0.3, 18],
    [0, 4, 0.3, 17],
    [0, 5, 0.3, 16],
    [0, 6, 0.3, 15],
    [0, 7, 0.3, 14],
    [0, 8, 0.3, 13],
    [0, 9, 0.3, 12],
    [0, 10, 0.3, 11],
    [0, 11, 0.3, 10],
    [0, 12, 0.3, 9],
    [0, 13, 0.3, 8],
    [0, 14, 0.3, 7],
    [0, 15, 0.3, 6],
    [0, 16, 0.3, 5],
    [0, 17, 0.3, 4],
    [0, 18, 0.3, 3],
    [0, 19, 0.3, 2],
    [0, 20, 0.3, 1],
    // [0, 5.9, 0.3, 0.1]


  ];




  function createAnimateDashArrayFunction(lineId: any) {
    let step = 0;

    return function animateDashArray(timestamp: any) {
      const newStep = Math.floor((timestamp / 50) % dashArraySequence.length);

      if (newStep !== step) {
        map.setPaintProperty(
          lineId,
          'line-dasharray',
          dashArraySequence[newStep]
        );
        step = newStep;
      }

      // Request the next frame of the animation.
      const animationFrame = requestAnimationFrame(animateDashArray);
      // setMapAnimation(animationFrame)

      return () => {
        if (animationFrame) {
          cancelAnimationFrame(animationFrame);
        }
      };
    }
  }

  // function stopAnimation() {
  //   if (mapAnimation !== null) {
  //     cancelAnimationFrame(mapAnimation);
  //     setMapAnimation(null);
  //   }
  // }


  const initializeMapFeatures = (map: any, mapFeatures:any, firstLoad: boolean) => {

    map.off('click');
    map.off('mouseenter');
    map.off('mouseleave');
    map.off('moveend');
    map.off('move');
    map.off('style.load');
    map.off('idle');

    const markerDivs = document.querySelectorAll('.mapboxgl-marker');
    markerDivs.forEach(marker => {
      marker.remove();
    });

    try {

         // Remove all event listeners

         const allLayers = mapInstance.getStyle().layers;
         const allSources = Object.keys(mapInstance.getStyle().sources);

         console.log("All layers before cleanup:", allLayers);

         // First, remove all layers associated with "slostr" or specified in `layerNames`
         allLayers.forEach((layer) => {
           if (
             (layer.id.includes("slostr")) &&
             mapInstance.getLayer(layer.id)
           ) {
             console.log(`Removing layer: ${layer.id}`);
             mapInstance.removeLayer(layer.id);
           }
         });

         // Then, remove all sources associated with "slostr" or specified in `sourceNames`
         allSources.forEach((sourceId) => {
           if (
             (sourceId.includes("slostr")) &&
             mapInstance.getSource(sourceId)
           ) {
             console.log(`Removing source: ${sourceId}`);
             mapInstance.removeSource(sourceId);
           }
         });
} catch (error) {
  console.error("Error initializing map features", error);
}

if (!firstLoad) {
  if (allMarkers.length > 0) {
    allMarkers.forEach(([marker, markerName]: any) => marker.remove());
    setAllMarkers([]);
      }

    allLayers.forEach((layerName: any) => {
      if (map.getLayer(layerName)) {
          map.removeLayer(layerName);
      }

  });
  allLayers.forEach((layerName: any) => {
    try {
    if (map.getSource(layerName)) {
      map.removeSource(layerName);
    }
  } catch (error) {
    console.error(`Error removing layer: ${layerName}`, error);
  }

  });


  setAllLayers([])
}








if (mapFeatures && mapFeatures.features.length > 0) {
  const newLayerNames: any[] = [];
  const newMarkers: any[] = [];
  mapFeatures.features.forEach((feature: any) => {
    if (feature.geometry.type === "LineString") {
      // Densify the LineString
      const densifiedLineString = densifyLineString(
        feature,
        50,
        feature.properties.transportation
      ); // Adjust interval as needed

      const layerName = `slostr-chapter-${feature.properties.chapter}-route-${feature.properties.id}`;
      const interactionLayerName = `${layerName}-interaction`;
      const dashedLayerName = `${layerName}-dashed`;



      newLayerNames.push(layerName);

      // Create a new source and layer for the densified LineString
if (!map.getSource(layerName)) {
      map.addSource(layerName, {
        type: "geojson",
        data: densifiedLineString,
      });
    } else {
      map.getSource(layerName).setData(densifiedLineString);
    }

      map.addLayer({
        id: layerName,
        type: "line",
        source: layerName,
        layout: {
          "line-join": "round",
          "line-cap": "round",
        },
        // @ts-ignore
        paint: getLinePaintTransport(),
      });

      map.addLayer({
        'id': dashedLayerName,
        'type': 'symbol',
        'source': layerName,
         "layout": {
            "text-field": "▶",
            "text-size": 28,
       //works ok with "line-center" or "point"
            "symbol-placement": "line",
            "symbol-spacing": 80,
            "text-rotation-alignment": "map",
            "text-keep-upright": false,
            'icon-allow-overlap': true,
            'icon-ignore-placement': true,
          },
          // @ts-ignore
          paint: getLinePaintDirection()
    })



      map.addLayer({
        id: interactionLayerName,
        type: "line",
        source: layerName,
        layout: {
          "line-join": "round",
          "line-cap": "round",
        },
        paint: {
          "line-color": "transparent",
          "line-width": 12, // Adjust as needed to make the click area larger
        }
      });

            // Add hover effect
            map.on('mouseenter', interactionLayerName, () => {
              map.getCanvas().style.cursor = 'pointer';
              map.setPaintProperty(layerName, 'line-color', '#FFA500'); // Change to red on hover
            });

            map.on('mouseleave', interactionLayerName, () => {
              map.getCanvas().style.cursor = '';
              map.setPaintProperty(layerName, 'line-color', getLinePaintTransport()['line-color']); // Revert to original color
            });

            // Add click event
            map.on('click', interactionLayerName, async (e: any) => {
              if (currentFeatureRef.current !== feature || !showElevationRef.current) {
                setElevationData(null)
                setCurrentFeature(feature)
                currentFeatureRef.current = feature

                if (feature.properties.transportation === "hike" || feature.properties.transportation === "run" || feature.properties.transportation === "bike") {
                  setShowChart(true)
                  showElevationRef.current = true
                  const routeElevation: any = await fetchRouteElevation(feature.properties.route_id)
                  setElevationData(routeElevation.elevation_profile)
                  getElevation(feature.properties.id, routeElevation.elevation_profile)

                }
              }

            });

      // Place the marker at 1/3 along the densified LineString
      const lineLength = turf.length(densifiedLineString, {
        units: "meters",
      });
      const oneThirdLength = lineLength / 3;
      const pointAtOneThird = turf.along(
        densifiedLineString,
        oneThirdLength,
        { units: "meters" }
      );

      // Create an HTML element for the marker
      const el = document.createElement("div");
      // @ts-ignore
      el.className = `marker icon-${feature.properties.transportation}`;

      const marker = new mapboxgl.Marker(el)
        // @ts-ignore
        .setLngLat(pointAtOneThird.geometry.coordinates)
        .addTo(map);

        marker.getElement().addEventListener("click", async () => {
          // Wait for the popup to be open and then render the React component
          setElevationData(null)
          setCurrentFeature(feature)
          currentFeatureRef.current = feature

          if (feature.properties.transportation === "hike" || feature.properties.transportation === "run" || feature.properties.transportation === "bike") {
            setShowChart(true)
            showElevationRef.current = true
            const routeElevation: any = await fetchRouteElevation(feature.properties.route_id)
            setElevationData(routeElevation.elevation_profile)
            getElevation(feature.properties.id, routeElevation.elevation_profile)

          }



        });

      const markerName = `chapter-${feature.properties.chapter}-marker-${feature.properties.id}`;

      newMarkers.push([marker, markerName]);
    }
  });
  fitBoundsToFeatures(map, mapFeatures);
  setAllLayers(newLayerNames);
  setAllMarkers(newMarkers);
  const el = document.createElement("div");
        el.className = `marker-small`; // Set class based on transportation mode
        // @ts-ignore
        const marker = new mapboxgl.Marker(el)
          // @ts-ignore
          .setLngLat([0, 0])
          .addTo(map);
        // @ts-ignore
        markersdistanceRef.current.push(marker);
}


  }

useEffect(() => {
  if (!currentFeature) return
  setChapterIndex(currentFeature.properties.chapter)
  // if (currentFeature.elevation_profile) {
  //   getElevation(currentFeature.properties.id)
  //   console.log('elevation id', currentFeature.properties.id)

  // }

}, [currentFeature])

useEffect(() => {
  if (!mapInstance) return
if (mapResize) {
  mapInstance.resize()
  setMapResize(false)
}

}, [mapResize])


useEffect(() => {
  if (currentFeature && currentFeature.properties.chapter !== chapterIndex) {
    setCurrentFeature(null)
    currentFeatureRef.current = null
    setElevationSummary([null, null, null])
    setShowChart(false)
    showElevationRef.current = false

  }


}, [chapterIndex])

useEffect(() => {
  const resetting = async (map: any) => {
    // if (mapAnimation !== null) {
    //   cancelAnimationFrame(mapAnimation);
    //   setMapAnimation(null);
    // }
    const mapContainerDiv = document.getElementById('map');
    if (mapContainerDiv && mapContainerRef.current) {
      if (mapContainerDiv !== mapContainerRef.current.parentNode) {
        mapContainerDiv.innerHTML = ''; // Clear existing content
        mapContainerDiv.appendChild(mapContainerRef.current);
      }
    }

    map.off('click');
    map.off('mouseenter');
    map.off('mouseleave');
    map.off('moveend');
    map.off('move');
    map.off('style.load');
    map.off('idle');

  }

  if (map) {
    if (map) {
      resetting(map)
      setMapInstance(map)
    } else {
      map.on('load', () => {
        resetting(map)
        setMapInstance(map)
      })
    }

    map.off('load');

  }
  // return () => {
  //   stopAnimation(); // Ensure the animation is stopped when the component unmounts
  // };

}, [map]);

  useEffect(() => {

    // let map = new mapboxgl.Map({
    //   // @ts-ignore
    //   container: mapContainerRef.current,
    //   // style: "mapbox://styles/mapbox/light-v10",
    //   style: "mapbox://styles/vladke99/clvmfz4xo01hg01qzengv0viu",
    //   center: [7, 48],
    //   zoom: 4,
    //   maxzoom: 15,
    //   preserveDrawingBuffer: true,
    // });

    if (!mapInstance) return

    // @ts-ignore
    // mapInstance.setContainer(mapContainerRef.current);
    mapInstance.resize()


      initializeMapFeatures(mapInstance, mapFeatures, true);

    // setMapInstance(map);

    // mapInstance.addControl(new mapboxgl.NavigationControl(), "bottom-left");

  }, [mapFeatures, mapInstance]);


  useEffect(() => {
    if (!mapInstance || !encoder) return; // Exit if mapInstance or encoder is not ready

    try {
      // @ts-ignore
      const gl = mapInstance.painter.context.gl;
      const width = gl.drawingBufferWidth;
      const height = gl.drawingBufferHeight;
      // @ts-ignore
      const Encoder = encoder.create({
        width,
        height,
        fps: 60,
        kbps: 8000,
        rgbFlipY: true,
      });

      encoderRef.current = Encoder;

      const frame = () => {
        const ptr = Encoder.getRGBPointer();
        const pixels = Encoder.memory().subarray(ptr);
        gl.readPixels(0, 0, width, height, gl.RGBA, gl.UNSIGNED_BYTE, pixels);
        Encoder.encodeRGBPointer();
      };

      mapInstance.on("render", frame);

      return () => {
        mapInstance.off("render", frame); // Clean up the event listener

        if (encoderRef.current) {
          // @ts-ignore
          const mp4 = encoderRef.current.end();
          const url = URL.createObjectURL(
            new Blob([mp4], { type: "video/mp4" })
          );
          const a = document.createElement("a");
          // a.href = url;
          // a.download = 'map-animation.mp4';
          // document.body.appendChild(a);
          // a.click();
        }
      };
    } catch (error) {
      console.error("Error setting up recording:", error);
    }
  }, [mapInstance]); // Depend on mapInstance and encoder

  useEffect(() => {
    if (chapterIndex) {
      hideUnrelatedMarkersAndLayers(
        mapInstance,
        chapterIndex,
        allLayers,
        allMarkers
      );
    } else {
      showMarkersAndLayers(mapInstance, allLayers, allMarkers, markersRef);
    }
  }, [chapterIndex]);

  useEffect(() => {
    if (!mapInstance || !mapFeatures) return;
    const removePoiMarkers = () => {
      poiMarkers.forEach((marker) => marker.remove());
      setPoiMarkers([]);
    };

    const newPoiMarkers: any = [];

    mapFeatures.features.forEach((feature: any) => {
      if (
        feature.properties.poi &&
        feature.properties.chapter === chapterIndex
      ) {
        const el = document.createElement("div");
        el.className = `marker-poi`;
        el.style.backgroundColor = `${feature.properties.color}`;

        // Add an inner div for the pseudo-element
        const innerDiv = document.createElement("div");
        innerDiv.className = 'marker-overlay';
        innerDiv.style.backgroundImage = `url(${feature.properties.icon})`;
        el.appendChild(innerDiv);

        const popupContent = document.createElement("div");
        popupContent.className = 'font-roboto text-secondary-600 text-sm'
        const galleryPlaceholderId = `gallery-${feature.properties.id}`; // Unique ID for each feature
        popupContent.innerHTML = `<div id="${galleryPlaceholderId}"></div><h3 class='leading-5 text-lg'>
        ${getLocalizedField(feature.properties, 'title', i18n.language, userIsAuthor)}</h3>
      <p class=''>${getLocalizedField(feature.properties, 'content', i18n.language, userIsAuthor)}</p>`;

        const marker = new mapboxgl.Marker(el)
          .setLngLat(feature.geometry.coordinates)
          .setPopup(
            new mapboxgl.Popup({ offset: 25 }).setDOMContent(popupContent)
          )
          .addTo(mapInstance);


        marker.getElement().addEventListener("click", () => {
          // Wait for the popup to be open and then render the React component
          window.requestAnimationFrame(() => {
            const galleryElement =
              document.getElementById(galleryPlaceholderId);
              const PoiImages = transformChapterImages(feature.properties)
            if (galleryElement && PoiImages.length > 0) {
            // if (false) {
              ReactDOM.render(
                <MyGallery
                  items={PoiImages}
                />,
                galleryElement
              );
            }
          });
        });

        // });

        newPoiMarkers.push(marker);
      }
    });
    removePoiMarkers();
    setPoiMarkers(newPoiMarkers);
  }, [chapterIndex, mapFeatures]);

  // Additional useEffect hook to adjust map view based on visible features and POIs
  useEffect(() => {
    if (!mapInstance) return;
    if (chapterIndex === null) {
      fitBoundsToFeatures(mapInstance, mapFeatures)
      showAllMarkersAndLayers(mapInstance, allLayers);
    } else {
    // Create an array to hold all coordinates from both features and POI markers
    const allCoords:any = [];

    // Add coordinates from route segments if they belong to the selected chapter
    mapFeatures.features.forEach((feature: any) => {
      if (feature.properties.chapter === chapterIndex) {
        if (feature.geometry.type === 'LineString') {
          allCoords.push(...feature.geometry.coordinates);
        } else if (feature.geometry.type === 'Point') {
          allCoords.push(feature.geometry.coordinates);
        }
      }
    });

    // Add coordinates from POI markers
    poiMarkers.forEach(marker => {
      const lngLat = marker.getLngLat();
      allCoords.push([lngLat.lng, lngLat.lat]);
    });



    if (allCoords.length > 0) {
      const bounds = allCoords.reduce((bounds:any, coord:any) => bounds.extend(coord), new mapboxgl.LngLatBounds(allCoords[0], allCoords[0])).toArray();


      // Fit the map bounds to the collected coordinates with some padding
      mapInstance.fitBounds(
          bounds
        ,
        {
          padding: 20, // Adjust padding as needed
        }
      );
    }

    }
  }, [chapterIndex, mapFeatures, poiMarkers]);

        // Local distance variable
        useEffect(() => {
            currentTransportDistanceRef.current = transportDistances;

        }, [transportDistances]);



  const setMapStyle = (styleURL: any) => {

    if (!mapInstance) return;

    mapInstance.setStyle(styleURL);
    mapInstance.on("style.load", () => {
      // Add terrain source, with slight exaggeration
      // if (is3D) {
      if (!styleInitialized) {

        initializeMapFeatures(mapInstance, mapFeatures, false);
        setStyleInitialized(true)
      }

      // Re-initialize map features when style changes

    });
  };

  const getElevation = (featureId: any, elevation_profile: any) => {
    // if (!mapInstance || chapterIndex === null || !mapFeatures || !mapFeatures.features) return;
    const feature = mapFeatures.features.find((f: any) => f.properties.id === featureId);

    // if (!feature || feature.geometry.type !== "LineString" || !feature.elevation_profile) {
    if (!feature || feature.geometry.type !== "LineString" || !elevation_profile) {
      setElevationSummary([null, null, null])
      setShowChart(false)
      showElevationRef.current = false

      return
    };

    let smoothFactor = 100
    let smoothTreshold = 0.2
    if (feature.properties.transportation === "hike" || feature.properties.transportation === "run") {
      smoothFactor = 25
      smoothTreshold = 0.1
    }


    // let elevations = feature.elevation_profile.coordinates;  // Smoothen with approx 100m resolution
    let elevations = elevation_profile.coordinates;  // Smoothen with approx 100m resolution

    // const lineString = turf.lineString(feature.elevation_profile.coordinates);
    const lineString = turf.lineString(elevation_profile.coordinates);
    const length = turf.length(lineString, { units: 'kilometers' });
    const points: any[] = [];
    const labels: any[] = ["0.0"];
    [] = [];
    let totalAscent = 0;
    let totalDescent = 0;
    let previousElevation = elevations[0][2];
    let cumulativeDistance = 0;


    elevations.forEach((coord: any, index: any) => {
        const currentElevation = coord[2];
        const elevationDifference = currentElevation - previousElevation;

        if (Math.abs(elevationDifference) > 0) { // Using a 1 meter threshold
            if (elevationDifference > 0) {
                totalAscent += elevationDifference;
            } else {
                totalDescent -= elevationDifference;
            }
        }

        points.push(Math.round(currentElevation));

        if (index > 0) {
            const segmentLength = turf.distance(elevations[index - 1], coord, { units: 'kilometers' });
            cumulativeDistance += segmentLength;
            labels.push(cumulativeDistance.toFixed(1));
        }

        previousElevation = currentElevation;
    });



    // Update chart or other display elements here...

    if (chartInstanceRef.current) {
      // @ts-ignore
      chartInstanceRef.current.destroy(); // Destroy the existing chart instance
    }


    if (chartRef.current) {
  // @ts-ignore
  const ctx = chartRef.current.getContext('2d');
  // @ts-ignore
  chartInstanceRef.current = new Chart(ctx, {
    type: 'line',
    data: {
      labels: labels,
      datasets: [{
        label: 'Elevation (m)',
        data: points,
        fill: false,
        borderColor: '#ff8c01',
        tension: 0.4,
        pointRadius: 0,
        // @ts-ignore
        hitRadius: 15,
      hoverRadius: 5
      }]
    },
    options: {
      responsive: true,
      maintainAspectRatio: false,
      scales: {
        x: {
          title: {
            display: true,
            text: 'Distance (km)'
          }
        },
        y: {
          title: {
            display: true,
            text: 'Elevation (m)'
          },
          beginAtZero: false
        }
      },
      plugins: {
        legend: {
            display: false
        },
    },
    onHover: (event, chartElement) => {
      if (chartElement.length > 0) {
        updateMarkerPosition(chartElement[0].index);
      } else {
        updateMarkerPosition(-1);
      }

  }
}
});
    }

// const geoJsonData = createGeoJSONLine(currentFeature.elevation_profile.coordinates);
const geoJsonData = createGeoJSONLine(elevation_profile.coordinates);
setGpxData(geoJsonData)


    setElevationSummary([Math.round(totalAscent), Math.round(totalDescent), length.toFixed(1)])
  }

  const updateMarkerPosition = (pointIndex: any) => {
    // Assuming you have a way to map label to coordinates
    if (pointIndex === -1) {
      // @ts-ignore
      markersdistanceRef.current[0].setLngLat([0,0]);
    }
    if (elevationData) {
      const coordinates = elevationData.coordinates[pointIndex];


    if (coordinates) {
      if (markersdistanceRef.current.length > 0) {
        // @ts-ignore
        const markerElement = markersdistanceRef.current[0].getElement();
        markerElement.className = `marker-small`; // Update the class for dynamic icon change
        // @ts-ignore
        markersdistanceRef.current[0].setLngLat([coordinates[0], coordinates[1]]);
      }
    }}
};

useEffect(() => {
  const loadImage = (src: any, ref: any) => {
    const img = new Image();
    img.crossOrigin = 'anonymous';
    img.onload = () => {
      ref.current = img;
    };
    img.src = src;
  };

  loadImage('/slostr-logo.svg', overlayImageRef);
  loadImage('/lowtrip.svg', overlayImageLowTripRef);
  loadImage('https://img.icons8.com/ios-glyphs/30/sail-boat.png', imageBoatRef);
  loadImage('https://img.icons8.com/ios-glyphs/30/sail-boat.png', imageSailRef);
  loadImage('https://img.icons8.com/ios-filled/50/cycling-road--v1.png', imageBikeRef);
  loadImage('https://img.icons8.com/ios/100/high-speed-train.png', imageTrainRef);
  loadImage('https://img.icons8.com/ios/100/car--v1.png', imageCarRef);
  loadImage('https://img.icons8.com/ios-filled/100/trekking.png', imageHikeRef);
  loadImage('https://img.icons8.com/ios-filled/100/bus.png', imageBusRef);
  loadImage('https://img.icons8.com/ios-filled/100/running.png', imageRunRef);
  loadImage('https://img.icons8.com/ios-filled/100/motorcycle.png', imageMotoRef);
  loadImage('https://storage.googleapis.com/slostr-imgs/icons/hitchhike.svg', imageHitchhikeRef);
}, []);




const startRecording = () => {
  const container = document.getElementById('map');
  const scale = window.devicePixelRatio || 1; // Device pixel ratio for high DPI displays
  const startTime = Date.now(); // Start time for the overlay timer


  const canvas = document.createElement('canvas');
  const ctx = canvas.getContext('2d');
  // @ts-ignore
  ctx.scale(scale, scale); // Scale the drawing context
  // @ts-ignore
  canvas.width = container.offsetWidth * scale;
  // @ts-ignore
  canvas.height = container.offsetHeight * scale;

  const drawMarkerToCanvas = (markerElement: any) => {
    const className = markerElement.className;
    const img = getMarkerImage(className); // Function to get the correct image based on className
    const radius = 15; // Set the radius to match the image size (15 for 30x30 markers)
    const diameter = radius * 2;
    if (img) {
      const markerRect = markerElement.getBoundingClientRect();
      // @ts-ignore
      const x = (markerRect.left - container.getBoundingClientRect().left) * scale;
      // @ts-ignore
      const y = (markerRect.top - container.getBoundingClientRect().top) * scale;
      // @ts-ignore
          // Draw a filled white circle as the background
    ctx.beginPath();
    // @ts-ignore
    ctx.arc(x + radius, y + radius, radius + 5, 0, 2 * Math.PI);
    // @ts-ignore
    ctx.fillStyle = "white";
    // @ts-ignore
    ctx.fill();

    // Draw a gray border around the circle
    // @ts-ignore
    ctx.lineWidth = 2;
    // @ts-ignore
    ctx.strokeStyle = "gray";
    // @ts-ignore
    ctx.stroke();
    // @ts-ignore
      ctx.drawImage(img, x, y, diameter, diameter); // Assuming all markers should be 30x30 pixels
    }
  };

  const getMarkerImage = (className: any) => {
    if (className.includes('icon-bike')) return imageBikeRef.current;
    if (className.includes('icon-train')) return imageTrainRef.current;
    if (className.includes('icon-car')) return imageCarRef.current;
    if (className.includes('icon-boat')) return imageBoatRef.current;
    if (className.includes('icon-sail')) return imageSailRef.current;
    if (className.includes('icon-hike')) return imageHikeRef.current;
    if (className.includes('icon-bus')) return imageBusRef.current;
    if (className.includes('icon-run')) return imageRunRef.current;
    if (className.includes('icon-moto')) return imageMotoRef.current;
    if (className.includes('icon-hitchhike')) return imageHitchhikeRef.current;
    return null;
  };


  const drawFrame = async () => {
      // Clear the canvas
      // @ts-ignore
      ctx.clearRect(0, 0, canvas.width, canvas.height);

      // Draw the map canvas
      // @ts-ignore
      const mapCanvas = mapContainerRef.current.querySelector('canvas');


      // @ts-ignore
      ctx.drawImage(mapCanvas, 0, 0, canvas.width , canvas.height );

   // Draw each marker
  document.querySelectorAll('.marker-animation').forEach(drawMarkerToCanvas);

      // if (Date.now() - startTime < 3000) {
      //   // Draw the text overlay
      //   // @ts-ignore
      //   ctx.fillStyle = 'rgba(0, 0, 0, 0.7)';
      //   // @ts-ignore
      //   ctx.fillRect(0, 0, canvas.width, canvas.height); // Background for text
      //   // @ts-ignore
      //   ctx.font = '40px Roboto Mono';
      //   // @ts-ignore
      //   ctx.fillStyle = 'white';
      //   // @ts-ignore
      //   ctx.fillText("Bike and climb in the south of France", canvas.width/2-300, canvas.height/2-30);
      //   // @ts-ignore
      //   ctx.fillText("A sporty 6 day trip", canvas.width/2-130, canvas.height/2+30);
      // }


      if (overlayImageRef.current) {
        const imageX = 20; // X coordinate - close to the left edge
        const imageY = (canvas.height) - 80 - 10;

        // Set desired width and height for the overlay image
        const desiredWidth = 190;  // Adjust width as needed
        const desiredHeight = 80; // Adjust height as needed

        // Draw the image with the new width and height
        // @ts-ignore
        ctx.drawImage(
          overlayImageRef.current,
          imageX,
          imageY,
          desiredWidth,
          desiredHeight
        );
      }


      // Optionally add other overlays
      // @ts-ignore
      ctx.fillStyle = 'rgba(255, 255, 255, 0.7)';
      // @ts-ignore
      ctx.fillRect(0, 0, canvas.width, 80);
      // @ts-ignore
      ctx.font = '28px Arial';
      // @ts-ignore
      ctx.fillStyle = 'black';

      const icons = {
        hike: '🥾',
        bike: '🚲',
        bus: '🚌',
        car: '🚗',
        train: '🚄',
        boat: '⛴',
        sail: '⛵',
        moto: '🏍',
        hitchhike: '👍',
        run: '🏃'
    };

    let offsetY = 50;
    let offsetX = 30;
    Object.entries(currentTransportDistanceRef.current).forEach(([mode, distance], index) => {
if (distance !== 0) {
                // @ts-ignore

ctx.fillText(`${icons[mode] || '🚶'} ${distance.toFixed(0)} km`, offsetX, offsetY);
offsetX += 160;  // Move text down for the next entry
}

    });
      requestAnimationFrame(drawFrame);
  }

  drawFrame();

  const stream = canvas.captureStream(30); // Capture the composite canvas at 30 FPS
  const mediaRecorder = new MediaRecorder(stream, { mimeType: "video/webm", videoBitsPerSecond: 50000000  // Increase bitrate for better quality
});

  mediaRecorder.ondataavailable = (event) => {
    if (event.data.size > 0) {
      // @ts-ignore
      setRecordedChunks((prev) => [...prev, event.data]);
    }
  };

  mediaRecorder.start(10); // For every 10 ms of data
  mediaRecorderRef.current = mediaRecorder;
  setRecording(true);
};





const stopRecording = () => {
  mediaRecorderRef.current?.stop();
  setRecording(false);

  setTimeout(() => {
    const blob = new Blob(recordedChunks, { type: "video/webm" });

    const url = URL.createObjectURL(blob);
    const a = document.createElement("a");
    a.href = url;
    a.download = "map-animation.webm";
    document.body.appendChild(a);
    a.click();
    URL.revokeObjectURL(url);
    setRecordedChunks([]);
  }, 1000);
};

const formatNumber = (number: any) => {
  const nfObject = new Intl.NumberFormat('en-US');
  return nfObject.format(number)
};

const captureLastFrameAsImage = (totalCO2: any) => {
  const container = document.getElementById('map');
  const scale = window.devicePixelRatio || 1;

  const canvas = document.createElement('canvas');
  const ctx = canvas.getContext('2d');
  // @ts-ignore
  ctx.scale(scale, scale);// @ts-ignore
  canvas.width = container.offsetWidth * scale;// @ts-ignore
  canvas.height = container.offsetHeight * scale;

  const drawMarkerToCanvas = (markerElement: any) => {
    const className = markerElement.className;
    const img = getMarkerImage(className);
    const radius = 15;
    const diameter = radius * 2;
    if (img) {
      const markerRect = markerElement.getBoundingClientRect();// @ts-ignore
      const x = (markerRect.left - container.getBoundingClientRect().left) * scale;// @ts-ignore
      const y = (markerRect.top - container.getBoundingClientRect().top) * scale;
// @ts-ignore
      ctx.beginPath();// @ts-ignore
      ctx.arc(x + radius, y + radius, radius + 5, 0, 2 * Math.PI);// @ts-ignore
      ctx.fillStyle = "white";// @ts-ignore
      ctx.fill();// @ts-ignore
      ctx.lineWidth = 2;// @ts-ignore
      ctx.strokeStyle = "gray";// @ts-ignore
      ctx.stroke();// @ts-ignore
      ctx.drawImage(img, x, y, diameter, diameter);
    }
  };

  const getMarkerImage = (className: any) => {
    if (className.includes('icon-bike')) return imageBikeRef.current;
    if (className.includes('icon-train')) return imageTrainRef.current;
    if (className.includes('icon-car')) return imageCarRef.current;
    if (className.includes('icon-boat')) return imageBoatRef.current;
    if (className.includes('icon-sail')) return imageSailRef.current;
    if (className.includes('icon-hike')) return imageHikeRef.current;
    if (className.includes('icon-bus')) return imageBusRef.current;
    if (className.includes('icon-run')) return imageRunRef.current;
    if (className.includes('icon-moto')) return imageMotoRef.current;
    if (className.includes('icon-hitchhike')) return imageHitchhikeRef.current;
    return null;
  };

  const drawFrame = () => {
    // @ts-ignore
    ctx.clearRect(0, 0, canvas.width, canvas.height);
    // @ts-ignore
    const mapCanvas = container.querySelector('canvas');
    if (mapCanvas) {
          // @ts-ignore
      ctx.drawImage(mapCanvas, 0, 0, canvas.width, canvas.height);
    }

    document.querySelectorAll('.marker').forEach(drawMarkerToCanvas);


    // @ts-ignore
    ctx.fillStyle = 'rgba(255, 255, 255, 0.7)';
    // @ts-ignore
    ctx.fillRect(0, 0, canvas.width, 100);
    // @ts-ignore
    ctx.font = 'bold 46px Arial';
    // @ts-ignore
    ctx.fillStyle = 'black';

    const icons = {
      hike: '🥾',
      bike: '🚲',
      bus: '🚌',
      car: '🚗',
      train: '🚄',
      boat: '⛴',
      sail: '⛵',
      moto: '🏍',
      hitchhike: '👍',
      run: '🏃'
    };

    let offsetY = 70;
    let offsetX = 30;

    if (transports) {
      const filteredStats = Object.entries(transports).reduce((acc: any, [key, value]: [any, any]) => {
        if (typeof value === 'object' && (value.ascent > 0 || value.descent > 0 || value.distance > 0)) {
          acc.push({
            type: key,
            ascent: value.ascent,
            descent: value.descent,
            distance: value.distance,
          });
        }
        return acc;
      }, []);

      filteredStats.map((item: any, index: any) => {
        if (item.distance !== 0) {
          if (offsetX > canvas.width) {
            offsetY = 200;
            offsetX = 30;
          }
          // @ts-ignore
          ctx.fillText(`${icons[item.type] || '🚶'} ${formatNumber(item.distance.toFixed(0))} km`, offsetX, offsetY);
          offsetX += 280;
        }
      })
    }



        // @ts-ignore
        // ctx.fillStyle = 'rgba(151, 188, 98, 0.3)';
    // @ts-ignore
    // ctx.fillRect(0, canvas.height-100, canvas.width, 100);
    // @ts-ignore
    ctx.font = 'bold 50px Arial';
    // @ts-ignore
    ctx.fillStyle = '#2C5F2D';



        if (overlayImageRef.current) {
          const imageX = 20;
          const imageY = (canvas.height) - 120 - 20;
          const desiredWidth = 280;
          const desiredHeight = 130;
          // @ts-ignore
          ctx.drawImage(
            overlayImageRef.current,
            imageX,
            imageY,
            desiredWidth,
            desiredHeight
          );
        }
        if (overlayImageLowTripRef.current) {
          const imageX = (canvas.width) - 150;
          const imageY = (canvas.height) - 70 ;
          const desiredWidth = 140;
          const desiredHeight = 60;
          // @ts-ignore
          ctx.drawImage(
            overlayImageLowTripRef.current,
            imageX,
            imageY,
            desiredWidth,
            desiredHeight
          );
        }

        if (totalCO2) {
          let offset = 0
          if (totalCO2 > 10) {
            offset = 20
          } else if (totalCO2 > 10) {
            offset = 60
          } else if (totalCO2 > 100) {
            offset = 100
          } else if (totalCO2 > 1000) {
            offset = 120
          }
                // @ts-ignore
                ctx.fillText(`${totalCO2}kg CO₂`, canvas.width-200 - offset, canvas.height-90)
        }


        animationFrameId.current = requestAnimationFrame(drawFrame);
      };

  if (tripPostImg===true) {
    drawFrame();

  }

  const stream = canvas.captureStream(30); // Capture the composite canvas at 30 FPS
  const recordedChunks: any = [];
  const mediaRecorder = new MediaRecorder(stream, { mimeType: "video/webm", videoBitsPerSecond: 50000000 });

  mediaRecorder.ondataavailable = (event) => {
    if (event.data.size > 0) {
      recordedChunks.push(event.data);
    }
  };

  mediaRecorder.start();

  setTimeout(() => {
    mediaRecorder.stop();
  }, 500); // Record for 100ms to ensure at least one frame is captured

  mediaRecorder.onstop = () => {
    const blob = new Blob(recordedChunks, { type: "video/webm" });
    const url = URL.createObjectURL(blob);
    // const a = document.createElement("a");
    // a.href = url;
    // a.download = "map-animation.webm";
    // document.body.appendChild(a);
    // a.click();
    // URL.revokeObjectURL(url);
    const video = document.createElement('video');
    video.src = url;

    video.onloadeddata = () => {
      video.currentTime = 0.1; // Seek to the last frame (slightly before the end)

      video.onseeked = () => {
        const tempCanvas = document.createElement('canvas');
        const tempCtx = tempCanvas.getContext('2d');
        tempCanvas.width = canvas.width;
        tempCanvas.height = canvas.height;// @ts-ignore
        tempCtx.drawImage(video, 0, 0, tempCanvas.width, tempCanvas.height);

        // Capture the frame as an image and trigger download
        const image = tempCanvas.toDataURL("image/png");
        setTripPostImg(image)
        // const a = document.createElement("a");
        // a.href = image;
        // a.download = "map-image.png";
        // document.body.appendChild(a);
        // a.click();
        // document.body.removeChild(a);

        URL.revokeObjectURL(url);
      };
    };

    video.onerror = (err) => {
      console.error('Error loading video:', err);
    };
  };
    // Clean up animation frame on unmount
    return () => {
      if (animationFrameId.current) {
        cancelAnimationFrame(animationFrameId.current);
      }
    };
  };



const captureImage = () => {

  let totalCO2: any = 0
  if (co2) {

    const rawData = JSON.parse(co2);

    // Group data by 'Mean of Transport' and sum up values
    const groupedData = rawData.reduce((acc: any, item: any) => {
        const key = item['Mean of Transport'];
        if (!acc[key]) {
            acc[key] = {
                total: 0,
                details: [],
                color: item.colors  // Assuming the color is the same for each group
            };
        }
        acc[key].total += item.kgCO2eq;
        acc[key].details.push(`${item.NAME}: ${item.kgCO2eq.toFixed(2)} kgCO2eq`);
        return acc;
    }, {});
    const co2Values = Object.values(groupedData).map((group: any) => group.total);

    totalCO2 = co2Values.reduce((acc: any, val: any) => acc + val, 0).toFixed(0);
  }



                    if (mapFeatures && mapFeatures.features.length > 0) {
                      const data = mapFeatures;
                      const bbox = turf.bbox(data);
                      mapInstance?.fitBounds([
                        [bbox[0], bbox[1]], // southwest coordinates
                        [bbox[2], bbox[3]] // northeast coordinates
                      ], {
                        padding: 40 // Adjust padding as needed
                      });
                    }
                    captureLastFrameAsImage(totalCO2)

};

useEffect(() => {

  if (tripPostImg===true) {
      captureImage()

  }


}, [tripPostImg])



  return (
    <>
      <div className="w-full relative h-full">
      <div className="absolute h-full right-0" style={{ width: isPhoneWidth ? `calc((100vh - 120px) * ${16/9})` : '100%' }}>
        {/* <div
          className={`shadow-all md:ml-5 box-border mx-0 h-full`}
          ref={mapContainerRef}
          // id="map-container"
          style={{ borderRadius: "12px" }}
        /> */}
        {/* <MapContainer map={map} setMap={setMap} mapContainerRef={mapContainerRef}/> */}
        <div
          id="map"
          className="w-full h-full rounded-[12px]"
          // ref={(el) => (mapContainerRef.current = el)}
        />
<div id="overlay" className="absolute top-0 left-0 w-full h-[0px] bg-[rgba(0, 0, 0, 0.7)] text-white">
    </div>

        <MapStyleSelector setMapStyle={setMapStyle} />
        <InfoView />
        {/* <div className="absolute bottom-[40px] right-2 hover:scale-95 transition">
              <IconButton aria-label="delete" size="medium" className="!bg-secondary !shadow-lg" onClick={() => setQaOpen(!qaOpen)}>
        <BiSolidCommentDetail color="white"/>
      </IconButton>
              </div> */}
        {/* {userIsAuthor && (
        <MapAnimation map={mapInstance} allLayers={allLayers} allMarkers={allMarkers} markersRef={markersRef} mapFeatures={mapFeatures} setTotalDistance={setTransportDistances} transports={transports} startRecording={startRecording} stopRecording={stopRecording} setIsPhoneWidth={setIsPhoneWidth}/>
        )} */}

      </div>
      </div>

    </>
  );
};

export default MapboxMapView;
