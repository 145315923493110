import React, { useEffect, useRef, useState, useMemo } from "react";
import mapboxgl from "mapbox-gl";
import "mapbox-gl/dist/mapbox-gl.css";
import * as turf from "@turf/turf";
import HomeIcon from "@mui/icons-material/Home";
import "@mapbox/mapbox-gl-draw/dist/mapbox-gl-draw.css";
import Button from "@mui/material/Button";
import { getLinePaintTransport } from "../../utils/mapStyles";
import ImageGallery from "react-image-gallery";
import ReactDOM from "react-dom";
import MapboxDraw from "@mapbox/mapbox-gl-draw";
import MapStyleSelector from "./MapStyleSelector";
// @ts-ignore
import polyline from "@mapbox/polyline";
import InfoViewEdit from "./InfoViewEdit";
// @ts-ignore
import isEqual from "lodash.isequal"; // You can use lodash's isEqual for deep comparison

import {
  transformChapterImages,
  MyGallery,
} from "./utils";


const NewMapboxMapEdit: React.FC<any> = ({
  mapFeatures,
  chapterIndex,
  setPoiChange,
  deleteFeature,
  width,
  selectedRouteIndex,
  setSelectedRouteIndex,
  methodUsed,
  setNewCoord,
  setMapInstance,
  mapInstance,
  transportationMethod,
  setRoute,
  map,
  mapContainerRef,
  allLayers,
  setAllLayers,
  allMarkers,
  setAllMarkers,
  mapAnimation, setMapAnimation
}) => {
  const drawRef = useRef<any>(null);
  const directionsRef = useRef<any>(null);
  const [profile, setProfile] = useState<any>(null);
  const [poiMarkers, setPoiMarkers] = useState<mapboxgl.Marker[]>([]);

  const fitBoundsToFeatures = (map: any) => {
    if (map && mapFeatures && mapFeatures.features.length > 0) {
      if (
        mapFeatures.features.length == 1 &&
        mapFeatures.features[0].geometry.type == "Point"
      ) {
        map.flyTo({
          center: mapFeatures.features[0].geometry.coordinates,
          zoom: 10, // Updated zoom level to 12 as requested
          essential: true, // this animation is considered essential with respect to prefers-reduced-motion
        });
      } else {
        const data = mapFeatures;
        const bbox = turf.bbox(data);
        map.fitBounds(
          [
            [bbox[0], bbox[1]], // southwest coordinates
            [bbox[2], bbox[3]], // northeast coordinates
          ],
          {
            padding: 20, // Adjust padding as needed
          }
        );
      }
    }
  };


  const removeSourcesAndLayers = async (
    mapInstance: any,
    layerNames: string[],
    sourceNames: string[]
  ) => {
    const markerDivs = document.querySelectorAll(".mapboxgl-marker");
    markerDivs.forEach((marker) => {
      marker.remove();
    });

    try {
      // Remove all event listeners

      const alllayers = map.getStyle().layers;

      alllayers.forEach((layer: any) => {
        if (layer.id.includes("slostr")) {
          if (map.getLayer(layer.id)) {
            map.removeLayer(layer.id);
          }
        }
      });
      alllayers.forEach((layer: any) => {
        if (layer.id.includes("slostr")) {
          if (map.getSource(layer.id)) {
            map.removeSource(layer.id);
          }
        }
      });
    } catch (error) {
      console.error("Error initializing map features", error);
    }

    if (allMarkers.length > 0) {
      allMarkers.forEach(([marker, markerName]: any) => marker.remove());
      setAllMarkers([]);
    }

    try {
          allLayers.forEach((layerName: any) => {

      if (map.getLayer(layerName)) {
        map.removeLayer(layerName);
      }
    });

    allLayers.forEach((layerName: any) => {
        if (map.getSource(layerName)) {
          map.removeSource(layerName);
        }

    });
  } catch (error) {
    console.error(`Error removing layers:`, error);
  }

    setAllLayers([]);
  }


  const cleanUpMap = async (mapInstance: any) => {


    if (allMarkers.length > 0) {
      allMarkers.forEach(([marker, markerName]: any) => marker.remove());
      setAllMarkers([]);
    }

    const markerDivs = document.querySelectorAll(".mapboxgl-marker");
    markerDivs.forEach((marker) => {
      marker.remove();
    });

    if (allLayers && allLayers.length > 0) {
      removeSourcesAndLayers(mapInstance, allLayers, allLayers);
    }
  };

  useEffect(() => {
    const resetting = async (map: any) => {
      cleanUpMap(map);
      const mapContainerDiv = document.getElementById("map");
      if (mapContainerDiv && mapContainerRef.current) {
        if (mapContainerDiv !== mapContainerRef.current.parentNode) {
          mapContainerDiv.innerHTML = ""; // Clear existing content
          mapContainerDiv.appendChild(mapContainerRef.current);
        }
      }

      map.off('click');
      map.off('mouseenter');
      map.off('mouseleave');
      map.off('moveend');
      map.off('move');
      map.off('style.load');
      map.off('idle');
    };

    if (map) {
      if (map) {
        resetting(map)
        setMapInstance(map)
      } else {
        map.on('load', () => {
          resetting(map)
          setMapInstance(map)
        })
      }

      map.off('load');
      map.resize()

    }

  }, [map]);

  useEffect(() => {
    if (!mapInstance) return;
    mapInstance.resize();
    const desiredStyleUrl =
      "mapbox://styles/vladke99/cm33k6g55002o01nwhohygudi";
    const desiredStyle = "cm33k6g55002o01nwhohygudi";

    mapInstance.off('click');
    mapInstance.off('mouseenter');
    mapInstance.off('mouseleave');
    mapInstance.off('moveend');
    mapInstance.off('move');
    mapInstance.off('style.load');
    mapInstance.off('idle');
    mapInstance.off('render');

    if (
      !mapInstance.getStyle().sprite ||
      !mapInstance.getStyle().sprite.includes(desiredStyle)
    ) {
      mapInstance.setStyle(desiredStyleUrl);
    }
  }, [mapInstance]);

  const initializeMapFeatures = async (
    map: any,
    mapFeatures: any,
    firstLoad: boolean
  ) => {
    if (!map) return;
    cleanUpMap(mapInstance);

    poiMarkers.forEach((marker) => marker.remove());
    setPoiMarkers([]);

    const newPoiMarkers: any = [];

    map.off("click");
    map.off("mouseenter");
    map.off("mouseleave");
    map.off("moveend");
    map.off("move");
    map.off("style.load");
    map.off('idle');

    if (mapFeatures && mapFeatures.features.length > 0) {
      const newLayerNames: any[] = [];
      const newMarkers: any[] = [];
      mapFeatures.features.forEach((feature: any, index: any) => {
        if (feature.geometry.type === "LineString") {
          const lineString = turf.lineString(feature.geometry.coordinates, {
            transport: feature.properties.transportation.name,
          });
          const layerName = `slostr-route-${index}`;
          newLayerNames.push(layerName);

          if (!map.getSource(layerName)) {
            map.addSource(layerName, {
              type: "geojson",
              data: lineString,
            });
          }

          if (methodUsed === "google") {
            if (!map.getLayer(layerName)) {
              map.addLayer({
                id: layerName,
                type: "line",
                source: layerName,
                layout: {
                  "line-join": "round",
                  "line-cap": "round",
                },
                paint: {
                  "line-color":
                    index === selectedRouteIndex ? "#97bc62" : "#5bb8ea",
                  "line-width": index === selectedRouteIndex ? 4 : 2,
                  "line-opacity": index === selectedRouteIndex ? 1 : 0.2,
                },
              });
            }

            // Add click event to toggle selected route
            map.on("click", layerName, (e: any) => {
              e.preventDefault();

              setSelectedRouteIndex(index); // Update the selected route index on click
              updateRouteStyles(map, mapFeatures.features.length, index); // Update styles based on new selection
            });
          } else {
            if (!map.getLayer(layerName)) {
              map.addLayer({
                id: layerName,
                type: "line",
                source: layerName,
                layout: {
                  "line-join": "round",
                  "line-cap": "round",
                },
                paint: getLinePaintTransport(),
              });
            }
          }

          const lineLength = turf.length(lineString, { units: "meters" });
          const oneThirdLength = lineLength / 3;
          const pointAtOneThird = turf.along(lineString, oneThirdLength, {
            units: "meters",
          });

          // Create an HTML element for the marker
          const el = document.createElement("div");
          // @ts-ignore
          el.className = `marker icon-${feature.properties.transportation.name}`;
          const deleteBtn = document.createElement("button");
          deleteBtn.className =
            "text-red-500 cursor-pointer border-none bg-transparent";
          deleteBtn.innerHTML = '<i class="fas fa-trash-alt"></i>'; // Using FontAwesome icon

          // Attach the click event listener
          const markerName = `marker-${JSON.stringify(feature.geometry)}`;
          deleteBtn.onclick = (e) => {
            e.preventDefault();
            deleteFeature(feature.properties.id, feature.geometry.type);
            // @ts-ignore
            marker.getPopup().remove();
            // remove marker name
            // deleteMarker(markerName);
          };

          const popupContent = document.createElement("div");
          popupContent.appendChild(deleteBtn);

          const marker = new mapboxgl.Marker(el)
            // @ts-ignore
            .setLngLat(pointAtOneThird.geometry.coordinates)
            .addTo(map);

          {
            methodUsed === "google"
              ? marker.getElement().addEventListener("click", () => {
                  // Wait for the popup to be open and then render the React component
                  setSelectedRouteIndex(index); // Update the selected route index on click
                  updateRouteStyles(map, mapFeatures.features.length, index);
                })
              : marker.setPopup(
                  new mapboxgl.Popup({ offset: 25 }).setDOMContent(popupContent)
                );
          }

          newMarkers.push([marker, markerName]);
        } else if (
          feature.geometry.type === "Point" &&
          feature.properties.chapter === chapterIndex
        ) {
          const el = document.createElement("div");
          el.className = `marker-poi`;
          el.style.backgroundColor = `${feature.properties.color || "#FF3131"}`;

                  // Add an inner div for the pseudo-element
        const innerDiv = document.createElement("div");
        innerDiv.className = 'marker-overlay';
        innerDiv.style.backgroundImage = `url(${feature.properties.icon})`;
        el.appendChild(innerDiv);

          const deleteBtn = document.createElement("button");
          deleteBtn.className =
            "text-red-500 cursor-pointer border-none bg-transparent";
          deleteBtn.innerHTML = '<i class="fas fa-trash-alt"></i>';

          // Attach the click event listener
          deleteBtn.onclick = (e) => {
            e.preventDefault();
            deleteFeature(feature.properties.id, feature.geometry.type);
            marker.remove();
          };

          const editBtn = document.createElement("button");
          editBtn.className =
            "text-primary cursor-pointer border-none bg-transparent";
          editBtn.innerHTML = '<i class="fas fa-pen-alt"></i>';

          // Attach the click event listener
          editBtn.onclick = (e) => {
            e.preventDefault();
            deleteFeature(feature.properties.id, feature.geometry.type);
            setPoiChange(feature);
            marker.remove();
          };

          const buttonDiv = document.createElement("div");
          buttonDiv.className = `w-full flex flex-row justify-between pt-5`;

          buttonDiv.appendChild(editBtn);
          buttonDiv.appendChild(deleteBtn);

          const popupContent = document.createElement("div");
          popupContent.className =
            "font-roboto text-secondary-600 text-sm";
          const galleryPlaceholderId = `gallery-${feature.properties.id}`; // Unique ID for each feature
          popupContent.innerHTML = `<div id="${galleryPlaceholderId}"></div><h3 class='leading-5 text-lg'>${feature.properties.title}</h3>
            <p>${feature.properties.content}</p>
          </div>
        `;
          popupContent.appendChild(buttonDiv);

          const marker = new mapboxgl.Marker(el)
            .setLngLat(feature.geometry.coordinates)
            .addTo(map);
          {
            methodUsed === null &&
              marker.setPopup(
                new mapboxgl.Popup({ offset: 25 }).setDOMContent(popupContent)
              );
          }
          marker.getElement().addEventListener("click", (e) => {
            // Wait for the popup to be open and then render the React component
            window.requestAnimationFrame(() => {
              const galleryElement =
                document.getElementById(galleryPlaceholderId);
                const PoiImages = transformChapterImages(feature.properties)
                if (galleryElement && PoiImages && PoiImages.length > 0) {
              // if (galleryElement) {
                ReactDOM.render(
                  <MyGallery
                    items={PoiImages}
                  />,
                  galleryElement
                );
              }
            });
          });

          newPoiMarkers.push(marker);
        }
      });
      fitBoundsToFeatures(map);
      setAllLayers(newLayerNames);
      setAllMarkers(newMarkers);
      setPoiMarkers(newPoiMarkers);
    }
  };

  const updateRouteStyles = (map: any, numRoutes: number, index: any) => {
    for (let i = 0; i < numRoutes; i++) {
      const layerId = `slostr-route-${i}`;
      const isSelected = i === index; // Use ref to get the current selected index
      map.setPaintProperty(
        layerId,
        "line-color",
        isSelected ? "#97bc62" : "#5bb8ea"
      );
      map.setPaintProperty(layerId, "line-width", isSelected ? 4 : 2);
      map.setPaintProperty(layerId, "line-opacity", isSelected ? 1 : 0.3);
    }
  };

  // useEffect(() => {
  //   const map = new mapboxgl.Map({
  //     // @ts-ignore
  //     container: mapContainerRef.current,
  //     // style: "mapbox://styles/mapbox/standard",
  //     style: "mapbox://styles/vladke99/clvmfz4xo01hg01qzengv0viu",
  //     center: [7, 48],
  //     zoom: 4,
  //   });

  //   map.on("load", () => {
  //     initializeMapFeatures(map, mapFeatures);
  //     setMapInstance(map);
  //   });

  // }, []);

  const setMapStyle = (styleURL: any) => {
    // event.preventDefault();
    if (!mapInstance) return;
    mapInstance.setStyle(styleURL);
    mapInstance.on("style.load", () => {
      // Re-initialize map features when style changes
      initializeMapFeatures(mapInstance, mapFeatures, true);
    });
  };

  const useDeepCompareEffect = (callback: any, dependencies: any) => {
    const currentDependenciesRef = useRef();

    if (!isEqual(currentDependenciesRef.current, dependencies)) {
      currentDependenciesRef.current = dependencies;
    }

    useEffect(callback, [currentDependenciesRef.current]);
  };

  useDeepCompareEffect(() => {
    if (!mapInstance) return;

    mapInstance.resize()

    initializeMapFeatures(mapInstance, mapFeatures, true);
  }, [mapFeatures, mapInstance]);

  return (
    <div className="w-full h-full relative">
      <div
        className="shadow-lg justify-center items-center"
        id="map"
        style={{
          width: width ? width : "100%",
          height: "100%",
          borderRadius: "12px",
        }}
      />
      <MapStyleSelector setMapStyle={setMapStyle} />
      {/* <InfoViewEdit /> */}
    </div>
  );
};

export default NewMapboxMapEdit;
