import { FunctionComponent, useState } from "react";
import CircularProgress from '@mui/material/CircularProgress';
import { useTranslation } from 'react-i18next';

import CreateAccountForm from "../components/authentification/CreateAccountForm";
import Navbar from "../components/main/Navbar";

const Divider = ({ width = "w-64" }) => {
  return (
    <div className={`w-full mb-4`}>
      <div
        className={`h-1 mx-auto bg-primary ${width} opacity-25 my-0 py-0 rounded-t mb-10`}
      ></div>
    </div>
  );
};

const SignUp: FunctionComponent = () => {
  const { t } = useTranslation();
  const [isLoading, setIsLoading] = useState(false);

  return (
    <div className="w-full relative bg-primary-contrast overflow-hidden flex flex-col items-center justify-start">
    <Navbar />
    <>
    {isLoading && (
        <div className="spinner-background white-bg-spinner">
          <CircularProgress/>
          {t('loading')}
        </div>
      )}
    <div className="w-full relative bg-primary-contrast flex flex-row items-start justify-center text-left text-[34.6px] text-darkslategray-200 font-poppins pt-[60px]">
      <div className="flex flex-row items-center justify-center w-full md:items-center md:justify-center">
        <div className="w-full bg-primary-contrast flex flex-col items-center justify-center box-border md:flex-col md:items-center md:justify-center p-2 gap-5">
          {/* <div className="self-stretch flex flex-col items-start justify-start md:self-stretch md:w-auto md:items-start md:justify-center">
            <div className="flex justify-center self-stretch h-[54px] relative leading-[53.41px] font-semibold md:text-lg">
            {t('signup-create')}
            </div>
          </div> */}
          <div className="w-full relative bg-primary-contrast flex flex-col gap-2 items-center justify-center text-center max-w-[400px] text-lg">
      {/* <div className="text-[80px]">🎉</div> */}
      <div className="w-full md:w-[500px]">
<h1>{t('test-user-thanks')}</h1>
<Divider/>
<p>{t('test-user-description')}</p>

      </div>

    </div>
          <CreateAccountForm
          setIsLoading={setIsLoading}
          />
        </div>
      </div>
      {/* <img
        className="self-stretch flex-1 relative max-w-full overflow-hidden max-h-full object-cover md:hidden md:invisible"
        alt=""
        src="/welcome_img.png"
      /> */}
    </div>
    </>
    </div>
  );
};

export default SignUp;
