import React, { useState, useContext } from 'react';
import Avatar from '@mui/material/Avatar';
import AvatarGroup from '@mui/material/AvatarGroup';
import PersonAddIcon from '@mui/icons-material/PersonAdd';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import DialogTitle from '@mui/material/DialogTitle';
import Dialog from '@mui/material/Dialog';
import IconButton from '@mui/material/IconButton';
import DeleteIcon from '@mui/icons-material/Delete';
import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';


import { useTranslation } from "react-i18next";
import { AuthContext } from "../authentification/AuthContext";
import { useNotification } from '../NotificationContext';
import { apiUpdateContributor } from '../../utils/api/api';  // Ensure correct path

const GroupAvatars: React.FC<any> = ({blog, contributors, updateContributors}) => {
    const { t } = useTranslation();

    const authContext = useContext(AuthContext);
    const { user } = authContext || {};
    const { notificationCount, community } = useNotification();

    const [showContributors, setShowContributors] = useState(false);


    const handleClose = (value: string) => {
        setShowContributors(false);
      };


      const handleAddContributor = async (event: any, option: any) => {
        if (!option) return;
        apiUpdateContributor(blog.id, 'add', option.follower.id);
        updateContributors([...contributors, option.follower]);
        setShowContributors(false);
    };

    const handleRemoveContributor = async (contributorId: any) => {
        apiUpdateContributor(blog.id, 'remove', contributorId);
        updateContributors(contributors.filter((c: any) => c.id !== contributorId));
    };


      const availableContributors = community.filter((person: any) =>
        !contributors.some((c: any) => c.id === person.follower.id) &&
        person.request_approved && user &&
        person.follower.id !== user.id &&
        person.followed.id == user.id
      );


  return (
    <>
    <div className='cursor-pointer'>
        {contributors.length > 0 ? (
    <AvatarGroup max={4} onClick={() => setShowContributors(true)}  sx={{
        '& .MuiAvatar-root': { width: 24, height: 24, fontSize: 15 },
      }}>
        {contributors.map((contributor: any) => (
            <Avatar alt={`${contributor.first_name || ''} ${contributor.last_name || ''}`} src={contributor.profile_picture} sx={{ width: 24, height: 24 }}> {!contributor.profile_picture && (
                `${contributor.first_name ? contributor.first_name[0].toUpperCase() : ''}`
              )} </Avatar>
              ))}
    </AvatarGroup>
        ) : ( (user && user.id === blog.author.id || blog.contributors.some((contributor: any) => contributor.id === user.id)) ?
            <Button variant="outlined" startIcon={<PersonAddIcon />} onClick={() => setShowContributors(true)}>
            {t("add-contributor")}
          </Button> : null
        )}

    </div>
          {user && showContributors && (
            <Dialog maxWidth="sm" fullWidth onClose={handleClose} open={showContributors}>
            <DialogTitle>{t("contributors")}</DialogTitle>
            <List sx={{ p: '5px' }}>
              {contributors.map((contributor: any) => (
                <ListItem key={contributor.id}>
                  <ListItemAvatar>
                    <Avatar src={contributor.profile_picture}> {!contributor.profile_picture && (
                `${contributor.first_name ? contributor.first_name[0].toUpperCase() : ''}`
              )} </Avatar>
                  </ListItemAvatar>
                    <ListItemText primary={`${contributor.first_name || ''}`}/>
                    {blog.author.id === user.id && (
                    <IconButton edge="end" aria-label="delete" onClick={() => handleRemoveContributor(contributor.id)}>
                  <DeleteIcon />
                </IconButton>
                    )}
                </ListItem>
              ))}
              {blog.author.id === user.id && (
                <ListItem>
              <Autocomplete
            options={availableContributors}
            getOptionLabel={(option) => `${option.follower.first_name} `}
            renderOption={(props, option) => (
              <li {...props}>
                <ListItemAvatar>
                  <Avatar src={option.follower.profile_picture}>
                    {!option.follower.profile_picture &&
                      `${option.follower.first_name ? option.follower.first_name[0].toUpperCase() : ''}`}
                  </Avatar>
                </ListItemAvatar>
                {`${option.follower.first_name} `}
              </li>
            )}
            renderInput={(params) => <TextField {...params} label={t('add-contributor')} variant="outlined" />}
            onChange={handleAddContributor}
            fullWidth
          />
        </ListItem>
              )}
            </List>
          </Dialog>

      )}
      </>
  );
}

export default GroupAvatars;

