import React, { useState, useRef, useEffect } from 'react';
import mapboxgl from 'mapbox-gl';
import '@mapbox/mapbox-gl-geocoder/dist/mapbox-gl-geocoder.css';
import MapboxGeocoder from '@mapbox/mapbox-gl-geocoder';
import Button from '@mui/material/Button';
import { useTranslation } from 'react-i18next';
// @ts-ignore
import polyline from '@mapbox/polyline';
import DeleteIcon from '@mui/icons-material/Delete';



const convertRouteToGeoJSON = (feature: any, transportation: any) => {
  // Decode the polyline string to an array of [latitude, longitude] coordinates
  const coordinates = polyline.decode(feature.geometry);

  // Convert the coordinates to a GeoJSON object
  const geoJson = {
    "type": "Feature",
    "geometry": {
      "type": "LineString",
      "coordinates": coordinates.map(([lat, lng]: [any, any]) => [lng, lat])  // Flip lat-lng to lng-lat for GeoJSON
    },
    "properties": {
      transportation: {name: transportation},
    }  // Add any properties you might need here
  };

  const routesFeatureCollection = {
    type: "FeatureCollection",
    features: [geoJson]
  };

  return routesFeatureCollection;
};


mapboxgl.accessToken = process.env.REACT_APP_MAPBOX_API_TOKEN as string;

const RouteSearcherMapbox: React.FC<any> = ({mapInstance, transportationMethod, setRoute, newCoord, allWaypoints, setAllWaypoints}) => {
  const { t } = useTranslation();

  const startGeocoderRef = useRef<any>(null);
  const endGeocoderRef = useRef<any>(null);
  const [startLocation, setStartLocation] = useState<any>(null);
  const [endLocation, setEndLocation] = useState<any>(null);
  const [startMarker, setStartMarker] = useState<any>(null);
  const [endMarker, setEndMarker] = useState<any>(null);
  const [changedMarker, setChangedMarker] = useState<any>(null);
  const [deletedMarker, setDeletedMarker] = useState<any[]>([]);
  const [waypoints, setWaypoints] = useState<any[]>([]);


  // Initialize the map
  useEffect(() => {

      const startGeocoder = new MapboxGeocoder({
        accessToken: mapboxgl.accessToken,
        // @ts-ignore
        mapboxgl: mapboxgl,
        placeholder: t('start-location'),
        marker:false,
        flyTo:true,
      });

      const endGeocoder = new MapboxGeocoder({
        accessToken: mapboxgl.accessToken,
        // @ts-ignore
        mapboxgl: mapboxgl,
        placeholder: t('end-location'),
        marker:false,
        flyTo:true,
      });

      startGeocoderRef.current = document.getElementById('start-geocoder')?.appendChild(startGeocoder.onAdd(mapInstance));
      endGeocoderRef.current = document.getElementById('end-geocoder')?.appendChild(endGeocoder.onAdd(mapInstance));

      startGeocoder.on('result', (e) => {
        const coordinates = e.result.geometry.coordinates;
        setStartLocation(coordinates);
      });

      endGeocoder.on('result', (e) => {
        const coordinates = e.result.geometry.coordinates;
        setEndLocation(coordinates);
      });

  }, []);

useEffect(()=> {

  if (newCoord) {
    if (!startLocation) {
      setStartLocation(newCoord)
      const inputElement = startGeocoderRef.current.querySelector('.mapboxgl-ctrl-geocoder--input');
      if (inputElement) {
        inputElement.value = `${newCoord[0].toFixed(3)}, ${newCoord[1].toFixed(3)}`;
      }
    } else if (!endLocation) {
      setEndLocation(newCoord)
      const inputElement = endGeocoderRef.current.querySelector('.mapboxgl-ctrl-geocoder--input');
      if (inputElement) {
        inputElement.value = `${newCoord[0].toFixed(3)}, ${newCoord[1].toFixed(3)}`;
      }
    } else {
      const newMarker: any = new mapboxgl.Marker({ draggable: true })
      .setLngLat(newCoord)
      .addTo(mapInstance)
      .on('dragend', () => setChangedMarker([newMarker, waypoints.length])); // Pass the current index

      const wps = [...waypoints, newMarker]

      setWaypoints(wps);
      setAllWaypoints([...allWaypoints, newMarker]);

      const geocoder = new MapboxGeocoder({
        accessToken: mapboxgl.accessToken,
        // @ts-ignore
        mapboxgl: mapboxgl,
        marker: false,
        flyTo: false
      });

      geocoder.on('result', e => {
        newMarker.setLngLat(e.result.geometry.coordinates);
        setChangedMarker([newMarker, waypoints.length])
      });


      const div = document.createElement('div');
      div.className = `flex flex-row z-[${99-waypoints.length}]`
      div.appendChild(geocoder.onAdd(mapInstance));
      // const deleteButton = document.createElement('button');
      // deleteButton.className = 'z-[10] p-2'
      // deleteButton.innerHTML = '<i style="color:indianred" class="fa-solid fa-trash"></i>';
      // deleteButton.onclick = () => handleDeleteWaypoint(newMarker, waypoints.length, geocoder, div);
      // div.appendChild(deleteButton);

      // if (deletedMarker.length > 0) {
        setDeletedMarker([...deletedMarker, [newMarker, geocoder, div]])
      // } else {
      //   setDeletedMarker([[newMarker, geocoder, div]])
      // }


      const geocoderElement = document.getElementById('end-geocoder')?.appendChild(div);

      const inputElement = geocoderElement?.querySelector('.mapboxgl-ctrl-geocoder--input');
      if (inputElement) {
        // @ts-ignore
        inputElement.value = `${newCoord[0].toFixed(3)}, ${newCoord[1].toFixed(3)}`;
      }
    }

  }

}, [newCoord])

const handleDeleteWaypoint = () => {
  // Then, update the waypoints array state by filtering out the deleted waypoint.
  const marker = deletedMarker[deletedMarker.length - 1]
  const updatedWaypoints = waypoints.filter((wp: any, idx: any) =>
wp !== marker[0])


  marker[0].remove(); // Remove the marker from the map.
  marker[1].onRemove(); // Clean up the geocoder instance.
  marker[2].remove(); // Remove the div from the DOM.


  setWaypoints(updatedWaypoints);

  const updatedDeletedMarkers = deletedMarker.slice(0, -1);
  setDeletedMarker(updatedDeletedMarkers);
};



  useEffect(() => {
    if (startLocation && mapInstance && !startMarker) {
      const marker: any = new mapboxgl.Marker({ draggable: true })
        .setLngLat(startLocation)
        .addTo(mapInstance)
        .on('dragend', () => onDragEnd(marker, setStartLocation));

      setStartMarker(marker);
      setAllWaypoints([...allWaypoints, marker]);

    } else if (startLocation && startMarker) {
      startMarker.setLngLat(startLocation)
    }
  }, [startLocation, mapInstance]);

  useEffect(() => {
    if (endLocation && mapInstance && !endMarker) {
      const marker: any = new mapboxgl.Marker({ draggable: true })
        .setLngLat(endLocation)
        .addTo(mapInstance)
        .on('dragend', () => onDragEnd(marker, setEndLocation));

      setEndMarker(marker);
      setAllWaypoints([...allWaypoints, marker]);

    } else if (endLocation && endMarker) {
      endMarker.setLngLat(endLocation)
    }
  }, [endLocation, mapInstance]);




  useEffect(() => {
  if (changedMarker) {
    const updatedWaypoints = waypoints.map((wp: any, idx: any) => {
      if (idx === changedMarker[1]) {
        return changedMarker[0]; // Update coordinates
      }
      return wp;
    });


    setWaypoints(updatedWaypoints);
  }
  }, [changedMarker])


  const onDragEnd = (marker: any, setLocation: any) => {
    const lngLat = marker.getLngLat();
    setLocation([lngLat.lng, lngLat.lat]);
  };

  useEffect(() => {
    if (startLocation && endLocation && mapInstance) {
      updateRoute()
    }
  }, [startLocation, endLocation, waypoints])


  // Draw route when both locations are selected
  const updateRoute = () => {
      if (startLocation && endLocation && mapInstance) {
      let waypointsString = ''
      if (waypoints.length > 0) {
      waypointsString = ';' + waypoints.map((wp: any) => wp.getLngLat().toArray().join(',')).join(';');
      }

      let profile = '';
      if (transportationMethod === 'bike') {
        profile = 'cycling';
      } else if (transportationMethod === 'car' || transportationMethod === 'bus' || transportationMethod === 'moto' || transportationMethod === 'hitchhike') {
        profile = 'driving';
      } else if (transportationMethod === 'hike' || transportationMethod === 'run') {
        profile = 'walking';
      }


      // @ts-ignore
      const coordinates = `${startLocation.join(',')};${endLocation.join(',')}${waypointsString}`;
      const url = `https://api.mapbox.com/directions/v5/mapbox/${profile}/${coordinates}?alternatives=false&geometries=polyline&overview=full&steps=false&access_token=${mapboxgl.accessToken}`;

      fetch(url)

        .then(response => response.json())
        .then(data => {

          const geoJsonRoute = convertRouteToGeoJSON(data.routes[0], transportationMethod);
          setRoute(geoJsonRoute)

          if (mapInstance.getSource('slostr-route-mapbox')) {
            mapInstance.getSource('slostr-route-mapbox').setData(geoJsonRoute);

          } else {
            mapInstance.addSource('slostr-route-mapbox', {
              type: 'geojson',
              data: geoJsonRoute
            });

            mapInstance.addLayer({
              id: 'slostr-route-mapbox',
              type: 'line',
              source: 'slostr-route-mapbox',
              layout: {
                'line-join': 'round',
                'line-cap': 'round'
              },
              paint: {
                'line-color': '#3b9ddd',
                'line-width': 5,
                'line-opacity': 0.75
              }
            });
          }
        });
    }
  }




  return (
    <div>
      <div className='flex flex-col gap-1 items-center'>
      <div className="flex items-center gap-2">
          <div id="start-geocoder" className="flex-1 z-[100]"></div>
        </div>
        {/* <input
          type="text"
          placeholder={t('enter-coordinates')}
          onChange={(e) => handleCoordinateInputChange(e, setStartLocation)}
          className="mt-2"
        /> */}
        <div className="flex items-center gap-2 mt-4">
          <div id="end-geocoder" className="flex-1 z-[99]"></div>
        </div>
      {waypoints && waypoints.length > 0 &&
      <Button color="error" className="z-[1]" variant="outlined" onClick={()=> handleDeleteWaypoint()} startIcon={<DeleteIcon />}> {t('delete-last-point')}</Button>
      }
      </div>

    </div>
  );
};

export default RouteSearcherMapbox;
