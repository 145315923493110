import { useState, useEffect } from "react";
import Navbar from "../components/main/Navbar";
import Footer from "../components/main/Footer";
import { useTranslation } from "react-i18next";

const Divider = ({ width = "w-64", mx= "mx-0" }) => {
  return (
    <div className={`w-full mb-4`}>
      <div
        className={`h-1 mx-auto md:${mx} bg-orange ${width} opacity-25 my-0 py-0 rounded-t mb-10`}
      ></div>
    </div>
  );
};
const AboutUs = () => {
  const { t } = useTranslation();
  const [activeText, setActiveText] = useState("community");
  const [flippedFounder, setFlippedFounder] = useState(false);
  const [flipped, setFlipped] = useState(0);
  const [flippedMarketer, setFlippedMarketer] = useState(false);

  useEffect(() => {
    const timer = setTimeout(() => {
      setFlipped(1);
    }, 500);
    const timer2 = setTimeout(() => {
      setFlipped(0);
    }, 700);

    return () => clearTimeout(timer);
  }, []);



  const getText = () => {
    switch (activeText) {
      case "founder":
        return t("about-founder");
      case "marketer":
        return t("about-marketer");
      case "community":
      default:
        return t("about-community");
    }
  };

  const getName = () => {
    switch (activeText) {
      case "founder":
        return "Vlad";
      case "marketer":
        return "Eleonore";
      case "community":
      default:
        return "slostr";
    }
  };

  const getSocial = () => {
    switch (activeText) {
      case "founder":
        return (
          <div className="flex flex-row items-start justify-start gap-[8px] mt-4">
          <div className="rounded-md bg-primary overflow-hidden flex flex-row items-center justify-center p-2 cursor-pointer">
            <a href="https://www.facebook.com/profile.php?id=100007587109068">
            <img
              className="h-4 w-4 relative overflow-hidden shrink-0"
              loading="lazy"
              alt=""
              src="/logofacebook.svg"
            />
            </a>
          </div>
          <div className="rounded-md bg-primary overflow-hidden flex flex-row items-center justify-center p-2 cursor-pointer">
            <a href="https://www.instagram.com/vladimirfayt/">
            <img
              className="h-4 w-4 relative overflow-hidden shrink-0"
              loading="lazy"
              alt=""
              src="/logoinstagram.svg"
            />
            </a>
          </div>
          <div className="rounded-md bg-primary overflow-hidden flex flex-row items-center justify-center p-[0.39rem] cursor-pointer">
            <a href="https://www.linkedin.com/in/vladimir-fayt-6507a91a1/">
            <img
              className="h-5 w-5 relative overflow-hidden shrink-0"
              loading="lazy"
              alt=""
              src="/logolinkedin.svg"
            />
            </a>
          </div>
          </div>
        );
      case "marketer":
        return (
          <div className="flex flex-row items-start justify-start gap-[8px] mt-4">
          </div>
        );
      case "community":
      default:
        return (
          <div className="flex flex-row items-start justify-start gap-[8px] mt-4">
          <div className="rounded-md bg-primary overflow-hidden flex flex-row items-center justify-center p-2 cursor-pointer">
            <a href="https://www.instagram.com/goslostr/">
            <img
              className="h-4 w-4 relative overflow-hidden shrink-0"
              loading="lazy"
              alt=""
              src="/logoinstagram.svg"
            />
            </a>
          </div>
          </div>
        );
    }
  };

  const handleImageClick = (textType: string) => {
    setActiveText((prevText) =>
      prevText === textType ? "community" : textType
    );
    if (textType === "marketer") {
        setFlippedMarketer(!flippedMarketer)
        setFlippedFounder(false)
    } else if (textType === "founder") {
        setFlippedFounder(!flippedFounder)
        setFlippedMarketer(false)
    } else {
        setFlippedFounder(false)
        setFlippedMarketer(false)
    }

  };


  return (
    <div className="w-full relative bg-primary-contrast overflow-hidden gap-[30px]">
      <Navbar />
      <div className="relative min-h-screen w-full">
        <div className="transition-all duration-500 ease-in-out h-screen pt-[60px]">
          <div className="grid md:grid-cols-2 h-full">
            <div className="relative flex items-center pt-10 md:pt-0">
              <div className="absolute top-0 left-0 w-2/3 h-2/3 bg-orange z-[0] rounded-br-xl"></div>
              <>
                <div className="flex w-full h-full flex-row md:flex-col gap-2 md:gap-8 justify-center m-3">
                  <div className="hover:scale-[0.98] transition-all duration-300 group relative md:ml-16 w-1/2 md:w-2/3 md:max-w-[400px] md:h-2/5 [perspective:1000px] cursor-pointer" onClick={() => handleImageClick('founder')} >
                    <div className={`relative h-full w-full shadow-lg  'transition-all duration-500 [transform-style:preserve-3d]  ${flippedFounder ? '[transform:rotateY(180deg)]' : ''} ${flipped === 1 ? '[transform:rotateY(40deg)]' :  ''}`}>
                      <div className="absolute inset-0">
                        <img
                          className="h-full w-full object-cover shadow-lg shadow-black/40 rounded-lg"
                          src="/pp.png"
                          alt=""
                        />
                      </div>
                      <div className="absolute inset-0 h-full w-full  px-12 text-center text-slate-200 [transform:rotateY(180deg)] [backface-visibility:hidden]">
                        <div className="absolute inset-0">
                          <img
                            className="h-full w-full object-cover shadow-lg shadow-black/40 rounded-lg"
                            src="/vlad_pp2.jpeg"
                            alt=""
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="hover:scale-[0.98] transition-all duration-300 group relative md:ml-16 w-1/2 md:w-2/3 md:max-w-[400px] md:h-2/5 [perspective:1000px] cursor-pointer" onClick={() => handleImageClick('marketer')} >
                  <div className={`relative h-full w-full shadow-lg  'transition-all duration-500 [transform-style:preserve-3d] ${flippedMarketer ? '[transform:rotateY(180deg)]' : ''} ${flipped === 1 ? '[transform:rotateY(-40deg)]' :  ''}`} >
                      <div className="absolute inset-0">
                        <img
                          className="h-full w-full object-cover shadow-lg shadow-black/40 rounded-lg"
                          src="/eleonore_pp.jpeg"
                          alt=""
                        />
                      </div>
                      <div className="absolute inset-0 h-full w-full  px-12 text-center text-slate-200 [transform:rotateY(180deg)] [backface-visibility:hidden]">
                        <div className="absolute inset-0">
                          <img
                            className="h-full w-full object-cover shadow-lg shadow-black/40 rounded-lg"
                            src="/eleonore_pp2.jpeg"
                            alt=""
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </>
            </div>
            <div className="flex flex-col items-center md:items-start justify-start md:justify-center p-5 md:pr-10 box-border">
              <h1 className="text-4xl font-extrabold tracking-tight">
                {t("about-hi")}, {activeText === "community" ? t("about-greeting-we") : t("about-greeting-I")}{" "}
                <span className="text-primary">{getName()}</span>
              </h1>
              <Divider />
              <p className="text-xl font-roboto text-secondary-600">
                {getText()}
              </p>
              {getSocial()}
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default AboutUs;
