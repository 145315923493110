import React, { useEffect, useRef } from 'react';
import mapboxgl from 'mapbox-gl';
import "mapbox-gl/dist/mapbox-gl.css";

const MapContainer: React.FC<any> = ({ map, setMap, mapContainerRef }) => {

  mapboxgl.accessToken = process.env.REACT_APP_MAPBOX_API_TOKEN as string;

  const logBaseLayers = (mapInstance: any) => {
    let layers: any = []
    if (mapInstance && mapInstance.getStyle) {
      const style = mapInstance.getStyle();
      style.layers.forEach((layer: any) => {
        layers.push(layer);
      });
    }
  };

  useEffect(() => {
    if (!map) {
      const initializeMap = new mapboxgl.Map({
        container: mapContainerRef.current,
        // container: 'map-container',
        style: "mapbox://styles/vladke99/cm33k6g55002o01nwhohygudi",
        center: [7, 48],
        zoom: 4,
      });

      // initializeMap.addControl(
      //   // @ts-ignore
      //   new MapboxGeocoder({
      //     accessToken: mapboxgl.accessToken,
      //     // @ts-ignore
      //     mapboxgl: mapboxgl,
      //   }),
      //   "top-left"
      // );
      initializeMap.addControl(new mapboxgl.NavigationControl(), "bottom-left");
      initializeMap.addControl(
        new mapboxgl.GeolocateControl({
          positionOptions: {
            enableHighAccuracy: true,
          },
          // When active the map will receive updates to the device's location as it changes.
          trackUserLocation: true,
          // Draw an arrow next to the location dot to indicate which direction the device is heading.
          showUserHeading: true,
        }),
        "bottom-left"
      );

      setMap(initializeMap);
      initializeMap.on('load', () => {
        logBaseLayers(initializeMap);

      })

      // return () => initializeMap.remove();
    }
  }, []);



  return (
    <div
      ref={mapContainerRef}
    // id='map-container'
      style={{ width: "100%", height: "100%", borderRadius: "12px"}}
    />
  );
};

export default MapContainer;
