import React, { FunctionComponent } from "react";
import { Link } from 'react-router-dom';
import Card from "./Card";


const Content: FunctionComponent<any> = ({routes}) => {
  return (
    <div className="self-stretch flex flex-row flex-wrap items-start justify-center gap-[20px] min-h-[787px] max-w-full text-left text-sm text-secondary-400 font-heading-h3-bold">
        {routes && routes.map((route: any, index: any) => (
          <Link to={`/municipal-route/${route.id}`} key={index}>
            <Card
            route={route}
            />
            </Link>
        ))}
    </div>
  );
};

export default Content;
