import { FunctionComponent } from "react";
import { Link } from "react-router-dom";
import { useTranslation } from 'react-i18next';


import BlogLikes from "./BlogLikes";

function getLocalizedField(blog: any, field: any, lang:any) {
  const suffix = lang ? `_${lang}` : '';
  if (blog[field + suffix] === '' || blog[field + suffix] === null) {
    return blog[field];

  } else {
    return blog[field + suffix]}
}


const Card: FunctionComponent<any> = ({ blog, event=null, chapter=null, date=null }) => {
  const { t, i18n } = useTranslation();


  return (
    <div className="flex flex-col gap-3">

<div className="md:hidden text-sm text-secondary-400 font-heading-h3-bold">
  {date}
</div>
<button
  id={`blog-${blog?.id}`}
  className={`hover-opacity-button rounded-[15px] w-full bg-primary-contrast shadow-[0px_3px_16px_rgba(0,_0,_0,_0.08)] flex flex-col items-start justify-start text-left text-sm text-secondary-400 font-heading-h3-bold cursor-pointer md:h-[350px] ${event ? 'h-[400px]' : 'h-auto'} p-3.5`}
>
<div className="w-full flex flex-col gap-2 text-secondary-800 font-semibold md:text-lg text-md">
  {(date && event) && (
    <>
<div className="relative flex flex-col">


   {event === 'created' ? t('trip-created') : event === 'updated' ? t('trip-updated') : `${t('trip-chapter-added')} ${chapter}`}


  </div>
    <div className="md:hidden relative md:text-base text-sm text-secondary-600 font-normal">
    {blog?.author?.first_name} {blog?.author?.last_name}
  </div>
  </>
)}


  </div>
  <div className={`w-full flex flex-col md:flex-row items-start justify-start ${event ? 'p-3.5 pl-0 pb-0 md:pb-5' : ''} box-border gap-[17px] max-w-full text-left text-sm h-full`}>

  <img
    className="rounded-[10px] self-stretch h-[150px] md:h-full relative md:w-[400px] w-full overflow-hidden shrink-0 object-cover"
    loading="lazy"
    alt=""
    src={blog?.header_image_thumbnail ? `${blog.header_image_thumbnail}` : "/welcome_img.png"}
  />
  <div className="flex flex-col items-start justify-between gap-[4px] w-[calc(100%-10px)] h-full overflow-hidden">
    <div className="flex flex-col items-start justify-start w-full gap-2">
      <div className="flex flex-col items-start justify-between w-full gap-2">
        <div className="hidden md:flex flex-row items-center justify-start gap-[8px] cursor-pointer">
          <img
            className="h-10 w-10 relative rounded-9xl object-cover"
            loading="lazy"
            alt=""
            src={blog?.author?.profile_picture ? `${blog.author.profile_picture}` : "/SlowTrav_icon.png"}
          />
          <div className="relative md:text-base text-sm">
            {blog?.author?.first_name} {blog?.author?.last_name}
          </div>
        </div>

      </div>
      <div className="overflow-hidden">
        <div className="font-semibold text-black text-base">
        {getLocalizedField(blog, 'title', i18n.language)}
        </div>
        <div className="md:text-sm text-xs">
        {getLocalizedField(blog, 'summary', i18n.language)}
        </div>
      </div>
    </div>
    <div className="flex flex-row items-center justify-between pt-4 w-full">
    <div className="relative flex flex-row text-xs gap-2">
          {blog?.activities?.map((activity: any, index: any) => (
            <img
              key={index}
              loading="lazy"
              src={activity.icon}
              className="p-[2px] rounded-[5px] border-2 border-solid border-olivegreen md:w-[25px] w-[25px]"
              alt=""
            />
          ))}
        </div>
      <BlogLikes blog={blog}/>
    </div>
  </div>

  </div>

</button>
</div>

  );
};

export default Card;
