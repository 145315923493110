import React, { FunctionComponent, useState, useEffect } from "react";
import { useTranslation } from 'react-i18next';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Box from '@mui/material/Box';
import Badge from '@mui/material/Badge';

import UserProfile from './UserProfile';
import UserCommunity from './UserCommunity';
import { CustomTabPanel, a11yProps } from './CustomTabPanel';

import { useNotification } from '../NotificationContext';




const UserSettings: FunctionComponent<any> = ({user}) => {
  const { t } = useTranslation();
  const { notificationCount, community } = useNotification();
  const [valueMainTab, setValueMainTab] = React.useState(0);


  const handleMainTabChange = (event: React.SyntheticEvent, newValue: number) => {
    setValueMainTab(newValue);
  };


  return (
    <>
    <section className="self-stretch flex flex-col items-center justify-start max-w-full text-center text-11xl text-gray1-500 font-text-small">
    <div className="self-stretch flex flex-row items-start justify-center py-0 px-8 box-border max-w-full">
      <div className="flex-1 flex flex-row items-end justify-start min-h-[278px] max-w-full">
        <div className="flex-1 flex flex-col items-center justify-center gap-[16px] max-w-full">
          <div className="w-40 h-40 relative rounded-181xl shrink-0 flex items-center justify-center">
            <img
              className="rounded-[50%] w-full h-full overflow-hidden shrink-0 object-cover absolute left-[0px] top-[12px] [transform:scale(1.2)]"
              loading="lazy"
              alt=""
              src={user?.profile_picture ? user.profile_picture : "SlowTrav-icon.png"}
            />
          </div>
          <h1 className="mt-5 self-stretch relative text-inherit leading-[38px] font-semibold font-inherit mq450:text-lg mq450:leading-[23px] mq750:text-2xl mq750:leading-[30px]">
            {user?.first_name} {user?.last_name}
          </h1>
        </div>
      </div>
    </div>
  </section>
  {/* <div className="self-stretch h-[1px] relative bg-whitesmoke-300" /> */}
  <Box sx={{ width: '100%' }}>
      <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
        <Tabs value={valueMainTab} onChange={handleMainTabChange} aria-label="basic tabs example" centered>
          <Tab label={t('user-profile')} {...a11yProps(0)} />
          <Tab label={<Badge badgeContent={notificationCount} variant="dot" color="primary">{`${t('user-community')}`}&nbsp;&nbsp;</Badge>} {...a11yProps(1)} />
          {/* <Tab label="Item Three" {...a11yProps(2)} /> */}
        </Tabs>
      </Box>
      <CustomTabPanel value={valueMainTab} index={0}>
      <UserProfile user={user} />
      </CustomTabPanel>
      <CustomTabPanel value={valueMainTab} index={1}>
      <UserCommunity community={community} user={user} />

      </CustomTabPanel>
      {/* <CustomTabPanel value={value} index={2}>
        Item Three
      </CustomTabPanel> */}
    </Box>

    </>
  );
};

export default UserSettings;
