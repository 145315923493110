import { FunctionComponent, useState } from "react";
import CircularProgress from '@mui/material/CircularProgress';
import { useTranslation } from 'react-i18next';

import CreateAccountForm from "../components/authentification/CreateAccountForm";
import Navbar from "../components/main/Navbar";

const Divider = ({ width = "w-64" }) => {
  return (
    <div className={`w-full mb-4`}>
      <div
        className={`h-1 mx-auto bg-orange ${width} opacity-25 my-0 py-0 rounded-t mb-10`}
      ></div>
    </div>
  );
};

const Welcome: FunctionComponent = () => {
  const { t } = useTranslation();
  const [isLoading, setIsLoading] = useState(false);

  return (
    <div className="w-full relative bg-primary-contrast overflow-hidden flex flex-col items-center justify-start">
    <Navbar />
    <>
    <div className="w-full relative bg-primary-contrast flex flex-col gap-4 items-center justify-center text-center pt-[80px] h-[calc(100vh-80px)] max-w-[400px] font-roboto text-lg">
      <div className="text-[80px]">🎉</div>
      <div>
<h1>{t('welcome-thanks')}</h1>
<Divider/>
<p>{t('welcome-description')}</p>
<br />
<p>{t('welcome-description-tester')}</p>

      </div>

    </div>
    </>
    </div>
  );
};

export default Welcome;
