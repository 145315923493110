import * as React from 'react';
import Grid from '@mui/material/Unstable_Grid2';
import DirectionsBikeIcon from '@mui/icons-material/DirectionsBike';
import DirectionsCarIcon from '@mui/icons-material/DirectionsCar';
import DirectionsBusIcon from '@mui/icons-material/DirectionsBus';
import DirectionsBoatIcon from '@mui/icons-material/DirectionsBoat';
import SailingIcon from '@mui/icons-material/Sailing';
import HikingIcon from '@mui/icons-material/Hiking';
import TwoWheelerIcon from '@mui/icons-material/TwoWheeler';
import ThumbUpIcon from '@mui/icons-material/ThumbUp';
import TrainIcon from '@mui/icons-material/Train';
import { useTranslation } from 'react-i18next';

import {transportationColors} from '../../utils/mapStyles'
import { TasksProgress } from './tasks-progress';
import { TotalDistances } from './total-customers';
import CO2BarChart from './BarChart';

const initialStatsSum = {
  bus: { distance: 0 },
  car: { distance: 0 },
  run: { ascent: 0, descent: 0, distance: 0 },
  bike: { ascent: 0, descent: 0, distance: 0 },
  boat: { distance: 0 },
  hike: { ascent: 0, descent: 0, distance: 0 },
  moto: { distance: 0 },
  sail: { distance: 0 },
  hitchhike: { distance: 0 },
  train: { distance: 0 }
};



const iconMapping: any = {
  bus: <DirectionsBusIcon />,
  car: <DirectionsCarIcon />,
  run: <HikingIcon />,
  bike: <DirectionsBikeIcon />,
  boat: <DirectionsBoatIcon />,
  sail: <SailingIcon />,
  hike: <HikingIcon />,
  moto: <TwoWheelerIcon />,
  train: <TrainIcon />,
  hitchhike: <ThumbUpIcon />
};

const formatNumber = (number: any) => {
  const nfObject = new Intl.NumberFormat('en-US');
  return nfObject.format(number)
};


export default function Dashboard(props: any): React.JSX.Element {
  const { t } = useTranslation();

  const distanceTitles: any = {
    bus: t('distance-bus'),
    car: t('distance-car'),
    hitchhike: t('distance-hitchike'),
    run: t('distance-run'),
    bike: t('distance-bike'),
    boat: t('distance-boat'),
    hike: t('distance-hike'),
    moto: t('distance-moto'),
    sail: t('distance-sail'),
    train: t('distance-train')
  };

  return (
    <div className='w-full md:w-2/3 pb-10'>
          <Grid container spacing={3} justifyContent={'center'}>
          <Grid lg={12} sm={12} xs={12}>
        <TasksProgress sx={{ height: '100%' }} value={props.statCO2} title={t("co2-reach")}/>
      </Grid>

          <Grid lg={12} sm={12} xs={12}>
        <CO2BarChart statCO2All={props.statCO2All}/>
      </Grid>
      {Object.keys(props.statsSum).map((key) => {

          const distance = props.statsSum[key].distance;
          if (distance > 0) {
            return (
              <Grid lg={4} sm={6} xs={12} key={key}>
                                <TotalDistances
                  diff={16} // Example diff value
                  icon={iconMapping[key]} // Icon based on mode of transport
                  title={distanceTitles[key]}
                  sx={{ height: '100%' }}
                  value={ formatNumber(distance.toFixed(0)) + ' km'}
                  color={transportationColors[key]}
                />

              </Grid>
            );
          }
        })}

    </Grid>
    </div>

  );
}
