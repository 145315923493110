import React, { useState } from 'react';
import {Dropdown, DropdownTrigger, DropdownMenu, DropdownItem, Button} from "@nextui-org/react";
import LayersIcon from '@mui/icons-material/Layers';

const MapStyleSelector: React.FC<any> = ({ setMapStyle }) => {

  const styles = [
    { id: 'streets-v12', name: 'Streets', styleUrl: 'mapbox://styles/mapbox/streets-v12' },
    { id: 'outdoors-v12', name: 'Outdoors', styleUrl: 'mapbox://styles/mapbox/outdoors-v12' },
    { id: 'light-v11', name: 'Light', styleUrl: 'mapbox://styles/vladke99/clw7jclj902nz01qrhqgn1v1v' },
    // { id: 'light-v11', name: 'Light', styleUrl: 'mapbox://styles/mapbox/light-v11' },
    // { id: 'dark-v10', name: 'Dark', styleUrl: 'mapbox://styles/mapbox/dark-v10' },
    { id: 'standard', name: 'Standard', styleUrl: 'mapbox://styles/mapbox/standard' },
    // { id: 'satellite-streets-v12', name: 'Satellite', styleUrl: 'mapbox://styles/mapbox/satellite-streets-v12' }
    { id: 'satellite-streets-v12', name: 'Satellite', styleUrl: 'mapbox://styles/vladke99/clvy8f6lm02ar01qvhn4wf752' }
  ];



  return (
    <div className="absolute top-5 right-2">
      <Dropdown>
        <DropdownTrigger>
          <Button
            variant="bordered"
            isIconOnly
            className="h-10 w-10 rounded-full bg-background/80 backdrop-blur-sm"
          >
            <LayersIcon className="h-5 w-5" />
            <span className="sr-only">Select map style</span>
          </Button>
        </DropdownTrigger>
        <DropdownMenu className="pl-0" onAction={(key) => setMapStyle(key)}>
          {styles.map((style) => (
            <DropdownItem
              key={style.styleUrl}
            >
              {style.name}
            </DropdownItem>
          ))}
        </DropdownMenu>
      </Dropdown>
    </div>
  );
};

export default MapStyleSelector;
