import React, { useState, useContext } from 'react';
import { Link } from 'react-router-dom';
import { useTranslation } from "react-i18next";
import FavoriteBorderIcon from '@mui/icons-material/FavoriteBorder';



const BlogLikes: React.FC<any> = ({ blog }) => {
    const { t } = useTranslation();


  return (
    <div className="flex flex-col items-end w-full">
      <div className="flex flex-row items-center justify-start gap-1">
          <FavoriteBorderIcon color="primary" sx={{ fontSize: 15 }}/>
        <p className="text-gray-600 text-xs">
          {blog.likers}
        </p>
      </div>

    </div>
  );
};

export default BlogLikes;
