import React, { useState, useEffect, useRef } from 'react';
import Button from '@mui/material/Button';
import { useTranslation } from 'react-i18next';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import TextField from "@mui/material/TextField";
import POITextEditor from './POITextEditor';
import mapboxgl from "mapbox-gl";
import MapboxGeocoder from '@mapbox/mapbox-gl-geocoder';
import Tooltip from "@mui/material/Tooltip";
import ClickAwayListener from "@mui/material/ClickAwayListener";
import ImageUploader from "../inputs/ImageUploader";

import {
  Feature,
  FeatureCollection,
  GeoJsonProperties,
  Geometry,
  LineString,
} from "geojson";

const PoiAdd: React.FC<any> = ({ spot, setSpot, pois, setPoiSearchOpen, mapInstance, setNewCoord, newCoord, setPoiChange, poiChange=null }) => {
  const { t } = useTranslation();
  const { i18n } = useTranslation();


    const [selectedPoiMarker, setSelectedPoiMarker] = React.useState('');
    const [selectedPoiIcon, setSelectedPoiIcon] = React.useState('');
    const [tempMapFeature, setTempMapFeature] = useState<FeatureCollection | null>(null);
    const [title, setTitle] = useState("");
    const [poiContent, setPoiContent] = useState("");
    const [poiImages, setPoiImages] = useState<any>([]);
    const startGeocoderRef = useRef<any>(null);
    const [startLocation, setStartLocation] = useState<any>(null);
    const [startMarker, setStartMarker] = useState<any>(null);
    const [isFormValid, setIsFormValid] = useState<boolean>(false);
    const [nextOpen, setNextOpen] = useState(false);

    useEffect(() => {
      if (poiChange) {
setTitle(poiChange.properties.title);
setPoiContent(poiChange.properties.content);
setStartLocation(poiChange.geometry.coordinates)
setSelectedPoiIcon(poiChange.properties.icon)
setSelectedPoiMarker(poiChange.properties.poi)
setPoiImages(poiChange.properties.images)

      }

    },[poiChange]);


      const handlePoiMarkerChange = (event: SelectChangeEvent) => {
        setSelectedPoiMarker(event.target.value as string);
        const poi = pois.find((poi: any) => poi.name === event.target.value);
        setSelectedPoiIcon(poi.icon)
      };

      useEffect(() => {
        const startGeocoder = new MapboxGeocoder({
          accessToken: mapboxgl.accessToken,
          // @ts-ignore
          mapboxgl: mapboxgl,
          placeholder: t('start-location'),
          marker:false,
          flyTo:true
        });

        startGeocoderRef.current = document.getElementById('start-geocoder')?.appendChild(startGeocoder.onAdd(mapInstance));

        startGeocoder.on('result', (e) => {
          const coordinates = e.result.geometry.coordinates;
          setStartLocation(coordinates);
        });

      }, [mapInstance]);


      useEffect(() => {
        if (!mapInstance) return

        mapInstance.off('click');

          const clickHandler = (e: any) => {
            const coordinates = [e.lngLat.lng, e.lngLat.lat];
            setNewCoord(coordinates);
          };

          mapInstance.on('click', clickHandler);

          // Cleanup function to remove the event listener
          return () => {
            mapInstance.off('click', clickHandler);
          }
        // }


      }, [mapInstance]);



      useEffect(()=> {
        if (newCoord) {

          setStartLocation(newCoord)
          // const inputElement = startGeocoderRef.current.querySelector('.mapboxgl-ctrl-geocoder--input');
          // if (inputElement) {
          //   inputElement.value = `${newCoord[0].toFixed(3)}, ${newCoord[1].toFixed(3)}`;
          // }
        }
      }, [newCoord])

      useEffect(() => {
        if (startLocation && mapInstance && !startMarker) {
          const marker: any = new mapboxgl.Marker({ draggable: true })
            .setLngLat(startLocation)
            .addTo(mapInstance)
            .on('dragend', () => onDragEnd(marker, setStartLocation));

          setStartMarker(marker);

        } else if (startLocation && startMarker) {
          startMarker.setLngLat(startLocation)
        }

        setTempMapFeature({
          type: "FeatureCollection",
          features: [
            {
              type: "Feature",
              properties: {
              },
              geometry: {
                type: "Point",
                coordinates: startLocation
              },
            },
          ],
        });
      }, [startLocation, mapInstance]);

      const onDragEnd = (marker: any, setLocation: any) => {
        const lngLat = marker.getLngLat();
        setLocation([lngLat.lng, lngLat.lat]);
      };

  const handleClose = () => {
    if (startMarker) {
      startMarker.remove()
      setStartMarker(null);
    }
    setPoiSearchOpen(false)
    setNewCoord(null)
    setPoiChange(null)

  }

  const saveFeatureToSpot = () => {
    if (tempMapFeature) {
      // Clone the current spot to avoid direct mutation
      const updatedSpot = { ...spot };
      updatedSpot.is_updated = true;

      // Ensure secondary_pois is initialized
      if (!updatedSpot.secondary_pois) {
        updatedSpot.secondary_pois = [];
      }

      // Handle Point geometry to add a POI
      if (tempMapFeature.features[0].geometry.type === "Point") {
        const poiFeature = {
          type: "Feature",
          geometry: tempMapFeature.features[0].geometry,
          poi: {
            name: selectedPoiMarker,
            icon: selectedPoiIcon,
          },
          title: title,
          content: poiContent,
          images: poiImages || [],
        };

        // Append the new POI to secondary_pois
        updatedSpot.secondary_pois.push(poiFeature);
      }

      // Update the spot state and clear the temporary feature state
      setSpot(updatedSpot);
      setTempMapFeature(null);
      handleClose();
    }
  };

  useEffect(() => {
    const isTitleValid = title.length > 0 && title.length <= 255;
    const isselectedPoiMarker = selectedPoiMarker !== null;
    const isLocation = startLocation !== null;
    setIsFormValid(isTitleValid && isLocation && isselectedPoiMarker);
  }, [title, selectedPoiMarker, startLocation]);

  const isStepValid = () => {
    if (!(title.trim().length > 0) && !(title.trim().length > 0)) {
      return { isValid: false, message: t("blog-error-title") };
    }
    if (!(selectedPoiMarker)) {
      return { isValid: false, message: t("blog-error-title") };
    }
    if (!(startLocation)) {
      return { isValid: false, message: t("blog-error-summary") };
    }

    return { isValid: true, message: "erfre" };

  }

  function getLocalizedField(option: any, field: any, lang:any) {
    const suffix = lang ? lang === 'en' ? '' : `_${lang}` : '';
    if (option[field + suffix] === '' || option[field + suffix] === null) {
      return option[field];

    } else {
      return option[field + suffix]}
  }



  return (
    <div className='w-full h-full p-3 overflow-auto'>
<h2>{t("create-poi")}</h2>
<p className="p-2 text-secondary-500 font-roboto">{t('poi-help')}</p>

<div className='flex flex-col gap-4'>
  <div className='flex flex-col md:flex-row gap-2'>
  <FormControl fullWidth>
        <InputLabel id="demo-simple-select-label">{t('create-poi-marker')}</InputLabel>
        <Select
          labelId="demo-simple-select-label"
          id="demo-simple-select"
          value={selectedPoiMarker}
          label={t('transport')}
          onChange={handlePoiMarkerChange}
        >
        {pois.map((option: any) => (
          <MenuItem key={option.id} value={option.name} >
            <div className='gap-3 flex flex-row'>
              <img src={option.icon} alt={option.label} style={{ width: 24, height: 24, marginRight: 8 }} />
              {getLocalizedField(option, 'label', i18n.language)}
              </div>
          </MenuItem>
      ))}

        </Select>
      </FormControl>
      <TextField
          id="standard-multiline-flexible"
          label={t('create-title')}
          variant="outlined"
          className="w-full"
          value={title}
          onChange={(e) => setTitle(e.target.value)}
          helperText={`${title.length}/100`}
          inputProps={{ maxLength: 100 }}
        />
  </div>

      <div id='start-geocoder' className="w-full flex flex-col gap-1 z-[100]"></div>
      <ImageUploader
                                        multiple={true}
                                        initialImages={poiImages}
                                        onImageChange={setPoiImages}
                                        pois={true}
                                      />
        <POITextEditor content={poiContent} setContent={setPoiContent} />
        </div>

        {/* <NewMapboxMapEdit
  mapFeatures={tempMapFeature || EMPTY_FEATURE_COLLECTION}
  isPoi={true}
  setPoi={setTempMapFeature}
  setMapInstance={setMapInstance}


/> */}
<div className="py-2 flex flex-row justify-between">
  {poiChange === null && (
          <Button onClick={handleClose}>
          {t('close')}
          </Button>
  ) }

          {isStepValid().isValid ? (
                      <Button
                      disabled={!isFormValid}
                      onClick={saveFeatureToSpot}
                      type="submit"
                    >
                      {t("save")} {t("poi")}
                    </Button>
          ) : (
            <ClickAwayListener onClickAway={()=> setNextOpen(false)}>
            <div>
              <Tooltip
                PopperProps={{
                  disablePortal: true,
                }}
                onClose={()=> setNextOpen(false)}
                open={nextOpen}
                arrow
                disableFocusListener
                disableHoverListener
                disableTouchListener
                title={isStepValid().message}
              >
                <Button
                  color="error"
                  onClick={()=> setNextOpen(!nextOpen)}
                  variant="text"
                >
                  {t("save")} {t("poi")}
                </Button>
              </Tooltip>
            </div>
          </ClickAwayListener>
        )
          }
          {/* <Button onClick={saveFeatureToChapter}>{t('save')}</Button> */}

          </div>
        </div>

  );
};



export default PoiAdd;
