import React, { useState, useEffect } from 'react';
import { Box, Button, TextField, Typography, Snackbar, IconButton, Stack, Chip, Divider, ToggleButton, ToggleButtonGroup } from '@mui/material';
import { Delete as DeleteIcon, Add as AddIcon, StarOutline, ReportProblemOutlined } from '@mui/icons-material';
import { useTranslation } from 'react-i18next';
import {Tabs, Tab} from "@nextui-org/react";


const AddSummary = ({ posNegs, editPosNegs }) => {
  const { t } = useTranslation();

  const [inputValue, setInputValue] = useState('');
  const [category, setCategory] = useState<any>('highlight'); // "highlight" or "watchOut"
  const [highlights, setHighlights] = useState(posNegs ? posNegs.highlights : []);
  const [watchOuts, setWatchOuts] = useState(posNegs ? posNegs.watchOuts : []);
  const [snackbarVisible, setSnackbarVisible] = useState(false);

  const handleAddItem = () => {
    if (!inputValue) return;
    if (category === 'highlight') {
      setHighlights([...highlights, inputValue]);
    } else {
      setWatchOuts([...watchOuts, inputValue]);
    }
    setInputValue('');
    setSnackbarVisible(true);
  };

  const handleRemoveItem = (index, isHighlight) => {
    if (isHighlight) {
      setHighlights(highlights.filter((_, i) => i !== index));
    } else {
      setWatchOuts(watchOuts.filter((_, i) => i !== index));
    }
  };

  useEffect(() => {
    const dataToSubmit = {
      highlights,
      watchOuts,
    };
    editPosNegs(dataToSubmit);
  }, [highlights, watchOuts]);

  return (
    <Box sx={{ padding: 4, maxWidth: 600, margin: 'auto' }}>
      {/* Header Text */}
      <h6 className='text-slate-600 font-normal'>
        {t('highlights-help')}
      </h6>
      <p className='text-sm text-slate-500 pb-4'>{t('highlights-help-second')}</p>

      {/* Input Field */}
      <Box display="flex" gap={2} sx={{ mb: 2 }}>
        <TextField
          label={category === 'highlight' ? t('add-highlight') : t('add-watchout')}
          value={inputValue}
          onChange={(e) => setInputValue(e.target.value)}
          variant="outlined"
          fullWidth
          multiline
        />
        <Button
          variant="contained"
          color="primary"
          onClick={handleAddItem}
          startIcon={<AddIcon />}
        >
          {t('add')}
        </Button>
      </Box>

      {/* Toggle Buttons for Category Selection */}

      <Tabs aria-label="Options" color="primary" variant="bordered"
      onSelectionChange={setCategory}
      selectedKey={category}
      fullWidth={true}
>
        <Tab
          key='highlight'
          title={
            <div className="flex items-center space-x-2">
              <StarOutline sx={{ mr: 1 }} />
              <span>{t('highlights')}</span>
            </div>
          }
        />
        <Tab
          key='watchOut'
          title={
            <div className="flex items-center space-x-2">
              <ReportProblemOutlined sx={{ mr: 1 }} />
              <span>{t('watchouts')}</span>
            </div>
          }
        />
      </Tabs>

      {/* Current Highlights and Watch Outs */}
      {/* Current Highlights and Watch Outs */}
      <h6 className="font-semibold mb-4">
        {t('highlights-current')}
      </h6>

      {/* Highlights */}
      <p className="text-base font-semibold text-slate-800 mb-2">{t('highlights')}:</p>
      {highlights.length > 0 ? (
        highlights.map((item, index) => (
          <Box key={index} display="flex" alignItems="center" justifyContent="space-between" sx={{ my: 1 }}>
            <span className="text-md">{index + 1}. {item}</span>
            <IconButton color="secondary" onClick={() => handleRemoveItem(index, true)}>
              <DeleteIcon />
            </IconButton>
          </Box>
        ))
      ) : (
        <p className="text-sm text-slate-500">{t('no-highlights')}</p>
      )}

      {/* Watch Outs */}
      <p className="text-base font-semibold text-slate-800 mt-4 mb-2">{t('watchouts')}:</p>
      {watchOuts.length > 0 ? (
        watchOuts.map((item, index) => (
          <Box key={index} display="flex" alignItems="center" justifyContent="space-between" sx={{ my: 1 }}>
            <span className="text-md">{index + 1}. {item}</span>
            <IconButton color="secondary" onClick={() => handleRemoveItem(index, false)}>
              <DeleteIcon />
            </IconButton>
          </Box>
        ))
      ) : (
        <p className="text-sm text-slate-500">{t('no-watchouts')}</p>
      )}
      {/* Snackbar */}
      <Snackbar
        open={snackbarVisible}
        onClose={() => setSnackbarVisible(false)}
        autoHideDuration={2000}
        message={category === 'highlight' ? t('highlight-added') : t('watchout-added')}
      />
    </Box>
  );
};

export default AddSummary;
