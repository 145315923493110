import { useState } from 'react';
import { Card, CardHeader, CardBody, CardFooter } from "@nextui-org/card";
import { Button, Input, Accordion, AccordionItem } from "@nextui-org/react";
import { createQuestion } from '../../utils/api/api';
import { CloseOutlined } from '@mui/icons-material'; // Import close icon

interface QA {
  question_text: string;
  answer_text: string | null;
}

interface AuthorQACardProps {
    blogId: number;
    questions: QA[];
    onClose: any; // Function to handle close action
  }

export default function AuthorQACard({ blogId, questions, onClose }: AuthorQACardProps) {
  const [qas, setQas] = useState<QA[]>(questions || []);
  const [newQuestion, setNewQuestion] = useState('');
  const [isSubmitting, setIsSubmitting] = useState(false);

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    if (newQuestion.trim()) {
      setIsSubmitting(true);
      try {
        const createdQuestion = await createQuestion(blogId, newQuestion);

        setQas([
          ...qas,
          { question_text: createdQuestion.question_text, answer_text: null },
        ]);
        setNewQuestion('');
      } catch (error) {
        console.error("Failed to submit question:", error);
      } finally {
        setIsSubmitting(false);
      }
    }
  };

  const answeredQuestions = qas.filter((qa) => qa.answer_text);
  const unansweredQuestions = qas.filter((qa) => !qa.answer_text);

  const itemClasses = {
    base: "py-0 w-full",
    title: "text-slate-800 font-msr text-md",
    content: "text-sm px-2 text-slate-700 !font-msr",
  };

  return (
    <Card className="w-full pb-4">
      <CardHeader className='flex items-center justify-between'>
        <div className='flex flex-col gap-2'>
          <h2 className='text-slate-800'>Author Q&A</h2>
          <p>Questions and answers with the author</p>
        </div>
        <Button
        isIconOnly={true}
          variant="light"
          onClick={() => onClose(false)}
          aria-label="Close"
        >
          <CloseOutlined />
        </Button>
      </CardHeader>
      <CardBody>
        <div className="h-[400px] pr-4">
          {/* Answered Questions Accordion */}
          <Accordion className="w-full" itemClasses={itemClasses}>
            {answeredQuestions.map((qa, index) => (
              <AccordionItem key={index} value={`item-${index}`} title={qa.question_text} isCompact={true}>
                <p>{qa.answer_text}</p>
              </AccordionItem>
            ))}
          </Accordion>

          {/* Unanswered Questions List */}
          {unansweredQuestions.length > 0 && (
            <div className="mt-4">
              <h3 className="text-slate-500 font-semibold">Unanswered Questions</h3>
              <ul className="mt-2">
                {unansweredQuestions.map((qa, index) => (
                  <li key={index} className="text-slate-400 italic">
                    {qa.question_text}
                  </li>
                ))}
              </ul>
            </div>
          )}
        </div>
      </CardBody>
      <CardFooter className='h-40'>
        <form onSubmit={handleSubmit} className="w-full space-y-4">
          <div className="space-y-2">
            <label htmlFor="question" className="text-sm font-medium text-gray-700 !font-msr">
              Ask a question
            </label>
            <Input
              id="question"
              placeholder="Type your question here..."
              value={newQuestion}
              onValueChange={setNewQuestion}
              className="w-full"
              isDisabled={isSubmitting}
            />
          </div>
          <Button type="submit" className="w-full" color='primary' isDisabled={isSubmitting}>
            {isSubmitting ? 'Submitting...' : 'Submit Question'}
          </Button>
        </form>
      </CardFooter>
    </Card>
  );
}
