import React, { useState, useEffect } from "react";
import {
  Box,
  Button,
  IconButton,
} from "@mui/material";
import HelpOutlineIcon from "@mui/icons-material/HelpOutline";
import CloseIcon from "@mui/icons-material/Close";
import { Rnd } from "react-rnd"; // For draggable functionality
import { useTranslation } from "react-i18next";

const HelpButtonWithVideo = () => {
    const { t } = useTranslation();

  const [isVideoVisible, setIsVideoVisible] = useState(false);
  const [animate, setAnimate] = useState(true); // Control animation state

  const toggleVideo = () => {
    setIsVideoVisible(!isVideoVisible);
  };

  useEffect(() => {
    // Stop animation after 15 seconds
    const timer = setTimeout(() => setAnimate(false), 15000);
  }, []);

  return (
    <Box>
      {/* Help Button */}
      <Button
        onClick={toggleVideo}
        sx={{
          position: "fixed",
          top: 80,
          left: 16,
          zIndex: 1000,
          animation: animate ? "pulse 1.5s infinite" : "none",
          "@keyframes pulse": {
            "0%": { transform: "scale(1)" },
            "50%": { transform: "scale(1.1)" },
            "100%": { transform: "scale(1)" },
          },
        }}
        startIcon={<HelpOutlineIcon />}
        variant="contained"
        color="secondary"
      >
        {t('help')}
      </Button>

      {/* Floating Video */}
      {isVideoVisible && (
        <Rnd
          default={{
            x: 50,
            y: 80,
            width: 400,
            height: 230, // 16:9 Aspect Ratio
          }}
          bounds="window"
          enableResizing={false}
          style={{
            padding: 10,
            position: "fixed",
            zIndex: 1200,
            border: "1px solid #ccc",
            borderRadius: 8,
            background: "white",
            boxShadow: "0 4px 8px rgba(0,0,0,0.2)",
          }}
        >
          <Box
            sx={{
              position: "relative",
              height: "100%",
              width: "100%",
            }}
          >
            {/* Close Button */}
            <IconButton
              onClick={toggleVideo}
              sx={{
                position: "absolute",
                top: 8,
                right: 8,
                zIndex: 10,
                background: "rgba(255, 255, 255, 0.8)",
              }}
            >
              <CloseIcon />
            </IconButton>

            {/* YouTube Video */}
            <Box sx={{ position: "relative", paddingTop: "56.25%" }}>
              <iframe width="560" height="315" src="https://www.youtube.com/embed/lHHZ9RWCGo8?si=EzsqcBtgx-y2NQom" title="YouTube video player" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerPolicy="strict-origin-when-cross-origin" allowFullScreen
                style={{
                  position: "absolute",
                  top: 0,
                  left: 0,
                  width: "100%",
                  height: "100%",
                  border: "none",
                }}
              />
            </Box>
          </Box>
        </Rnd>
      )}
    </Box>
  );
};

export default HelpButtonWithVideo;
