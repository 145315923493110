import * as React from 'react';
import dayjs, { Dayjs } from 'dayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { DemoContainer } from '@mui/x-date-pickers/internals/demo';

const locales = ['en', 'en-gb', 'zh-cn', 'de'];

type LocaleKey = (typeof locales)[number];

const isValidDate = (date: Dayjs | null) => {
  return date && date.isValid();
};


const DatePickerValue: React.FC<any> = ({ selectedChapter, updateChapterDates, index }) => {
    const [locale, setLocale] = React.useState<LocaleKey>('en-gb');

  const [startDate, setStartDate] = React.useState<Dayjs | null>(
    selectedChapter.start_date ? dayjs(selectedChapter.start_date) : null
  );
  const [endDate, setEndDate] = React.useState<Dayjs | null>(
    selectedChapter.end_date ? dayjs(selectedChapter.end_date) : null
  );

  const handleStartDateChange = (newValue: Dayjs | null) => {
    if (isValidDate(newValue)) {
      setStartDate(newValue);
      updateChapterDates(index, 'start_date', newValue ? newValue.toISOString() : null);
    } else {
      setStartDate(null);
      updateChapterDates(index, 'start_date', null);
    }
  };

  const handleEndDateChange = (newValue: Dayjs | null) => {
    if (isValidDate(newValue)) {
      setEndDate(newValue);
      updateChapterDates(index, 'end_date', newValue ? newValue.toISOString() : null);
    } else {
      setEndDate(null);
      updateChapterDates(index, 'end_date', null);
    }
  };

  const conditionalStartStyles = !startDate
  ? {
      '.MuiFormLabel-root': {
        transform: 'translate(10px, 6px)',
      },
    }
  : {};
  const conditionalEndStyles = !endDate
  ? {
      '.MuiFormLabel-root': {
        transform: 'translate(10px, 6px)',
      },
    }
  : {};

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale={locale}>
        <div className='w-full flex flex-row gap-2'>
        <DatePicker
      label="Start Date"
      value={startDate}
      onChange={handleStartDateChange}
      className="w-full"
      format="DD/MM/YYYY"
      formatDensity="dense"
      sx={{
        '.MuiInputBase-input': {
          padding: '6px',
        },
        ...conditionalStartStyles, // Apply conditional styles
      }}
    />
        <DatePicker
          label="End Date"
          value={endDate}
          onChange={handleEndDateChange}
          className='w-full'
          format="DD/MM/YYYY"
          formatDensity='dense'
          sx={{
            '.MuiInputBase-input': {
              padding: '6px',
            },
            ...conditionalEndStyles, // Apply conditional styles
          }}
        />
        </div>
    </LocalizationProvider>
  );
}

export default DatePickerValue;
