import { FunctionComponent, useState, useEffect, useContext } from "react";
import CircularProgress from '@mui/material/CircularProgress';
import { useTranslation } from 'react-i18next';
import Skeleton from '@mui/material/Skeleton';
import Box from '@mui/material/Box';
import Button from "@mui/material/Button";
import EditIcon from "@mui/icons-material/Edit";
import AddIcon from '@mui/icons-material/Add';
import GroupIcon from '@mui/icons-material/Group';
import ExploreIcon from '@mui/icons-material/Explore';
import { Autocomplete, TextField, ListItem, ListItemAvatar, Avatar, Popper } from '@mui/material';
import { format, isToday, isYesterday, isThisWeek, isThisMonth, parseISO } from 'date-fns';
import { enUS, fr, es, de } from 'date-fns/locale';  // Import the necessary locales



import Navbar from "../components/main/Navbar";
import { Link, useNavigate } from 'react-router-dom';
import Card from "../components/blog/CardHorizontalFeed";
import Footer from "../components/main/Footer";
import { AuthContext } from '../components/authentification/AuthContext';

// @ts-ignore
import { VerticalTimeline, VerticalTimelineElement }  from 'react-vertical-timeline-component';
import 'react-vertical-timeline-component/style.min.css';

import { fetchBlogFeed, searchUsers } from '../utils/api/api';

const Divider = ({ width = "w-64", mx= "mx-0" }) => {
  return (
    <div className={`w-full mb-1`}>
      <div
        className={`h-1 mx-auto md:${mx} bg-orange ${width} opacity-25 my-0 py-0 rounded-t mb-5`}
      ></div>
    </div>
  );
};

const localeMap: { [key: string]: any } = {
  en: enUS,
  fr: fr,
  es: es,
  de: de,
  // Add more locales as needed
};

const FeedPage: FunctionComponent = () => {
  const { t, i18n } = useTranslation();
  const [timeline, setTimeline] = useState<any[]>([]);
  const [loading, setLoading] = useState<any>(true);
  const [currentTrip, setCurrentTrip] = useState<any>(null);
  const [searchQuery, setSearchQuery] = useState('');
  const [searchResults, setSearchResults] = useState<any[]>([]);

  const locale = localeMap[i18n.language] || enUS;  // Default to enUS if the language is not found

  const authContext = useContext(AuthContext);
  const { user } = authContext || {};

  const navigate = useNavigate();

  const handleSearch = async (query: string) => {
    setSearchQuery(query);
    if (query.length > 2) {
      try {
        const results = await searchUsers(query);
        setSearchResults(results);
      } catch (error) {
        console.error('Error searching users:', error);
      }
    } else {
      setSearchResults([]);
    }
  };

  const navigateToAuthorBlogs = (authorId: any) => {
    navigate(`/${authorId}`);
  };


  useEffect(() => {
    fetchBlogFeed().then(data => {
      const { blogs, feeds, current_trip } = data;
      setLoading(false);

      setCurrentTrip(current_trip);

      // Create a mapping of blog IDs to blogs
      const blogMap = blogs.reduce((map: any, blog: any) => {
        map[blog.id] = blog;
        return map;
      }, {} as { [key: number]: any });

      // Attach corresponding blog to each feed
      const timelineData = feeds.map((feed: any) => ({
        ...feed,
        blog: blogMap[feed.blog],
        date: new Date(feed.date).getTime(),  // Ensure date is a number
      }));

      // Sort by date
      timelineData.sort((a: any, b: any) => b.date - a.date);

      setTimeline(timelineData);
    })
    .catch(error => console.error('Error fetching blogs:', error));
  }, []);

  console.log('timeline', timeline)

  const handleAddChapter = () => {
    const page = currentTrip[0].chapters.length - 1
    console.log('page', page, currentTrip[0].id)
    navigate(`/edit-chapters/${currentTrip[0].id}`, { state: { page } });
  };

  const formatDate = (date: string | number | Date, locale: any): string => {
    const parsedDate = typeof date === 'string' ? parseISO(date) : new Date(date);

    if (isToday(parsedDate)) {
      return t('today');
    } else if (isYesterday(parsedDate)) {
      return t('yesterday');
    // } else if (isThisWeek(parsedDate, { weekStartsOn: 1 })) { // Assuming the week starts on Monday
    //   return t('this-week');
    // } else if (isThisMonth(parsedDate)) {
    //   return t('this-month');
    } else {
      return format(parsedDate, 'MMMM d, yyyy', { locale });
    }
  };

  return (
    <div className={`w-full relative bg-primary-contrast overflow-hidden gap-[30px] min-h-screen`}>
      <Navbar />
      <section className="w-full flex flex-col items-center justify-start pt-[90px] px-5 box-border gap-[48px] max-w-full text-left text-xl text-secondary-800 min-h-screen">
      {/* <Header
      blogs={blogs}
      /> */}
      {loading ? (
        <div className="w-full flex flex-col items-center justify-center gap-4">
<div className="w-full flex flex-col items-center justify-center gap-4 max-w-[800px]">
{/* <Grid className="w-full flex items-center" container> */}
      {Array.from(new Array(3)).map((item, index: any) => (
        <Box key={index} sx={{ width: '100%', my: 5 }}>

            <Skeleton variant="rectangular" width={'100%'} height={300} />
            <Box sx={{ pt: 0.5 }}>
              <Skeleton />
              <Skeleton width="60%" />
            </Box>
        </Box>
      ))}
    {/* </Grid> */}
    </div>


</div>
      ) : (
        <>

        {currentTrip ? (
          <div className="w-full flex flex-col items-center">
            <h2>{t("your-current-trip")}</h2>
            <Divider/>

  <div className="w-full max-w-[800px] flex flex-col justify-center items-center gap-4">
  <Link to={`/${currentTrip[0].author.username}/${currentTrip[0].id}`} className="w-full">
                        <Card
                        blog={currentTrip[0]}
                        // event={item.event}
                        // chapter={item.chapter || ''}
                        // date={new Date(item.date).toLocaleDateString()}
                         />
                      </Link>

                      <Button startIcon={<EditIcon/>} variant="contained" size="large" fullWidth onClick={()=>handleAddChapter()}>{t("edit")}</Button>
  </div>

          </div>
        ) : (

          <div className="w-full flex flex-col items-center">
            <h2>{t("no-current-trip")}</h2>
            <Divider/>

  <div className="w-full max-w-[800px] flex flex-col justify-center items-center gap-4">

  <Button variant="outlined" startIcon={<AddIcon />} size="large" sx={{width: '300px'}} onClick={()=>navigate('/create-blog-post')}>
          {t("nav-create")}
        </Button>

        <Button variant="outlined" startIcon={<ExploreIcon />} size="large" sx={{width: '300px'}} onClick={()=>navigate(`/${user.username}`)}>
          {t("select-current-trip")}
        </Button>
  </div>

          </div>

        )}

          <div className="flex flex-col items-center justify-center gap-[32px] max-w-full text-2xl">

          {timeline.length > 0 ? (
            <>
            <div>
            <h2 className="text-center">{t("friends-trips")}</h2>
            <Divider/>
            </div>

              <div className="max-w-[1000px] pb-10">
                <VerticalTimeline lineColor={'#97bc62'}>
                  {timeline.map((item, index) => (
                    <VerticalTimelineElement
                      key={index}
                      className="vertical-timeline-element--work"
                      position='right'
                      date={formatDate(item.date, locale)}  // Convert back to readable date
                      iconStyle={{ background: `url(${item.blog?.author?.profile_picture ? `${item.blog.author.profile_picture}` : "/SlowTrav_icon.png"})`, color: '#fff'}}
                    >
                      <Link to={`/${item.blog.author.username}/${item.blog.id}`}>
                        <Card
                        blog={item.blog}
                        event={item.event}
                        chapter={item.chapter_title || ''}
                        date={formatDate(item.date, locale)}
                         />
                      </Link>
                    </VerticalTimelineElement>
                  ))}
                </VerticalTimeline>
              </div>
              </>
          ) : (
            <>
            <div>
                      <h2 className="text-center">{t("no-friends")}</h2>
            <Divider/>
            </div>
            <div className="w-full max-w-[800px] flex flex-col justify-center items-center gap-4">

            <Autocomplete
                                  freeSolo
                                  className="w-[300px] hidden md:flex"
                                  size="small"
                                  options={searchResults.map((option) => option)}
                                  getOptionLabel={(option: any) => option.name}
                                  onInputChange={(event, newInputValue) => handleSearch(newInputValue)}
                                  renderInput={(params) => (
                                    <TextField
                                      {...params}
                                      label={t('search-users')}
                                      variant="outlined"
                                    />
                                  )}
                                  renderOption={(props, option: any) => (
                                    <ListItem {...props} key={option.id} onClick={() => navigateToAuthorBlogs(option.username)}>
                                      <ListItemAvatar>
                                        <Avatar src={option.profile_picture}
                                        sx={{ width: 24, height: 24 }}/>
                                      </ListItemAvatar>
                                      <p className="text-sm">{option.name}</p>
                                      </ListItem>

                                  )}
                                />

        <Button variant="outlined" startIcon={<GroupIcon />} size="large" sx={{width: '300px'}} onClick={()=>navigate(`/settings`)}>
          {t("see-friends")}
        </Button>
  </div>
  </>
          )}

        </div>
        </>
      )}



      </section>
      <Footer />
    </div>
  );
};

export default FeedPage;
