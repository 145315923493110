import React, { useState } from "react";
import { IconButton, Modal, Box, Typography, Button, TextField } from "@mui/material";
import InfoIcon from "@mui/icons-material/Info";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import { useTranslation } from "react-i18next";

const InfoModal = () => {
    const { t } = useTranslation();

  const [open, setOpen] = useState(false);
  const exampleText = `
<p class="p1">ℹ️ <strong>Description</strong> : ex. Superbe site pour l'été avec environ 200 voies</p><br>
<p class="p1">💪 <strong>Niveau</strong>: ex. 6a-9a</p><br>
<p class="p1">📍 <strong>Accès</strong> : Des trucs à savoir ? ex. Dernier bus de la ligne A à 18h</p><br>
<p class="p1">🥾 <strong>Approche</strong>: ex. 10 mins à pied</p><br>
<p class="p1">🚲 <strong>Friendly</strong> : Conseillé ou dur? Pour accrocher le vélo ? Des bons plans pour que ce soit plus facile ?</p><br>
<p class="p1">👍 <strong>Friendly: </strong>Facile de faire du stop? Conseils ou bons plans ?</p><br>
<p class="p1">🛒 <strong>Courses</strong> : Proche ou loin? Restos ou bons plans pour dépanner ?</p>
  `;
  const exampleTextEmpty = `
<p class="p1">ℹ️ <strong>Description </strong>:&nbsp;</p>
<p class="p1">💪 <strong>Niveau </strong>:&nbsp;</p>
<p class="p1">📍 <strong>Accès</strong> :&nbsp;</p>
<p class="p1">🥾 <strong>Approche </strong>:&nbsp;</p>
<p class="p1">🚲 <strong>Friendly</strong> :</p>
<p class="p1">👍 <strong>Friendly :&nbsp;</strong></p>
<p class="p1">🛒 <strong>Courses</strong> :&nbsp;</p>
  `;
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const handleCopy = () => {

  navigator.clipboard.write([
    new ClipboardItem({
      "text/html": new Blob([exampleTextEmpty], {
        type: "text/html",
      }),
      "text/plain": new Blob([exampleTextEmpty], {
        type: "text/plain",
      }),
    }),
  ]);

  handleClose()

};



  return (
    <div className="absolute right-0 top-0">
      {/* IconButton with Info Icon */}
      <IconButton color="primary" onClick={handleOpen}>
        <InfoIcon />
      </IconButton>

      {/* Modal */}
      <Modal open={open} onClose={handleClose}>
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: 600,
            bgcolor: "background.paper",
            border: "2px solid #000",
            boxShadow: 24,
            p: 4,
            borderRadius: 2,
          }}
        >
          <Typography variant="h6" component="h2" gutterBottom>
          {t('spot.example-description')}
          </Typography>
          <div
            dangerouslySetInnerHTML={{
              __html: exampleText,
            }}
          />
          <div className="flex flex-row w-full justify-end">
          <Button
            variant="contained"
            style={{marginTop: 20}}
            color="primary"
            startIcon={<ContentCopyIcon />}
            onClick={handleCopy}
          >
           {t('spot.copy-text')}
          </Button>
          </div>
        </Box>
      </Modal>
    </div>
  );
};

export default InfoModal;
