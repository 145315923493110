import React, {
  FunctionComponent,
  useEffect,
  useState,
  useContext,
  useRef,
} from "react";
import { useParams, useNavigate, Link } from "react-router-dom";
import { FeatureCollection, Feature, LineString } from "geojson";
import * as turf from "@turf/turf";
import CircularProgress from "@mui/material/CircularProgress";
import { Button } from "@mui/material";
import { useTranslation } from 'react-i18next';

import Navbar from "../components/main/Navbar";
import MapboxMapView from "../components/map/MapView";

import { AuthContext } from "../components/authentification/AuthContext";

import { fetchMunicipalityRouteById, deleteMunicipalityRoute } from "../utils/api/api";

const MunicipalRouteView: FunctionComponent = () => {
  const { t } = useTranslation();

  const authContext = useContext(AuthContext);
  const { user } = authContext || {};
  const { id } = useParams<{ id: string }>();
  const [mapFeatures, setMapFeatures] = useState<FeatureCollection | null>(
    null
  );

  const navigate = useNavigate();

  const [route, setRoute] = useState<any>(null);


  useEffect(() => {
    const fetchRouteData = async () => {
      try {
        const data = await fetchMunicipalityRouteById(id);
        setRoute(data);
      } catch (error) {
        console.error("Error fetching blog data:", error);
      }
    };

    fetchRouteData();
  }, [id]);


  useEffect(() => {
    if (route) {
      const features: FeatureCollection = {
        type: "FeatureCollection",
        features: [
          {
            type: "Feature",
            properties: {
              routeId: route.id,
              title: route.title,
            },
            geometry: route.geometry, // Ensure this is a valid GeoJSON geometry object
          },
        ],
      };

      setMapFeatures(features);
    }
  }, [route]);

  const handleEdit = () => {
    navigate(`/edit-municipal-post/${id}`);
  };

  const handleDelete = async () => {
    if (window.confirm(`${t('blog-confirm-delete')}`)) {
      try {
        await deleteMunicipalityRoute(id);
        navigate("/"); // Redirect to home or another page after deletion
      } catch (error) {
        console.error("Error deleting blog:", error);
      }
    }
  };


  return (
    <div
      className={`w-full relative bg-primary-contrast overflow-hidden flex flex-col items-center justify-start gap-[30px] ${
        route ? "" : "h-screen"
      }`}
    >
      <Navbar />
      {route ? (
        <div className="w-full relative bg-white overflow-hidden flex flex-row items-start justify-center pt-[80px] px-0 pb-[269px] box-border tracking-[normal]">
          <main className="flex-1 flex flex-col items-center justify-start max-w-full text-left text-base text-secondary-800 font-heading-h3-bold mq450:gap-[16px]">
            <div className="w-[820px] flex flex-col items-center justify-start py-0 px-5 box-border max-w-full mq450:gap-[25px] mq725:gap-[50px]">
                <div className="self-stretch flex flex-col items-center justify-start gap-[32px] max-w-full mq450:gap-[16px]">
                  <div className="self-stretch flex flex-col items-center justify-start gap-[32px] max-w-full mq450:gap-[16px]">
                    <div className="self-stretch flex flex-col items-start justify-start gap-[20px] text-17xl">
                      <div className="self-stretch flex flex-row  justify-between gap-[10px] ">
                        <div className="self-stretch flex flex-row  justify-between gap-[10px] ">
                        {route.activities.map((activity: any) => (
                          <button
                            key={activity.id}
                            className="cursor-pointer [border:none] py-1.5 px-3 bg-darkseagreen-100 rounded-md flex flex-row items-center justify-center  bg-olivegreen hover:bg-darkseagreen-200"
                          >
                            <div className="relative text-sm leading-[20px] font-medium font-heading-h3-bold text-white text-left">
                              {activity.label}
                            </div>
                          </button>
                        ))}
                        </div>
                        {user?.municipalities.includes(route.municipality) && (
                      <div className="flex gap-[10px]">
                        <Button onClick={handleEdit} variant="outlined" >{t('blog-edit')}</Button>
                        <Button onClick={handleDelete} variant="outlined" color="error">{t('blog-delete')}</Button>
                      </div>
                    )}
                      </div>
                      <h2 className="m-0 self-stretch relative font-normal leading-[40px] font-roboto mq450:text-[22px] mq450:leading-[24px] mq850:text-[29px] mq850:leading-[32px]">
                          {route.title}
                        </h2>

                    </div>
                    {route.header_image_url && (
                      <div className="w-full flex flex-col">
                      <img
                        src={route.header_image_url}
                        alt="Header"
                        className="self-stretch h-[462px] relative rounded-xl max-w-full overflow-hidden shrink-0 object-cover"
                      />
                      {/* <div className="relative flex justify-end bottom-6 right-2"><p className="m-0 p-0 text-2xs"
                      dangerouslySetInnerHTML={{ __html: blog.header_image_attrib }}/></div> */}

                      </div>
                    )}
                    {/* <div className="self-stretch flex flex-row items-start justify-start max-w-full italic text-xl text-secondary-600 font-article-lg-regular">
                      {blog.summary}
                    </div> */}
                  </div>

                  {/* <MapboxMain
                  ref={mapboxMainRef}
                    mapFeatures={mapFeatures}
                    // @ts-ignore
                    simplifiedFeatures={simplifiedFeatures}
                  /> */}
                  <MapboxMapView
                    mapFeatures={mapFeatures}
                  />

                  <div className="self-stretch flex flex-col items-start justify-start mq450:gap-[24px]">
                    <div
                      className="blog-content"
                      dangerouslySetInnerHTML={{ __html: route.content }}
                    />

                  </div>
                </div>
            </div>
          </main>
        </div>
      ) : (
        <div className="spinner-background">
          <CircularProgress />
          {t('loading')}
        </div>
      )}
    </div>
  );
};

export default MunicipalRouteView;
