import React, { useEffect } from "react";
import { useTranslation } from "react-i18next"
import {Button, Popover, PopoverTrigger, PopoverContent} from "@nextui-org/react";
import LayersIcon from '@mui/icons-material/Layers';
import BarChartIcon from '@mui/icons-material/BarChart';
import DirectionsWalkIcon from '@mui/icons-material/DirectionsWalk';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBicycle, faCar, faShip, faHiking, faBus, faTrain, faPersonRunning, faMotorcycle, faThumbsUp, faSailboat } from '@fortawesome/free-solid-svg-icons';
import InfoIcon from '@mui/icons-material/Info';
import { truncate } from "lodash";

export default function RouteDist({ stats }: any) {
  const { t } = useTranslation()
  const [isOpen, setIsOpen] = React.useState(true);


    // Define icons for each type of transportation
    const icons: any = {
      bike: faBicycle,
      car: faCar,
      boat: faShip,
      sail: faSailboat,
      hike: faHiking,
      bus: faBus,
      train: faTrain,
      moto: faMotorcycle,
      hitchhike: faThumbsUp,
      run: faPersonRunning,
    };

    // Function to create an array of entries to display
    const filteredStats = Object.entries(stats).reduce((acc: any, [key, value]: [any, any]) => {
      if (typeof value === 'object' && (value.ascent > 0 || value.descent > 0 || value.distance > 0)) {
        acc.push({
          type: key,
          ascent: value.ascent,
          descent: value.descent,
          distance: value.distance,
          icon: icons[key]
        });
      }
      return acc;
    }, []);

    const formatNumber = (number: any) => {
      const nfObject = new Intl.NumberFormat('en-US');
      return nfObject.format(number)
    };

    const hasBikeOrHikeData = filteredStats.some((item: any) => item.type === 'bike' || item.type === 'hike' || item.type === 'run');

    useEffect(() => {
      const handleClickOutside = (event: MouseEvent) => {
        if ((event.target as HTMLElement).tagName === 'INPUT') {
          setIsOpen(false);
        }
      };

      document.addEventListener("click", handleClickOutside);
      return () => document.removeEventListener("click", handleClickOutside);
    }, []);


  return (
    <Popover placement="right-start" isOpen={isOpen} style={{zIndex: 50 }}>
      <PopoverTrigger>
        <Button
          variant="solid"
          isIconOnly
          onClick={() => setIsOpen(!isOpen)}
          className="absolute left-2 top-[110px] md:left-8 h-10 w-10 rounded-full bg-background/80 backdrop-blur-sm"
        >
          <BarChartIcon className="h-5 w-5"/>
          <span className="sr-only">Open information</span>
        </Button>
      </PopoverTrigger>
      <PopoverContent className="w-80">
      <div>

{filteredStats.length > 0 ? (
  filteredStats.map((item: any, index: any) => (
    <div className='flex flex-row gap-2' key={index} style={{ display: 'flex', alignItems: 'center', marginBottom: '10px' }}>
      <FontAwesomeIcon className="w-[30px]" color='#696a75' icon={item.icon} style={{ marginRight: '10px' }} />
      <div>
        {/* <h4 style={{ margin: 0 }}>{item.type.toUpperCase()}</h4> */}
        <div className="w-full flex flex-row gap-3 justify-center items-center font-msr">
        {item.distance > 0 && (
            <div className='font-msr text-secondary-500'>
            <i className="fa-solid fa-arrows-left-right"></i>{" "}
            {formatNumber(item.distance.toFixed(0))}km
          </div>
        )}
{item.ascent > 0 && (
            <div className='font-msr text-secondary-500'>
            <i className="fa-solid fa-arrow-trend-up"></i>{" "}
            {formatNumber(item.ascent.toFixed(0))}m
          </div>
)}
{item.descent > 0 && (
            <div className='font-msr text-secondary-500'>
            <i className="fa-solid fa-arrow-trend-down"></i>{" "}
            {formatNumber(item.descent.toFixed(0))}m
          </div>
)}

            </div>
      </div>
    </div>
  ))
) : (
  <p>No data available.</p>
)}
              {hasBikeOrHikeData && (
                  <div className='flex flex-row pt-3 text-secondary-400 gap-2'>
                      <InfoIcon fontSize='small'/>
                      <p className='font-msr text-secondary-500 text-sm'>{t("blog-stats-info")}</p>

</div>
)}
</div>
      </PopoverContent>
    </Popover>
  )
}
