import React, { FunctionComponent, useState, useContext, useEffect } from "react";
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import Divider from '@mui/material/Divider';
import Box from '@mui/material/Box';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import { useTranslation } from 'react-i18next';
import { useNavigate, useLocation } from 'react-router-dom';
import CircularProgress from '@mui/material/CircularProgress';
import IconButton from '@mui/material/IconButton';
import OutlinedInput from '@mui/material/OutlinedInput';
import InputLabel from '@mui/material/InputLabel';
import InputAdornment from '@mui/material/InputAdornment';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';

import Alert from '@mui/material/Alert';
import Zoom from '@mui/material/Zoom';

import Navbar from "../components/main/Navbar";
import { AuthContext } from '../components/authentification/AuthContext';
import { GoogleSignUp } from '../components/authentification/GoogleSignUp';
import { loginBackend } from "../utils/api/api";



// TODO remove, this demo shouldn't need to reset the theme.

const Login: FunctionComponent = () => {
    const { t } = useTranslation();
  const authContext = useContext(AuthContext);

  if (!authContext) {
    // Handle the null context scenario, e.g., return null, redirect, or show an error
    console.error('Authentication context not available');
    return null;
  }
  const { setIsAuthenticated, user } = authContext;

  const [isLoading, setIsLoading] = useState(false);
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [showPassword, setShowPassword] = useState(false);
  const [isFormValid, setIsFormValid] = useState(false);
  const [showAlert, setshowAlert] = useState(false);
  const [isAuthChecked, setIsAuthChecked] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();


  useEffect(() => {
    const isValid = username.trim() !== '' && password.trim() !== '';
    setIsFormValid(isValid);
  }, [username, password]);

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setIsLoading(true)

    // Await the promise from loginBackend
    const isAuthenticated = await loginBackend(username, password);


    if (isAuthenticated) {
      setIsAuthChecked(true)
      setIsAuthenticated(true);
      setIsLoading(false)
    } else {
      // Handle login failure (e.g., show an error message)
      setshowAlert(true)
      setIsLoading(false)
    }

  };

  useEffect(() => {
    if (isAuthChecked && user) {
      const redirect = new URLSearchParams(location.search).get('redirect');
      navigate(redirect || '/');    }
  }, [isAuthChecked, user]);


  useEffect(() => {
    if (showAlert) {
      const timer = setTimeout(() => {
        setshowAlert(false)

      }, 5000);


      return () => clearTimeout(timer);

    }

  }, [showAlert]);

  useEffect(() => {
    if (showAlert) {
      const timer = setTimeout(() => {
        setshowAlert(false)

      }, 5000);


      return () => clearTimeout(timer);

    }

  }, [showAlert]);


  const handleLoginSuccess = (isAuthenticated: boolean) => {
    if (isAuthenticated) {
      console.log('Login successful');
    } else {
      console.log('Login unsuccessful');
    }
  };


  const handleClickShowPassword = () => setShowPassword((show) => !show);

  const handleMouseDownPassword = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
  };




  return (
          <div className="w-full relative bg-primary-contrast overflow-hidden flex flex-col items-center justify-start">
      <Navbar />
      {isLoading && (
        <div className="spinner-background white-bg-spinner">
          <CircularProgress/>
          {t('loading')}
        </div>
      )}
      <Container maxWidth="xs">
        <CssBaseline />
        <Box
          sx={{
            marginTop: 18,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >
          <Avatar sx={{ m: 1, bgcolor: 'primary.main' }}>
            <LockOutlinedIcon />
          </Avatar>
          <Typography component="h1" variant="h5">
          {t("login")}
          </Typography>
          <Box component="form" onSubmit={handleSubmit} noValidate sx={{ mt: 1, width: '100%' }}>
            <TextField
              margin="normal"
              required
              fullWidth
              id="email"
              label="Email Address"
              value={username}
              onChange={(e) => setUsername(e.target.value)}
              name="email"
              autoComplete="email"
              autoFocus
            />
            <InputLabel htmlFor="outlined-adornment-password">{t("password")}</InputLabel>
          <OutlinedInput
            id="outlined-adornment-password"
            fullWidth
            type={showPassword ? 'text' : 'password'}
            endAdornment={
              <InputAdornment position="end">
                <IconButton
                  aria-label="toggle password visibility"
                  onClick={handleClickShowPassword}
                  onMouseDown={handleMouseDownPassword}
                  edge="end"
                >
                  {showPassword ? <VisibilityOff /> : <Visibility />}
                </IconButton>
              </InputAdornment>
            }
            label="Password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
          />

            {/* <FormControlLabel
              control={<Checkbox value="remember" color="primary" />}
              label="Remember me"
            /> */}
            <Button
              type="submit"
              fullWidth
              variant="contained"
              sx={{ mt: 3, mb: 2 }}
            >
              {t("login")}
            </Button>
            {/* <Grid container>
              <Grid item xs>
                <Link href="#" variant="body2">
                  Forgot password?
                </Link>
              </Grid>
              <Grid item>
                <Link href="#" variant="body2">
                  {"Don't have an account? Sign Up"}
                </Link>
              </Grid>
            </Grid> */}

<Divider>{t("or")}</Divider>
<div className="flex flex-col mt-3">
<GoogleSignUp
        onSignUpSuccess={handleLoginSuccess}
        setIsLoading={setIsLoading}
        text={t("login-google")}
        />
        </div>
        {showAlert && (
          <div className="py-5 max-w-full">
                  <Zoom in={showAlert} style={{ transitionDelay: showAlert ? '1000ms' : '0ms' }}>
        <Alert severity="error">{t("error-authentication")}</Alert>
                </Zoom>
          </div>


        )}

          </Box>
        </Box>
      </Container>
      </div>
  );
}

export default Login;
