import React, {
  FunctionComponent,
  useState,
  useEffect,
  useContext,
  useRef,
  useCallback
} from "react";
import { Chip, Box, Typography, Button } from "@mui/material";
import { useNavigate, UNSAFE_NavigationContext as NavigationContext } from 'react-router-dom';
import TextField from "@mui/material/TextField";
import CircularProgress from "@mui/material/CircularProgress";
import { useTranslation } from "react-i18next";
import HelpOutlineIcon from "@mui/icons-material/HelpOutline";
import IconButton from "@mui/material/IconButton";
import Tooltip from "@mui/material/Tooltip";
import {Tooltip as TooltipUI} from "@nextui-org/tooltip";
import ClickAwayListener from "@mui/material/ClickAwayListener";
import mapboxgl from "mapbox-gl";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import MapIcon from "@mui/icons-material/Map";
import ContentPasteIcon from "@mui/icons-material/ContentPaste";
import MapboxGeocoder from '@mapbox/mapbox-gl-geocoder';
import { FaMapMarkerAlt } from "react-icons/fa";

import { TbRoute, TbDirections } from "react-icons/tb";

import {
  createOrUpdateSpot,
  fetchActivities,
  fetchSpotPOIs,
  uploadSpotImages,
  fetchTags,
  fetchStartCities,
  addSpotTrip,
} from "../../utils/api/api";
import {
  FeatureCollection,
  Geometry,
} from "geojson";
import { AuthContext } from "../authentification/AuthContext";
import {
  createSpotGeoJSONFeatureCollection,
  getLocalizedField,
} from "../../utils/utils";
import { CustomTabPanel, a11yProps } from "../settings/CustomTabPanel";
import TextEditor from './TextEditor'
import NewSpotMapEdit from "../map/NewSpotMapEdit";
import ImageUploader from "../inputs/ImageUploader";
import RouteAdd from "./RouteAdd";
import PoiAdd from "./PoiAdd";
import AddTrip from "./AddTrip";
import HelpButtonWithVideo from "./HelperVideo";

const EMPTY_FEATURE_COLLECTION: FeatureCollection<Geometry> = {
  type: "FeatureCollection",
  features: [],
};



const NewSpotContent: FunctionComponent<any> = ({
  initialSpotData,
  map,
  setMap,
  mapContainerRef,
  allLayers,
  setAllLayers,
  allMarkers,
  setAllMarkers,
  mapAnimation,
  setMapAnimation,
}) => {
  const { t } = useTranslation();
  const authContext = useContext(AuthContext);
  const { user } = authContext || {};

  const [spot, setSpot] = useState(
    {
      title: '',
      content: '',
      feature: null,
      images: [],
      route_segments: [],
      main_poi: null,
      secondary_pois: []
    }
  );


  const navigate = useNavigate();

  const [isLoading, setIsLoading] = useState<boolean | null>(false);
  const [activityOptions, setActivityOptions] = useState<any[]>([]);
  const [poisOptions, setPoisOptions] = useState<any[]>([]);

  const [mapInstance, setMapInstance] = useState<mapboxgl.Map | null>(null);

  const [activeStep, setActiveStep] = useState(0);
  const startGeocoderRef = useRef<any>(null);
  const [startCities, setstartCities] = useState<any>([]);
  const [filterTags, setFilterTags] = useState<any>([]);
  const [selectedTags, setSelectedTags] = useState([]);
  const [isSelectingSpotMap, setIsSelectingSpotMap] = useState<any>(false);

  const [currentChapterIndex, setCurrentChapterIndex] = useState(0);
  const [nextOpen, setNextOpen] = useState(false);

  const [uploadProgress, setUploadProgress] = useState(0);
  const [uploadedBytes, setUploadedBytes] = useState(0);
  const [totalBytes, setTotalBytes] = useState(0);

  const [selectedGoogleRoute, setSelectedGoogleRoute] = useState<any>(null);
  const [methodUsed, setMethodUsed] = useState<any>(null);
  const [selectedTransportMode, setSelectedTransportMode] = useState("");
  const [newCoord, setNewCoord] = useState<any>(null);
  const [routes, setRoutes] = useState<any>(null);
  const [routeSearchOpen, setRouteSearchOpen] = useState<any>(false);
  const [poiSearchOpen, setPoiSearchOpen] = useState<any>(false);
  const [poiChange, setPoiChange] = useState<any>(null);
  const [isBlogOpen, setIsBlogOpen] = useState(false);
  const [isTripAddOpen, setIsTripAddOpen] = useState(false);
  const [tripData, setTripData] = useState({
    start_city: "",
    total_time: "",
    approximate_cost: "",
    stages: [
      {
        start: "",
        end: "",
        transportation_mode: "",
        description: "",
        operator: "",
      },
    ],
  });

  useEffect(() => {
    const handleBeforeUnload = (event) => {
      // This displays the default browser prompt
      event.preventDefault();
      event.returnValue = ''; // Most browsers will show a default message
    };

    // Add the event listener to block page unload
    window.addEventListener('beforeunload', handleBeforeUnload);

    // Clean up the event listener on component unmount
    return () => {
      window.removeEventListener('beforeunload', handleBeforeUnload);
    };
  }, []);

  useEffect(() => {
    if (initialSpotData) {
      const imagesTransformed = transformMedia(initialSpotData.images || []);
      const poiFeature = {
        type: "Feature",
        geometry: {
          type: "Point",
          coordinates: initialSpotData.geometry.coordinates
        },
        properties: {
          main: true,
          poi: 'climbing',
          icon: "https://slostr.s3.fr-par.scw.cloud/slostr-imgs/icons/climbing.svg",
          color: '#FFA500',
        },
        main: true,
      };

      const updatedSpot = {
        ...initialSpotData,
        images: imagesTransformed,
        feature: poiFeature,
      };
      setSpot(updatedSpot);
      setSelectedTags(initialSpotData.tags?.map(({ id }) => id) || []);
    }
  }, [initialSpotData]);


  useEffect(() => {
    // Fetch tags when the component loads
    const fetchFilterTags = async () => {
      try {
        const data = await fetchTags();
        setFilterTags(data);
      } catch (error) {
        console.error("Error fetching tags:", error);
      }
    };

    fetchFilterTags();
  }, []);
  useEffect(() => {
    // Fetch tags when the component loads
    const getStartCities = async () => {
      try {
        const data = await fetchStartCities();
        setstartCities(data);
        console.log(data);
      } catch (error) {
        console.error("Error fetching tags:", error);
      }
    };

    getStartCities();
  }, []);

  const handleTagClick = (tagId) => {
    setSelectedTags((prevSelected) =>
      prevSelected.includes(tagId)
        ? prevSelected.filter((id) => id !== tagId) // Deselect tag
        : [...prevSelected, tagId] // Select tag
    );
  };


  const handleProgress = (progress: any, loaded: any, total: any) => {
    setUploadProgress(progress);
    setUploadedBytes(loaded);
    setTotalBytes(total);
  };


  const handleNextClose = () => {
    setNextOpen(false);
  };
  const handleNextOpen = () => {
    setNextOpen(!nextOpen);
  };


  const updateCurrentSpot = (field, value) => {
    setSpot((prevSpot) => ({
      ...prevSpot,
      [field]: value,
    }));
  };


  const handleBack = (e: any) => {
    e.preventDefault();
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };


  useEffect(() => {
    const geocoderDivs = document.querySelectorAll(".mapboxgl-ctrl-geocoder");

    geocoderDivs.forEach((div) => {
      if (poiSearchOpen) {
        // @ts-ignore
        div.style.display = "block";
      } else {
        // @ts-ignore
        div.style.display = "none";
      }
    });
  }, [poiSearchOpen, mapInstance]);

  useEffect(() => {
    fetchActivities()
      .then((fetchedActivities) => {
        setActivityOptions(fetchedActivities);
      })
      .catch((error) => {
        console.error("Error fetching activities:", error);
        const customEvent = new CustomEvent("apiError", {
          detail: error.response,
        });
        window.dispatchEvent(customEvent);
      });
    fetchSpotPOIs()
      .then((fetchedPOIs) => {
        setPoisOptions(fetchedPOIs);
      })
      .catch((error) => {
        console.error("Error fetching pois:", error);
        const customEvent = new CustomEvent("apiError", {
          detail: error.response,
        });
        window.dispatchEvent(customEvent);
      });
  }, []);


  const transformMedia = (images = []) => {
    return images.map((media: any) => {
      return {
        preview: media.image_file
          ? media.image_file
          : media.image_url,
        image_url: media.image_file
        ? media.image_file
        : media.image_url,
        type: "image",
        caption: media.caption || "",
      };
    });
  };



  const deleteFeatureFromSpot = (featureIndex, featureType) => {
    // Clone the current state to avoid direct mutation
    const updatedSpot = { ...spot };

    if (featureType === "LineString") {
      // If it's a LineString, we assume it's part of route_segments
      updatedSpot.route_segments = updatedSpot.route_segments.filter(
        (_, index) => index !== featureIndex
      );
    } else if (featureType === "Point") {
      // If it's a Point, we assume it's part of secondary_pois
      updatedSpot.secondary_pois = updatedSpot.secondary_pois.filter(
        (_, index) => index !== featureIndex
      );
    }

    // Update the state with the modified spot object
    setSpot(updatedSpot);
  };

  const handleSubmit = async (e: any) => {
    e.preventDefault();
    setIsLoading(true);

    const transformedSpot = (with_media: any) =>
{
        const routeSegments = spot.route_segments.map((feature: any) => {
          const transportationName =
            feature.properties?.transportation?.name ||
            feature.route_segment.transportation?.name ||
            "defaultTransportationName";
          const elevationProfile = feature.properties?.elevation_profile
            ? feature.properties.elevation_profile
            : feature.elevation_profile
            ? feature.elevation_profile
            : null;
          return {
            id: feature.route_segment ? feature.route_segment.id : null,
            transportation: { name: transportationName },
            elevation_profile: feature.route_segment ? null : elevationProfile,
            geometry: feature.route_segment ? null : feature.geometry,
          };
        });
        const POIs = spot.secondary_pois.map((feature: any) => {
          // Transform the chapter images into the expected format
          const poiImages = feature.images.map((img: any) => ({
            id: img.id || null,
            file: img.file,
            image_url: img.image_url,
            type: img.type,
          }));

          return {
            // chapter: feature.chapter,
            id: feature.id || null,
            content: with_media ? null : feature.content,
            title: with_media ? null : feature.title,
            poi: with_media ? null : { name: feature.poi.name },
            geometry: with_media ? null : feature.geometry,
            images: with_media ? poiImages : [],
          };
        });

        // Transform the chapter images into the expected format
        const chapterImages = spot.images.map((img: any) => ({
          id: img.id || null,
          file: img.file,
          image_url: img.image_url,
          type: img.type,
          caption: img.caption,
        }));

        return {
          ...spot,
          route_segments: with_media ? null : routeSegments,
          secondary_pois: POIs,
          tags: selectedTags,
          images: with_media ? chapterImages : [],
        };
      }

    try {
      const spotData = {
        id: initialSpotData ? initialSpotData.id : null,
        ...transformedSpot(false),
      };

      let newSpot = null;

      newSpot = await createOrUpdateSpot(spotData);

      const newSpotImgs = {
        id: newSpot.id,
        ...transformedSpot(true),
      };

      await uploadSpotImages(newSpot, newSpotImgs, handleProgress);

      if (initialSpotData === null && tripData.start_city !== "") {
        const newSpotTrip = {
          end_spot: newSpot.id,
          ...tripData
                }
                await addSpotTrip(newSpotTrip)
      }

      setIsLoading(false);
      navigate(`/climbing-spots`);
    } catch (error) {
      console.error("Error saving blog:", error);
      const customEvent = new CustomEvent("apiError", { detail: error });
      window.dispatchEvent(customEvent);
      setIsLoading(false);
    }
  };

  const selectSpotMap = () => {
    // Add the click event listener to the map
    mapInstance.on('click', clickHandler);

    setIsSelectingSpotMap(true)
  };

  const clickHandler = (e: any) => {
    const coordinates = [e.lngLat.lng, e.lngLat.lat];
    setNewCoord(coordinates);
    console.log('clicked');

    const poiFeature = {
      type: "Feature",
      geometry: {
        type: "Point",
        coordinates: coordinates
      },
      properties: {
        main: true,
        poi: 'climbing',
        icon: "https://slostr.s3.fr-par.scw.cloud/slostr-imgs/icons/climbing.svg",
        color: '#FFA500',
      },
      main: true,
    };

    // Update the spot with the new feature
    updateCurrentSpot('feature', poiFeature);
    setNewCoord(null);
    setIsSelectingSpotMap(false)

    // Remove the click event listener after it is triggered
    mapInstance.off('click', clickHandler);
  };

  useEffect(() => {
    if (!mapInstance) return;

    // Ensure the click event is cleared when the component is updated
    mapInstance.off('click', clickHandler);

    return () => {
      // Clean up the event listener when the component is unmounted
      mapInstance.off('click', clickHandler);
    };
  }, [spot, newCoord]);


  useEffect(() => {
    if (!mapInstance || poiSearchOpen) return;


    const startGeocoder = new MapboxGeocoder({
      accessToken: mapboxgl.accessToken,
      // @ts-ignore
      mapboxgl: mapboxgl,
      placeholder: t('start-location'),
      marker:false,
      flyTo:true
    });

    startGeocoderRef.current = document.getElementById('start-geocoder')?.appendChild(startGeocoder.onAdd(mapInstance));

    startGeocoder.on('result', (e) => {
      const coordinates = e.result.geometry.coordinates;
      setNewCoord(coordinates);

      const poiFeature = {
        type: "Feature",
        geometry: {
            type: "Point",
            coordinates: coordinates
          },
          properties: {
            main: true,
            poi: 'climbing',
            icon: "https://slostr.s3.fr-par.scw.cloud/slostr-imgs/icons/climbing.svg",
            color: '#FFA500',
          },
        main: true,
      };

      updateCurrentSpot('feature', poiFeature);
      setNewCoord(null)
    });

        // // Cleanup function to remove the event listener
        // return () => {
        //   mapInstance.off('click', clickHandler);
        // }

  }, [mapInstance, poiSearchOpen, routeSearchOpen]);




  const isStepValid = () => {
  const hasTitle = spot.title && spot.title.trim().length > 0;
  const hasGeometry = spot.feature;

  if (!hasTitle && !hasGeometry) {
    return { isValid: false, message: t("blog-error-title-geometry") };
  }
  if (!hasTitle) {
    return { isValid: false, message: t("blog-error-title") };
  }
  if (!hasGeometry) {
    return { isValid: false, message: t("blog-error-geometry") };
  }

  return { isValid: true, message: "" };

  };


  return (
    <div className={`w-full `}>
      {isLoading && (
        <div className="spinner-background white-bg-spinner">
          <CircularProgress />
          <Box display="flex" alignItems="center">
            <div className="text-center">
              <span>
                {uploadProgress === 0
                  ? "Saving your trip"
                  : uploadProgress === 100
                  ? "Finalizing"
                  : "Saving all the media files"}
              </span>{" "}
              <br /> <br />
              <span>{`${uploadProgress}%`}</span>
            </div>
          </Box>

          <span>{t("create-saving")}</span>
        </div>
      )}

      <form
        className="flex justify-center items-center pt-[60px]"
        // onSubmit={handleSubmit}
      >
        <div className="w-full h-[calc(100vh-60px)] flex flex-col items-center box-border	overflow-auto">
          <div className="w-full h-full flex flex-col justify-start relative">
              <React.Fragment>
                <div className="w-full h-full flex flex-col justify-between overflow-hidden items-center">
                <div className="w-screen h-full overflow-auto">
            <Box sx={{ width: "100%", height: "100%" }}>

                <>
                  <div className="w-full h-full flex flex-col md:flex-row">
                    <div className="md:hidden w-full block relative pt-1 md:pt-0">
                      <div className=" bg-white box-border h-[35px] flex justify-center items-center flex-col">
                        <div className="w-full h-full flex flex-row">
                          <div
                            className={`w-1/2 h-full flex items-center justify-center ${
                              !isBlogOpen ? "bg-primary text-white" : ""
                            }`}
                            onClick={() => setIsBlogOpen(false)}
                          >
                            <MapIcon />
                          </div>
                          <div
                            className={`w-1/2 h-full flex items-center justify-center ${
                              isBlogOpen ? "bg-primary text-white" : ""
                            }`}
                            onClick={() => setIsBlogOpen(true)}
                          >
                            <ContentPasteIcon />
                          </div>
                        </div>
                      </div>
                    </div>
                    <div
                      className={`w-full h-[calc(100%-90px)] md:w-1/2 flex flex-col md:flex-row ${
                        isBlogOpen && "hidden md:flex"
                      }`}
                    >
                      <HelpButtonWithVideo/>
                      <div
                        className={`w-full h-full flex justify-start flex-col relative`}
                      >
                        <div className="w-full h-full absolute">
                          <NewSpotMapEdit
                            // key={JSON.stringify(chapters[currentChapterIndex].route_segments)}
                            mapFeatures={
                              methodUsed === "google"
                                ? routes || EMPTY_FEATURE_COLLECTION
                                : createSpotGeoJSONFeatureCollection(
                                    spot
                                  )
                            }
                            chapterIndex={currentChapterIndex}
                            deleteFeature={deleteFeatureFromSpot}
                            setMapInstance={setMapInstance}
                            mapInstance={mapInstance}
                            methodUsed={methodUsed}
                            selectedRouteIndex={selectedGoogleRoute}
                            setSelectedRouteIndex={setSelectedGoogleRoute}
                            transportationMethod={selectedTransportMode}
                            setNewCoord={setNewCoord}
                            map={map}
                            setMap={setMap}
                            mapContainerRef={mapContainerRef}
                            allLayers={allLayers}
                            setAllLayers={setAllLayers}
                            allMarkers={allMarkers}
                            setAllMarkers={setAllMarkers}
                            setPoiChange={setPoiChange}
                            mapAnimation={mapAnimation}
                            setMapAnimation={setMapAnimation}
                          />
                          {!routeSearchOpen && !poiSearchOpen && !poiChange && !isTripAddOpen && (
                            <div className="absolute flex flex-col items-end justify-center gap-4 box-border p-3 z-[9] bottom-4 right-0 h-full transition">
                                  <TooltipUI showArrow={true} content={t("create-route")} placement='left'>

                                            <IconButton aria-label="delete" size="large" className="!bg-primary !shadow-xl transition hover:scale-95 ring-2 ring-slate-100" onClick={() => {
                                  setRouteSearchOpen(true);
                                  setIsBlogOpen(true);
                                }}>
        <TbRoute color="white"/>
      </IconButton>
      </TooltipUI>
      <TooltipUI showArrow={true} content={t("create-poi")} placement='left'>

      <IconButton aria-label="delete" size="large" className="!bg-primary !shadow-xl transition hover:scale-95 ring-2 ring-slate-100" onClick={() => {
                                  setPoiSearchOpen(true);
                                  setIsBlogOpen(true);
                                }}>
        <FaMapMarkerAlt color="white"/>
      </IconButton>
      </TooltipUI>
      {initialSpotData === null && (
      <TooltipUI showArrow={true} content={t("spot.add-trip")} placement='left'>

      <IconButton aria-label="delete" size="large" className="!bg-primary !shadow-xl transition hover:scale-95 ring-2 ring-slate-100" onClick={() => {
                                  setIsTripAddOpen(true);
                                  setIsBlogOpen(true);
                                }}>
        <TbDirections color="white"/>
      </IconButton>
      </TooltipUI>
      )}


                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                    <div
                      className={`w-full md:w-1/2 overflow-auto h-[calc(100%-80px)] ${
                        !isBlogOpen && "hidden md:flex"
                      }`}
                    >
                      {routeSearchOpen ? (
                        <RouteAdd
                          spot={spot}
                          setSpot={setSpot}
                          setMethodUsed={setMethodUsed}
                          methodUsed={methodUsed}
                          setRouteSearchOpen={setRouteSearchOpen}
                          mapInstance={map}
                          newCoord={newCoord}
                          setNewCoord={setNewCoord}
                          routes={routes}
                          setRoutes={setRoutes}
                          selectedRouteIndex={selectedGoogleRoute}
                          setSelectedRouteIndex={setSelectedGoogleRoute}
                        />
                      ) : poiSearchOpen || poiChange ? (
                        <PoiAdd
                        spot={spot}
                        setSpot={setSpot}
                          pois={poisOptions}
                          setPoiSearchOpen={setPoiSearchOpen}
                          mapInstance={map}
                          setNewCoord={setNewCoord}
                          newCoord={newCoord}
                          setPoiChange={setPoiChange}
                          poiChange={poiChange}
                        />
                      ) : isTripAddOpen ? (
                        <AddTrip
                        startCities={startCities}
                        tripData={tripData}
                        setTripData={setTripData}
                        setIsAddTripOpen={setIsTripAddOpen}
                        />
                      ) : (
                        <>
                            <CustomTabPanel
                              value={currentChapterIndex}
                              index={0}
                            >
                              <div className="w-full h-full flex flex-col gap-2 px-4 pb-3">
                                <div className="md:px-5 flex flex-col w-full h-full justify-between shadow-lg rounded-lg py-2">
                                  <div className="overflow-auto pt-2">
                                    <ImageUploader
                                      multiple={true}
                                      noVideo = {true}
                                      initialImages={spot.images}
                                      onImageChange={(images: any) =>
                                        updateCurrentSpot(
                                          "images",
                                          images
                                        )
                                      }
                                    />
                                    <Box
                                      sx={{
                                        display: "flex",
                                        alignItems: "center",
                                        width: "100%",
                                      }}
                                    >
                                      {/* <TitleIcon
                                        sx={{
                                          color: "action.active",
                                          mr: 1,
                                          my: 0,
                                        }}
                                      /> */}
                                      <TextField
                                        label={t("spot.spot-name")}
                                        variant="outlined"
                                        fullWidth
                                        size="small"
                                        className="!pb-3 !mt-3"
                                        value={spot.title}
                                        onChange={(e) =>
                                          updateCurrentSpot(
                                            "title",
                                            e.target.value
                                          )
                                        }
                                      />
                                    </Box>

                                    <div className="flex flex-row gap-2 mt-2 mb-4">
                                    <div id='start-geocoder' className="w-full flex flex-col gap-1 z-[100] border-slate-300 border-1 rounded-md"></div>
                                    <Button
                                    style={{width: '200px', borderRadius: '10px'}}
                                    variant="contained"
                                    startIcon={<FaMapMarkerAlt/>}
                                    onClick={selectSpotMap}
                                    disabled={isSelectingSpotMap}
                                    >
                                      {t('spot.select-spot')}
                                    </Button>
                                    </div>

                                    <div className="border-1 border-slate-300 p-3 rounded-lg mb-4">
      <p className="text-md mb-2">
       {t('spot.select-tags')}
      </p>
      <Box
        sx={{
          display: "flex",
          flexWrap: "wrap",
          gap: 1,
        }}
      >
        {filterTags.map((tag) => (
          <Chip
            key={tag.id}
            label={tag.name}
            clickable
            color={selectedTags.includes(tag.id) ? "primary" : "default" }
            variant={selectedTags.includes(tag.id) ? "filled" : "outlined" }
            onClick={() => handleTagClick(tag.id)}
          />
        ))}
      </Box>
    </div>
                                    <TextEditor
                                      blogContent={spot.content}
                                      setBlogContent={(content: any) =>
                                        updateCurrentSpot(
                                          "content",
                                          content
                                        )
                                      }
                                    />

                                  </div>
                                </div>
                              </div>
                            </CustomTabPanel>
                        </>
                      )}
                    </div>
                  </div>
                </>
            </Box>
          </div>
                </div>

                {!routeSearchOpen && !poiSearchOpen && !poiChange && !isTripAddOpen && (
                <div className="absolute bottom-3 self-center w-full flex flex-row bg-white z-[99] p-3 shadow-xl rounded-lg border border-slate-300 border-solid max-w-5xl">
                  {/* <Button
                    color="inherit"
                    disabled={activeStep === 0}
                    onClick={handleBack}
                    sx={{ mr: 1 }}
                  >
                    {t("back")}
                  </Button> */}
                  <Box sx={{ flex: "1 1 auto" }} />
                  {
                    isStepValid().isValid &&
                    !routeSearchOpen &&
                    !poiSearchOpen &&
                    !poiChange ? (
                      <Button
                        onClick={(e) => handleSubmit(e)}
                        type="submit"
                      >
                        {t("save")} {t("spot.spot")}
                      </Button>
                    ) : (
                      !routeSearchOpen &&
                      !poiSearchOpen &&
                      !poiChange && (
                        <ClickAwayListener onClickAway={handleNextClose}>
                          <div>
                            <Tooltip
                              PopperProps={{
                                disablePortal: true,
                              }}
                              onClose={handleNextClose}
                              open={nextOpen}
                              arrow
                              disableFocusListener
                              disableHoverListener
                              disableTouchListener
                              title={isStepValid().message}
                            >
                              <Button
                                color="error"
                                onClick={handleNextOpen}
                                variant="text"
                              >
                                {t("save")} {t("spot.spot")}
                              </Button>
                            </Tooltip>
                          </div>
                        </ClickAwayListener>
                      )
                    )
                  }
                </div>
                )}
              </React.Fragment>
          </div>
        </div>
      </form>
    </div>
  );
};

export default NewSpotContent;
