import { FunctionComponent } from "react";
import { useState, useEffect, useContext } from 'react';
import TextField from '@mui/material/TextField';
import FormControl from '@mui/material/FormControl';
import Input from '@mui/material/Input';
import InputLabel from '@mui/material/InputLabel';
import InputAdornment from '@mui/material/InputAdornment';
import EmailIcon from '@mui/icons-material/Email';
import PersonIcon from '@mui/icons-material/Person';
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import HttpsIcon from '@mui/icons-material/Https';
import InfoIcon from '@mui/icons-material/Info';
import Divider from '@mui/material/Divider';
import { HiOutlineMail } from "react-icons/hi";

import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import { redirect, useNavigate } from "react-router-dom";

import { GoogleSignUp } from './GoogleSignUp';
import { AuthContext } from './AuthContext';
import { apiCreateUser } from "../../utils/api/api";
import { useTranslation } from 'react-i18next';
import { Button } from "@mui/material";



interface CreateAccountFormProps {
  setIsLoading: (isLoading: boolean) => void;
}
const ErrorDisplay: FunctionComponent<any> = ({ errors }) => {
  try {
    return (
      <>
        {Object.keys(errors).map((key) => (
          <div key={key}>
            {errors[key].map((message: any, index: any) => (
              <span key={index}>{message}</span>
            ))}
          </div>
        ))}
      </>
    );
  } catch (error) {
    console.error("Error rendering error messages:", error);
    return <span>Error.</span>;
  }
};

const CreateAccountForm: FunctionComponent<any> = ({ setIsLoading, redirection=true }) => {
  const { t } = useTranslation();
  const authContext = useContext(AuthContext);
  const { setIsAuthenticated, user } = authContext;

  const [username, setUsername] = useState('');
  const [email, setEmail] = useState('');
  const [password1, setPassword1] = useState('');
  const [password2, setPassword2] = useState('');
  const [firstName, setfirstName] = useState('');
  const [lastName, setlastName] = useState('');
  const [showPassword, setShowPassword] = useState(false);
  const [isFormValid, setIsFormValid] = useState(false);
  const [passwordError, setPasswordError] = useState('');
  const [showPasswordInfo, setShowPasswordInfo] = useState(false);
  const [checked, setChecked] = useState(false);
  const [withEmail, setWithEmail] = useState(false);
  const [errors, setErrors] = useState<any>(null);
  const [isAuthChecked, setIsAuthChecked] = useState(false);

  const navigate = useNavigate();




  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    if (checked) {
      setIsLoading(true)
    try {
      const isAuthenticated = await apiCreateUser(email, username, password1, firstName, lastName);
      if (isAuthenticated) {
        setIsAuthenticated(true);
        setIsAuthenticated(true);
        setIsLoading(false)
      }
      // Handle successful authentication
      navigate(`/${username}`)

    } catch (error: any) {
      // Assuming the error response is in error.response.data
      if (error.response && error.response.data) {
        setErrors(error.response.data);
      }
      setIsLoading(false)

    }

  }
}

useEffect(() => {
  if (isAuthChecked && user) {
    // const redirect = new URLSearchParams(location.search).get('redirect');
    if (redirection) {
      navigate('/');
    }
  }
}, [isAuthChecked, user]);

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };
  const togglePasswordInfo = () => {
    setShowPasswordInfo(!showPasswordInfo);
  };


  const validatePassword = (password: string) => {
    if (password.length < 8) {
      return t("password-req-1");
    }
    if (!/[A-Z]/.test(password)) {
      return t("password-req-2");
    }
    if (!/[a-z]/.test(password)) {
      return t("password-req-3");
    }
    if (!/[0-9]/.test(password)) {
      return t("password-req-4");
    }
    if (!/[" !"#$%&'()*+,-./:;<=>?@[\]^_`{|}~]/.test(password)) {
      return t("password-req-5");
    }
    return '';
  };

  useEffect(() => {
    const passwordValidationError = validatePassword(password1);
    if (password1 !== '' && password2 !== '') {
      setPasswordError(passwordValidationError);
    }
    setIsFormValid(password1 === password2 &&
      passwordValidationError === '' &&
      checked &&
    username !== '' &&
  email !== '' &&
firstName !== '' &&
lastName !== '')
  }, [password1, password2, checked, username, email, firstName, lastName]);

  const handleLoginSuccess = (isAuthenticated: boolean) => {
    if (isAuthenticated) {
      console.log('Login successful');
    } else {
      console.log('Login unsuccessful');
    }
  };


  return (
    <div className="w-full flex flex-col items-center justify-center  text-center text-xl text-gray1 md:flex-col md:items-center md:justify-center px-4 sm:px-0">


      <div className="flex flex-col items-center justify-center w-full sm:w-[500px]">
    {!withEmail ? (
    <div className="h-12 flex flex-row items-center justify-center w-full sm:flex-row">
    <Button variant="contained" fullWidth onClick={() => setWithEmail(!withEmail)} size="large" startIcon={<HiOutlineMail/>}>{t('signup-email')}</Button>
        </div>
    ) : (
      <form className="flex flex-col items-center justify-center gap-2 md:flex-col md:items-center md:justify-center bg-white w-full rounded-lg shadow-all p-4" onSubmit={handleSubmit}>
        <div className="w-full flex flex-col items-center justify-center gap-6 md:flex-col md:items-center md:justify-center sm:w-auto">
          <div className="w-full flex flex-col sm:flex-row gap-10 justify-between">
          <FormControl variant="standard" fullWidth>
        <InputLabel htmlFor="input-with-icon-adornment">
        {t("email")}
        </InputLabel>
        <Input
          id="input-with-icon-adornment"
          startAdornment={
            <InputAdornment position="start">
              <EmailIcon />
            </InputAdornment>
          }
          value={email}
          type="email"
          onChange={(e) => setEmail(e.target.value)}
        />
      </FormControl>
      <FormControl variant="standard" fullWidth>
        <InputLabel htmlFor="input-with-icon-adornment">
        {t("username")}
        </InputLabel>
        <Input
          id="input-with-icon-adornment"
          startAdornment={
            <InputAdornment position="start">
              <PersonIcon />
            </InputAdornment>
          }
          value={username}
          onChange={(e) => setUsername(e.target.value)}
        />
      </FormControl>
          </div>

          <div className="w-full flex flex-col sm:flex-row gap-10 justify-between">
          <FormControl variant="standard" fullWidth>
        <InputLabel htmlFor="input-with-icon-adornment">
        {t("user-first-name")}
        </InputLabel>
        <Input
          id="input-with-icon-adornment"
          value={firstName}
          onChange={(e) => setfirstName(e.target.value)}
        />
      </FormControl>
      <FormControl variant="standard" fullWidth>
        <InputLabel htmlFor="input-with-icon-adornment">
        {t("user-last-name")}
        </InputLabel>
        <Input
          id="input-with-icon-adornment"
          value={lastName}
          onChange={(e) => setlastName(e.target.value)}
        />
      </FormControl>
          </div>

          <div className="w-full flex flex-col sm:flex-row gap-10 justify-between">
          <FormControl variant="standard" fullWidth>
        <InputLabel htmlFor="input-with-icon-adornment">
        {t("password")}
        </InputLabel>
        <Input
          id="input-with-icon-adornment"
          startAdornment={
            <InputAdornment position="start">
              <HttpsIcon />
            </InputAdornment>
          }
          endAdornment={
            <>
            <InputAdornment position="end" onClick={togglePasswordVisibility}>
              {showPassword ? <VisibilityOffIcon /> : <VisibilityIcon />}
            </InputAdornment>
            <InputAdornment position="end" onClick={togglePasswordInfo}>
              <InfoIcon/>
            </InputAdornment>
            </>
          }
          value={password1}
          type={showPassword ? "text" : "password"}
          onChange={(e) => setPassword1(e.target.value)}
        />
      </FormControl>
      <FormControl variant="standard" fullWidth>
        <InputLabel htmlFor="input-with-icon-adornment">
{t("password-confirm")}
        </InputLabel>
        <Input
          id="input-with-icon-adornment"
          startAdornment={
            <InputAdornment position="start">
              <HttpsIcon />
            </InputAdornment>
          }
          type="password"
          value={password2}
          onChange={(e) => setPassword2(e.target.value)}
        />
      </FormControl>
          </div>
          {showPasswordInfo && (
            <div className="password-requirements-tooltip">
              <ul className="text-xs">
                <li>{t("password-req-1")}</li>
                <li>{t("password-req-2")}</li>
                <li>{t("password-req-3")}</li>
                <li>{t("password-req-4")}</li>
                <li>{t("password-req-5")}</li>
              </ul>
            </div>
          )}
<div className="w-full flex-col gap-1">
          {passwordError && (
          <div className="text-base" style={{ color: 'indianred' }}>{passwordError}</div>
        )}
          {password1 && password2 && password1 !== password2 && (
            <span className="text-base" style={{ color: 'indianred' }}>{t("password-no-match")}</span>
          )}
          {errors && (
        <div className="text-base" style={{ color: 'indianred' }}>
          <ErrorDisplay errors={errors} />
        </div>
      )}

<FormControlLabel checked={checked} onChange={()=>setChecked(!checked)} control={<Checkbox />}
label={<p>{t("test-user-agree-1")} <a target="_blank" href="/policies/terms-of-use">{t("test-user-agree-toc")}</a> {t("test-user-agree-2")} <a target="_blank" href="/policies/privacy-policy">{t("test-user-agree-pp")}</a></p>}
/>

          </div>
        </div>
        <Button className={``}
  type="submit"
  disabled={!isFormValid}
  variant="contained"
  fullWidth
>
            {t("subscribe")}

</Button>

      </form>
    )}

<div className="py-3 w-full">
<Divider className="w-full">{t("or")}</Divider>

</div>


        <div className="h-12 flex flex-row items-center justify-center w-full sm:flex-row">
        <GoogleSignUp
        onSignUpSuccess={handleLoginSuccess}
        setIsLoading={setIsLoading}
        text={t('signup-google')}
        checked={true}
        redirection={redirection}

        />
          {/* <button className="cursor-pointer [border:none] py-0 px-[25.131956100463867px] bg-whitesmoke-100 self-stretch flex-1 rounded-[9.42px] flex flex-col items-center justify-end md:pl-2 md:pr-2 md:box-border sm:flex-1">
            <div className="overflow-hidden flex flex-row items-center justify-center sm:self-stretch sm:w-auto">
              <img
                className="w-[30px] relative h-[30px] overflow-hidden shrink-0"
                alt=""
                src="/socialiconfb.svg"
              />
            </div>
            <div className="relative text-base leading-[43.98px] font-text-small text-gray1 text-center sm:self-stretch sm:w-auto">
              Facebook
            </div>
          </button> */}
        </div>
      </div>
      {/* <div className="self-stretch relative leading-[53.41px] sm:w-[200px]">
        <span>I already have an account.</span>
        <span className="text-slategray">{` `}</span>
        <span className="text-accent">Login</span>
      </div> */}
    </div>
  );
};

export default CreateAccountForm;
