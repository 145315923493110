// NewBlogWritingPage.tsx
import React, { FunctionComponent, useEffect, useState, useContext } from "react";
import { useParams, useLocation } from "react-router-dom";
import CircularProgress from "@mui/material/CircularProgress";
import { useTranslation } from 'react-i18next';


import NewSpotContent from "../components/spots/NewSpotContent";
import { fetchSpotDetail } from "../utils/api/api";
import Navbar from "../components/main/Navbar";
import Footer from "../components/main/Footer";
import { AuthContext } from "../components/authentification/AuthContext";
import ErrorPage from "./ErrorPage";


const NewSpotPage: FunctionComponent<any> = ({map, setMap, mapContainerRef,allLayers, setAllLayers, allMarkers, mapAnimation,
  setMapAnimation, setAllMarkers, startAtChapters = false}) => {
  const { t } = useTranslation();
  const authContext = useContext(AuthContext);
  const location = useLocation();
  const { state } = location || {};
const feature = state?.feature || null;
  const [spotData, setSpotData] = useState(null)

  const [isLoading, setIsLoading] = useState<boolean>(true);



  useEffect(() => {
    if (map) {

      setTimeout(() => {
        setIsLoading(false);
      }, 750);
    }
  }, [map]);


  useEffect(() => {
    if (feature) {
      setSpotData(feature)
    }

  }, [feature]);

  return (
    <>
          <Navbar />
          <div className="w-full h-screen relative bg-primary-contrast overflow-hidden flex flex-col items-center justify-start">
      <div className="w-full h-full relative bg-white flex flex-col items-center justify-center">

          {isLoading ? (
            // Display a loader or a placeholder component while data is being fetched
            <div className="spinner">
            <CircularProgress />
            {t('loading')}
          </div>
          ) : (
            // Once data is fetched or if creating a new blog (id is undefined), render the BlogWritingContent component
            true ? (
            // isAllowed || !blog ? (
              <NewSpotContent
              initialSpotData={spotData}
              startAtChapters={startAtChapters} map={map}
              setMap={setMap}
              mapContainerRef={mapContainerRef}
              mapAnimation={mapAnimation}
                setMapAnimation={setMapAnimation}
              allLayers={allLayers}
              setAllLayers={setAllLayers}
              allMarkers={allMarkers}
              setAllMarkers={setAllMarkers}
/>
            ) : (
              <ErrorPage/>
            )

          )}
      </div>
      {/* <Footer/> */}
    </div>
    </>
  );
};

export default NewSpotPage;
