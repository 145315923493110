import React, {
  FunctionComponent,
  useState,
  useEffect,
  useContext,
} from "react";
import { updateUser, uploadProfilePicture } from "../../utils/api/api";
import { useTranslation } from "react-i18next";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import Box from "@mui/material/Box";
import InstagramIcon from "@mui/icons-material/Instagram";
import FacebookIcon from "@mui/icons-material/Facebook";
import YouTubeIcon from "@mui/icons-material/YouTube";
import LanguageIcon from "@mui/icons-material/Language";
import Alert from '@mui/material/Alert';
import FormControl from '@mui/material/FormControl';
import Input from '@mui/material/Input';
import InputLabel from '@mui/material/InputLabel';
import InputAdornment from '@mui/material/InputAdornment';
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import HttpsIcon from '@mui/icons-material/Https';
import InfoIcon from '@mui/icons-material/Info';


import ImageUploader from "../inputs/ImageUploader";
import InputField from "../inputs/InputField";

import { AuthContext } from "../authentification/AuthContext";

const UserProfile: FunctionComponent<any> = ({ user }) => {
  const { t } = useTranslation();
  const authContext = useContext(AuthContext);

  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [username, setUsername] = useState("");
  const [email, setEmail] = useState("");
  const [password1, setPassword1] = useState("");
  const [password2, setPassword2] = useState("");
  const [bio, setBio] = useState<string>("");
  const [insta, setInsta] = useState<any>(null);
  const [facebook, setFacebook] = useState<any>(null);
  const [youtube, setYoutube] = useState<any>(null);
  const [web, setWeb] = useState<any>(null);
  const [headerImage, setHeaderImage] = useState<any>([]);
  const isSocialAccount = user?.is_social_account;
  const [errors, setErrors] = useState({}); // State to hold errors
  const [alert, setAlert] = useState(false); // State to hold errors
  const [showPassword, setShowPassword] = useState(false);
  const [isFormValid, setIsFormValid] = useState(false);
  const [passwordError, setPasswordError] = useState('');
  const [showPasswordInfo, setShowPasswordInfo] = useState(false);


  // Update state when user prop changes
  useEffect(() => {
    if (user) {
      setFirstName(user.first_name || "");
      setLastName(user.last_name || "");
      setUsername(user.username || "");
      setEmail(user.username || "");
      setBio(user.bio || "");
      setInsta(user.insta);
      setFacebook(user.facebook);
      setYoutube(user.youtube);
      setWeb(user.web);
      // setHeaderImage(user.profile_picture || null);
    }
  }, [user]);

  const updateHeaderImage = (image: any) => {
    setHeaderImage(image);
  };

  const handleFirstNameChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setFirstName(event.target.value);
  };
  const handleLastNameChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setLastName(event.target.value);
  };
  const handleUsernameChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setUsername(event.target.value);
  };

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    try {
      if (headerImage.length > 0) {
        const userData = {
          profile_picture: headerImage[0].file,
        };

        await uploadProfilePicture(user?.id, userData);
      }

      // Prepare the data to be sent
      const userData: any = {
        first_name: firstName,
        last_name: lastName,
        username: username,
        bio: bio,
        insta: insta,
        facebook: facebook,
        youtube: youtube,
        web: web,
        // profile_picture: headerImage? headerImage : null,
      };

      if (password1 && password2) {
        if (password1 === password2) {
          const passwordValidationError = validatePassword(password1);
          if (passwordValidationError==='') {
            userData.new_password = password1;
            userData.new_password_confirm = password2;
          }
        }
      }

      const response = await updateUser(user?.id, userData);

      authContext?.setUser(response);
      setErrors({});
      setAlert(true)
    } catch (error: any) {
      console.error("Error updating user:", error);
      if (error.response && error.response.data) {
        setErrors(error.response.data);
      } else {
        // General error fallback
        setErrors({ general: "An unknown error occurred." });
      }
    }
  };

  useEffect(() => {
    let timer: any;
    // Show the alert when `alert` is true
    if (alert) {
      // Set the alert to fade out after 1 second
      timer = setTimeout(() => {
        setAlert(false);
      }, 3000);
    }
    return () => clearTimeout(timer); // Cleanup the timer
  }, [alert]);

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };
  const togglePasswordInfo = () => {
    setShowPasswordInfo(!showPasswordInfo);
  };

  const validatePassword = (password: string) => {
    if (password.length < 8) {
      return t("password-req-1");
    }
    if (!/[A-Z]/.test(password)) {
      return t("password-req-2");
    }
    if (!/[a-z]/.test(password)) {
      return t("password-req-3");
    }
    if (!/[0-9]/.test(password)) {
      return t("password-req-4");
    }
    if (!/[" !"#$%&'()*+,-./:;<=>?@[\]^_`{|}~]/.test(password)) {
      return t("password-req-5");
    }
    return '';
  };

  useEffect(() => {
    const passwordValidationError = validatePassword(password1);
    if (password1 !== '' && password2 !== '') {
      setPasswordError(passwordValidationError);
    }
    setIsFormValid(password1 === password2 &&
      passwordValidationError === '' &&
    username !== '' &&
  email !== '' &&
firstName !== '' &&
lastName !== '')
  }, [password1, password2, username, email, firstName, lastName]);


  return (
    <div className="w-full flex justify-center pt-3">
      <form
        className="w-[680px] flex flex-col items-start justify-start py-0 px-5 box-border gap-[20px] max-w-full text-left text-sm text-gray-700 font-text-small"
        onSubmit={handleSubmit}
      >
        <div className="flex flex-col w-full gap-[20px] md:flex-row">
          {/* <InputField
      topLabel="First name"
      placeholder="Provide first name"
      value={firstName}
      valueChange={setFirstName}
    /> */}
          <TextField
            required
            className="w-full"
            id="standard-required"
            label={t("user-first-name")}
            defaultValue={firstName}
            variant="standard"
            value={firstName}
            onChange={handleFirstNameChange}
          />
          {/* <InputField
      topLabel="Last name"
      placeholder="Provide last name"
      value={lastName}
      valueChange={setLastName}
    /> */}
          <TextField
            required
            className="w-full"
            id="standard-required"
            label={t("user-last-name")}
            defaultValue={lastName}
            variant="standard"
            value={lastName}
            onChange={handleLastNameChange}
          />
        </div>
        <TextField
            required
            className="w-full"
            id="standard-required"
            label={t("username")}
            defaultValue={username}
            variant="standard"
            value={username}
            onChange={handleUsernameChange}
          />
        {!isSocialAccount && (
          <>
            {/* <InputField
              topLabel="Email"
              placeholder="Provide email"
              type="email"
              value={email}
              valueChange={setEmail}
              leftIcon="/email-icon.svg"
            /> */}

<div className="w-full flex flex-col sm:flex-row gap-10 justify-between">
          <FormControl variant="standard" fullWidth>
        <InputLabel htmlFor="input-with-icon-adornment">
        {t("password")}
        </InputLabel>
        <Input
          id="input-with-icon-adornment"
          startAdornment={
            <InputAdornment position="start">
              <HttpsIcon />
            </InputAdornment>
          }
          endAdornment={
            <>
            <InputAdornment position="end" onClick={togglePasswordVisibility}>
              {showPassword ? <VisibilityOffIcon /> : <VisibilityIcon />}
            </InputAdornment>
            <InputAdornment position="end" onClick={togglePasswordInfo}>
              <InfoIcon/>
            </InputAdornment>
            </>
          }
          value={password1}
          type={showPassword ? "text" : "password"}
          onChange={(e) => setPassword1(e.target.value)}
        />
      </FormControl>
      <FormControl variant="standard" fullWidth>
        <InputLabel htmlFor="input-with-icon-adornment">
{t("password-confirm")}
        </InputLabel>
        <Input
          id="input-with-icon-adornment"
          startAdornment={
            <InputAdornment position="start">
              <HttpsIcon />
            </InputAdornment>
          }
          type="password"
          value={password2}
          onChange={(e) => setPassword2(e.target.value)}
        />
      </FormControl>
          </div>
          {showPasswordInfo && (
            <div className="password-requirements-tooltip">
              <ul className="text-xs">
                <li>{t("password-req-1")}</li>
                <li>{t("password-req-2")}</li>
                <li>{t("password-req-3")}</li>
                <li>{t("password-req-4")}</li>
                <li>{t("password-req-5")}</li>
              </ul>
            </div>
          )}
<div className="w-full flex-col gap-1">
          {passwordError && (
          <div className="text-base font-roboto" style={{ color: 'indianred' }}>{passwordError}</div>
        )}
          {password1 && password2 && password1 !== password2 && (
            <span className="text-base font-roboto" style={{ color: 'indianred' }}>{t("password-no-match")}</span>
          )}
                      </div>

          </>
        )}
        <div className="self-stretch h-[1px] relative bg-whitesmoke-300" />
        <div className="self-stretch flex flex-col items-start justify-start max-w-full text-brand-700 mq675:gap-[16px]">
          <div className="self-stretch flex flex-row flex-wrap items-center justify-start gap-[20px] max-w-full">
            <ImageUploader
              multiple={false}
              initialImages={headerImage}
              onImageChange={updateHeaderImage}
              text={t("profile-picture")}
            />
          </div>
        </div>
        <div className="self-stretch flex flex-row items-start justify-start pt-0 px-0 pb-1 box-border max-w-full">
          <div className="flex-1 flex flex-col items-start justify-start max-w-full mq675:gap-[16px]">
            <div className="self-stretch flex flex-col items-start justify-start gap-[6px]">
              <TextField
                required
                className="w-full"
                id="outlined-multiline-static"
                label={t("user-bio")}
                placeholder="Enthusiastic traveller"
                value={bio}
                onChange={(e) => setBio(e.target.value)}
                multiline
                rows={4}
                defaultValue={bio}
                helperText={`${bio ? bio.length : '0'}/255`}
                inputProps={{ maxLength: 255 }}
              />
            </div>
          </div>
        </div>
        <div className="self-stretch h-[1px] relative bg-whitesmoke-300" />
        <div className="flex flex-col w-full gap-6">
          <div className="flex flex-col w-full md:flex-row gap-6">
            <div className="flex flex-row w-full justify-center items-end">
              <InstagramIcon sx={{ color: "action.active", mr: 1, my: 0.5 }} />
              <TextField
                id="input-with-sx"
                label={t("social-insta")}
                variant="standard"
                className="w-full"
                value={insta}
                onChange={(e) => setInsta(e.target.value)}
                defaultValue={insta}
              />
            </div>
            <div className="flex flex-row w-full justify-center items-end">
              <FacebookIcon sx={{ color: "action.active", mr: 1, my: 0.5 }} />
              <TextField
                id="input-with-sx"
                label={t("social-fb")}
                variant="standard"
                className="w-full"
                value={facebook}
                onChange={(e) => setFacebook(e.target.value)}
                defaultValue={facebook}
              />
            </div>
          </div>
          <div className="flex flex-col md:flex-row gap-6">
            <div className="flex flex-row w-full justify-center items-end">
              <YouTubeIcon sx={{ color: "action.active", mr: 1, my: 0.5 }} />
              <TextField
                id="input-with-sx"
                label={t("social-yt")}
                variant="standard"
                className="w-full"
                value={youtube}
                onChange={(e) => setYoutube(e.target.value)}
                defaultValue={youtube}
              />
            </div>
            <div className="flex flex-row w-full justify-center items-end">
              <LanguageIcon sx={{ color: "action.active", mr: 1, my: 0.5 }} />
              <TextField
                id="input-with-sx"
                label={t("social-web")}
                variant="standard"
                className="w-full"
                value={web}
                onChange={(e) => setWeb(e.target.value)}
                defaultValue={web}
              />
            </div>
          </div>
        </div>


        <div className="self-stretch flex flex-col items-start justify-start gap-[16px]">
      <div className="self-stretch h-px relative bg-gray-200" />
      {Object.keys(errors).length > 0 && (
        <ul style={{ color: 'red' }}> {/* Display errors in red */}
          {Object.entries(errors).map(([key, value]: any) => (
            <li className="font-roboto" key={key}>{`${key}: ${value.join(", ")}`}</li>
          ))}
        </ul>
      )}
      <div className="self-stretch flex flex-col items-start justify-start">
        <div className="self-stretch flex flex-row items-center justify-end gap-[12px] mq450:flex-wrap">
        <Button variant="outlined" type="submit">
                {t("save")}
              </Button>

        </div>
      </div>
    </div>
      </form>
      {alert && (
      <Alert className="w-[80vw] fixed top-[140px]" severity="success" color="warning">{t("user-updated")}</Alert>
      )}


    </div>
  );
};

export default UserProfile;
