// utils/mapStyles.js

export const activityColors: any = {
    1: "rgba(102, 136, 114, 0.7)", // Hiking
    2: "rgba(136, 114, 102, 0.7)", // Biking
    3: "rgba(252, 228, 201, 0.7)", // city trip
    4: "rgba(54, 82, 173, 0.7)", // sailing
    5: "rgba(231, 136, 149, 0.7)", // train
    6: "#0000FF",
    7: "#0000FF",
  };

  export const transportationColors: any = {
    'hike': "rgba(102, 136, 114, 0.7)",
    'bike': "rgba(136, 114, 102, 0.7)",
    'train': "rgba(218, 165, 32, 0.7)",
    'car': "rgba(54, 82, 173, 0.7)",
    'bus': "rgba(231, 136, 149, 0.7)",
    'boat': "rgba(50, 50, 255, 0.7)",
    'sail': "rgba(11, 97, 91, 0.7)",
    'moto': "rgba(80, 130, 125, 0.7)",
    'hitchhike': "rgba(135, 107, 170, 0.7)",
    'run': "rgba(152, 106, 94, 0.7)",
    7: "#0000FF",
  };
  export const transportationColorsDirection: any = {
    'hike': "rgba(102, 136, 114, 0.8)",
    'bike': "rgba(136, 114, 102, 0.8)",
    'train': "rgba(218, 165, 32, 0.8)",
    'car': "rgba(54, 82, 173, 0.8)",
    'bus': "rgba(231, 136, 149, 0.8)",
    'boat': "rgba(50, 50, 255, 0.8)",
    'sail': "rgba(11, 97, 91, 0.8)",
    'moto': "rgba(80, 130, 125, 0.8)",
    'hitchhike': "rgba(135, 107, 170, 0.8)",
    'run': "rgba(152, 106, 94, 0.8)",
    7: "#0000FF",
  };
  export const transportationColorsFaded: any = {
    'hike': "rgba(102, 136, 114, 0.5)",
    'bike': "rgba(136, 114, 102, 0.5)",
    'train': "rgba(218, 165, 32, 0.5)",
    'car': "rgba(54, 82, 173, 0.5)",
    'bus': "rgba(231, 136, 149, 0.5)",
    'boat': "rgba(50, 50, 255, 0.5)",
    'sail': "rgba(11, 97, 91, 0.5)",
    'moto': "rgba(80, 130, 125, 0.5)",
    'hitchhike': "rgba(135, 107, 170, 0.5)",
    'run': "rgba(152, 106, 94, 0.5)",
    7: "#0000FF",
  };

  // export const lineColors = [
  //   "rgba(143, 188, 143, 1)", // Sea Green, muted for a soft forest look
  //   "rgba(46, 139, 87, 1)",   // SeaGreen, deeper for forest undergrowth
  //   "rgba(32, 178, 170, 1)",  // LightSeaGreen, for clear mountain waters
  //   "rgba(95, 158, 160, 1)",  // CadetBlue, for mountain skies at dusk
  //   "rgba(70, 130, 180, 1)",  // SteelBlue, for deep sea
  //   "rgba(176, 224, 230, 1)", // PowderBlue, for gentle morning skies
  //   // "rgba(255, 248, 220, 1)", // Cornsilk, for sandy shores
  //   "rgba(255, 222, 173, 1)", // NavajoWhite, for beach sand in sunlight
  //   "rgba(107, 142, 35, 1)",  // OliveDrab, for dense forest foliage
  //   "rgba(160, 82, 45, 1)",   // Sienna, for mountain trails
  //   "rgba(218, 165, 32, 1)",  // Goldenrod, for fields of wildflowers
  //   "rgba(189, 183, 107, 1)", // DarkKhaki, for dry grasslands
  //   "rgba(112, 128, 144, 1)", // SlateGray, for rocky mountain surfaces
  //   "rgba(47, 79, 79, 1)",    // DarkSlateGray, for deep forest shadows
  //   "rgba(119, 136, 153, 1)", // LightSlateGray, for misty mountain mornings
  //   // "rgba(255, 250, 250, 1)", // Snow, for mountain peaks
  //   // "rgba(240, 255, 255, 1)", // Azure, for clear skies over the sea
  //   "rgba(102, 205, 170, 1)", // MediumAquamarine, for vibrant river life
  //   "rgba(143, 188, 143, 1)", // PaleGreen, for light through the tree canopy
  //   "rgba(60, 179, 113, 1)",  // MediumSeaGreen, for lush meadows
  // ];

  export const lineColors = [
    "rgba(0, 123, 255, 1)",    // Bright Blue, for clear contrast
    "rgba(40, 167, 69, 1)",    // Green, for natural elements
    "rgba(255, 193, 7, 1)",    // Yellow, for vibrant routes
    "rgba(220, 53, 69, 1)",    // Red, for high visibility
    "rgba(23, 162, 184, 1)",   // Cyan, for water-related routes
    "rgba(255, 133, 27, 1)",   // Orange, for distinct visibility
    "rgba(111, 66, 193, 1)",   // Purple, for unique paths
    "rgba(54, 162, 235, 1)",   // Sky Blue, for general routes
    "rgba(255, 99, 132, 1)",   // Pink, for standout paths
    "rgba(153, 102, 255, 1)",  // Light Purple, for variety
    "rgba(75, 192, 192, 1)",   // Teal, for a soft contrast
    "rgba(201, 203, 207, 1)",  // Light Grey, for subtle routes
    "rgba(255, 159, 64, 1)",   // Light Orange, for easy visibility
    "rgba(0, 140, 255, 1)",    // Medium Blue, for standard routes
    "rgba(100, 149, 237, 1)",  // Cornflower Blue, for a pleasant tone
    "rgba(144, 238, 144, 1)",  // Light Green, for eco-friendly paths
    "rgba(255, 105, 180, 1)",  // Hot Pink, for attention-grabbing routes
    "rgba(255, 215, 0, 1)",    // Gold, for important routes
    "rgba(173, 216, 230, 1)",  // Light Blue, for soft contrast
    "rgba(0, 206, 209, 1)",    // Dark Turquoise, for distinct water routes
  ];





  export const getLinePaint = (valueMainTab:number) => {
    if (valueMainTab === 1) {
      // For municipalities: light green, visible at zoom level 12, width of 2, and dotted
      return {
        'line-color': [
          'case',
          ['boolean', ['feature-state', 'hover'], false],
          ['match',
          ['get', 'activityId'],
          ...Object.keys(activityColors).flatMap(key => [parseInt(key, 10), activityColors[key].replace('0.7', '1')]),
          'rgba(102, 136, 114, 0.7)'], // Width when hovered
          ['match',
          ['get', 'activityId'],
          ...Object.keys(activityColors).flatMap(key => [parseInt(key, 10), activityColors[key]]),
          'rgba(102, 136, 114, 0.7)'] // default color
        ],
        'line-width': [
          'case',
          ['boolean', ['feature-state', 'hover'], false],
          6, // Width when hovered
          ['case', // Existing conditional width logic here
            ['has', 'activityId'], 4,
            1
          ]
        ],
        'line-opacity': ['step', ['zoom'], 0, 9, 1],
        'line-dasharray': [
          'case',
          ['has', 'activityId'],
          ['literal', [1]], // Solid line for routes with activityId
          ['literal', [10, 8]] // Dotted line for default
        ]
      };
    } else {
      // For blogs: use activityColors mapping
      return {
        'line-color': ['get', 'lineColor'],

        'line-width': [
          'case',
                    ['boolean', ['feature-state', 'hover'], false],
                    5,
                    3
        ],
        'line-opacity': [
          'case',
          ['boolean', ['feature-state', 'hover'], false],
          1,
          0.05
        ],
        'line-dasharray': [1] // Solid line
      };
    }
  };

  export const getLinePaintTransport = (is3d = false) => {
    const defaultColor = is3d ? 'rgba(255, 0, 0, 0.7)' : 'rgba(102, 136, 114, 0.7)'; // Replace 'bright red' with an actual RGBA or hex value if needed

    // Assuming transportationColors is an object where keys are transport types as strings
    return {
      'line-color': [
        'match',
        ['get', 'transport'],
        ...Object.keys(transportationColors).flatMap(key => [key, transportationColors[key]]),
        defaultColor // default color
      ],
      'line-width': 4,
      'line-opacity': 1,
      'line-dasharray': [1] // solid line
    };
  }
  export const getLinePaintDirection = (is3d = false) => {
    const defaultColor = is3d ? 'rgba(255, 0, 0, 0.7)' : 'rgba(102, 136, 114, 0.7)'; // Replace 'bright red' with an actual RGBA or hex value if needed

    // Assuming transportationColors is an object where keys are transport types as strings
    return {
      'text-color': [
        'match',
        ['get', 'transport'],
        ...Object.keys(transportationColorsDirection).flatMap(key => [key, transportationColorsDirection[key]]),
        defaultColor // default color
      ],
    };
  }

