import {
  FunctionComponent,
  useState,
  useContext,
  useEffect,
  useRef,
} from "react";
import SlowTravLogo from "./Logo";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import {
  Autocomplete,
  TextField,
  List,
  ListItem,
  ListItemAvatar,
  Avatar,
  InputAdornment
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import { CiSearch } from "react-icons/ci";
import {Card, CardBody, Button} from "@nextui-org/react";
import { MdOutlineFeedback } from "react-icons/md";

import SizesmallStyleStrokeShap from "../SizesmallStyleStrokeShap";
import UserDropdown from "./UserDropdown";
import LanguageSwitcher from "../LanguageSwitcher";

import { AuthContext } from "../authentification/AuthContext";
import { searchUsers } from "../../utils/api/api";

const Navbar: FunctionComponent<any> = ({ scrollToNextSection = null }) => {
  const { t, i18n } = useTranslation();
  const authContext = useContext(AuthContext);
  const isAuthenticated = authContext?.isAuthenticated || false;
  const { user, logout } = authContext || {};
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const dropdownRef = useRef<HTMLDivElement>(null);
  const dropdownRef2 = useRef<HTMLDivElement>(null);
  const [searchQuery, setSearchQuery] = useState("");
  const [searchResults, setSearchResults] = useState<any[]>([]);

  const navigate = useNavigate();

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (
        dropdownRef.current &&
        !dropdownRef.current.contains(event.target as Node) &&
        !dropdownRef2.current?.contains(event.target as Node)
      ) {
        setIsMenuOpen(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [dropdownRef]);

  const handleSearch = async (query: string) => {
    console.log(`Searching ${query}`)
    setSearchQuery(query);
    if (query.length > 2) {
      try {
        const results = await searchUsers(query);
        setSearchResults(results);
      } catch (error) {
        console.error("Error searching users:", error);
      }
    } else {
      setSearchResults([]);
    }
  };

  const navigateToAuthorBlogs = (authorId: any) => {
    navigate(`/${authorId}`);
  };

  const handleOnClick = (id: any) => {
    if (scrollToNextSection) {
      scrollToNextSection(id);
    } else {
      // Navigate to home and attempt to scroll to 'product-showcase' section
      navigate("/");
      setTimeout(() => {
        const showcaseElement = document.getElementById(id);
        if (showcaseElement) {
          showcaseElement.scrollIntoView({ behavior: "smooth" });
        }
      }, 100); // Delay to allow for page load, adjust as necessary
    }
  };

  return (
    <div className="fixed flex flex-col w-full items-center z-[1000] shadow-lg">
      <header className="w-full h-[65px] sticky bg-primary-contrast flex flex-row items-center justify-center py-[20px] px-20 top-[0] [background:white] lg:pl-10 lg:pr-10 lg:box-border pl-6 pr-6 box-border">
        <div className="flex-1 flex flex-row items-center justify-between gap-2">
        <div ref={dropdownRef} onClick={toggleMenu}>
                <img
                  className="h-6 w-6 relative overflow-hidden shrink-0 cursor-pointer md:hidden block"
                  alt=""
                  src="/hamburger.svg"
                />
              </div>
          <Link to={`/`}>
            <SlowTravLogo
              dimension="/slostr-logo.svg"
              // dimensionText="/SlowTrav_icon.png"
              SlowTravLogoTextDecoration="none"
            />
          </Link>
          <div className="flex-1 flex flex-row items-center md:justify-center gap-[15px] justify-end">
            {/* Menu items */}
            <div className="flex-1 md:flex flex-row items-center justify-center gap-0 lg:gap-[20px] hidden">
              {/* {false ? ( */}
              {isAuthenticated ? (
                user?.is_municipal ? (
                  <Link to="/create-municipal-post">
                    <SizesmallStyleStrokeShap
                      buttonText={t("nav-create-municipal")}
                      buttonColor="primary"
                    />
                  </Link>
                ) : (
                  <>
                    <Link to="/map">
                      <SizesmallStyleStrokeShap
                        buttonText={t("nav-map")}
                        buttonColor="primary"
                      />
                    </Link>
                    <Link to={`/explore`} className="hidden lg:block">
                      <SizesmallStyleStrokeShap
                        buttonText={t("nav-explore")}
                        buttonColor="primary"
                      />
                    </Link>
                    <Link to={`/${user?.username}`}>
                      <SizesmallStyleStrokeShap
                        buttonText={t("user-trips")}
                        buttonColor="primary"
                      />
                    </Link>
                    <Link to="/feed" className="hidden xl:block">
                      <SizesmallStyleStrokeShap
                        buttonText={t("nav-feed")}
                        buttonColor="primary"
                      />
                    </Link>
                    <Link to="/add-adventure">
                      <SizesmallStyleStrokeShap
                        buttonText={t("nav-create")}
                        buttonColor="primary"
                      />
                    </Link>
                    <Link to="/feedback" className="hidden lg:block">
                      <MdOutlineFeedback color="indianred"
                      size={24}
                      />
                    </Link>
                  </>
                )
              ) : (
                <>
                  {/* <Link to="/about"> */}
                  <div onClick={() => handleOnClick("product-showcase")}>
                    <SizesmallStyleStrokeShap
                      buttonText={t("slostr-about")}
                      buttonColor="primary"
                    />
                  </div>
                  {/* </Link> */}
                  {/* <Link to="/how-it-works"> */}
                  <div onClick={() => handleOnClick("interviews")}>
                    <SizesmallStyleStrokeShap
                      buttonText={t("interviews")}
                      buttonColor="primary"
                    />
                  </div>
                  <div onClick={() => handleOnClick("about-us")}>
                    <SizesmallStyleStrokeShap
                      buttonText={t("about-us")}
                      buttonColor="primary"
                    />
                  </div>
                  <div onClick={() => handleOnClick("next-steps")}>
                    <SizesmallStyleStrokeShap
                      buttonText={t("slostr-next-nav")}
                      buttonColor="primary"
                    />
                  </div>
                  <div onClick={() => handleOnClick("faq")}>
                    <SizesmallStyleStrokeShap
                      buttonText={t("faq-nav")}
                      buttonColor="primary"
                    />
                  </div>
                  {/* <Link to="/map">
                      <SizesmallStyleStrokeShap
                        buttonText={t("nav-map")}
                        buttonColor="primary"
                      />
                    </Link> */}

                  {/* </Link> */}
                </>
              )}
                                  {/* <Link to="/#next-steps">
                    <img src={'https://slostr.s3.fr-par.scw.cloud/slostr-imgs/slostr/support.svg'} alt="Support Us Icon" style={{ width: '30px', height: '30px', marginLeft: '8px' }} />

                    </Link> */}
            </div>

            {/* Right-side items */}
            <div className="flex flex-row items-center justify-end gap-[9px] sm:gap-[12px]">

            {/* <Link to="/#next-steps">
                    <img src={'https://slostr.s3.fr-par.scw.cloud/slostr-imgs/slostr/support.svg'}  className={'min-w-[15px] sm:hidden'} alt="Support Us Icon" style={{width: '30px', height: '30px', marginLeft: '8px' }} />

                    </Link> */}
              {isAuthenticated ? (
              // {false ? (
                <div className="flex flex-row justify-center items-center">
                      <div className="relative flex-col h-10">
      <TextField
      className="rounded-lg"
        value={searchQuery}
        onChange={(e) => handleSearch(e.target.value)}
        placeholder={t("search-users")}
        variant="outlined"
        fullWidth
        size="small"
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <CiSearch />
            </InputAdornment>
          ),
        }}
      />

      {/* Render the search results as a list below the input field */}
      {searchQuery && searchResults.length > 0 && (
            <Card className="absolute top-12">
      <CardBody>
        <List >
          {searchResults.map((option) => (
            <ListItem
              key={option.id}
              button
              onClick={() => navigateToAuthorBlogs(option.username)}
            >
              <ListItemAvatar>
                <Avatar
                  src={option.profile_picture}
                  sx={{ width: 24, height: 24 }}
                />
              </ListItemAvatar>
              <p className="text-sm">{option.name}</p>
            </ListItem>
          ))}
        </List>
        </CardBody>
    </Card>
      )}
    </div>

                  <UserDropdown user={user} />
                </div>
              ) : (
                <>
                  <Link to="/login">
                    <SizesmallStyleStrokeShap
                      buttonText={t("nav-login")}
                      fillColor="primary"
                      buttonColor="primary"
                    />
                  </Link>
                  <Button variant="solid" color="primary" onClick={() => navigate("/signup")}>
                <p className="text-white">{t("nav-signup")}</p>
              </Button>
                </>
              )}
              <LanguageSwitcher />
              {/* {scriptLoaded && <div id="google_translate_element"></div>} */}
            </div>
          </div>
        </div>
      </header>
      <div
        ref={dropdownRef2}
        className={`${
          isMenuOpen
            ? "md:block flex flex-col justify-center w-full relative top-0 bg-white"
            : "hidden"
        }`}
      >
        {/* {false ? ( */}
        {isAuthenticated ? (
          <>
            <Link to="/map">
              <SizesmallStyleStrokeShap
                buttonText={t("nav-map")}
                buttonColor="primary"
              />
            </Link>
            <Link to={`/explore`}>
              <SizesmallStyleStrokeShap
                buttonText={t("nav-explore")}
                buttonColor="primary"
              />
            </Link>
            <Link to={`/${user?.username}`}>
              <SizesmallStyleStrokeShap
                buttonText={t("user-trips")}
                buttonColor="primary"
              />
            </Link>

            <Link to="/create-blog-post">
              <SizesmallStyleStrokeShap
                buttonText={t("nav-create")}
                buttonColor="primary"
              />
            </Link>
          </>
        ) : (
          <div className="flex flex-col gap-4 p-4">
              <div onClick={() => handleOnClick("product-showcase")}>
              {t("slostr-about")}
              </div>
              <div onClick={() => handleOnClick("interviews")}>
              {t("interviews")}
              </div>
              <div onClick={() => handleOnClick("about-us")}>
              {t("about-us")}
              </div>
              <div onClick={() => handleOnClick("next-steps")}>
              {t("slostr-next-nav")}
              </div>
              <div onClick={() => handleOnClick("faq")}>
              {t("faq-nav")}
              </div>
            {/* <div onClick={() => navigate("/map")}>
                {t("nav-map")}
              </div> */}
          </div>
        )}

        {/* {false ? ( */}
        {isAuthenticated ? (
          <Autocomplete
            freeSolo
            className="w-full p-2 box-border"
            size="small"
            options={searchResults.map((option) => option)}
            getOptionLabel={(option: any) => option.name}
            onInputChange={(event, newInputValue) =>
              handleSearch(newInputValue)
            }
            renderInput={(params) => (
              <TextField
                {...params}
                label={t("search-users")}
                variant="outlined"
              />
            )}
            renderOption={(props, option: any) => (
              <ListItem
                {...props}
                key={option.id}
                onClick={() => navigateToAuthorBlogs(option.username)}
                onTouchEnd={() => navigateToAuthorBlogs(option.username)}
              >
                <ListItemAvatar>
                  <Avatar
                    src={option.profile_picture}
                    sx={{ width: 24, height: 24 }}
                  />
                </ListItemAvatar>
                <p className="text-sm">{option.name}</p>
              </ListItem>
            )}
          />
        ) : null}
      </div>
    </div>
  );
};

export default Navbar;
