import React, {
  FunctionComponent,
  useState,
  useEffect,
  useContext,
  useRef,
  useCallback
} from "react";
import Button from "@mui/material/Button";
import { useNavigate, UNSAFE_NavigationContext as NavigationContext } from 'react-router-dom';
import TextField from "@mui/material/TextField";
import Slider from "@mui/material/Slider";
import CircularProgress from "@mui/material/CircularProgress";
import { useTranslation } from "react-i18next";
import VisibilityTooltip from "./VisibilityTooltip";
import Typography from "@mui/material/Typography";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import Box from "@mui/material/Box";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Alert from "@mui/material/Alert";
import AddIcon from "@mui/icons-material/Add";
import HelpOutlineIcon from "@mui/icons-material/HelpOutline";
import IconButton from "@mui/material/IconButton";
import Tooltip from "@mui/material/Tooltip";
import {Tooltip as TooltipUI} from "@nextui-org/tooltip";
import ClickAwayListener from "@mui/material/ClickAwayListener";
import LinearProgress from "@mui/material/LinearProgress";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import MapIcon from "@mui/icons-material/Map";
import ContentPasteIcon from "@mui/icons-material/ContentPaste";
import Accordion from "@mui/material/Accordion";
import AccordionDetails from "@mui/material/AccordionDetails";
import AccordionSummary from "@mui/material/AccordionSummary";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import CropOriginalIcon from "@mui/icons-material/CropOriginal";
import AllInclusiveIcon from "@mui/icons-material/AllInclusive";
import SwapHorizIcon from "@mui/icons-material/SwapHoriz";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Switch from "@mui/material/Switch";
import { FaMapMarkerAlt } from "react-icons/fa";
import { TbRoute } from "react-icons/tb";
import TitleIcon from "@mui/icons-material/Title";
import { ScrollShadow, Pagination } from "@nextui-org/react";

import {
  timeToSliderValue,
  sliderValueToTime,
  calculateLabel,
  getMarks,
} from "../../utils/utils";

import {
  createOrUpdateBlog,
  fetchActivities,
  fetchBlogPOIs,
  uploadBlogImages,
} from "../../utils/api/api";
import {
  Feature,
  FeatureCollection,
  GeoJsonProperties,
  Geometry,
  LineString,
} from "geojson";
import { AuthContext } from "../authentification/AuthContext";
import {
  convertArrayBufferToBase64,
  createChapterGeoJSONFeatureCollection,
  getLocalizedField,
} from "../../utils/utils";
import { CustomTabPanel, a11yProps } from "../settings/CustomTabPanel";
import MultiInput from "../inputs/Multiinput";
import TextEditor from "./TextEditor";
import SpotifySongSelector from "../inputs/MusicSearch";
import NewMapboxMapEdit from "../map/NewMapEdit";
import ImageUploader from "../inputs/ImageUploader";
import RouteAdd from "../inputs/RouteAdd";
import PoiAdd from "../inputs/PoiAdd";
import DatePickerValue from "../inputs/DateInput";
import DraggableChapterList from "./DragChapters";
import AddSummary from "./AddSummary";

const EMPTY_FEATURE_COLLECTION: FeatureCollection<Geometry> = {
  type: "FeatureCollection",
  features: [],
};

const sanitizeChapters = (chapters: any) => {
  return chapters.map((chapter: any) => ({
    title: chapter.title,
    content: chapter.content,
    chapter_poi: chapter.chapter_poi,
    images: chapter.images,
    videos: chapter.videos,
    route_segments: chapter.route_segments,
    song: chapter.song,
    // videos: chapter.videos,
    // Exclude images and route_segments
  }));
};




const NewBlogWritingContent: FunctionComponent<any> = ({
  blog,
  map,
  setMap,
  mapContainerRef,
  allLayers,
  setAllLayers,
  allMarkers,
  setAllMarkers,
  mapAnimation,
  setMapAnimation,
}) => {
  const { t } = useTranslation();
  const authContext = useContext(AuthContext);
  const { user } = authContext || {};
  const [blogContent, setBlogContent] = useState(blog?.content || "");
  const [title, setTitle] = useState(
    () => JSON.parse(localStorage.getItem("title") || '""') || blog?.title || ""
  );
  const [summary, setSummary] = useState(
    () =>
      JSON.parse(localStorage.getItem("summary") || '""') || blog?.summary || ""
  );
  const [posNegs, setPosNegs] = useState(
    () =>
      JSON.parse(localStorage.getItem("posNegs") || '""') || blog?.pos_negs || ""
  );
  const [author, setAuthor] = useState(user?.id);
  const [headerImage, setHeaderImage] = useState(
    () =>
      JSON.parse(localStorage.getItem("headerImage") || "[]") ||
      (blog?.header_image ? [blog.header_image] : [])
  );

  const [isFormValid, setIsFormValid] = useState<boolean>(false);
  const navigate = useNavigate();
  const [privateBlog, setPrivateBlog] = useState(
    () => localStorage.getItem("privateBlog") || "private"
  );
  const [isLoading, setIsLoading] = useState<boolean | null>(false);
  const [activityOptions, setActivityOptions] = useState<any[]>([]);
  const [poisOptions, setPoisOptions] = useState<any[]>([]);
  const [selectedActivities, setSelectedActivities] = useState<any[]>(() =>
    JSON.parse(localStorage.getItem("selectedActivities") || "[]")
  );
  const [lineStringFeature, setLineStringFeature] = useState<Geometry | null>(
    null
  );
  const [mapInstance, setMapInstance] = useState<mapboxgl.Map | null>(null);
  const [selectedDaysRange, setSelectedDaysRange] = useState(() =>
    JSON.parse(localStorage.getItem("selectedDaysRange") || "[0]")
  );

  const [activeStep, setActiveStep] = useState(0);
  const [returnJourney, setReturnJourney] = useState(false);
  const [skipped, setSkipped] = useState(new Set<number>());
  // const [chapters, setChapters] = useState<any[]>([
  //   {
  //     title: "",
  //     content: "",
  //     route_segments: [],
  //     chapter_poi: [],
  //     images: [],
  //     videos: [],
  //   },
  // ]);
  const [chapters, setChapters] = useState<any[]>(() => {
    try {
      const storedChapters = JSON.parse(localStorage.getItem("chapters")!);
      if (storedChapters) {
        return storedChapters.map((chapter: any) => ({
          ...chapter,
          route_segments: chapter.route_segments || [],
          chapter_poi: chapter.chapter_poi || [],
          images: chapter.images || [],
          videos: chapter.videos || [],
          is_return: false,
        }));
      }
      return [
        {
          title: `${t("chapter")} - 1`,
          content: "",
          route_segments: [],
          chapter_poi: [],
          images: [],
          videos: [],
          song: "",
          is_return: false,
        },
      ];
    } catch (error) {
      console.error("Error parsing chapters from localStorage:", error);
      return [
        {
          title: `${t("chapter")} - 1`,
          content: "",
          route_segments: [],
          chapter_poi: [],
          images: [],
          videos: [],
          song: "",
          is_return: false,
        },
      ];
    }
  });

  const [currentChapterIndex, setCurrentChapterIndex] = useState(0);
  const [tooltipOpen, setTooltipOpen] = useState(false);
  const [nextOpen, setNextOpen] = useState(false);
  const [creationExplanation, setCreationExplanation] = useState(true);

  const [uploadProgress, setUploadProgress] = useState(0);
  const [uploadedBytes, setUploadedBytes] = useState(0);
  const [totalBytes, setTotalBytes] = useState(0);

  const [selectedGoogleRoute, setSelectedGoogleRoute] = useState<any>(null);
  const [methodUsed, setMethodUsed] = useState<any>(null);
  const [selectedTransportMode, setSelectedTransportMode] = useState("");
  const [newCoord, setNewCoord] = useState<any>(null);
  const [routes, setRoutes] = useState<any>(null);
  const [routeSearchOpen, setRouteSearchOpen] = useState<any>(false);
  const [poiSearchOpen, setPoiSearchOpen] = useState<any>(false);
  const [poiChange, setPoiChange] = useState<any>(null);
  const [isBlogOpen, setIsBlogOpen] = useState(false);
  const [switchChapters, setSwitchChapters] = useState(false);
  const [statsAlert, setStatsAlert] = useState<any>(null);
  const [alertVisible, setAlertVisible] = useState(false);
  const [expandedAccordion, setExpandedAccordion] = React.useState<
    string | false
  >(false);

  const steps = [t("trip-overview"), t("trip-details"), t("trip-chapters")];
  const marks = getMarks(t);

  useEffect(() => {
    const handleBeforeUnload = (event) => {
      // This displays the default browser prompt
      event.preventDefault();
      event.returnValue = ''; // Most browsers will show a default message
    };

    // Add the event listener to block page unload
    window.addEventListener('beforeunload', handleBeforeUnload);

    // Clean up the event listener on component unmount
    return () => {
      window.removeEventListener('beforeunload', handleBeforeUnload);
    };
  }, []);

  useEffect(() => {
    localStorage.setItem("title", JSON.stringify(title));
    localStorage.setItem("summary", JSON.stringify(summary));
    localStorage.setItem("posNegs", JSON.stringify(posNegs));
    localStorage.setItem("headerImage", JSON.stringify(headerImage));
    localStorage.setItem("privateBlog", privateBlog);
    localStorage.setItem(
      "selectedDaysRange",
      JSON.stringify(selectedDaysRange)
    );
    localStorage.setItem(
      "selectedActivities",
      JSON.stringify(selectedActivities)
    );
    const sanitizedChapters = sanitizeChapters(chapters);
    localStorage.setItem("chapters", JSON.stringify(sanitizedChapters)); // Add other states to local storage as needed
  }, [
    title,
    summary,
    posNegs,
    headerImage,
    privateBlog,
    selectedDaysRange,
    selectedActivities,
    chapters,
  ]);
  // Function to clear all stored data
  const clearStorage = () => {
    localStorage.removeItem("title");
    localStorage.removeItem("summary");
    localStorage.removeItem("posNegs");
    // localStorage.removeItem('headerImage');
    localStorage.removeItem("privateBlog");
    localStorage.removeItem("selectedDaysRange");
    localStorage.removeItem("selectedActivities");
    localStorage.removeItem("chapters");
  };

  const handleProgress = (progress: any, loaded: any, total: any) => {
    setUploadProgress(progress);
    setUploadedBytes(loaded);
    setTotalBytes(total);
  };

  const handleTooltipClose = () => {
    setTooltipOpen(false);
  };
  const handleTooltipOpen = () => {
    setTooltipOpen(!tooltipOpen);
  };
  const handleNextClose = () => {
    setNextOpen(false);
  };
  const handleNextOpen = () => {
    setNextOpen(!nextOpen);
  };

  const updateHeaderImage = (image: any) => {
    setHeaderImage(image);
  };
  const handlePosNegsChange = (text) => setPosNegs(text);

  const addNewChapter = () => {
    setChapters([
      ...chapters,
      {
        title: `${t("chapter")} - ${chapters.length + 1}`,
        content: "",
        route_segments: [],
        chapter_poi: [],
        images: [],
        videos: [],
        song: "",
        is_return: false,
      },
    ]);
    setCurrentChapterIndex(chapters.length);
  };
  const removeChapter = () => {
    const confirmation = window.confirm(t("remove-chapter"));

    if (confirmation) {
      // Use filter to remove the chapter at the specified index
      setChapters(chapters.filter((_, index) => index !== currentChapterIndex));
      setCurrentChapterIndex(0);
    }
  };

  const updateCurrentChapter = (index: any, field: any, value: any) => {
    const newChapters: any = [...chapters];
    newChapters[index][field] = value;
    setChapters(newChapters);
  };

  const handleNext = (e: any) => {
    e.preventDefault();
    let newSkipped = skipped;

    setActiveStep((prevActiveStep) => prevActiveStep + 1);
    setSkipped(newSkipped);
  };

  const handleBack = (e: any) => {
    e.preventDefault();
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleReset = () => {
    setActiveStep(0);
  };

  const handleBlogVisibility = (
    event: React.MouseEvent<HTMLElement>,
    newVisibility: string | null
  ) => {
    if (newVisibility !== null) {
      setPrivateBlog(newVisibility);
    }
  };
  const handleTitleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setTitle(event.target.value);
  };
  const handleSummaryChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSummary(event.target.value);
  };

  const handleAccordionChange =
    (panel: string) => (event: React.SyntheticEvent, isExpanded: boolean) => {
      setExpandedAccordion(isExpanded ? panel : false);
    };

  useEffect(() => {
    const geocoderDivs = document.querySelectorAll(".mapboxgl-ctrl-geocoder");

    geocoderDivs.forEach((div) => {
      if (poiSearchOpen) {
        // @ts-ignore
        div.style.display = "block";
      } else {
        // @ts-ignore
        div.style.display = "none";
      }
    });
  }, [poiSearchOpen, mapInstance]);

  useEffect(() => {
    fetchActivities()
      .then((fetchedActivities) => {
        setActivityOptions(fetchedActivities);
      })
      .catch((error) => {
        console.error("Error fetching activities:", error);
        const customEvent = new CustomEvent("apiError", {
          detail: error.response,
        });
        window.dispatchEvent(customEvent);
      });
    fetchBlogPOIs()
      .then((fetchedPOIs) => {
        setPoisOptions(fetchedPOIs);
      })
      .catch((error) => {
        console.error("Error fetching pois:", error);
        const customEvent = new CustomEvent("apiError", {
          detail: error.response,
        });
        window.dispatchEvent(customEvent);
      });
  }, []);

  useEffect(() => {
    if (blog) {
      setTitle(blog.title || "");
      setSummary(blog.summary || "");
      setAuthor(user.id);
      setBlogContent(blog.content || "");
      setHeaderImage(blog.header_image ? [blog.header_image] : []);
      setPrivateBlog(blog.visibility || "public");
      setSelectedDaysRange([blog.number_of_days || 0]);
      setSelectedActivities(blog.activities || []);
      setPosNegs(blog.pos_negs);

      const updatedChapters = (blog.chapters || []).map((chapter: any) => ({
        ...chapter,
        images: transformMedia(chapter.images, chapter.videos),
      }));

      setChapters(updatedChapters);
    }
  }, [blog, user]);

  const transformMedia = (images = [], videos = []) => {
    return [...images, ...videos].map((media: any) => {
      return {
        preview: media.image_file
          ? media.image_file
          : media.video_file
          ? media.video_file
          : media.image_url
          ? media.image_url
          : media.video_url,
        image_url: media.image_file
          ? media.image_file
          : media.video_file
          ? media.video_file
          : media.image_url
          ? media.image_url
          : media.video_url,
        type: media.video_file ? "video" : media.video_url ? "video" : "image",
        caption: media.caption || "",
      };
    });
  };

  useEffect(() => {
    const isTitleValid = title.length > 0 && title.length <= 255;
    // const isSummaryValid = summary.length > 0 && summary.length <= 255;
    const isActivityValid = selectedActivities.length > 0;
    setIsFormValid(isTitleValid && isActivityValid);
  }, [title, summary, lineStringFeature, selectedActivities]);

  const handleActivitiesChange = (activities: any[]) => {
    setSelectedActivities(activities);
  };

  const deleteFeatureFromChapter = (featureIndex: any, featureType: any) => {
    // Clone the current state to avoid direct mutation
    const updatedChapters = [...chapters];

    // Find the chapter from which we want to delete the feature
    const chapter = updatedChapters[currentChapterIndex];

    if (featureType === "LineString") {
      // If it's a LineString, we assume it's part of route_segments
      chapter.route_segments = chapter.route_segments.filter(
        (_: any, index: any) => index !== featureIndex
      );
    } else if (featureType === "Point") {
      // If it's a Point, we assume it's part of chapter_poi
      chapter.chapter_poi = chapter.chapter_poi.filter(
        (_: any, index: any) => index !== featureIndex
      );
    }

    // Update the state with the modified chapters array
    setChapters(updatedChapters);
  };

  const handleSubmit = async (e: any) => {
    e.preventDefault();
    setIsLoading(true);

    const transformedChapters = (with_media: any) =>
      chapters.map((chapter) => {
        const routeSegments = chapter.route_segments.map((feature: any) => {
          const transportationName =
            feature.properties?.transportation?.name ||
            feature.transportation?.name ||
            "defaultTransportationName";
          const elevationProfile = feature.properties?.elevation_profile
            ? feature.properties.elevation_profile
            : feature.elevation_profile
            ? feature.elevation_profile
            : null;
          return {
            id: feature.id || null,
            transportation: { name: transportationName },
            elevation_profile: feature.id ? null : elevationProfile,
            geometry: feature.id ? null : feature.geometry,
          };
        });
        const POIs = chapter.chapter_poi.map((feature: any) => {
          // Transform the chapter images into the expected format
          const poiImages = feature.images.map((img: any) => ({
            id: img.id || null,
            file: img.file,
            image_url: img.image_url,
            type: img.type,
          }));

          return {
            // chapter: feature.chapter,
            id: feature.id || null,
            content: with_media ? null : feature.content,
            title: with_media ? null : feature.title,
            poi: with_media ? null : { name: feature.poi.name },
            geometry: with_media ? null : feature.geometry,
            images: with_media ? poiImages : [],
          };
        });

        // Transform the chapter images into the expected format
        const chapterImages = chapter.images.map((img: any) => ({
          id: img.id || null,
          file: img.file,
          image_url: img.image_url,
          type: img.type,
          caption: img.caption,
        }));

        return {
          ...chapter,
          // route_segments: routeSegments,
          // chapter_poi: POIs,
          // images: with_media ? chapterImages,
          route_segments: with_media ? null : routeSegments,
          chapter_poi: POIs,
          images: with_media ? chapterImages : [],
          videos: [],
        };
      });

    try {
      const blogData = {
        id: blog ? blog.id : null,
        title,
        author: user.id,
        headerImage: headerImage.length > 0 ? headerImage[0].file : null,
        summary,
        pos_negs: posNegs,
        visibility: privateBlog,
        activities: selectedActivities.map((activity) => activity.id),
        number_of_days: selectedDaysRange[0],
        chapters: transformedChapters(false),
      };

      let newBlog = null;

      newBlog = await createOrUpdateBlog(blogData);

      const blogDataImgs = {
        id: newBlog.id,
        // title,
        // author: user.id,
        headerImage: headerImage.length > 0 ? headerImage[0].file : null,
        // summary,
        // visibility: privateBlog,
        // activities: selectedActivities.map((activity) => activity.id),
        // number_of_days: selectedDaysRange[0],
        chapters: transformedChapters(true),
      };

      await uploadBlogImages(newBlog, blogDataImgs, handleProgress);

      clearStorage();

      setIsLoading(false);
      navigate(`/${user.username}/${newBlog.id}`);
    } catch (error) {
      console.error("Error saving blog:", error);
      const customEvent = new CustomEvent("apiError", { detail: error });
      window.dispatchEvent(customEvent);
      setIsLoading(false);
    }
  };
  useEffect(() => {
    if (statsAlert) {
      setAlertVisible(true);
      const timer = setTimeout(() => {
        setAlertVisible(false);
        setStatsAlert(null);
      }, 6000);

      return () => clearTimeout(timer);
    }
  }, [statsAlert, setStatsAlert]);

  const handleCopyClick = () => {
    const textToCopy = `↔ ${statsAlert.distance}km ➚ ${statsAlert.ascent}m ➘ ${statsAlert.descent}m`;

    navigator.clipboard
      .writeText(textToCopy)
      .then(() => {
        // Clear the statsAlert state after copying
        setStatsAlert(null);
      })
      .catch((err) => {
        console.error("Failed to copy text: ", err);
      });
  };

  const getStepContent = (stepIndex: any) => {
    switch (stepIndex) {
      case 0:
        return (
          <div className="w-full h-full flex flex-col items-center overflow-auto max-w-5xl justify-start">
            <div className="w-full flex justify-center relative max-h-[300px] bg-gradient-to-b from-slate-300 to-transparent">
              <h1
                className={`z-10  text-slate-500 tracking-tight font-roboto font-normal  my-1 md:my-6 pb-4 absolute top-4 w-full text-center`}
              >
                {t("create-new")}{" "}
                <span className="text-primary">{t("trip")}</span>
              </h1>

              <ImageUploader
                multiple={false}
                initialImages={headerImage}
                onImageChange={updateHeaderImage}
              />
            </div>

            <div className="w-full mt-2  box-border flex flex-col md:flex-row items-center justify-center gap-6">
              <div className="md:w-full text-secondary-500 flex flex-col md:flex-row gap-6">
                <div className="flex flex-col w-full gap-6">
                  <div className="">
                    <p className="mb-2">{t("side-visibility")}</p>
                    <VisibilityTooltip
                      value={privateBlog}
                      onChange={handleBlogVisibility}
                    />
                  </div>
                  <TextField
                    id="standard-multiline-flexible"
                    label={t("create-title")}
                    multiline
                    maxRows={4}
                    variant="outlined"
                    className="w-full"
                    value={title}
                    onChange={handleTitleChange}
                    helperText={`${title.length}/75`}
                    inputProps={{ maxLength: 75 }}
                  />
                </div>
                <div className="w-full mt-7">
                  <TextField
                    id="standard-multiline-flexible"
                    label={t("create-summary")}
                    multiline
                    rows={4}
                    variant="outlined"
                    className="w-full h-24"
                    value={summary}
                    onChange={handleSummaryChange}
                    helperText={`${summary.length}/255`}
                    inputProps={{ maxLength: 255 }}
                  />
                </div>
              </div>
            </div>
            <div className="w-full pb-[90px]">
              <div className="w-full box-border gap-2 justify-center items-center">
                <h5
                  className={`tracking-tight font-roboto font-normal flex flex-row mt-8 py-0 mb-2 items-center`}
                >
                  <ClickAwayListener onClickAway={handleTooltipClose}>
                    <div>
                      <Tooltip
                        PopperProps={{
                          disablePortal: true,
                        }}
                        onClose={handleTooltipClose}
                        open={tooltipOpen}
                        arrow
                        disableFocusListener
                        disableHoverListener
                        disableTouchListener
                        title={
                          <p className="text-md font-msr ">
                            {t("create-details-info")}
                          </p>
                        }
                      >
                        <IconButton className="" onClick={handleTooltipOpen}>
                          <HelpOutlineIcon />
                        </IconButton>
                      </Tooltip>
                    </div>
                  </ClickAwayListener>
                  {t("create-add")}{" "}
                  <span className="text-primary whitespace-break-spaces">
                    {" "}
                    {t("details")}
                  </span>
                </h5>
              </div>
              <div className="box-border flex flex-col md:flex-row gap-6">
                <div className="w-full">
                  <p className="font-roboto text-md text-secondary-500 pb-2">
                    {t("create-activities")}
                  </p>

                  <MultiInput
                    activityOptions={activityOptions}
                    onActivitiesChange={handleActivitiesChange}
                    defaultOptions={selectedActivities}
                    placeholder={t("create-activities")}
                  />
                </div>
                <div className="px-4 w-full">
                  <p className="font-roboto text-md text-secondary-500">
                    {t("create-duration")}
                  </p>

                  <Slider
                    getAriaLabel={() => `${t("create-private")}`}
                    value={timeToSliderValue(selectedDaysRange[0])}
                    valueLabelDisplay="auto"
                    valueLabelFormat={(e) => calculateLabel(e, t)}
                    marks={marks}
                    min={timeToSliderValue(0)}
                    max={timeToSliderValue(365)}
                    step={1}
                    onChange={(_, newValue) => {
                      const days = typeof newValue === "number" ? newValue : 0;
                      setSelectedDaysRange([
                        Math.round(sliderValueToTime(days)),
                      ]);
                    }}
                  />
                </div>
              </div>
            </div>
          </div>
        );
      case 1:
        return (
          <div className="w-full overflow-auto">
            <AddSummary posNegs={posNegs} editPosNegs={handlePosNegsChange} />
          </div>
        );
      case 2:
        return (
          <div className="w-screen h-full overflow-auto">
            <Box sx={{ width: "100%", height: "100%" }}>

                <>
                  <div className="absolute top-0 w-[calc(50%-60px)] flex flex-col justify-center pb-3 mt-6">
                    <ScrollShadow
                      orientation="horizontal"
                      className="z-10 relative left-2 max-h-[150px]"
                    >
                      <Box
                        display="flex"
                        flexDirection="row"
                        gap={2}
                        className="w-fit relative rounded-lg py-2 pl-2"
                      >
                        {chapters.map((chapter, index) => (
                          <div
                            key={index}
                            className={`relative w-[150px] h-[80px] rounded-lg cursor-pointer ${
                              currentChapterIndex === index
                                ? "ring-2 ring-secondary"
                                : ""
                            }`}
                            onClick={() => setCurrentChapterIndex(index)}
                          >
                            {chapter.images[0] ? (
                              <img
                                className="rounded-lg w-full h-full object-cover"
                                loading="lazy"
                                alt=""
                                src={
                                  chapter.images[0]
                                    ? `${
                                        chapter.images[0].preview ||
                                        chapter.images[0].image_url ||
                                        chapter.images[0].image_file
                                      }`
                                    : "/welcome_img.png"
                                }
                              />
                            ) : (
                              <div className="w-full h-full bg-slate-50/90 rounded-lg"></div>
                            )}
                            <div className="absolute inset-0 bg-black bg-opacity-30 rounded-lg flex items-center justify-center">
                              <h3 className="text-white text-sm font-semibold px-2 text-center">
                                {getLocalizedField(chapter, "title", null, true)
                                  .length > 20
                                  ? getLocalizedField(
                                      chapter,
                                      "title",
                                      null,
                                      true
                                    ).slice(0, 20) + "..."
                                  : getLocalizedField(
                                      chapter,
                                      "title",
                                      null,
                                      true
                                    )}
                              </h3>
                            </div>
                          </div>
                        ))}

                        {/* Add Chapter Card */}
                        <div
                          className="relative w-[150px] h-[80px] rounded-lg flex items-center justify-center cursor-pointer border-2 border-dashed bg-slate-100 border-slate-300 hover:border-primary transition"
                          onClick={addNewChapter}
                        >
                          <AddIcon fontSize="large" color="primary" />
                        </div>
                        {/* Optional Swap Button */}
                        {chapters.length > 1 && (
                          <div
                            className="relative w-[70px] h-[80px] rounded-lg flex items-center justify-center cursor-pointer border-2 border-dashed bg-slate-100 border-slate-300 hover:border-primary transition"
                            onClick={() => setSwitchChapters(!switchChapters)}
                          >
                            <SwapHorizIcon fontSize="large" color="secondary" />
                          </div>
                          // <Button
                          //   onClick={() => setSwitchChapters(!switchChapters)}
                          //   variant="text"
                          //   color="success"
                          //   startIcon={<SwapHorizIcon />}
                          //   size="large"
                          //   className="mt-2"
                          // >
                          //   {t("switch-chapters")}
                          // </Button>
                        )}
                      </Box>
                    </ScrollShadow>

                    {/* Pagination
      <div className="flex w-full justify-center mt-4 z-50">
        <Pagination
          total={chapters.length}
          page={currentChapterIndex}
          onChange={(e) => setCurrentChapterIndex(e)}
          color="primary"
          size="sm"
        />
      </div> */}
                  </div>
                  <div className="w-full h-full flex flex-col md:flex-row">
                    {switchChapters && (
                      <DraggableChapterList
                        chapters={chapters}
                        setChapters={setChapters}
                        setSwitchChapters={setSwitchChapters}
                        currentChapterIndex={currentChapterIndex}
                        setCurrentChapterIndex={setCurrentChapterIndex}
                      />
                    )}
                    <div className="md:hidden w-full block relative pt-1 md:pt-0">
                      <div className=" bg-white box-border h-[35px] flex justify-center items-center flex-col">
                        <div className="w-full h-full flex flex-row">
                          <div
                            className={`w-1/2 h-full flex items-center justify-center ${
                              !isBlogOpen ? "bg-primary text-white" : ""
                            }`}
                            onClick={() => setIsBlogOpen(false)}
                          >
                            <MapIcon />
                          </div>
                          <div
                            className={`w-1/2 h-full flex items-center justify-center ${
                              isBlogOpen ? "bg-primary text-white" : ""
                            }`}
                            onClick={() => setIsBlogOpen(true)}
                          >
                            <ContentPasteIcon />
                          </div>
                        </div>
                      </div>
                    </div>
                    <div
                      className={`w-full h-[calc(100%-90px)] md:w-1/2 flex flex-col md:flex-row ${
                        isBlogOpen && "hidden md:flex"
                      }`}
                    >
                      <div
                        className={`w-full h-full flex justify-start flex-col relative`}
                      >
                        <div className="w-full h-full absolute">
                          <NewMapboxMapEdit
                            // key={JSON.stringify(chapters[currentChapterIndex].route_segments)}
                            mapFeatures={
                              methodUsed === "google"
                                ? routes || EMPTY_FEATURE_COLLECTION
                                : createChapterGeoJSONFeatureCollection(
                                    chapters[currentChapterIndex]
                                  )
                            }
                            chapterIndex={currentChapterIndex}
                            deleteFeature={deleteFeatureFromChapter}
                            setMapInstance={setMapInstance}
                            mapInstance={mapInstance}
                            methodUsed={methodUsed}
                            selectedRouteIndex={selectedGoogleRoute}
                            setSelectedRouteIndex={setSelectedGoogleRoute}
                            transportationMethod={selectedTransportMode}
                            setNewCoord={setNewCoord}
                            map={map}
                            setMap={setMap}
                            mapContainerRef={mapContainerRef}
                            allLayers={allLayers}
                            setAllLayers={setAllLayers}
                            allMarkers={allMarkers}
                            setAllMarkers={setAllMarkers}
                            setPoiChange={setPoiChange}
                            mapAnimation={mapAnimation}
                            setMapAnimation={setMapAnimation}
                          />
                          {!routeSearchOpen && !poiSearchOpen && !poiChange && (
                            <div className="absolute flex flex-col items-end justify-center gap-4 box-border p-3 z-[9] bottom-4 right-0 h-full transition">
                                  <TooltipUI showArrow={true} content={t("create-route")} placement='left'>

                                            <IconButton aria-label="delete" size="large" className="!bg-primary !shadow-xl transition hover:scale-95 ring-2 ring-slate-100" onClick={() => {
                                  setRouteSearchOpen(true);
                                  setIsBlogOpen(true);
                                }}>
        <TbRoute color="white"/>
      </IconButton>
      </TooltipUI>
      <TooltipUI showArrow={true} content={t("create-poi")} placement='left'>

      <IconButton aria-label="delete" size="large" className="!bg-primary !shadow-xl transition hover:scale-95 ring-2 ring-slate-100" onClick={() => {
                                  setPoiSearchOpen(true);
                                  setIsBlogOpen(true);
                                }}>
        <FaMapMarkerAlt color="white"/>
      </IconButton>
      </TooltipUI>

                              {/* <Button
                                variant="contained"
                                fullWidth
                                size="large"
                                startIcon={<TbRoute />}
                                onClick={() => {
                                  setRouteSearchOpen(true);
                                  setIsBlogOpen(true);
                                }}
                              >
                                {t("create-route")}
                              </Button>
                              <Button
                                variant="contained"
                                fullWidth
                                size="large"
                                startIcon={<FaMapMarkerAlt />}
                                onClick={() => {
                                  setPoiSearchOpen(true);
                                  setIsBlogOpen(true);
                                }}
                              >
                                {t("create-poi")}
                              </Button> */}
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                    <div
                      className={`w-full md:w-1/2 overflow-auto h-[calc(100%-80px)] ${
                        !isBlogOpen && "hidden md:flex"
                      }`}
                    >
                      {routeSearchOpen ? (
                        <RouteAdd
                          chapters={chapters}
                          currentChapterIndex={currentChapterIndex}
                          setChapters={setChapters}
                          setMethodUsed={setMethodUsed}
                          methodUsed={methodUsed}
                          setRouteSearchOpen={setRouteSearchOpen}
                          mapInstance={map}
                          newCoord={newCoord}
                          setNewCoord={setNewCoord}
                          routes={routes}
                          setRoutes={setRoutes}
                          selectedRouteIndex={selectedGoogleRoute}
                          setSelectedRouteIndex={setSelectedGoogleRoute}
                          setStatsAlert={setStatsAlert}
                        />
                      ) : poiSearchOpen || poiChange ? (
                        <PoiAdd
                          chapters={chapters}
                          currentChapterIndex={currentChapterIndex}
                          setChapters={setChapters}
                          pois={poisOptions}
                          setPoiSearchOpen={setPoiSearchOpen}
                          mapInstance={map}
                          setNewCoord={setNewCoord}
                          newCoord={newCoord}
                          setPoiChange={setPoiChange}
                          poiChange={poiChange}
                        />
                      ) : (
                        <>
                          {chapters.map((chapter, index) => (
                            <CustomTabPanel
                              value={currentChapterIndex}
                              index={index}
                              key={index}
                            >
                              <div className="w-full h-full flex flex-col gap-2 px-4 pb-3">
                                <div className="md:px-5 flex flex-col w-full h-full justify-between shadow-lg rounded-lg py-2">
                                  <div className="overflow-auto pt-2">
                                    {statsAlert && (
                                      <Alert
                                        className={`static z-[100] w-full alert-fade ${
                                          alertVisible ? "" : "hide"
                                        }`}
                                        color="warning"
                                        severity="success"
                                        action={
                                          <IconButton
                                            color="inherit"
                                            size="small"
                                            onClick={handleCopyClick}
                                          >
                                            <ContentCopyIcon />
                                          </IconButton>
                                        }
                                      >
                                        <div className="w-full flex flex-row gap-3 justify-center items-center">
                                          <div>
                                            ↔{statsAlert.distance}km ➚{" "}
                                            {statsAlert.ascent}m ➘{" "}
                                            {statsAlert.descent}m
                                          </div>
                                        </div>
                                      </Alert>
                                    )}
                                    <ImageUploader
                                      multiple={true}
                                      initialImages={chapter.images}
                                      onImageChange={(images: any) =>
                                        updateCurrentChapter(
                                          index,
                                          "images",
                                          images
                                        )
                                      }
                                    />
                                    <Box
                                      sx={{
                                        display: "flex",
                                        alignItems: "center",
                                        width: "100%",
                                      }}
                                    >
                                      <TitleIcon
                                        sx={{
                                          color: "action.active",
                                          mr: 1,
                                          my: 0,
                                        }}
                                      />
                                      <TextField
                                        label={t("chapter-title")}
                                        variant="outlined"
                                        fullWidth
                                        size="small"
                                        className="!pb-3 !mt-3"
                                        value={chapter.title}
                                        onChange={(e) =>
                                          updateCurrentChapter(
                                            index,
                                            "title",
                                            e.target.value
                                          )
                                        }
                                      />
                                    </Box>
                                    {/* <TextField
                                    label={t("chapter-title")}
                                    variant="outlined"
                                    fullWidth
                                    size="small"
                                    className="!pb-3 !mt-3"
                                    value={chapter.title}
                                    onChange={(e) =>
                                      updateCurrentChapter(
                                        index,
                                        "title",
                                        e.target.value
                                      )
                                    }
                                  /> */}

                                    <FormGroup>
                                      <FormControlLabel
                                        control={
                                          <Switch
                                            checked={chapter.is_return}
                                            onChange={(e) => {
                                              updateCurrentChapter(
                                                index,
                                                "is_return",
                                                !chapter.is_return
                                              );
                                            }}
                                          />
                                        }
                                        label={t("return-journey")}
                                      />
                                    </FormGroup>
                                    <SpotifySongSelector
                                      chapterSong={chapter.song}
                                      setChapterSong={(song: any) =>
                                        updateCurrentChapter(
                                          index,
                                          "song",
                                          song
                                        )
                                      }
                                    />
                                    <div className="w-full py-3">
                                      <DatePickerValue
                                        selectedChapter={chapter}
                                        updateChapterDates={
                                          updateCurrentChapter
                                        }
                                        index={index}
                                      />
                                    </div>

                                    <TextEditor
                                      blogContent={chapter.content}
                                      setBlogContent={(content: any) =>
                                        updateCurrentChapter(
                                          index,
                                          "content",
                                          content
                                        )
                                      }
                                    />
                                  </div>
                                </div>
                              </div>
                            </CustomTabPanel>
                          ))}
                        </>
                      )}
                    </div>
                  </div>
                </>
            </Box>
          </div>
        );

      default:
        return "Unknown step";
    }
  };

  const isStepValid = () => {
    switch (activeStep) {
      case 0:
        // Check for title and summary
        // if (!(title.trim().length > 0) && !(summary.trim().length > 0)) {
        //   return { isValid: false, message: t("blog-error-title-summary") };
        // }
        if (!(title.trim().length > 0)) {
          return { isValid: false, message: t("blog-error-title") };
        }
        // if (!(summary.trim().length > 0)) {
        //   return { isValid: false, message: t("blog-error-summary") };
        // }

        if (!(selectedActivities.length > 0) && !(selectedDaysRange[0] > 0)) {
          return { isValid: false, message: t("blog-error-duration-activity") };
        }
        if (!(selectedDaysRange[0] > 0)) {
          return { isValid: false, message: t("blog-error-duration") };
        }
        if (!(selectedActivities.length > 0)) {
          return { isValid: false, message: t("blog-error-activity") };
        }
        return { isValid: true, message: "" };

      case 1:
        // Check for duration and activity selection
        if (!(selectedActivities.length > 0) && !(selectedDaysRange[0] > 0)) {
          return { isValid: false, message: t("blog-error-duration-activity") };
        }
        if (!(selectedDaysRange[0] > 0)) {
          return { isValid: false, message: t("blog-error-duration") };
        }
        if (!(selectedActivities.length > 0)) {
          return { isValid: false, message: t("blog-error-activity") };
        }
        return { isValid: true, message: "" };
      case 2:
        // Check each chapter for a title and at least one route_segment across all chapters
        const chapterWithoutTitle = chapters.find(
          (chapter) => !(chapter.title.trim().length > 0)
        );
        const hasRouteSegment = chapters.some(
          (chapter) => chapter.route_segments.length > 0
        );

        if (chapterWithoutTitle && !hasRouteSegment) {
          return { isValid: false, message: t("blog-error-chapter-route") };
        }

        if (chapterWithoutTitle) {
          return { isValid: false, message: t("blog-error-chapter") };
        }
        if (!hasRouteSegment) {
          return { isValid: false, message: t("blog-error-route") };
        }
        return { isValid: true, message: "" };
      default:
        return { isValid: false, message: "Invalid step" };
    }
  };

  return (
    <div className={`w-full `}>
      {isLoading && (
        <div className="spinner-background white-bg-spinner">
          <CircularProgress />
          <Box display="flex" alignItems="center">
            <div className="text-center">
              <span>
                {uploadProgress === 0
                  ? "Saving your trip"
                  : uploadProgress === 100
                  ? "Finalizing"
                  : "Saving all the media files"}
              </span>{" "}
              <br /> <br />
              <span>{`${uploadProgress}%`}</span>
            </div>
          </Box>

          <span>{t("create-saving")}</span>
        </div>
      )}

      <form
        className="flex justify-center items-center pt-[60px]"
        onSubmit={handleSubmit}
      >
        <div className="w-full h-[calc(100vh-60px)] flex flex-col items-center box-border	overflow-auto">
          <div className="w-full h-full flex flex-col justify-start relative">
            {/* <Stepper activeStep={activeStep} className="pb-3">
              {steps.map((label, index) => {
                const stepProps: { completed?: boolean } = {};
                const labelProps: {
                  optional?: React.ReactNode;
                } = {};
                return (
                  <Step key={label} {...stepProps}>
                    <StepLabel {...labelProps}>{label}</StepLabel>
                  </Step>
                );
              })}
            </Stepper> */}
            {activeStep === steps.length ? (
              <React.Fragment>
                <Typography sx={{ mt: 2, mb: 1 }}>
                  All steps completed - you&apos;re finished
                </Typography>
                <Box sx={{ display: "flex", flexDirection: "row", pt: 2 }}>
                  <Box sx={{ flex: "1 1 auto" }} />
                  <Button onClick={handleReset}>Reset</Button>
                </Box>
              </React.Fragment>
            ) : (
              <React.Fragment>
                <div className="w-full h-full flex flex-col justify-between overflow-hidden items-center">
                  {getStepContent(activeStep)}
                </div>

                {!routeSearchOpen && !poiSearchOpen && !poiChange && (
                <div className="absolute bottom-3 self-center w-full flex flex-row bg-white z-[99] p-3 shadow-xl rounded-lg border border-slate-300 border-solid max-w-5xl">
                  <Button
                    color="inherit"
                    disabled={activeStep === 0}
                    onClick={handleBack}
                    sx={{ mr: 1 }}
                  >
                    {t("back")}
                  </Button>
                  <Box sx={{ flex: "1 1 auto" }} />
                  {activeStep === steps.length - 1 ? (
                    isStepValid().isValid &&
                    !routeSearchOpen &&
                    !poiSearchOpen &&
                    !poiChange ? (
                      <Button
                        disabled={!isFormValid}
                        onClick={(e) => handleSubmit(e)}
                        type="submit"
                      >
                        {t("save")} {t("trip")}
                      </Button>
                    ) : (
                      !routeSearchOpen &&
                      !poiSearchOpen &&
                      !poiChange && (
                        <ClickAwayListener onClickAway={handleNextClose}>
                          <div>
                            <Tooltip
                              PopperProps={{
                                disablePortal: true,
                              }}
                              onClose={handleNextClose}
                              open={nextOpen}
                              arrow
                              disableFocusListener
                              disableHoverListener
                              disableTouchListener
                              title={isStepValid().message}
                            >
                              <Button
                                color="error"
                                onClick={handleNextOpen}
                                variant="text"
                              >
                                {t("save")} {t("trip")}
                              </Button>
                            </Tooltip>
                          </div>
                        </ClickAwayListener>
                      )
                    )
                  ) : isStepValid().isValid ? (
                    <Button onClick={handleNext}>{t("next")}</Button>
                  ) : (
                    <ClickAwayListener onClickAway={handleNextClose}>
                      <div>
                        <Tooltip
                          PopperProps={{
                            disablePortal: true,
                          }}
                          onClose={handleNextClose}
                          open={nextOpen}
                          arrow
                          disableFocusListener
                          disableHoverListener
                          disableTouchListener
                          title={isStepValid().message}
                        >
                          <Button
                            color="error"
                            onClick={handleNextOpen}
                            variant="text"
                          >
                            {t("next")}
                          </Button>
                        </Tooltip>
                      </div>
                    </ClickAwayListener>
                  )}
                </div>
                )}
              </React.Fragment>
            )}
          </div>
        </div>
      </form>
    </div>
  );
};

export default NewBlogWritingContent;
