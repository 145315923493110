// export const transformBlogsToFeatures = (blogs: any) => {
//     const features = blogs.map(
//       (blog: any) => ({
//         type: "Feature",
//         id: blog.id,
//         properties: {
//           blogId: blog.id,
//           title: blog.title,
//           activityId: blog.activities[0].id,
//           lineColor: blog.lineColor
//         },
//         geometry: blog.route, // Assuming this is already a GeoJSON LineString object
//       })
//     );

//     return {
//       type: "FeatureCollection",
//       features,
//     };
//   };
  export const transformBlogsToFeatures = (blogs: any) => {
    const features = blogs.flatMap((blog: any) => {
      const geometryCollection = blog.route; // Assuming `route` is a GeometryCollection
      if (geometryCollection.type !== "GeometryCollection") return []; // Skip invalid geometry

      // Map over each LineString in the GeometryCollection
      return geometryCollection.geometries.map((geometry: any, index: number) => {
        const lineColor = geometry.properties.color; // Retrieve the color for this LineString

        return {
          type: "Feature",
          id: blog.id, // Unique ID for each LineString
          properties: {
            blogId: blog.id,
            title: blog.title,
            activityId: blog.activities[0].id,
            lineColor: lineColor, // Use the specific color of this LineString
          },
          geometry: geometry, // The LineString geometry
        };
      });
    });

    return {
      type: "FeatureCollection",
      features,
    };
  };
  interface Municipality {
    id: number;
    name: string;
    geometry: any;
    activities: any;
    description: string;
    description_fr: string;
    description_nl: string;
    summary: string;
    summary_fr: string;
    summary_nl: string;
  }

  export const transformMunicipalitiesToFeatures = (municipalities: any) => {
    const features = municipalities.map((municipality: any) => ({
      type: "Feature" as const, // "as const" makes the type more specific (literal type) than just string
      properties: {
        municipalityId: municipality.id,
        name: municipality.name,
        activities: municipality.activities,
        description: municipality.description,
        description_fr: municipality.description_fr,
        description_nl: municipality.description_nl,
        summary: municipality.summary,
        summary_fr: municipality.summary_fr,
        summary_nl: municipality.summary_nl,
      },
      geometry: municipality.geometry,
    }));

    return {
      type: "FeatureCollection" as const,
      features,
    };
  };

  export const transformMunicipalityToFeature = (municipality: any) => {
    return {
      type: "Feature" as const,
      properties: {
        // Add all properties of the municipality that you want to include
        municipalityId: municipality.id,
        name: municipality.name,
        activities: municipality.activities,
        description: municipality.description,
        description_fr: municipality.description_fr,
        description_nl: municipality.description_nl,
        summary: municipality.summary,
        summary_fr: municipality.summary_fr,
        summary_nl: municipality.summary_nl,
      },
      geometry: municipality.geometry,
    };

  };

  export const transformMunicipalityRoutesToFeatures = (routes: any[]) => {

    return routes.map((route: any) => ({
      type: "Feature" as const,
      id: route.id,
      properties: {
        routeId: route.id,
        title: route.title,
        content: route.content,
        imageUrl: route.header_image_url,
        activityId: route.activities[0].id,
      },
      geometry: route.geometry,
    }));

  };


  export const transformMunicipalPOIsToFeatures = (pois: any) => {
    const features = pois.map((poi: any) => ({
      type: "Feature" as const, //
      properties: {
        id: poi.id,
        title: poi.title,
        content: poi.content,
        imageUrl: poi.header_image_url,
        icon: poi.poi.icon,
      },
      geometry: poi.geometry,
    }));

    return {
      type: "FeatureCollection" as const,
      features,
    };
  };
