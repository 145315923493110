import React, { useState } from "react";
import {
  TextField,
  Button,
  Typography,
  Grid,
  Box,
  Paper,
  IconButton,
  TextareaAutosize,
  FormControl,
  Select,
  MenuItem,
  InputLabel,
} from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import { FaHiking, FaBicycle, FaTrain, FaBus, FaCar, FaShip } from "react-icons/fa";
import { useTranslation } from "react-i18next";


const AddTrip = ({
    startCities,
    tripData,
    setTripData,
    setIsAddTripOpen
}) => {
    const { t } = useTranslation();
    const [timeError, setTimeError] = useState("");

    // Validate and handle time input
    const handleTimeChange = (value) => {
      const regex = /^\d{1,2}:\d{1,2}:\d{1,2}$/; // Matches dd:hh:mm
      if (!regex.test(value)) {
        setTimeError("Time must be in the format dd:hh:mm");
      } else {
        setTimeError(""); // Clear error if valid
      }
      setTripData({ ...tripData, total_time: value });
    };

    const initialTripData = {
        start_city: "",
        total_time: "",
        approximate_cost: "",
        stages: [
          {
            start: "",
            end: "",
            transportation_mode: "",
            description: "",
            operator: "",
          },
        ],
      };



  const transportationOptions = [
    { label: "Hiking", value: "hike", icon: <FaHiking /> },
    { label: "Biking", value: "bike", icon: <FaBicycle /> },
    { label: "Train", value: "train", icon: <FaTrain /> },
    { label: "Bus", value: "bus", icon: <FaBus /> },
  ];

  const operators = {
    bus: [
      "FlixBus",
      "Eurolines",
      "BlaBlaCar Bus",
      "RegioJet",
      "Megabus",
      "National Express",
      "Arriva",
      "Alsa",
      "PKS Polonus",
      "Lux Express",
      "Student Agency",
      "Deutsche Bahn (Intercity Buses)",
      "ÖBB Postbus",
      "Sadem",
      "TrawsCymru",
      "Other",
    ],
    train: [
      "SNCF",
      "SNCB",
      "DB (Deutsche Bahn)",
      "ÖBB",
      "SBB",
      "Trenitalia",
      "Renfe",
      "Eurostar",
      "Thalys",
      "TGV",
      "Italo",
      "NS (Nederlandse Spoorwegen)",
      "SJ",
      "DSB",
      "ČD",
      "MAV-Start",
      "ZSSK",
      "PKP Intercity",
      "Leo Express",
      "RegioJet",
      "RhB",
      "EuroCity Trains",
      "InterCity Express (ICE)",
      "Other",
    ],
  };
  const handleInputChange = (field, value) => {
    setTripData({ ...tripData, [field]: value });
  };

  const handleStageChange = (index, field, value) => {
    const updatedStages = [...tripData.stages];
    updatedStages[index][field] = value;
    setTripData({ ...tripData, stages: updatedStages });
  };

  const addStage = () => {
    const lastStage = tripData.stages[tripData.stages.length - 1];
    const newStage = {
      start: lastStage?.end || "",
      end: "",
      transportation_mode: "",
      description: "",
      operator: "",
    };

    setTripData({
      ...tripData,
      stages: [...tripData.stages, newStage],
    });
  };

  const removeStage = (index) => {
    const updatedStages = tripData.stages.filter((_, i) => i !== index);
    setTripData({ ...tripData, stages: updatedStages });
  };

  const handleCancel = () => {
    setTripData(initialTripData); // Reset tripData
    setIsAddTripOpen(false); // Close the tab
  };

  const handleSubmit = () => {
    if (!validateForm()) return;

    console.log("Submitting Trip Data:", tripData);
    setIsAddTripOpen(false); // Close the tab after saving
  };

  const validateForm = () => {
    // Check if start city, total time, and cost are provided
    if (!tripData.start_city || !tripData.total_time || !tripData.approximate_cost) {
      alert(t('spot.error-spot'));
      return false;
    }

    // Check if all stages have required fields
    for (const stage of tripData.stages) {
      if (!stage.start || !stage.end || !stage.transportation_mode) {
        alert(t('spot.error-trip'));
        return false;
      }
    }

    return true;
  };

  return (
    <Box sx={{ padding: 4 }}>
      <Typography variant="h4" gutterBottom>
        {t('spot.add-spot-trip')}
      </Typography>

      <div className="flex flex-col gap-3">
        <FormControl fullWidth size="small" variant="outlined">
          <InputLabel>{t('spot.start-city')}</InputLabel>
          <Select
            value={tripData.start_city}
            onChange={(e) => handleInputChange("start_city", e.target.value)}
            label={t('spot.start-city')}
          >
            {startCities.map((city) => (
              <MenuItem key={city.id} value={city.id}>
                {city.name}
              </MenuItem>
            ))}
          </Select>
        </FormControl>

        <Grid container spacing={2}>
          <Grid item xs={12} sm={6}>
          <TextField
        label={t('spot.total-time')}
        fullWidth
        size="small"
        variant="outlined"
        value={tripData.total_time}
        onChange={(e) => handleTimeChange(e.target.value)}
        error={!!timeError} // Highlight the field in red if there's an error
        helperText={timeError} // Display the error message
      />
          </Grid>

          <Grid item xs={12} sm={6}>
            <TextField
              label={t('spot.approx-cost')}
              type="number"
              fullWidth
              size="small"
              variant="outlined"
              value={tripData.approximate_cost}
              onChange={(e) => handleInputChange("approximate_cost", e.target.value)}
            />
          </Grid>
        </Grid>

        <Typography variant="h5">{t('spot.stages')}</Typography>

        {tripData.stages.map((stage, index) => (
          <Paper
            key={index}
            sx={{ padding: 2, marginBottom: 2, position: "relative" }}
            elevation={3}
          >
            <Typography variant="h6" gutterBottom>
            {t('spot.stage')} {index + 1}
            </Typography>

            <Grid container spacing={2}>
              <Grid item xs={12} sm={6}>
                <TextField
                  label={t('spot.start-location')}
                  fullWidth
                  size="small"
                  variant="outlined"
                  value={stage.start}
                  onChange={(e) => handleStageChange(index, "start", e.target.value)}
                />
              </Grid>

              <Grid item xs={12} sm={6}>
                <TextField
                  label={t('spot.end-location')}
                  fullWidth
                  size="small"
                  variant="outlined"
                  value={stage.end}
                  onChange={(e) => handleStageChange(index, "end", e.target.value)}
                />
              </Grid>

              {/* Transportation Mode */}
              <Grid item xs={12}>
                <Box sx={{ display: "flex", gap: 2, flexWrap: "wrap" }}>
                  {transportationOptions.map((option) => (
                    <IconButton
                      key={option.value}
                      onClick={() =>
                        handleStageChange(index, "transportation_mode", option.value)
                      }
                      sx={{
                        border: "2px solid",
                        borderColor: stage.transportation_mode === option.value ? "#8ABFA3" : "gray",
                        backgroundColor:
                          stage.transportation_mode === option.value ? "#8ABFA3" : "white",
                        color: stage.transportation_mode === option.value ? "white" : "black",
                        borderRadius: "50%",
                        width: 35,
                        height: 35,
                        "&:hover": {
                          backgroundColor:
                            stage.transportation_mode === option.value ? "#8ABFA3" : "#f0f0f0",
                        },
                      }}
                    >
                      {option.icon}
                    </IconButton>
                  ))}
                </Box>
              </Grid>

              {/* Description */}
              <Grid item xs={12}>
                <TextareaAutosize
                  minRows={2}
                  placeholder={t('spot.description-location')}
                  style={{
                    width: "100%",
                    padding: "10px",
                    border: "1px solid #ccc",
                    borderRadius: "5px",
                  }}
                  value={stage.description}
                  onChange={(e) =>
                    handleStageChange(index, "description", e.target.value)
                  }
                />
              </Grid>

              {/* Operator (conditionally render) */}
              {(stage.transportation_mode === "bus" || stage.transportation_mode === "train") && (
                <Grid item xs={12} sm={6}>
                  <FormControl fullWidth>
                    <Select
                      value={stage.operator}
                      size="small"
                      onChange={(e) =>
                        handleStageChange(index, "operator", e.target.value)
                      }
                    >
                      {operators[stage.transportation_mode].map((operator, i) => (
                        <MenuItem key={i} value={operator}>
                          {operator}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Grid>
              )}
            </Grid>

            {/* Remove Button */}
            <IconButton
              onClick={() => removeStage(index)}
              sx={{ position: "absolute", top: 10, right: 10 }}
            >
              <DeleteIcon color="error" />
            </IconButton>
          </Paper>
        ))}

        <Button variant="outlined" onClick={addStage}>
        {t('spot.add-stage')}
        </Button>

        <div className="flex flex-row justify-between" style={{ marginTop: "16px" }}>
          <Button variant="contained" color="error" onClick={handleCancel}>
          {t('cancel')}
          </Button>
          <Button variant="contained" color="primary" onClick={handleSubmit}>
          {t('spot.save')}
          </Button>
        </div>
      </div>
    </Box>
  );
};

export default AddTrip;
