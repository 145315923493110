import React, { useEffect, useRef, useState, useContext } from "react";
import mapboxgl from "mapbox-gl";
import "mapbox-gl/dist/mapbox-gl.css";
import { FeatureCollection, Feature, LineString, Geometry } from "geojson";
import * as turf from "@turf/turf";
import booleanIntersects from "@turf/boolean-intersects";
import { useTranslation } from "react-i18next";

import { points, featureCollection } from "@turf/helpers";
import kmeans from "@turf/clusters-kmeans";

import Navbar from "../components/main/Navbar";
import Sidebar from "../components/main/Sidebar";
import { transformBlogsToFeatures, transformMunicipalityToFeature, transformMunicipalityRoutesToFeatures, transformMunicipalPOIsToFeatures } from "../utils/geoHelpers"
import { activityColors, getLinePaint, lineColors } from "../utils/mapStyles";
import { createConcatenatedRoutesForBlogs } from "../utils/utils";

import { fetchMunicipalityAreas, fetchMunicipalityRoutes, fetchMunicipalityPOI } from "../utils/api/api";
import LoadingPage from "./Loading";
import "@mapbox/mapbox-gl-draw/dist/mapbox-gl-draw.css";
import { AuthContext } from "../components/authentification/AuthContext";

// import { useMap } from '../components/map/MapContext';

const emptyFeatureCollection = {
  type: "FeatureCollection",
  features: []
};

const MapboxMap: React.FC<any> = ({map, mapContainerRef, allLayers, setAllLayers, allMarkers, setAllMarkers, mapAnimation, setMapAnimation}) => {
  const { t, i18n } = useTranslation();
  const authContext = useContext(AuthContext);
  const { user } = authContext || {};
  // const { map, mapContainerRef } = useMap();


  const [valueMainTab, setValueMainTab] = React.useState(0);
  const [selectedFeatureId, setSelectedFeatureId] = useState(null);
  const [fetchedBlogs, setFetchedBlogs] = useState<any>([]);
  const [blogs, setBlogs] = useState<any>([]);
  const [visibleBlogs, setVisibleBlogs] = useState<any>([]);
  const [mapInstance, setMapInstance] = useState<mapboxgl.Map | null>(null);
  const [mapFeatures, setMapFeatures] = useState<any>(null);
  const hoveredBlogRef = useRef<any>(null);
  const [hoveredBlog, setHoveredBlog] = useState<any>(null);


  const [municipalityRoutes, setMunicipalityRoutes] = useState([]);
  const [municipalityRoutesFeatures, setMunicipalityRoutesFeatures] = useState<any>(null);
  const [municipalPOIs, setMunicipalPOIs] = useState<any>(null);
  const [selectedMunicipalityRoute, setSelectedMunicipalityRoute] = useState<any>(null);
  const initialLoadRef = useRef(true);
  const valueMainTabRef = useRef(valueMainTab);
  const [selectedMunicipality, setSelectedMunicipality] = useState<Feature<Geometry> | null>(null);
  const currentOnClickHandler = useRef<any>(null);
  const markersRef = useRef([]);

  const [hoveredBlogDetails, setHoveredBlogDetails] = useState<any>(null);
  const [hoveredCard, setHoveredCard] = useState<any>(null);
  const [oldHoveredCard, setoldHoveredCard] = useState<any>(null);
  const [visibleCard, setVisibleCard] = useState(false);
  const [position, setPosition] = useState({ x: 0, y: 0 });
  const [positionFixed, setPositionFixed] = useState({ x: 0, y: 0 });
  const [communityLocations, setCommunityLocations] = useState<any>(null);
  const [communityCurrentTrips, setCommunityCurrentTrips] = useState<any>(null);
  const [communityCurrentTripFeatures, setCommunityCurrentTripFeatures] = useState<any>(null);
  const [showCommunityLocations, setShowCommunityLocations] = useState<any>(false);
  const [userMarker, setUserMarker] = useState<any>(null);
  const [currentLocation, setCurrentLocation] = useState<any>({ lat: null, lon: null });

  const [activeChips, setActiveChips] = useState<number[]>([]);


  const getBottomCenterPosition = (offsetX = 0, offsetY = 0) => {
    const x = (window.innerWidth / 2) + offsetX;
    const y = window.innerHeight + offsetY;
    return { x, y };
  };


useEffect(() => {
  valueMainTabRef.current = valueMainTab;
}, [valueMainTab]);


  // mapboxgl.accessToken = process.env.REACT_APP_MAPBOX_API_TOKEN as string;

  const handleMainTabChange = (
    event: React.SyntheticEvent,
    newValue: number
  ) => {
    setValueMainTab(newValue);
  };

  useEffect(() => {
  const sortedBlogs = [...fetchedBlogs].sort((a, b) => b.id - a.id);


  const geoJSONFeatureCollection = createConcatenatedRoutesForBlogs(sortedBlogs);
  setBlogs(geoJSONFeatureCollection);
  setMapFeatures(geoJSONFeatureCollection);
  setVisibleBlogs(geoJSONFeatureCollection);

}, [fetchedBlogs]);


const chipLengthRanges = {
  0: [0, 200], // Microadventures
  1: [200, 1000], // Exploration
  2: [1000, 3000], // Expedition
  3: [3000, Infinity], // Odysseys
};

  const updateVisibleBlogs = () => {
    if (!mapInstance || blogs.length === 0) return;


try {


    const bounds = mapInstance.getBounds();
    const bbox: turf.BBox = [
      bounds?.getWest(),
      bounds?.getSouth(),
      bounds?.getEast(),
      bounds?.getNorth(),
    ];
    const boundsPolygon = turf.bboxPolygon(bbox);

    // const zoomLevel = mapInstance.getZoom();



    const visibleBlogs = blogs.filter((blog: any) => {
      const geometries = blog.route.geometries;
      const totalLength = blog.route.totalLength;

            // Concatenate all geometries into one LineString
      const allCoordinates = geometries.reduce((acc: any[], geometry: any) => {
        acc.push(...geometry.coordinates);
        return acc;
      }, []);
      const concatenatedLine = turf.lineString(allCoordinates);

      const isInBounds = booleanIntersects(concatenatedLine, boundsPolygon);

      // If no chips are selected, show all trips within bounds
      if (activeChips.length === 0) {
        return isInBounds;
      }

      // Check if the totalLength falls within any selected chip's range
      const isInSelectedRange = activeChips.some((chipIndex) => {
        const [minLength, maxLength] = chipLengthRanges[chipIndex];
        return totalLength >= minLength && totalLength < maxLength;
      });

      // Return true if both conditions are met
      return isInBounds && isInSelectedRange;
        });
    // const visibleBlogs = blogs.filter((blog: any) => {
    //   const geometries = blog.route.geometries;

    //   // Concatenate all geometries into one LineString
    //   const allCoordinates = geometries.reduce((acc: any[], geometry: any) => {
    //     acc.push(...geometry.coordinates);
    //     return acc;
    //   }, []);
    //   const concatenatedLine = turf.lineString(allCoordinates);

    //   const totalLength = blog.route.totalLength;


    //   // Determine if the concatenated route should be displayed based on its length and the current zoom level
    //   if (totalLength < 200 && zoomLevel >= 7) {
    //     return booleanIntersects(concatenatedLine, boundsPolygon);
    //   } else if (totalLength >= 200 && totalLength < 1000 && zoomLevel >= 5.8 && zoomLevel < 7) {
    //     return booleanIntersects(concatenatedLine, boundsPolygon);
    //   } else if (totalLength >= 1000 && totalLength < 3000 && zoomLevel >= 4.5 && zoomLevel < 5.8) {
    //     return booleanIntersects(concatenatedLine, boundsPolygon);
    //   } else if (totalLength >= 3000 && zoomLevel >= 0 && zoomLevel < 4.4) {
    //     return booleanIntersects(concatenatedLine, boundsPolygon);
    //   }
    //   return false;
    // });

    if (visibleBlogs.length === 0) {
      setVisibleBlogs([]);
      setHoveredCard(null);
      return;
    } else {
      const isCardInVisibleBlogs = visibleBlogs.some((blog: any) => blog.id === hoveredCard);
            setVisibleBlogs(visibleBlogs);

            // If it's not in the visible blogs, update the hovered card state
            // if (!isCardInVisibleBlogs) {
            //   console.log('changed')
            //   setHoveredCard(visibleBlogs[0].id);
            // }

    }

    // const pointsFeature = visibleBlogs.map((blog: any) => ({
    //   type: 'Feature',
    //   properties: { id: blog.id },
    //   geometry: {
    //     type: 'Point',
    //     coordinates: turf.center(turf.lineString(blog.route.coordinates)).geometry.coordinates
    //   }
    // }));

    // const pointsFeature = visibleBlogs.map((blog: any) => {
    //   const geometries = blog.route.geometries;
    //   const allCoordinates = geometries.reduce((acc: any[], geometry: any) => {
    //     acc.push(...geometry.coordinates);
    //     return acc;
    //   }, []);
    //   const center = turf.center(turf.lineString(allCoordinates)).geometry.coordinates;

    //   return {
    //     type: 'Feature',
    //     properties: { id: blog.id },
    //     geometry: {
    //       type: 'Point',
    //       coordinates: center,
    //     }
    //   };
    // });


    // const geoJsonPoints: any = featureCollection(pointsFeature);
    // const maxClusters = Math.min(visibleBlogs.length, 10); // Ensure we do not exceed the number of visible blogs

    // if (maxClusters > 1) {
    //   const clustered = kmeans(geoJsonPoints, { numberOfClusters: maxClusters });

    //   // Map clusters to the most central blog
    //   const representativeBlogs = new Set();
    //   for (let i = 0; i < maxClusters; i++) {
    //     const clusterPoints = clustered.features.filter(f => f.properties.cluster === i);
    //     if (clusterPoints.length > 0) {
    //       const centroid = turf.center(turf.featureCollection(clusterPoints));
    //       clusterPoints.sort((a, b) => turf.distance(centroid, a) - turf.distance(centroid, b));
    //       representativeBlogs.add(clusterPoints[0].properties.id);
    //     }
    //   }

    //   // Filter blogs to include only representatives
    //   const sparsedBlogs = visibleBlogs.filter((blog: any) => representativeBlogs.has(blog.id));
    //   setVisibleBlogs(sparsedBlogs);
    // } else {
    //   // If there's only one or no cluster possible, show all visible blogs
    //   setVisibleBlogs(visibleBlogs);
    // }

  }
  catch (error) {
    console.error("Error updating the visible blogs", error);
  };
}


  useEffect(() => {

    const resetting = async (map: any) => {
      await cleanUpMap(map)
      const mapContainerDiv = document.getElementById('map');
      if (mapContainerDiv && mapContainerRef.current) {
        if (mapContainerDiv !== mapContainerRef.current.parentNode) {
          mapContainerDiv.innerHTML = ''; // Clear existing content
          mapContainerDiv.appendChild(mapContainerRef.current);
        }
      }

      map.off('click');
      map.off('mouseenter');
      map.off('mouseleave');
      map.off('moveend');
      map.off('move');
      map.off('style.load');
      map.off('idle');

      map.resize();
      map.easeTo({center: [7, 47], zoom: 4});


    }

    if (map) {
      if (map.isStyleLoaded()) {
        resetting(map)
        setMapInstance(map)
      } else {

        map.on('load', () => {
          resetting(map)
          setMapInstance(map)
        })
      }

      map.off('load');


    }
  }, [map, mapContainerRef]);

  const desiredStyleUrl = "mapbox://styles/vladke99/cm33k6g55002o01nwhohygudi";
const desiredStyle = "cm33k6g55002o01nwhohygudi";

// const cleanupMap = async (mapInstance: any) => {
//   // Cleanup layers and sources

// }



//   // Change the map style at the end of cleanup if it is different from the desired style
// };

const resetStyleAndFeatures = async (mapInstance: any) => {

    mapInstance.off('click');
  mapInstance.off('mouseenter');
  mapInstance.off('mouseleave');
  mapInstance.off('move');
  mapInstance.off('moveend');
  mapInstance.off('style.load');
  mapInstance.off('render');

  if (!mapInstance.getStyle().sprite || !mapInstance.getStyle().sprite.includes(desiredStyle)) {
    mapInstance.setStyle(desiredStyleUrl);

    // Wait for the style to load before initializing features
    mapInstance.once('style.load', () => {
      initialiseRoutes(mapInstance);


      try {
        mapInstance.flyTo({center: [7, 47], zoom: 4});
      }
      catch (error) {
        console.log('error in setting center and zoom',error)
      }

    });
  } else {
    initialiseRoutes(mapInstance);
  }

}

const removeSourcesAndLayers = async (mapInstance: any, layerNames: string[], sourceNames: string[]) => {
  try {

    const alllayers = mapInstance.getStyle().layers;

    alllayers.forEach((layer: any) => {
      if (layer.id.includes('slostr')) {
        if (mapInstance.getLayer(layer.id)) {
          mapInstance.removeLayer(layer.id);
        }
      }
    });
    alllayers.forEach((layer: any) => {
      if (layer.id.includes('slostr')) {
        if (mapInstance.getSource(layer.id)) {
          mapInstance.getSource(layer.id).setData(emptyFeatureCollection)
          mapInstance.removeSource(layer.id);
        }
      }
    });

  layerNames.forEach(layerName => {

      if (mapInstance.getLayer(layerName)) {
        mapInstance.removeLayer(layerName);
      }


  })
  layerNames.forEach(layerName => {

    if (mapInstance.getSource(layerName)) {
      mapInstance.getSource(layerName).setData(emptyFeatureCollection)

mapInstance.removeSource(layerName);
}


  })
} catch (error) {
  console.error(`Error removing layers:`, error);
};


  // sourceNames.forEach(sourceName => {
  //   try {
  //     if (mapInstance.getSource(sourceName)) {
  //       console.log(`Source ${mapInstance.getSource(sourceName)}`)
  //       mapInstance.removeSource(sourceName);
  //     }
  //   } catch (error) {
  //     console.error(`Error removing source: ${sourceName}`, error);
  //   }
  // });
}

const cleanUpMap = async (mapInstance: any) => {

  if (mapAnimation !== null) {
    cancelAnimationFrame(mapAnimation);
    setMapAnimation(null);
  }

  mapInstance.off('click');
  mapInstance.off('mouseenter');
  mapInstance.off('mouseleave');
  mapInstance.off('move');
  mapInstance.off('moveend');
  mapInstance.off('style.load');
  mapInstance.off('render');

  if (allMarkers.length > 0) {
    allMarkers.forEach(([marker, markerName]: any) => marker.remove());
    setAllMarkers([]);
      }

  const markerDivs = document.querySelectorAll('.mapboxgl-marker');
  markerDivs.forEach(marker => {
    marker.remove();
  });

  if (allLayers && allLayers.length > 0) {
    removeSourcesAndLayers(mapInstance, allLayers, allLayers);
    setAllLayers([])
  }
}


  const initialiseRoutes = async (mapInstance: any) => {
      // Remove all event listeners



      cleanUpMap(mapInstance)

      setAllLayers((prevLayers: any) => [...prevLayers, 'slostr-routes', 'slostr-routes-highlighted']);

      if (!mapInstance.getSource("slostr-routes")) {

    mapInstance.addSource("slostr-routes", {
      type: "geojson",
      data: mapFeatures,
    });
  } else {
    // console.log('mapfeatures',mapFeatures )
    mapInstance.getSource("slostr-routes").setData(mapFeatures);
  }

    const paintProperties = getLinePaint(0);

    mapInstance.addLayer({
      id: "slostr-routes",
      type: "line",
      source: "slostr-routes",
      layout: {
        "line-join": "round",
        "line-cap": "round",
      },
      // @ts-ignore
      paint: paintProperties,
    });
    mapInstance.addLayer({
      id: "slostr-routes-highlighted",
      type: "line",
      source: "slostr-routes",
      layout: {
        "line-join": "round",
        "line-cap": "round",
      },
      paint: {
        "line-color": "rgba(255,0,0,0.8)",
        "line-width": 4,
      },

      filter: ["in", "blogId", ""],
    });

  }

  const initialiseCommunityLocations = async (mapInstance: any) => {
    // Remove all event listeners

    if (!communityLocations) return


    cleanUpMap(mapInstance)

    for (const marker of communityLocations.features) {
      // Create a DOM element for each marker.
      const el = document.createElement('div');
      const width = marker.properties.iconSize[0];
      const height = marker.properties.iconSize[1];
      el.className = `marker-user ${marker.properties.isUser ? 'marker-is-user' : ''}`;
      el.style.backgroundImage = `url(${marker.properties.imageId}`;
      el.style.width = `${width}px`;
      el.style.height = `${height}px`;
      el.style.backgroundSize = '100%';

//       el.addEventListener('click', (e) => {
//         setVisibleCard(true);

//         if (hoveredBlogRef.current?.author.id === marker.properties.user) {
//           hoveredBlogRef.current = null
//           setHoveredBlogDetails(null)

//         } else {
//           const foundBlog = communityCurrentTrips.find((blog:any) => blog.author.id === marker.properties.user)
//           setHoveredBlogDetails( foundBlog || null)
//           hoveredBlogRef.current = foundBlog || null
//           const yOffset = window.innerWidth > 730 ? -350 : -300;
//           const position = getBottomCenterPosition(-150, yOffset); // Adjust offsets as needed
// setPositionFixed({ x: Math.round(position.x), y: Math.round(position.y) });

//         }


//       });

      // Add markers to the map.
      const newMarker = new mapboxgl.Marker(el)
          .setLngLat(marker.geometry.coordinates)
          .addTo(mapInstance);

          if (marker.properties.isUser) {
          setUserMarker(newMarker)
          }

}

setAllLayers((prevLayers: any) => [...prevLayers, 'slostr-community-trips']);


      if (!mapInstance.getSource("slostr-community-trips")) {


    mapInstance.addSource("slostr-community-trips", {
      type: "geojson",
      data: communityCurrentTripFeatures,
    });
  } else {

    // console.log('mapfeatures',mapFeatures )
    mapInstance.getSource("slostr-community-trips").setData(communityCurrentTripFeatures);
  }

    const paintProperties = getLinePaint(0);

    mapInstance.addLayer({
      id: "slostr-community-trips",
      type: "line",
      source: "slostr-community-trips",
      layout: {
        "line-join": "round",
        "line-cap": "round",
      },
      // @ts-ignore
      paint: paintProperties,
    });

  }

useEffect(() => {

  if (!currentLocation && !currentLocation.lat) return
  if (!mapInstance) return
  if (!userMarker && user) {
    const pp = user.profile_picture
    const el = document.createElement('div');
    const width = 25;
    const height = 25;
    el.className = `marker-user marker-is-user`;
    el.style.backgroundImage = `url(${user.profile_picture !== undefined ? user.profile_picture : '/slostr_icon.svg'}`;
    el.style.width = `${width}px`;
    el.style.height = `${height}px`;
    el.style.backgroundSize = '100%';

//     el.addEventListener('click', (e) => {
//       setVisibleCard(true);

//       if (hoveredBlogRef.current?.author.id === user.id) {
//         hoveredBlogRef.current = null
//         setHoveredBlogDetails(null)

//       } else {
//         const foundBlog = communityCurrentTrips.find((blog:any) => blog.author.id === user.id)
//         setHoveredBlogDetails( foundBlog || null)
//         hoveredBlogRef.current = foundBlog || null
//         const yOffset = window.innerWidth > 730 ? -350 : -300;
//         const position = getBottomCenterPosition(-150, yOffset); // Adjust offsets as needed
// setPositionFixed({ x: Math.round(position.x), y: Math.round(position.y) });

//       }


//     });

    // Add markers to the map.
    const newMarker = new mapboxgl.Marker(el)
        .setLngLat([currentLocation.lon, currentLocation.lat])
        .addTo(mapInstance);
        setUserMarker(newMarker)
  } else {

    userMarker.setLngLat([currentLocation.lon, currentLocation.lat])
  }


},[currentLocation])


  useEffect(() => {
    if (!mapInstance) return
    if (showCommunityLocations) {
      setVisibleCard(false);
      initialiseCommunityLocations(mapInstance);
    } else {
      initialiseRoutes(mapInstance)
    }
  }, [showCommunityLocations])

  useEffect(() => {
    // const map = new mapboxgl.Map({
    //   // @ts-ignore
    //   container: mapContainerRef.current,
    //   // style: "mapbox://styles/mapbox/light-v10",
    //   style: "mapbox://styles/vladke99/cm33k6g55002o01nwhohygudi",
    //   center: [8, 47],
    //   zoom: 4,
    // });

    if (!mapInstance) return

    resetStyleAndFeatures(mapInstance)


    mapInstance.resize()
try {
  mapInstance.flyTo({center: [7, 47], zoom: 4});
}
catch (error) {
  console.log('error in setting center and zoom',error)
}





    let hoveredFeatureId:any = null;

    // // Mouse enter event
    // mapInstance.on('mouseenter', 'slostr-routes', (e) => {
    //   // @ts-ignore
    //   if (e.features.length > 0) {
    //             // @ts-ignore
    //             hoveredFeatureId = e.features[0].id; // Assuming 'id' is your unique identifier


    //     if (hoveredFeatureId) {
    //       // Reset previous hover state
    //       mapInstance.setFeatureState(
    //         { source: 'slostr-routes', id: hoveredFeatureId },
    //         { hover: false }
    //       );
    //       mapInstance.setPaintProperty('slostr-routes', 'line-opacity', 0.6);



    //     }
    //     setPositionFixed({x: e.point.x + 10, y: e.point.y + 5});
    //     setHoveredBlog(hoveredFeatureId)
    //     mapInstance.setFeatureState(
    //       { source: 'slostr-routes', id: hoveredFeatureId },
    //       { hover: true }
    //     );
    //     mapInstance.setPaintProperty('slostr-routes', 'line-opacity', [
    //       'case',
    //       ['==', ['id'], hoveredFeatureId], // Check if the current feature is the hovered one
    //       1,    // Opacity for hovered feature
    //       0.15   // Opacity for non-hovered features
    //     ]);

    //     mapInstance.getCanvas().style.cursor = 'pointer';
    //   }
    // });

    // // Mouse leave event
    // mapInstance.on('mouseleave', 'slostr-routes', () => {
    //   if (hoveredFeatureId) {
    //     mapInstance.setFeatureState(
    //       { source: 'slostr-routes', id: hoveredFeatureId },
    //       { hover: false }
    //     );
    //     mapInstance.setPaintProperty('slostr-routes', 'line-opacity', 0.6);
    //   }
    //   mapInstance.getCanvas().style.cursor = '';
    //   console.log('leaving mouseleave')
    //   hoveredFeatureId = null;
    //   setHoveredBlog(hoveredFeatureId)

    // });

    // const onClick = async (e: any) => {
    //   // @ts-ignore
    //   const zoom = mapInstance.getZoom();
    //   const clickPoint = turf.point([e.lngLat.lng, e.lngLat.lat]);
    //   const lng = e.lngLat.lng;
    //   const lat = e.lngLat.lat;
    //   let foundFeature: any | null = null;
    //   let pixelSensitivity = 10;
    //   const sensitivityInMeters = pixelsToMetersAtZoom(pixelSensitivity, e.lngLat.lat, zoom);

    //   if (valueMainTabRef.current === 0) { // Blogs are active
    //     // const blogFeatures = transformBlogsToFeatures(blogs); // Assuming this returns GeoJSON FeatureCollection

    //     // for (const feature of blogFeatures.features) {
    //     //   // Using turf.pointToLineDistance to find if the click is close enough to a line
    //     //   const distance = turf.pointToLineDistance(clickPoint, feature, {units: 'meters'});
    //     //   if (distance < sensitivityInMeters) { // Adjust the threshold distance as needed
    //     //     foundFeature = feature;
    //     //     break;
    //     //   }
    //     // }

    //     console.log('map clicked')

    //     if (hoveredBlogRef.current) {
    //       // @ts-ignore
    //       mapInstance.setFilter("slostr-routes-highlighted", ["in", "blogId", hoveredBlogRef.current]);
    //       setSelectedFeatureId(hoveredBlogRef.current || null)
    //     }
    //   }
    // }

    // mapInstance.on('click', onClick)
    // Clean up
    // return () => {
    //   if (mapInstance) {
    //     // @ts-ignore
    //     mapInstance.off('mouseenter', 'slostr-routes');
    //     // @ts-ignore
    //     mapInstance.off('mouseleave', 'slostr-routes');
    //     mapInstance.off('click', onClick);

    //   }
    // };


    // Clean up on unmount
    // return () => map.remove();
  }, [mapInstance]);

  useEffect(() => {

    if (mapInstance) {
      // @ts-ignore
      const onMapMoveEnd = () => {
        updateVisibleBlogs();
      };

      mapInstance.on("moveend", onMapMoveEnd);

      // Initial update
      updateVisibleBlogs();
    }

    if (mapInstance && mapInstance.isStyleLoaded() && mapInstance.getSource("slostr-routes")) {

      if (valueMainTab === 0) {
        const blogFeatures = transformBlogsToFeatures(visibleBlogs);
        // @ts-ignore
        mapInstance.getSource("slostr-routes").setData(blogFeatures);
        // @ts-ignore
        markersRef.current.forEach(marker => marker.remove());
        markersRef.current = [];
      } else if (valueMainTab === 1) {
        if (selectedMunicipality) {
          const areaFeature = transformMunicipalityToFeature(selectedMunicipality);
          const routeFeatures = transformMunicipalityRoutesToFeatures(municipalityRoutes);


          const combinedFeatures = {
            type: "FeatureCollection",
            features: [areaFeature, ...routeFeatures],
          };
        // @ts-ignore
        mapInstance.getSource("slostr-routes").setData(combinedFeatures);
        if (municipalPOIs) {
          for (const feature of municipalPOIs.features) {
            // create a HTML element for each feature
            const el = document.createElement('div');
            el.className = `marker icon-${feature.properties.icon}`;

            // make a marker for each feature and add it to the map
            const marker = new mapboxgl.Marker(el)
            // @ts-ignore
              .setLngLat(feature.geometry.coordinates)
              .setPopup(
                new mapboxgl.Popup({ offset: 25, className: 'w-[300px]', maxWidth: '400px' }) // add popups
                  .setHTML(
                    `<img class="w-full" src="https://t4.ftcdn.net/jpg/03/92/59/57/360_F_392595730_CcMG0lYVT1tJjy3uXBqbBVoTMDTZM9LP.jpg"/><h2>${feature.properties.title}</h2><p class="text-sm">${feature.properties.content}</p>`
                  )
              )
              .addTo(mapInstance);
              // @ts-ignore
              markersRef.current.push(marker);

          }
        }
        }

      }
    }

        // Clean up
        return () => {
          if (mapInstance) {
            // @ts-ignore
            mapInstance.off('moveend');
          }
        };
  }, [mapFeatures, mapInstance, valueMainTab, selectedMunicipality, municipalityRoutes, municipalPOIs, showCommunityLocations, activeChips]);

  useEffect(() => {
    if (mapInstance && visibleBlogs.length > 0 && !showCommunityLocations) {
      const visibleIds = visibleBlogs.map((blog: any) => blog.id);
      mapInstance.setFilter('slostr-routes', ['in', 'blogId', ...visibleIds]);
    } else if (mapInstance) {
      // Hide all routes if there are no visible blogs
      // mapInstance.setFilter('routes', ['in', 'blogId', '']);
    }
  }, [visibleBlogs, mapInstance]);



useEffect(() => {
  if (!mapInstance) return;
  mapInstance.setPaintProperty('slostr-routes', 'line-opacity', 0.1);


  if (oldHoveredCard) {

  mapInstance.setFeatureState(
    { source: 'slostr-routes', id: oldHoveredCard },
    { hover: false }
  );

}

  if (hoveredCard) {
    mapInstance.setFeatureState(
      { source: 'slostr-routes', id: hoveredCard },
      { hover: true }
    )
    setoldHoveredCard(hoveredCard)
    mapInstance.setPaintProperty('slostr-routes', 'line-opacity', [
      'case',
      ['==', ['id'], hoveredCard], // Check if the current feature is the hovered one
      1,    // Opacity for hovered feature
      0.06   // Opacity for non-hovered features
    ]);
   }


}, [hoveredCard])

  useEffect(() => {
    let timeoutId: any;

    if (hoveredBlog) {
      // setPositionFixed(position)

      setHoveredBlogDetails(visibleBlogs.find((blog:any) => blog.id === hoveredBlog))
      hoveredBlogRef.current = hoveredBlog
      setVisibleCard(true);
    } else {
        // Clear existing timeout to prevent unwanted behavior when quickly hovering/unhovering
        clearTimeout(timeoutId);

        // Set a delay before setting visibleCard to false
        timeoutId = setTimeout(() => {
            setVisibleCard(false);
            hoveredBlogRef.current = null
            // setHoveredBlogDetails(null);
        }, 1000); // Delay in milliseconds

    }
    return () => clearTimeout(timeoutId);

},[hoveredBlog])

  useEffect(() => {
    // This effect listens for changes in valueMainTab and updates the map accordingly
    if (mapInstance && mapInstance.isStyleLoaded() && mapInstance.getLayer("slostr-routes")) {
      const paintProperties = getLinePaint(valueMainTab);

      // Update the layer's paint properties based on the current tab
      mapInstance.setPaintProperty('slostr-routes', 'line-color', paintProperties['line-color'] as any);
    mapInstance.setPaintProperty('slostr-routes', 'line-width', paintProperties['line-width'] as any);
    mapInstance.setPaintProperty('slostr-routes', 'line-opacity', paintProperties['line-opacity'] as any);
      if (paintProperties["line-dasharray"]) {
        mapInstance.setPaintProperty(
          "slostr-routes",
          "line-dasharray",
          paintProperties["line-dasharray"] as any
        );
      } else {
        // Remove the line-dasharray property if not needed
        mapInstance.setPaintProperty("slostr-routes", "line-dasharray", [1]);
      }
    }


const onClick = async (e: any) => {
  // @ts-ignore
  const zoom = mapInstance.getZoom();
  const clickPoint = turf.point([e.lngLat.lng, e.lngLat.lat]);
  const lng = e.lngLat.lng;
  const lat = e.lngLat.lat;
  let foundFeature: any | null = null;
  let pixelSensitivity = 10;
  const sensitivityInMeters = pixelsToMetersAtZoom(pixelSensitivity, e.lngLat.lat, zoom);

  if (valueMainTabRef.current === 0) { // Blogs are active
    // const blogFeatures = transformBlogsToFeatures(blogs); // Assuming this returns GeoJSON FeatureCollection

    // for (const feature of blogFeatures.features) {
    //   // Using turf.pointToLineDistance to find if the click is close enough to a line
    //   const distance = turf.pointToLineDistance(clickPoint, feature, {units: 'meters'});
    //   if (distance < sensitivityInMeters) { // Adjust the threshold distance as needed
    //     foundFeature = feature;
    //     break;
    //   }
    // }


    if (hoveredBlog) {
      // @ts-ignore
      mapInstance.setFilter("slostr-routes-highlighted", ["in", "blogId", hoveredBlog]);
      setSelectedFeatureId(hoveredBlog || null)
    }
  } else if (valueMainTabRef.current === 1) {

    let foundFeature = false;


    if (municipalityRoutesFeatures) {
      for (const feature of municipalityRoutesFeatures.features) {
        // Using turf.pointToLineDistance to find if the click is close enough to a line
        const distance = turf.pointToLineDistance(clickPoint, feature, {units: 'meters'});
        if (distance < sensitivityInMeters) { // Adjust the threshold distance as needed
          setSelectedMunicipalityRoute(feature.properties);
          foundFeature = true;
          break;
        }
      }
    }


    if (!foundFeature) {
      const municipalityData = await fetchMunicipalityAreas(lat, lng);

      // Assuming the backend returns only one closest municipality
      if (municipalityData && municipalityData.length > 0) {
        const municipality = municipalityData[0];
        setSelectedMunicipality(municipality);

        const municipalityRoutes  = await fetchMunicipalityRoutes();
        setMunicipalityRoutes(municipalityRoutes);
        const routeFeatures = {
          type: "FeatureCollection",
          features: transformMunicipalityRoutesToFeatures(municipalityRoutes),
        };
        setMunicipalityRoutesFeatures(routeFeatures)

        const municipalityPOI  = await fetchMunicipalityPOI();
        setMunicipalPOIs(transformMunicipalPOIsToFeatures(municipalityPOI))
      }
    }

  }
}
currentOnClickHandler.current = onClick;

}, [mapInstance, valueMainTab, blogs, municipalityRoutesFeatures]);


function pixelsToMetersAtZoom(pixels: number, latitude: number, zoom: number) {
  const earthCircumference = 40075017; // Earth's circumference in meters
  const latitudeRadians = latitude * (Math.PI / 180);
  const metersPerPixel = Math.cos(latitudeRadians) * earthCircumference / Math.pow(2, zoom + 8);
  return pixels * metersPerPixel;
}

  useEffect(() => {
    if (!mapInstance) return;

    const setLanguage = () => {
      const style = mapInstance.getStyle();
      if (style && style.layers) {
        style.layers.forEach((layer) => {
          if (
            layer.type === "symbol" &&
            layer.layout &&
            layer.layout["text-field"]
          ) {
            // Adjust the text-field for each label layer
            mapInstance.setLayoutProperty(layer.id, "text-field", [
              "coalesce",
              ["get", `name_${i18n.language}`], // Try to get the localized name
              ["get", "name"], // Fallback to the default name
            ]);
          }
        });
      }
    };

    setLanguage();
  }, [i18n.language]);



  return (
    <div className="w-full h-screen relative bg-primary-contrast overflow-hidden flex flex-col items-center justify-start"
    >
      <Navbar />
      {/* <div className="absolute bottom-3 ">
      <Tooltip title={t('nav-create')}>
      <Fab color="primary" aria-label="add">
        <AddIcon />
      </Fab>
      </Tooltip>
      </div> */}

      <Sidebar
        blogs={visibleBlogs}
        map={mapInstance}
        setFetchedBlogs={setFetchedBlogs}
        focusedBlogId={selectedFeatureId}
        valueMainTab={valueMainTab}
        changeMainTab={handleMainTabChange}
        selectedMunicipality={selectedMunicipality}
        selectedMunicipalityRoute={selectedMunicipalityRoute}
        setSelectedMunicipalityRoute={setSelectedMunicipalityRoute}
        hoveredCard={hoveredCard}
        setHoveredCard={setHoveredCard}
        setCommunityLocations={setCommunityLocations}
        showCommunityLocations={showCommunityLocations}
        setShowCommunityLocations={setShowCommunityLocations}
        setCommunityCurrentTrips={setCommunityCurrentTrips}
        setCommunityCurrentTripFeatures={setCommunityCurrentTripFeatures}
        setCurrentLocation={setCurrentLocation}
        activeChips={activeChips}
        setActiveChips={setActiveChips}
        />


      <div
          id="map"
          className="absolute left-0 w-[calc(100vw-380px)] h-[calc(100vh-65px)] top-[65px]"

          // ref={(el) => (mapContainerRef.current = el)}
        />



{/* {(hoveredBlogDetails) &&(
        <div className={`tooltip-content ${!visibleCard ? 'hide' : ''}`} style={{ top: `${positionFixed.y}px`, left: `${positionFixed.x}px` }}>
                    <Link to={`/${hoveredBlogDetails.author.username}/${hoveredBlogDetails.id}`}>
            <Card
            blog={hoveredBlogDetails}
            />
            </Link>
        </div>
      )} */}

    </div>
  );
};

export default MapboxMap;
