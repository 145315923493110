import React, { useState } from 'react';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import { Button, IconButton, List, ListItem, ListItemText } from '@mui/material';
import DragIndicatorIcon from '@mui/icons-material/DragIndicator';
import { Delete } from "@mui/icons-material";
import { useTranslation } from "react-i18next";
import CloseIcon from '@mui/icons-material/Close';

const DraggableChapterList: React.FC<any> = ({ chapters, setChapters, setSwitchChapters, currentChapterIndex, setCurrentChapterIndex }) => {
    const { t } = useTranslation();


  const onDragEnd = (result: any) => {
    if (!result.destination) return;

    const reorderedChapters = Array.from(chapters);
    const [removed] = reorderedChapters.splice(result.source.index, 1);
    reorderedChapters.splice(result.destination.index, 0, removed);

    const updatedChapters = reorderedChapters.map((chapter: any) => ({
      ...chapter,
      is_updated: true
  }));

    setChapters(updatedChapters);
  };

  const removeChapter = (index: any) => {
    const confirmation = window.confirm(t("remove-chapter"));

    if (confirmation) {
      // Use filter to remove the chapter at the specified index
      setChapters(chapters.filter((_: any, i:any) => i !== index));
      setCurrentChapterIndex(0);
    }
  };

  return (
    <div className="absolute w-full z-[100] bg-white">
        <div className='w-full flex justify-end pt-3'>
        <IconButton
                          onClick={()=>setSwitchChapters(false)}
                          className=""><CloseIcon /></IconButton>
        </div>
    <DragDropContext onDragEnd={onDragEnd}>
        <Droppable droppableId="chapters">
          {(provided) => (
            <List ref={provided.innerRef} {...provided.droppableProps}>
              {chapters.map((chapter: any, index: any) => (
                <Draggable key={index} draggableId={`chapter-${index}`} index={index}>
                  {(provided, snapshot) => (
                    <ListItem
                      ref={provided.innerRef}
                      {...provided.draggableProps}
                      {...provided.dragHandleProps}
                      className='box-border my-2 rounded-lg'
                      style={{
                        ...provided.draggableProps.style,
                        marginBottom: '8px',
                        border: snapshot.isDragging ? '2px solid var(--primary-bg)' : '2px solid gainsboro',
                        backgroundColor: snapshot.isDragging ? 'white' : 'white'
                      }}

                    >
                                                <DragIndicatorIcon/>

                      <ListItemText
                        primary={`${index + 1}. ${chapter.title || "chapter" + " " + (index + 1)}`}
                      />
                      {chapters.length > 1 && (
                        <IconButton
                          onClick={()=>removeChapter(index)}
                          color="error"
                          className=""><Delete /></IconButton>
                      )}

                    </ListItem>
                  )}
                </Draggable>
              ))}
              {provided.placeholder}
            </List>
          )}
        </Droppable>
      </DragDropContext>

    </div>
  );
};

export default DraggableChapterList;
