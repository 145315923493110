import { FunctionComponent, useContext, useState, useEffect } from "react";
import { useTranslation } from 'react-i18next';
import Button from '@mui/material/Button';
import PersonAddIcon from '@mui/icons-material/PersonAdd';
import PersonIcon from '@mui/icons-material/Person';
import AnalyticsIcon from '@mui/icons-material/Analytics';
import CollectionsBookmarkIcon from '@mui/icons-material/CollectionsBookmark';

import { followUser, unfollowUser, checkFollowStatus } from "../../utils/api/api";
import { AuthContext } from '../authentification/AuthContext';

const Header: FunctionComponent<any> = ({user:pageUser, setStatsOpen, statsOpen}) => {
  const { t } = useTranslation();
  const authContext = useContext(AuthContext);
  const { user } = authContext || {};
  const [isFollowing, setIsFollowing] = useState(false);
  const [isRequestPending, setIsRequestPending] = useState(false);

  useEffect(() => {
    const fetchFollowStatus = async () => {
      if (!pageUser?.id) return;

      try {
        const status = await checkFollowStatus(pageUser.id);
        setIsRequestPending(status.isRequestPending);
        setIsFollowing(status.isFollowing);
      } catch (error) {
        console.error("Error checking follow status:", error);
      }
    };

    fetchFollowStatus();
  }, [pageUser?.id]);

  const handleFollow = async () => {
    if (!pageUser?.id) {
      console.error("User ID is missing.");
      return;
    }

    try {
      await followUser(pageUser.id);
      setIsRequestPending(true);
    } catch (error) {
      console.error("Error following user:", error);
      const customEvent = new CustomEvent("apiError", { detail: error });
      window.dispatchEvent(customEvent);
    }
  };

  return (
    <div className="w-full flex flex-col items-center justify-start max-w-full">
          <div className="w-full md:w-2/3 rounded-xl bg-primary flex flex-col items-center justify-center p-12 box-border max-w-full   shadow-2xl">
            <div className="w-full md:w-2/3 flex flex-col items-center justify-start gap-[24px] max-w-full">
            {pageUser && (
              <div className="flex flex-row items-center justify-start gap-[16px]">


                <img
                  className="h-16 w-16 relative rounded-61xl object-cover"
                  loading="lazy"
                  alt=""
                  src={pageUser?.profile_picture? `${pageUser.profile_picture}` : "/profile-picture@3x.png"}
                />
                <div className="flex flex-col items-start justify-center">
                  <h2 className="m-0 relative text-inherit leading-[28px] font-medium font-inherit mq450:text-base mq450:leading-[22px] text-white tracking-wide	">
                    {pageUser?.first_name} {pageUser?.last_name}
                  </h2>
                  <div className="relative text-sm font-normal leading-[20px] text-whitesmoke-300 tracking-wider">{`Traveller`}</div>
                </div>

              </div>
              )}
              <h3 className="m-0 self-stretch relative text-lg leading-[26px] font-normal font-inherit text-whitesmoke-100 text-center">
                {pageUser?.bio}
              </h3>
              <div className="flex flex-row items-start justify-start gap-[8px]">
              {pageUser?.insta && (
                <a target="_blank" href={pageUser?.insta}>
                <div className="rounded-md bg-lightolivegreen overflow-hidden flex flex-row items-center justify-center p-2 cursor-pointer">
                  <img
                    className="h-4 w-4 relative overflow-hidden shrink-0"
                    loading="lazy"
                    alt=""
                    src="/logoinstagram.svg"
                  />
                </div>
                </a>
              )}
              {pageUser?.facebook && (
                <a target="_blank" href={pageUser?.facebook}>
                <div className="rounded-md bg-lightolivegreen overflow-hidden flex flex-row items-center justify-center p-2 cursor-pointer">
                  <img
                    className="h-4 w-4 relative overflow-hidden shrink-0"
                    loading="lazy"
                    alt=""
                    src="/logofacebook.svg"
                  />
                </div>
                </a>
)}
{pageUser?.youtube && (
                <a target="_blank" href={pageUser?.youtube}>
                <div className="rounded-md bg-lightolivegreen overflow-hidden flex flex-row items-center justify-center p-2 cursor-pointer">
                  <img
                    className="h-4 w-4 relative overflow-hidden shrink-0"
                    loading="lazy"
                    alt=""
                    src="/logoyoutube.svg"
                  />
                </div>
                </a>
)}
{pageUser?.web && (
                <a target="_blank" href={pageUser?.web}>
                <div className="rounded-md bg-lightolivegreen overflow-hidden flex flex-row items-center justify-center p-2 cursor-pointer">
                  <img
                    className="h-4 w-4 relative overflow-hidden shrink-0"
                    loading="lazy"
                    alt=""
                    src="/logoweb.svg"
                  />
                </div>
                </a>
)}
              </div>
              {/* {user.id !== pageUser?.id ?
              isFollowing ?
              (
                <Button variant="outlined" startIcon={<PersonAddIcon />} onClick={() => handleFollow()}>
                {t('user-unfollow')}
              </Button>
              ) :
              (
                      <Button variant="outlined" color='info' startIcon={<PersonAddIcon />} onClick={() => handleFollow()}>
                      {t('user-follow')}
                    </Button>
              ) : null
              } */}
          {(user && user.id !== pageUser?.id) ?
            isRequestPending ?
              (
                <Button variant="outlined" color='info' startIcon={<PersonAddIcon />} disabled>
                  {t('user-requested')}
                </Button>
              ) :
              isFollowing ?
                (
                  null
                ) :
                (
                  <Button variant="outlined" color='info' startIcon={<PersonAddIcon />} onClick={() => handleFollow()}>
                    {t('user-follow')}
                  </Button>
                ) : null
          }

{(user && user.id === pageUser?.id) &&
<div className="flex flex-row gap-2">
                <Button variant="outlined" color='info' startIcon={statsOpen ? <PersonIcon/> : <AnalyticsIcon />} onClick={()=>setStatsOpen(!statsOpen)}>
                  {statsOpen ? t('user-trips') : t('user-stats')}
                </Button>

                  {/* <Button variant="outlined" color='info' startIcon={<CollectionsBookmarkIcon />} onClick={() => handleFollow()}>
                    {t('user-follow')}
                  </Button> */}
                              </div>

          }



            </div>
          </div>
        </div>
  );
};

export default Header;
