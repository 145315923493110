import * as React from 'react';
import Avatar from '@mui/material/Avatar';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Stack from '@mui/material/Stack';
import type { SxProps } from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import Co2Icon from '@mui/icons-material/Co2';

export interface TotalDistancesProps {
  diff?: number;
  icon: React.ReactElement;
  title: string;
  sx?: SxProps;
  value: string;
  color: string;
}

export function TotalDistances({ diff, icon, title, sx, value, color }: TotalDistancesProps): React.JSX.Element {
  return (
    <Card sx={sx}>
      <CardContent>
        <Stack spacing={2}>
          <Stack direction="row" sx={{ alignItems: 'flex-start', justifyContent: 'space-between' }} spacing={3}>
            <Stack spacing={1}>
              <Typography color="text.secondary" variant="overline">
                {title}
              </Typography>
              <Typography variant="h4">{value}</Typography>
            </Stack>
            <Avatar className={``} sx={{ height: '56px', width: '56px', backgroundColor: color }}>
              {icon}
            </Avatar>
          </Stack>
        </Stack>
      </CardContent>
    </Card>
  );
}
