import * as React from 'react';
import Stack from '@mui/material/Stack';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Radio from '@mui/material/Radio';
import { useTranslation } from 'react-i18next';
import Tooltip from '@mui/material/Tooltip';


import { BarChart, BarChartProps } from '@mui/x-charts/BarChart';
import { axisClasses } from '@mui/x-charts/ChartsAxis';




export default function CO2BarChart({statCO2All}: any) {
  const { t } = useTranslation();


  const transformedData = Object.keys(statCO2All).map(key => ({
    name: key,
    kgCO2eq: statCO2All[key].kgCO2eq
  })).sort((a, b) => b.kgCO2eq - a.kgCO2eq);

  const dataset = transformedData.map((item, index) => ({
    rank: index + 1,
    name: item.name,
    kgCO2eq: item.kgCO2eq,
  }));

  const valueFormatter = (value: any) => `${value?.toFixed(0)} kgCO2eq`;

  const chartParams: BarChartProps = {
    yAxis: [
      {
        label: 'kgCO2eq',
      },
    ],
    series: [
      {
        label: t('co2-trip'),
        dataKey: 'kgCO2eq',
        valueFormatter
      },
    ],
    slotProps: { legend: { hidden: true } },
    dataset,
    height: 400,
    // sx: {
    //   [`.${axisClasses.left} .${axisClasses.label}`]: {
    //     transform: 'translate(-20px, 0)',
    //   },
    // },
  };

  return (
    <div style={{ width: '100%' }}>

      <BarChart
      xAxis={[
        {
          scaleType: 'band',
          dataKey: 'rank',
          valueFormatter: (rank: number, context: any) =>
            context.location === 'tick'
              ? `${rank}`
              : `${dataset.find((d) => d.rank === rank)?.name}`,
        },
      ]}
      {...chartParams}
    />
    </div>
  );
}
