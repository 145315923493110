import React, { useState, useEffect } from "react";
import { TextField, CircularProgress, FormControlLabel, Radio, Button, IconButton, InputAdornment, Box } from "@mui/material";
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import SearchIcon from '@mui/icons-material/Search';
import { useTranslation } from "react-i18next";
import { CloseOutlined, MusicNote } from '@mui/icons-material'; // Import close icon
import { Title as TitleIcon, MusicNote as MusicNoteIcon } from '@mui/icons-material';

import {
    fetchSongsAPI
  } from "../../utils/api/api";

const SpotifySongSelector: React.FC<any> = ({setChapterSong, chapterSong}) => {
  const { t } = useTranslation();

  const [query, setQuery] = useState('');
  const [songs, setSongs] = useState([]);
  const [loading, setLoading] = useState(false);
  const [selectedSong, setSelectedSong] = useState<any>(null);

  useEffect(() => {
    if (query && query.length > 3) {
      const fetchSongs = async () => {
        setLoading(true);
        const searchUrl = query

        try {
          const response = await fetchSongsAPI(searchUrl);
          setSongs(response.tracks.items);
        } catch (error) {
          console.error('Error fetching songs:', error);
        } finally {
          setLoading(false);
        }
      };

      fetchSongs();
      setSelectedSong(null);
    }
  }, [query]);



  const handleQueryChange = (e: any) => {
    setQuery(e.target.value);
  };

  const handleSongSelect = (song: any) => {
    setSelectedSong(song);
  };

  return (
    <div>
            {(chapterSong && chapterSong!=='') ? (
                <div className="flex flex-col md:flex-row justify-center items-center gap-6">
                  <div className="scale-y-80 mt-[-8px] w-full">
                  <iframe
            className="rounded-[12px]"
            src={`https://open.spotify.com/embed/track/${chapterSong}?utm_source=generator&theme=0`}
              width="100%"
              height="80"
            frameBorder="0"
            allow="autoplay; clipboard-write; encrypted-media; fullscreen; picture-in-picture"
            loading="lazy"
          ></iframe>
                  </div>

                          {/* <Button variant="outlined" startIcon={<SearchIcon />}
                onClick={() => setChapterSong('')}
                size="medium"
                className="!h-[64px] !px-6 !mb-2">
{t('other-song')}
              </Button> */}
              <IconButton color="info" onClick={() => {setChapterSong('')
                setSongs([])
                selectedSong(null)
              }}>
              <CloseOutlined />
            </IconButton>
                </div>

            ) : (
                <>
                <div className="flex flex-row gap-3 items-end">
                <Box sx={{ display: 'flex', alignItems: 'center', width: '100%' }}>
        <MusicNote sx={{ color: 'action.active', mr: 1, my: 0 }} />
        <TextField
        label={t("search-song")}
        variant="outlined"
        fullWidth
        size="small"
        value={query}
        onChange={handleQueryChange}
        margin="normal"
        className="!mt-0"
        />
      </Box>
      {/* <TextField
        label={t("search-song")}
        variant="outlined"
        fullWidth
        size="small"
        value={query}
        onChange={handleQueryChange}
        margin="normal"
        className="!mt-0"
        slotProps={{
          input: {
            startAdornment: (
              <InputAdornment position="end">
                <MusicNote />
              </InputAdornment>
            ),
          },
        }}
      /> */}
            {selectedSong && (
                <Button variant="outlined" startIcon={<CheckCircleOutlineIcon />}
                onClick={() => setChapterSong(selectedSong.id)}
                size="medium"
                className="!h-[40px] !px-6 !mb-2">
{t("select-song")}
              </Button>
      )}
      </div>
      {loading && <CircularProgress />}
      <div>
        {songs.map((song: any, index) => (
          <div className="w-full my-1" key={song.id} style={{ display: 'flex', alignItems: 'center' }}>
                        <FormControlLabel
                                          className="!mr-0"

              control={
                <Radio
                  checked={selectedSong === song}
                  onChange={() => handleSongSelect(song)}
                  value={song.id}
                  name="selectedSong"
                />
              }
              label=""
            />
            <iframe
              className="rounded-[12px]"
              src={`https://open.spotify.com/embed/track/${song.id}?utm_source=generator&theme=0`}
              width="80%"
              height="80"
              frameBorder="0"
              allow="autoplay; clipboard-write; encrypted-media; fullscreen; picture-in-picture"
              loading="lazy"
            ></iframe>

          </div>
        ))}
              </div>
              </>
            )}



    </div>
  );
};

export default SpotifySongSelector;
