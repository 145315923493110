import React, { useEffect, useState, useMemo } from "react";
import { useAutocomplete, AutocompleteGetTagProps } from '@mui/base/useAutocomplete';
import CheckIcon from '@mui/icons-material/Check';
import CloseIcon from '@mui/icons-material/Close';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import Collapse from '@mui/material/Collapse';
import { styled } from '@mui/material/styles';
import { autocompleteClasses } from '@mui/material/Autocomplete';
import { useTranslation } from 'react-i18next';

import { MultiInputProps, ActivityOptionType } from '../../types';


const Label = styled('label')`
line-height: 1.5;
display: block;
display: flex;
justify-content: center;
align-items: center;
color: grey;
padding: 0 10px;
`;

const InputWrapper = styled('div')(
  ({ theme }) => `
  width: 100%;
  border: 1px solid ${theme.palette.mode === 'dark' ? '#434343' : '#d9d9d9'};
  background-color: ${theme.palette.mode === 'dark' ? '#141414' : '#fff'};
  border-radius: 4px;
  padding: 1px;
  display: flex;
  flex-wrap: wrap;

  &:hover {
    border-color: ${theme.palette.mode === 'dark' ? '#177ddc' : '#40a9ff'};
  }

  &.focused {
    border-color: ${theme.palette.mode === 'dark' ? '#177ddc' : '#40a9ff'};
    box-shadow: 0 0 0 2px rgba(24, 144, 255, 0.2);
  }

  & input {
    background-color: ${theme.palette.mode === 'dark' ? '#141414' : '#fff'};
    color: ${
      theme.palette.mode === 'dark' ? 'rgba(255,255,255,0.65)' : 'rgba(0,0,0,.85)'
    };
    height: 30px;
    box-sizing: border-box;
    padding: 4px 6px;
    width: 0;
    min-width: 30px;
    flex-grow: 1;
    border: 0;
    margin: 0;
    outline: 0;
  }
`,
);

interface TagProps extends ReturnType<AutocompleteGetTagProps> {
  label: string;
  icon: string;
}

function Tag(props: TagProps) {
  const { label, icon, onDelete, ...other } = props;
  return (
    <div {...other}>
                        <img
            loading="lazy"
            width="20"
            src={icon}
            srcSet={icon}
            alt=""
          />
      <span className='pl-[5px]'>{label}</span>
      <CloseIcon onClick={onDelete} />
    </div>
  );
}

const StyledTag = styled(Tag)<TagProps>(
  ({ theme }) => `
  display: flex;
  align-items: center;
  height: 24px;
  margin: 2px;
  line-height: 22px;
  background-color: ${
    theme.palette.mode === 'dark' ? 'rgba(255,255,255,0.08)' : '#fafafa'
  };
  border: 1px solid ${theme.palette.mode === 'dark' ? '#303030' : '#e8e8e8'};
  border-radius: 2px;
  box-sizing: content-box;
  padding: 0 4px 0 10px;
  outline: 0;
  overflow: hidden;

  &:focus {
    border-color: ${theme.palette.mode === 'dark' ? '#177ddc' : '#40a9ff'};
    background-color: ${theme.palette.mode === 'dark' ? '#003b57' : '#e6f7ff'};
  }

  & span {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }

  & svg {
    font-size: 12px;
    cursor: pointer;
    padding: 0px;
  }
`,
);

const Listbox = styled('ul')<any>(
  ({ theme, position }) => `
  width: 300px;
  border: 1px solid rgba(0, 0, 0, 0.15);
  margin: 2px 0 0;
  padding: 0;
  position: absolute;
  list-style: none;
  background-color: rgba(252, 252, 252, 90);
  overflow: auto;
  max-height: 250px;
  border-radius: 10px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);
  z-index: 1;
  ${position === 'top' ? 'bottom: 125%;' : 'top: 125%;'}

  & li {
    padding: 5px 12px;
    display: flex;

    & span {
      flex-grow: 1;
    }

    & svg {
      color: transparent;
    }
  }

  & li[aria-selected='true'] {
    background-color: ${theme.palette.mode === 'dark' ? '#2b2b2b' : '#fafafa'};
    font-weight: 600;

    & svg {
      color: #1890ff;
    }
  }

  & li.${autocompleteClasses.focused} {
    background-color: ${theme.palette.mode === 'dark' ? '#003b57' : '#e6f7ff'};
    cursor: pointer;

    & svg {
      color: currentColor;
    }
  }
`,
);

const MultiInput: React.FC<any> = ({ activityOptions, onActivitiesChange, defaultOptions, placeholder}) => {
  const { t, i18n } = useTranslation();
  const [searchText, setSearchText] = useState('');
  const [expandedCategories, setExpandedCategories] = useState<Record<number, boolean>>({});
  const [isInitialized, setIsInitialized] = useState(false); // Add a state to track initialization
  const [listboxPosition, setListboxPosition] = useState<'top' | 'bottom'>('bottom');
  const [anchoredEl, setAnchoredEl] = useState<HTMLDivElement | null>(null);

  // Calculate position when anchor element is set or when it changes
  useEffect(() => {
    if (anchoredEl) {
      const rect = anchoredEl.getBoundingClientRect();
      const viewportHeight = window.innerHeight;

      // Check available space above and below
      const spaceBelow = viewportHeight - rect.bottom;
      const spaceAbove = rect.top;

      // Set the position based on which side has more space
      setListboxPosition(spaceBelow < 250 && spaceAbove > spaceBelow ? 'top' : 'bottom');
    }
  }, [anchoredEl]);

  const toggleCategory = (categoryId: number) => {
    setExpandedCategories(prev => ({ ...prev, [categoryId]: !prev[categoryId] }));
  };

  // Flatten and include subcategories in search results
  const filteredOptions = useMemo(() => {
    let options: ActivityOptionType[] = [];

    let lang = i18n.language

    activityOptions.forEach((option:any) => {

      if (option.name === 'roadtrip') return

      let label = ''
      if (lang === 'nl') {
          label = option.label_nl
      } else if (lang === 'fr') {
        label = option.label_fr
      } else {
        label = option.label
      }

      if (searchText === '' || label.toLowerCase().includes(searchText.toLowerCase())) {
      options.push({...option, label: label});
    }

      if (option.subcategories && (expandedCategories[option.id] || searchText !== '' )) {
        option.subcategories.forEach((subcategory:any) => {
          let sublabel = ''
          if (lang === 'nl') {
            sublabel = subcategory.label_nl
          } else if (lang === 'fr') {
            sublabel = subcategory.label_fr
          } else {
            sublabel = subcategory.label
          }
          if (sublabel.toLowerCase().includes(searchText.toLowerCase())) {
            options.push({ ...subcategory, parentCategoryId: option.id, id: subcategory.id, label: sublabel});
          }
        });
      }
    });
    return options;

  }, [activityOptions, expandedCategories, searchText, i18n.language]);

  const allOptions = useMemo(() => {
    let options: ActivityOptionType[] = [];
    let lang = i18n.language;

    activityOptions.forEach((option: any) => {
      // Decide the label based on current language
      let label = '';
      if (lang === 'nl') {
        label = option.label_nl;
      } else if (lang === 'fr') {
        label = option.label_fr;
      } else {
        label = option.label;
      }

      // Add the parent category to the options list
      options.push({ ...option, label: label });

      // Add all subcategories to the options list, if they exist
      if (option.subcategories) {
        option.subcategories.forEach((subcategory: any) => {
          let sublabel = '';
          if (lang === 'nl') {
            sublabel = subcategory.label_nl;
          } else if (lang === 'fr') {
            sublabel = subcategory.label_fr;
          } else {
            sublabel = subcategory.label;
          }

          options.push({ ...subcategory, parentCategoryId: option.id, id: subcategory.id, label: sublabel });
        });
      }
    });

    return options;
  }, [activityOptions, i18n.language]);



  const {
    getRootProps,
    getInputLabelProps,
    getInputProps,
    getTagProps,
    getListboxProps,
    getOptionProps,
    groupedOptions,
    value,
    focused,
    setAnchorEl,
  } = useAutocomplete({
    id: 'customized-hook-demo',
    defaultValue: defaultOptions ? allOptions.filter(option =>
      defaultOptions.some((defaultOption: any) => defaultOption.id === option.id)) : undefined,
    multiple: true,
    options: filteredOptions,
    getOptionLabel: (option) => option.label,
    isOptionEqualToValue: (option, value) => option.id === value.id,
    onInputChange: (_, value) => setSearchText(value),
  });


  useEffect(() => {
    // Only update activities after the initial load
    if (isInitialized) {
      onActivitiesChange(value.map(option => option));
    } else {
      // Mark the component as initialized after the first render
      setIsInitialized(true);
    }
  }, [value]);



  return (
    <div className="w-full text-[14px] relative">
      <div {...getRootProps()} ref={setAnchoredEl}>
        {/* <Label {...getInputLabelProps()}>Select activities</Label> */}

        <InputWrapper ref={setAnchorEl} className={focused ? 'focused' : ''}>

        {/* {value.length === 0 && (
            <Label {...getInputLabelProps()}>
              {placeholder? placeholder : 'Any'}
            </Label>
          )} */}
          {value.map((option, index: number) => (
            <StyledTag label={option.label} icon={option.icon} {...getTagProps({ index })} />
          ))}

          <input {...getInputProps()} />
        </InputWrapper>
      </div>
      {groupedOptions.length > 0 ? (
        <Listbox {...getListboxProps()} position={listboxPosition}>
                    {filteredOptions.map((option, index) => (
          <div key={index} className="flex flex-row justify-between cursor-pointer">

            <li className="w-full" key={option.id} {...getOptionProps({ option, index })}>
              <div className='flex items-center justify-between'>
                <div className={`flex items-center gap-3 ${option.parent ? 'pl-8' : ''}`}>
                  <img src={option.icon} alt="" width="20" />
                  <span className="text-slate-600 font-msr">{i18n.language !== 'en' ?
                  (i18n.language === 'fr' ? option.label_fr : option.label_nl) :
                  option.label}</span>
                </div>
                </div>
            </li>
            {(option.subcategories && option.subcategories.length > 0) && (
                  expandedCategories[option.id] ? (

                    <div className="w-[30px] hover:bg-primary"><ExpandLessIcon onClick={() => toggleCategory(option.id)} />
                   </div>) : (
                    <div className="w-[30px] hover:bg-primary">
                    <ExpandMoreIcon onClick={() => toggleCategory(option.id)} /> </div>
                  )

                )
               }
            </div>

          ))}

        </Listbox>
      ) : null}
    </div>
  );
}

export default MultiInput;

