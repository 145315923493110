import React, { useState, useContext } from 'react';
import { Link } from 'react-router-dom';
import { useTranslation } from "react-i18next";
import FavoriteIcon from '@mui/icons-material/Favorite';
import FavoriteBorderIcon from '@mui/icons-material/FavoriteBorder';
import Button from '@mui/material/Button';
import Avatar from '@mui/material/Avatar';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import DialogTitle from '@mui/material/DialogTitle';
import Dialog from '@mui/material/Dialog';
import PersonIcon from '@mui/icons-material/Person';

import { AuthContext } from "../authentification/AuthContext";
import { LikeBlog } from "../../utils/api/api";


const BlogLikeUpdate: React.FC<any> = ({ blog, likers, updateLikers }) => {
    const { t } = useTranslation();

    const authContext = useContext(AuthContext);
    const { user } = authContext || {};


    const initialLikedStatus = user && likers.some((liker: any) => liker.id === user.id);

    const [isLiked, setIsLiked] = useState(initialLikedStatus);
    const [showLikers, setShowLikers] = useState(false);


  const toggleLike = async () => {
    const newIsLiked = !isLiked;
    setIsLiked(newIsLiked);

    // Optimistically update the likers list
    if (newIsLiked) {
        updateLikers([...likers, { id: user.id, username: user.username }]);
    } else {
        updateLikers(likers.filter((liker: any) => liker.id !== user.id));
    }

    // Call the API to update the like status on the server
    try {
        await LikeBlog(blog.id);
        // Optionally handle response
    } catch (error) {
        console.error('Failed to update like status:', error);
        // Rollback if there's an error
        setIsLiked(!newIsLiked);
        updateLikers(blog.likers);
    }
};


  const handleClickOpen = () => {
    setShowLikers(true);
  };

  const handleClose = (value: string) => {
    setShowLikers(false);
  };


  return (
    <div className="flex flex-col items-end w-full">
      <div className="flex flex-row items-center justify-start gap-2">
        <button onClick={toggleLike} className="text-red-400">
          {isLiked ? <FavoriteIcon/> : <FavoriteBorderIcon />}
        </button>
        <button onClick={() => setShowLikers(!showLikers)} className="text-gray-600 hover:text-gray-900">
          {likers.length}
        </button>
      </div>
      {showLikers && (
            <Dialog onClose={handleClose} open={showLikers}>
            <DialogTitle>{t("likers")}</DialogTitle>
            <List sx={{ pt: 0 }}>
              {likers.map((liker: any) => (
                <ListItem disableGutters key={liker.id}>
                   <Link
                              to={`/${liker.username}`}
                              className="text-gray1 hover:text-olivegreen w-full"
                            >
                                <ListItemButton >
                  <ListItemAvatar>
                    <Avatar src={liker.profile_picture
                                      ? `${liker.profile_picture}`
                                      : "/SlowTrav_icon.png"
                                  }>
                    </Avatar>
                  </ListItemAvatar>
                    <ListItemText primary={`${liker.first_name || ''} ${liker.last_name || ''}`}/>
                    </ListItemButton>
                    </Link>
                </ListItem>
              ))}
            </List>
          </Dialog>

      )}
    </div>
  );
};

export default BlogLikeUpdate;
