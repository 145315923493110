import React, {
    FunctionComponent,
  } from "react";
import ToggleButton from "@mui/material/ToggleButton";
import ToggleButtonGroup from "@mui/material/ToggleButtonGroup";
import Tooltip from "@mui/material/Tooltip";
import {Tabs, Tab} from "@nextui-org/react";
import { FaLock, FaUserFriends } from "react-icons/fa";
import { MdPublic } from "react-icons/md";


import { useTranslation } from 'react-i18next';

export interface VisibilityProp {
    value: any;
    onChange: any;
  }

const VisibilityTooltip: FunctionComponent<VisibilityProp> = ({ value, onChange  }) => {
    const { t } = useTranslation();

    return (
      <div className="flex w-full flex-col">
      <Tabs aria-label="Options" color="primary" variant="bordered"
      onSelectionChange={onChange}
      selectedKey={value}
      fullWidth={true}
>
        <Tab
          key={'private'}
          title={
            <div className="flex items-center space-x-2">
              <FaLock/>
              <span>{t('create-private')}</span>
            </div>
          }
        />
        <Tab
          key={'friends'}
          title={
            <div className="flex items-center space-x-2">
              <FaUserFriends/>
              <span>{t('create-friends')}</span>
            </div>
          }
        />
        <Tab
          key={'public'}
          title={
            <div className="flex items-center space-x-2">
              <MdPublic/>
              <span>{t('create-public')}</span>
            </div>
          }
        />
      </Tabs>
    </div>


);
};

export default VisibilityTooltip;
