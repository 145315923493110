import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { motion, AnimatePresence } from 'framer-motion'
// @ts-ignore
import confetti from 'canvas-confetti'
import Navbar from "../components/main/Navbar";
import {Button, Card, CardBody} from "@nextui-org/react";

import {fetchGPXTrack} from '../utils/api/api'

const GPXDownloadPage = () => {
  const { type, segmentId } = useParams();
  const [isReady, setIsReady] = useState(false)

    useEffect(() => {
        // Fetch and download GPX
        const fetchAndDownloadGPX = async () => {
          try {
            const gpxData = await fetchGPXTrack(type, segmentId);
            const blob = new Blob([gpxData], { type: 'application/gpx+xml' });
            const url = window.URL.createObjectURL(blob);

            // Automatically download the GPX file
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', `segment_${segmentId}.gpx`);
            document.body.appendChild(link);
            link.click();

            link.parentNode.removeChild(link);
            window.URL.revokeObjectURL(url);
          } catch (error) {
            console.error('Error fetching GPX track:', error);
          } finally {
            setIsReady(true);
          }
        };

        fetchAndDownloadGPX();
      }, [segmentId]);


  return (
    <div className={`w-full relative bg-primary-contrast overflow-hidden gap-[30px] min-h-screen`}>
      <Navbar />
<div className="flex relative h-[calc(100vh-68px)] top-[68px] items-center justify-center bg-gradient-to-br from-primary to-primary/10">

      <AnimatePresence mode="wait">
        {!isReady ? (
          <motion.div
            key="preparing"
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            exit={{ opacity: 0, y: -20 }}
            transition={{ duration: 0.5 }}
          >
            <Card className="w-full max-w-md mx-auto overflow-hidden bg-white/10 backdrop-blur-md border-none shadow-lg">
              <CardBody className="p-6 text-center">
                <motion.div
                  animate={{ rotate: 360 }}
                  transition={{ duration: 2, repeat: Infinity, ease: "linear" }}
                  className="w-16 h-16 border-4 border-t-blue-500 border-r-transparent border-b-blue-500 border-l-transparent rounded-full mx-auto mb-6"
                />
                <h2 className="text-3xl font-bold mb-2 text-white">We're preparing your track</h2>
                <p className="text-lg text-blue-200">Hold tight, it'll be worth the wait!</p>
              </CardBody>
            </Card>
          </motion.div>
        ) : (
          <motion.div
            key="ready"
            initial={{ opacity: 0, scale: 0.8 }}
            animate={{ opacity: 1, scale: 1 }}
            transition={{ duration: 0.5 }}
          >
            <Card className="w-full max-w-md mx-auto overflow-hidden bg-white/10 backdrop-blur-md border-none shadow-lg">
              <CardBody className="p-6 text-center">
                <h2 className="text-4xl font-bold mb-4 text-white">Let's get going!</h2>
                <p className="text-xl text-slate-500 mb-6">Your track is ready for an amazing journey</p>
                <Button
                  size="lg"
                  color='primary'
                  onClick={() => confetti({
                    particleCount: 100,
                    spread: 70,
                    origin: { y: 0.6 }
                  })}
                >
                  Let's go!
                </Button>
              </CardBody>
            </Card>
          </motion.div>
        )}
      </AnimatePresence>
    </div>
    </div>

  );
};

export default GPXDownloadPage;
