import { Card, CardBody, CardHeader } from "@nextui-org/react";
import { CiStar } from "react-icons/ci";
import { GoAlert } from "react-icons/go";

interface TravelSummaryProps {
  blog: {
    pos_negs: {
      highlights: string[];
      watchOuts: string[];
    };
  };
}

export default function TravelSummary({ blog }: TravelSummaryProps) {
  const { highlights = [], watchOuts = [] } = blog.pos_negs || {};

  return (
    <div className="flex flex-col gap-4">
      <Card className="overflow-hidden">
        <CardHeader className="bg-green-50 dark:bg-[#8ABFA3]">
          <div className="flex items-center text-white dark:text-white">
            <CiStar className="mr-2 h-5 w-5" />
            Highlights
          </div>
        </CardHeader>
        <CardBody className="pt-6">
          {highlights.length > 0 ? (
            <ul className="space-y-4">
              {highlights.map((highlight, index) => (
                <li key={index} className="flex items-start">
                  <CiStar className="mr-2 h-5 w-5 text-green-500 flex-shrink-0" />
                  <span className="text-sm">{highlight}</span>
                </li>
              ))}
            </ul>
          ) : (
            <p className="text-sm text-muted-foreground">No highlights available.</p>
          )}
        </CardBody>
      </Card>

      <Card className="overflow-hidden">
        <CardHeader className="bg-amber-50 dark:bg-[#CB6040]">
          <div className="flex items-center text-amber-700 dark:text-white">
            <GoAlert className="mr-2 h-5 w-5" />
            Watch Outs
          </div>
        </CardHeader>
        <CardBody className="pt-6">
          {watchOuts.length > 0 ? (
            <ul className="space-y-4">
              {watchOuts.map((watchOut, index) => (
                <li key={index} className="flex items-start">
                  <GoAlert className="mr-2 h-5 w-5 text-amber-500 flex-shrink-0" />
                  <span className="text-sm">{watchOut}</span>
                </li>
              ))}
            </ul>
          ) : (
            <p className="text-sm text-muted-foreground">No watch-outs available.</p>
          )}
        </CardBody>
      </Card>
    </div>
  )
}
