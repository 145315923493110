import { FunctionComponent, useState, useEffect, useContext } from "react";
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import Navbar from "../components/main/Navbar";
import Header from "../components/municipality/Header";
import Content from "../components/municipality/Content";
import Footer from "../components/main/Footer";

import { fetchMunicipalityRoutesByAuthor, fetchUser } from '../utils/api/api';



const MunicipalPage: FunctionComponent = () => {
  const { t } = useTranslation();

    const [routes, setRoutes] = useState([]);
    const [pageUser, setPageUser] = useState<any>(null);
    const { authorId } = useParams();

    useEffect(() => {
      const fetchMunicipalData = async () => {
        try {
          const data = await fetchMunicipalityRoutesByAuthor(authorId);
          setRoutes(data);
        } catch (error) {
          console.error('Error fetching data:', error);
        }
        try {
          const data = await fetchUser(authorId);
          setPageUser(data);
        } catch (error) {
          console.error('Error fetching data:', error);
        }
      };

      fetchMunicipalData();
    }, [authorId]);

  return (
    <div className="w-full relative bg-primary-contrast overflow-hidden flex flex-col items-center justify-start gap-[30px]">
      <Navbar />
      <section className="w-[1256px] flex flex-col items-center justify-start pt-[90px] px-5 box-border gap-[48px] max-w-full text-left text-xl text-secondary-800 font-heading-h3-bold mq900:gap-[24px] pb-[100px]">
      <Header
      user={pageUser}
      />
        <div className="self-stretch flex flex-col items-center justify-start gap-[32px] max-w-full text-2xl mq900:gap-[16px]">
          <div className="self-stretch flex flex-row items-center justify-start max-w-full mq900:gap-[16px]">
            <h1 className="m-0 flex-1 relative text-inherit leading-[28px] font-bold font-inherit inline-block max-w-full mq450:text-[19px] mq450:leading-[22px]">
            {t('author-latest')}
            </h1>
          </div>
          <Content
          routes={routes}
          />
        </div>
      </section>
      <Footer />
    </div>
  );
};

export default MunicipalPage;
