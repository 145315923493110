import React, {
  FunctionComponent,
  useEffect,
  useState,
  useContext,
  useRef,
} from "react";
import { useParams, useNavigate, Link, useLocation } from "react-router-dom";
import CircularProgress from "@mui/material/CircularProgress";
import { Button, InputAdornment, TextField, IconButton } from "@mui/material";
import { useTranslation } from "react-i18next";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import Typography from "@mui/material/Typography";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ImageGallery from "react-image-gallery";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import DownloadIcon from "@mui/icons-material/Download";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import { QRCode } from "react-qrcode-logo";
import GroupAvatars from "../components/blog/BlogContributors";
// import Pagination from "@mui/material/Pagination";
import AccessTimeIcon from "@mui/icons-material/AccessTime";
import HistoryIcon from "@mui/icons-material/History";
import { ScrollShadow, Pagination } from "@nextui-org/react";

// @ts-ignore
import { debounce } from "lodash";
import DragHandleIcon from "@mui/icons-material/DragHandle";
import ArrowLeftIcon from "@mui/icons-material/ArrowLeft";
import ArrowRightIcon from "@mui/icons-material/ArrowRight";
import MapIcon from "@mui/icons-material/Map";
import Tooltip from "@mui/material/Tooltip";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import ArticleIcon from "@mui/icons-material/Article";
import InstagramIcon from "@mui/icons-material/Instagram";

import PDF from "./PDF";
import Navbar from "../components/main/Navbar";
import MapboxMapView from "../components/map/MapView";
import { AuthContext } from "../components/authentification/AuthContext";
import BlogLikes from "../components/blog/BlogLikeUpdate";
import {
  deleteBlogPost,
  fetchNewBlogById,
  updateCurrentTripAPI,
  fetchBlogQuestions,
} from "../utils/api/api";
import {
  createGeoJSONFeatureCollection,
  getLocalizedField,
  downloadGPX,
} from "../utils/utils";
import RouteDist from "../components/map/RouteDist";
import TravelSummary from "../components/blog/TravelSummary";
import AuthorQACard from "../components/blog/Questions";
import { Close } from "@mui/icons-material";
import DoughnutChart from "../components/blog/DoughnutChart";
import StackedBarCO2 from "../components/blog/StackedBarCO2";
import QrCodeIcon from "@mui/icons-material/QrCode";
import ShareIcon from "@mui/icons-material/Share";
import Dialog, { DialogProps } from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";


const formatDate = (isoString: any) => {
  if (!isoString) return "";
  const date = new Date(isoString);
  return date.toLocaleDateString();
};

function transformChapterImages(chapter: any) {
  if (!chapter) return [];

  const images =
    chapter.images?.map((image: any) => ({
      type: "image",
      original: image.image_url || image.image_file,
      caption: image.caption || "",
    })) || [];

  const videos =
    chapter.videos?.map((video: any) => ({
      type: "video",
      original: video.video_url || video.video_file,
      caption: video.caption || "",
    })) || [];

  return [...images, ...videos];
}

const MyGallery: React.FC<any> = ({ items, isFullscreen, setIsFullscreen }) => {

  const handleScreenChange = (fullScreen: any) => {
    setIsFullscreen(fullScreen);
  };

  const renderItem = (item: any) => {
    if (item.type === "video") {
      return (
        <div className="image-gallery-image flex justify-start items-start">
          <video
            className="overflow-auto object-contain"
            controls
            style={{
              maxHeight: isFullscreen ? "100vh" : "300px",
              height: isFullscreen ? "auto" : "300px",
              width: "100%",
              maxWidth: "100%",
              margin: "auto",
              display: "block",
            }}
          >
            <source
              src={item.original}
              type="video/mp4"
              className="h-full w-full"
            />
            Your browser does not support the video tag.
          </video>
        </div>
      );
    }

    return (
      <div className="image-gallery-image flex justify-start items-start">
        <img
          src={item.original}
          alt={item.description}
          className="overflow-auto object-contain"
          style={{
            maxHeight: isFullscreen ? "100vh" : "300px",
            height: isFullscreen ? "auto" : "300px",
            width: "100%",
            maxWidth: "100vw",
            margin: "auto",
            display: "block",
          }}
        />
        {item.caption && (
          <span
            className="image-gallery-description"
            style={{ color: "white" }}
          >
            {item.caption}
          </span>
        )}
      </div>
    );
  };

  return (
    // <div className="w-full" style={{ height: isFullscreen ? '100vh' : '300px' }}>
    <ImageGallery
      items={items}
      renderItem={renderItem}
      onScreenChange={handleScreenChange}
      useBrowserFullscreen={false}
      showBullets={true}
      showPlayButton={false}
    />
    // </div>
  );
};

const NewBlogView: React.FC<any> = ({
  map,
  setMap,
  mapContainerRef,
  allLayers,
  setAllLayers,
  allMarkers,
  setAllMarkers,
  mapAnimation,
  setMapAnimation,
}) => {
  const { t, i18n } = useTranslation();

  const authContext = useContext(AuthContext);
  const { user } = authContext || {};
  const { id, authorId } = useParams<{ id: any; authorId: any }>();
  const [mapFeatures, setMapFeatures] = useState<any>(null);
  const [width, setWidth] = useState(100); // State to handle the width in percentage of the map
  const chartRef = useRef(null);
  const [elevationSummary, setElevationSummary] = useState<any[]>([
    null,
    null,
    null,
  ]);

  const navigate = useNavigate();

  const [isOverviewOpen, setIsOverviewOpen] = useState(false);
  const [isBlogOpen, setIsBlogOpen] = useState(false);

  const [blogID, setBlogID] = useState<any>(null);
  const [blog, setBlog] = useState<any>(null);
  const [selectedChapter, setSelectedChapter] = useState<any>(null);
  const [chapterIndex, setChapterIndex] = useState<any>(null);
  const [isLoading, setIsLoading] = useState<boolean | null>(true);
  const [tooltipOpen, setTooltipOpen] = useState(true);
  const [likers, setLikers] = useState<any[]>([]);
  const [contributors, setContributors] = useState<any[]>([]);
  const [page, setPage] = useState(1);
  const [gpxData, setGpxData] = useState(null);
  const [userIsAuthor, setUserIsAuthor] = useState(false);
  const [openQR, setOpenQR] = useState(false);
  const [userCurrentBlog, setUserCurrentBlog] = useState(false);
  const [pdfReady, setpdfReady] = useState(false);
  const [mapResize, setMapResize] = useState(false);
  const [showChart, setShowChart] = useState(false);
  const [tripPostImg, setTripPostImg] = useState<any>(null);
  const showElevationRef = useRef<any>(null);
  const [isFullscreen, setIsFullscreen] = useState(false);

  const [questions, setQuestions] = useState([]);
  const [qaOpen, setQaOpen] = useState(false);

  const query = new URLSearchParams(location.search);
  const code = query.get("code");

  const handleTooltipClose = () => {
    setTooltipOpen(false);
  };

  const updateLikers = (newLikers: any[]) => {
    setLikers(newLikers); // Update likers from BlogLikes component
  };
  const updateContributors = (newContributors: any[]) => {
    setContributors(newContributors); // Update likers from BlogLikes component
  };

  const handleBlogOpen = (value: boolean) => {
    setIsBlogOpen(value);
    setTooltipOpen(false);
  };

  const changeWidth = () => {
    if (width === 33 || width === 100) {
      setWidth(50);
    } else {
      setWidth(33);
    }
  };

  const handleResize = debounce(() => {
    if (window.innerWidth <= 768) {
      setWidth(100);
    } else {
      setWidth((width) => (width === 100 ? 33 : width));
    }
  }, 100); // Adjust the milliseconds according to your needs

  useEffect(() => {
    handleResize();
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const displayChapterContent = (chapterIndex: number) => {
    const chapterContent = blog.chapters.find(
      (chapter: any) => chapter.id === chapterIndex
    );
    const relativeChapterIndex = blog.chapters.findIndex(
      (chapter: any) => chapter.id === chapterIndex
    );
    setSelectedChapter(chapterContent);
    setChapterIndex(chapterIndex);
    // setTooltipOpen(true);
    setPage(relativeChapterIndex + 2);
  };
  const resetChapter = () => {
    setSelectedChapter(null);
    setChapterIndex(null);
    // setTooltipOpen(true);
    setPage(1);
    // const geoJSONFeatureCollection = createGeoJSONFeatureCollectionForChapter(blog, chapterIndex);
    // setMapFeatures(geoJSONFeatureCollection);
  };

  const pageChange = (event: any) => {
    if (event === 1) {
      setSelectedChapter(null);
      setChapterIndex(null);
    } else if (event > 1) {
      const chapterContent = blog.chapters[event - 2];
      setSelectedChapter(chapterContent);
      setChapterIndex(blog.chapters[event - 2].id);
    }
    setPage(event);
  };

  //   useEffect(() => {
  //     if (chapterIndex) {
  //       console.log('chapterIndex', chapterIndex)
  // setPage(chapterIndex + 1)
  //     } else {
  //       setPage(1)
  //     }

  //   }, [chapterIndex])

  useEffect(() => {
    if (chapterIndex) {
      displayChapterContent(chapterIndex);
    } else {
      resetChapter();
    }
  }, [chapterIndex]);

  useEffect(() => {
    const fetchBlogData = async () => {
      try {
        setBlogID(id);
        const data = await fetchNewBlogById(id, code);
        if (data.author.username === authorId) {
          setBlog(data);
          setLikers(data.likers || []);
          setContributors(data.contributors || []);
          const geoJSONFeatureCollection = createGeoJSONFeatureCollection(data);
          setMapFeatures(geoJSONFeatureCollection);
        } else {
          navigate(`/${authorId}`)
          throw new Error("Wrong URL. Redirected to the author's page");
        }
      } catch (error) {
        navigate(`/${authorId}`)
        console.error("Erroooooor fetching blog data:", error);
        const customEvent = new CustomEvent("apiError", { detail: error });
        window.dispatchEvent(customEvent);
        setIsLoading(false);
      }
    };

    fetchBlogData();
  }, [id]);

  useEffect(() => {
    const loadQuestions = async () => {
      try {
        const data = await fetchBlogQuestions(blogID);
        setQuestions(data);
      } catch (err) {
        console.log('error in fetching questions', err);
      }
    };

    loadQuestions();
  }, [blogID]);

  useEffect(() => {
    if (user && blog) {
      setUserIsAuthor(blog.author.id === user.id);
      setUserCurrentBlog(user.current_travel_blog === blog.id);
    }
  }, [user, blog]);

  const handleEdit = () => {
    navigate(`/edit-blog/${blogID}`);
  };
  const handleAddChapter = (page: any) => {
    navigate(`/edit-chapters/${blogID}`, {
      state: { page, fetchedBlog: blog },
    });
  };

  const handleDelete = async () => {
    if (window.confirm(`${t("blog-confirm-delete")}`)) {
      try {
        await deleteBlogPost(blogID);
        navigate("/"); // Redirect to home or another page after deletion
      } catch (error) {
        console.error("Error deleting blog:", error);
      }
    }
  };

  const handleCopy = (link: any) => {
    navigator.clipboard.writeText(link).then(
      () => {},
      (err) => {
        console.error("Could not copy text: ", err);
      }
    );
  };

  const handleCurrentTrip = async (link: any) => {
    try {
      await updateCurrentTripAPI(blogID);
      setUserCurrentBlog(!userCurrentBlog);
    } catch (error) {
      console.error("Error deleting blog:", error);
    }
  };

  const shareOnInsta = () => {
    setTripPostImg(true);
  };

  useEffect(() => {
    if (tripPostImg !== null && tripPostImg !== true) {
      const shareImage = async (imageUri: any) => {
        try {
          let blobImage;
          if (imageUri.startsWith("data:")) {
            // Convert data URI to Blob
            const res = await fetch(imageUri);
            blobImage = await res.blob();
          } else {
            // Fetch image from URL
            const fetchedImage = await fetch(imageUri);
            blobImage = await fetchedImage.blob();
          }

          const fileName = "image.png"; // Default name, can be adjusted if you have the original name
          const filesArray = [
            new File([blobImage], fileName, {
              type: blobImage.type,
              lastModified: Date.now(),
            }),
          ];
          const shareData = {
            title: fileName,
            files: filesArray,
            url: document.location.origin,
          };
          if (navigator.canShare && navigator.canShare(shareData)) {
            await navigator.share(shareData);
          } else {
            throw new Error("Sharing not supported");
          }
        } catch (error) {
          console.error("Sharing failed:", error);
          // Fallback to download the file
          const a = document.createElement("a");
          a.href = imageUri;
          a.download = "map-post.png";
          document.body.appendChild(a);
          a.click();
          document.body.removeChild(a);
        }
      };

      if (window.innerWidth <= 768) {
        shareImage(tripPostImg);
      } else {
        const a = document.createElement("a");
        a.href = tripPostImg;
        a.download = "map-post.png";
        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);
      }

      setTripPostImg(null);
    }
  }, [tripPostImg]);


  let shouldShowStats = false;
  let visibleStats = null;

  if (blog) {
    shouldShowStats =
      (page === 1 && blog.stats) ||
      (page !== 1 && blog.chapters[page - 1]?.stats);

      console.log('shouldShowStats', page, blog.chapters[page - 2])
    visibleStats = page === 1
      ? blog.stats
      : blog.chapters[page - 2]?.stats;
  }

  return (
    <div
      className={`w-full h-screen relative bg-primary-contrast overflow-hidden flex flex-col items-center justify-start gap-[30px]`}
    >
      <Navbar />

      {blog ? (
        <div className="w-full flex md:flex-row flex-col items-start justify-center md:pt-[80px] pt-[66px]">
          {/*<div
            className={`${
              !isOverviewOpen
                ? "w-[300px] pt-2 pr-2 p-5 rounded-xl"
                : "rounded-lg pr-2 pl-5"
            }  max-h-[250px] absolute bg-whitesmoke-100 z-[99] shadow-all md:left-8 md:top-[90px] overflow-auto left-2 top-[110px]`}
          >
             <div className="flex flex-row items-center justify-between">
              <div
                onClick={resetChapter}
                className={`${
                  chapterIndex
                    ? "text-slate-800"
                    : "font-[500] tracking-tight text-olivegreen"
                } hover:text-olivegreen transition duration-150 ease-in-out tracking-tight font-roboto font-medium antialiased text-sm flex flex-row justify-center items-center uppercase`}
              >
                {t("blog-overview")}
              </div>
              <IconButton
                className={`md:hidden block`}
                onClick={() => setIsOverviewOpen(!isOverviewOpen)}
              >
                {!isOverviewOpen ? <ExpandLessIcon /> : <ExpandMoreIcon />}
              </IconButton>
            </div>

            <ul
              className={`list-none space-y-2 p-0 ${
                isOverviewOpen && "hidden"
              }`}
            >
              <li className="px-8">
                <ol className="list-[circle] space-y-2">
                  {blog.chapters.map((chapter: any, index: number) => (
                    <li key={chapter.id}>
                      <div
                        onClick={() => displayChapterContent(chapter.id)}
                        className={`${
                          chapterIndex !== chapter.id
                            ? "text-slate-800"
                            : "font-[500] tracking-tight text-olivegreen"
                        }  hover:text-olivegreen hover:cursor-pointer transition duration-150 ease-in-out font-roboto text-sm antialiased uppercase`}
                      >
                        {getLocalizedField(
                          chapter,
                          "title",
                          i18n.language,
                          userIsAuthor
                        )}
                      </div>
                    </li>
                  ))}
                </ol>
              </li>
            </ul> */}
            {visibleStats && (
            <RouteDist stats={visibleStats}/>

            )}

          <div className="w-full h-full flex flex-row mt-[30px] md:mt-0">
            <div
              className="h-[calc(100vh-95px)] md:h-full  flex flex-col md:gap-2 md:pl-5"
              style={{ width: `${width === 100 ? 100 : 100 - width}%` }}
            >
              <div></div>

              <MapboxMapView
                mapFeatures={mapFeatures}
                chapterIndex={chapterIndex}
                setChapterIndex={setChapterIndex}
                chartRef={chartRef}
                setElevationSummary={setElevationSummary}
                setGpxData={setGpxData} //
                userIsAuthor={userIsAuthor}
                transports={blog.stats}
                co2={blog.co2_stats}
                map={map}
                setMap={setMap}
                mapAnimation={mapAnimation}
                setMapAnimation={setMapAnimation}
                mapContainerRef={mapContainerRef}
                allLayers={allLayers}
                setAllLayers={setAllLayers}
                allMarkers={allMarkers}
                setAllMarkers={setAllMarkers}
                mapResize={mapResize}
                setMapResize={setMapResize}
                setShowChart={setShowChart}
                showElevationRef={showElevationRef}
                tripPostImg={tripPostImg}
                setTripPostImg={setTripPostImg}
                qaOpen={qaOpen}
                setQaOpen={setQaOpen}
              />
              {/* <div className="h-3 w-full hidden md:flex justify-center items-center z-[1001]"></div> */}

              <div
                style={{
                  height: `${showChart ? "250px" : "0"}`,
                  visibility: `${showChart ? "visible" : "hidden"}`,
                }}
              >
                <div
                  className={`bg-white rounded-xl shadow-all box-border h-full`}
                >
                  {elevationSummary[0] !== null ? (
                    <>
                      <div className="flex flex-row gap-3 justify-between pt-2 mr-2">
                        <IconButton
                          onClick={() => {
                            setShowChart(false);
                            showElevationRef.current = false;
                            setElevationSummary([null, null, null]);
                            setMapResize(true);
                          }}
                        >
                          {" "}
                          <Close />
                        </IconButton>
                        <div className="w-full flex flex-row gap-3 justify-center items-center">
                          <div>
                            <i className="fa-solid fa-arrow-trend-up"></i>{" "}
                            {elevationSummary[0]}m
                          </div>
                          <div>
                            <i className="fa-solid fa-arrow-trend-down"></i>{" "}
                            {elevationSummary[1]}m
                          </div>
                          <div>
                            <i className="fa-solid fa-arrows-left-right"></i>{" "}
                            {elevationSummary[2]}km
                          </div>
                        </div>

                        <Button
                          onClick={() => downloadGPX(gpxData)}
                          color="primary"
                          variant="outlined"
                          startIcon={<DownloadIcon />}
                        >
                          {" "}
                          GPX
                        </Button>
                      </div>
                    </>
                  ) : (
                    <div className="w-full h-full flex flex-col gap-3 items-center justify-center">
                      <div className="graph-border overflow-hidden">
                        <div className="graph0"></div>
                        <div className="graph"></div>
                        <div className="graph2"></div>
                        <div className="graph3"></div>
                      </div>
                      {t("retrieving-gpx")}
                    </div>
                  )}

                  <div className="h-[200px] mr-2 box-border">
                    <canvas ref={chartRef} id="elevation-chart"></canvas>
                  </div>
                </div>
              </div>
            </div>

            <div
              className="h-full w-6 hidden md:flex justify-center items-center cursor-pointer"
              onClick={changeWidth}
            >
              {/* <div className="w-4 h-4 bg-transparent rounded-full animate-ripple"></div> */}
              <div className="text-secondary-500 z-[0]">
                {width === 33 ? <ArrowLeftIcon /> : <ArrowRightIcon />}
              </div>
            </div>

            <div
              className={`md:max-w-[${
                width === 100 ? 33 : 50
              }%] md:relative md:mr-5  absolute z-[100] mr-0`}
              style={{ width: `${width}%` }}
            >
              <div
                className={`z-[100] w-full h-[calc(100vh-260px)] mb-4 top-[100px] flex flex-col md:rounded-xl shadow-all overflow-auto bg-white rounded-none justify-between ${
                  !isBlogOpen && "hidden md:flex"
                }`}
              >

                {/* show questions otherwise blog */}
                {qaOpen ? (
                  <AuthorQACard blogId={blog.id} questions={questions} onClose={setQaOpen}/>
                ) : (
                  <>
                                  {selectedChapter ? (
                  <ScrollShadow
                  className=""
                  style={{
                    // @ts-ignore
                    '--scroll-shadow-size': isFullscreen ? null : '40px'
                  }}
                >
                    {Object.keys(transformChapterImages(selectedChapter))
                      .length > 0 && (
                      <div className="w-full max-w-full h-[300px] box-border">
                        <MyGallery
                          items={transformChapterImages(selectedChapter)}
                          isFullscreen={isFullscreen}
                          setIsFullscreen={setIsFullscreen}
                        />
                        {/* <ImageGallery items={transformChapterImages(selectedChapter)}showPlayButton={false}/> */}
                      </div>
                    )}

                    {user &&
                      (user.id === blog.author.id ||
                        blog.contributors.some(
                          (contributor: any) => contributor.id === user.id
                        )) && (
                        <div className="flex pt-3 justify-center md:justify-end md:pr-3">
                          <div className="flex flex-row gap-[10px]">
                            <IconButton
                              onClick={() => handleAddChapter(page - 2)}
                              color="primary"
                            >
                              <EditIcon />
                            </IconButton>
                          </div>
                        </div>
                      )}

                    <div className="m-5 mt-2 pb-5 overflow-auto">
                      {/* Render selected chapter content */}
                      {/* <h2>{selectedChapter.title}</h2> */}
                      {/* <Typography
                      dangerouslySetInnerHTML={{
                        __html: selectedChapter.content,
                      }}
                    ></Typography> */}
                      {(selectedChapter.start_date ||
                        selectedChapter.end_date) && (
                        <div className="w-full flex flex-row justify-start gap-2 italic text-sm text-secondary-500">
                          {selectedChapter.start_date && (
                            <div>{formatDate(selectedChapter.start_date)}</div>
                          )}
                          {selectedChapter.end_date && (
                            <>
                              <div>-</div>
                              <div>{formatDate(selectedChapter.end_date)}</div>
                            </>
                          )}
                        </div>
                      )}

                      <h2 className="my-3">
                        {getLocalizedField(
                          selectedChapter,
                          "title",
                          i18n.language,
                          userIsAuthor
                        )}
                      </h2>
                      {selectedChapter.song && selectedChapter.song !== "" && (
                        <iframe
                          className="rounded-[12px]"
                          src={`https://open.spotify.com/embed/track/${selectedChapter.song}?utm_source=generator&theme=1`}
                          width="100%"
                          height="90"
                          frameBorder="0"
                          allow="autoplay; clipboard-write; encrypted-media; fullscreen; picture-in-picture"
                          loading="lazy"
                        ></iframe>
                      )}
                      <Typography
                        dangerouslySetInnerHTML={{
                          __html: getLocalizedField(
                            selectedChapter,
                            "content",
                            i18n.language,
                            userIsAuthor
                          ),
                        }}
                      ></Typography>
                    </div>
                  </ScrollShadow>
                ) : (
                  <div className="overflow-auto">
                    <div
                      className="w-full"
                      style={{
                        background: "radial-gradient(#ffffff, #d1d1d19c)",
                      }}
                    >
                      <img
                        className="max-h-[300px] w-full object-contain md:rounded-t-lg"
                        src={blog.header_image}
                        alt=""
                      />
                    </div>
                    <div className="m-3">
                      {user &&
                        (user.id === blog.author.id ||
                          blog.contributors.some(
                            (contributor: any) => contributor.id === user.id
                          )) && (
                          <div className="flex gap-[10px] justify-between">
                            <div className="flex flex-row gap-[10px]">
                              <IconButton
                                onClick={() => handleAddChapter(0)}
                                color="primary"
                              >
                                <EditIcon />
                              </IconButton>
                              <IconButton onClick={handleDelete} color="error">
                                <DeleteIcon />
                              </IconButton>
                            </div>
                            <div className="flex flex-row">
                              {/* <PDF
                                data={blog}
                                render={pdfReady}
                                setRender={setpdfReady}
                              /> */}
                              <IconButton onClick={() => setOpenQR(true)}>
                                <ShareIcon />
                              </IconButton>
                              <Dialog
                                fullWidth={true}
                                maxWidth={"lg"}
                                open={openQR}
                                onClose={() => setOpenQR(false)}
                              >
                                <DialogContent>
                                  <div className="w-full flex flex-col justify-center items-center gap-5">
                                    <QRCode
                                      size={250}
                                      value={`https://slostr.com/${blog.author.username}/${blog.id}/?code=${blog.special_access_code}`}
                                      fgColor="#8ABFA3"
                                      logoImage="/slostr_icon.svg"
                                      removeQrCodeBehindLogo={true}
                                      qrStyle="fluid"
                                      eyeRadius={10}
                                      logoWidth={50}
                                      logoPadding={0}
                                      logoPaddingStyle="circle"
                                    />

                                    <TextField
                                      value={`slostr.com/${blog.author.username}/${blog.id}/?code=${blog.special_access_code}`}
                                      InputProps={{
                                        readOnly: true,
                                        endAdornment: (
                                          <InputAdornment position="end">
                                            <IconButton
                                              onClick={() =>
                                                handleCopy(
                                                  `https://slostr.com/${blog.author.username}/${blog.id}/?code=${blog.special_access_code}`
                                                )
                                              }
                                              edge="end"
                                            >
                                              <ContentCopyIcon />
                                            </IconButton>
                                          </InputAdornment>
                                        ),
                                      }}
                                    />
                                    {/* <div className="flex flex-row gap-3">
                                      <IconButton
                                        aria-label="delete"
                                        onClick={() => shareOnInsta()}
                                      >
                                        <InstagramIcon />
                                      </IconButton>
                                    </div> */}
                                  </div>
                                </DialogContent>
                                <DialogActions>
                                  <Button onClick={() => setOpenQR(false)}>
                                    {t("close")}
                                  </Button>
                                </DialogActions>
                              </Dialog>
                            </div>
                          </div>
                        )}
                      <Accordion defaultExpanded>
                        <AccordionSummary
                          expandIcon={<ExpandMoreIcon />}
                          aria-controls="panel1-content"
                          id="panel1-header"
                        >
                          <h2 className="text-slate-800 font-roboto font-normal antialiased text-2xl m-1">
                            {t("blog-overview")}
                          </h2>
                        </AccordionSummary>
                        <AccordionDetails>
                          <div className=" h-full">
                            <h2 className="m-0 self-stretch font-semibold font-roboto text-slate-800 mb-2">
                              {/* {blog.title} */}
                              {getLocalizedField(
                                blog,
                                "title",
                                i18n.language,
                                userIsAuthor
                              )}
                            </h2>
                            <div className="flex flex-row items-center justify-start gap-[24px] text-sm text-secondary-500 mb-2">
                              <Link
                                to={`/${blog.author.username}`}
                                className="text-gray1 hover:text-olivegreen"
                              >
                                <div className="flex flex-row items-center justify-start gap-[8px] w-max">
                                  <img
                                    className="h-7 w-7 relative rounded-9xl object-cover"
                                    loading="lazy"
                                    alt=""
                                    src={
                                      blog.author.profile_picture
                                        ? `${blog.author.profile_picture}`
                                        : "/SlowTrav_icon.png"
                                    }
                                  />
                                  <div className="relative leading-[20px] font-medium font-roboto">
                                    {blog.author.first_name}{" "}
                                    {blog.author.last_name}
                                  </div>
                                </div>
                              </Link>
                              <div className="relative leading-[20px] text-gray1 font-roboto">
                                {new Date(blog.date).toLocaleDateString()}
                              </div>
                              {user && (
                                <BlogLikes
                                  blog={blog}
                                  likers={likers}
                                  updateLikers={updateLikers}
                                />
                              )}
                            </div>
                            <div className="flex flex-row items-center justify-between gap-[24px] text-sm text-secondary-500 mb-2">
                              <GroupAvatars
                                blog={blog}
                                contributors={contributors}
                                updateContributors={updateContributors}
                              />
                              {userIsAuthor &&
                                (!userCurrentBlog ? (
                                  <Button
                                    onClick={handleCurrentTrip}
                                    variant="outlined"
                                    size="small"
                                    startIcon={<AccessTimeIcon />}
                                  >
                                    {t("set-current-trip")}
                                  </Button>
                                ) : (
                                  <Button
                                    onClick={handleCurrentTrip}
                                    variant="outlined"
                                    size="small"
                                    startIcon={<HistoryIcon />}
                                  >
                                    {t("set-past-trip")}
                                  </Button>
                                ))}
                            </div>
                            <div className="h-fit text-lg text-secondary-600 font-roboto overflow-auto">
                              {/* {blog.summary} */}
                              {getLocalizedField(
                                blog,
                                "summary",
                                i18n.language,
                                userIsAuthor
                              )}
                            </div>
                          </div>
                        </AccordionDetails>
                      </Accordion>

                      {blog.pos_negs && (
                        <Accordion defaultExpanded>
                          <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls="panel1-content"
                            id="panel1-header"
                          >
                            <h2 className="text-slate-800 font-roboto font-normal antialiased text-2xl m-1">
                              {t("blog-stats")}
                            </h2>
                          </AccordionSummary>
                          <AccordionDetails>
                            <div className=" h-full">
                              <TravelSummary blog={blog} />
                            </div>
                          </AccordionDetails>
                        </Accordion>
                      )}
                      {blog.co2_stats && (
                        <Accordion>
                          <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls="panel1-content"
                            id="panel1-header"
                          >
                            <h2 className="text-slate-800 font-roboto font-normal antialiased text-2xl m-1">
                              {t("blog-stats-co2")}
                            </h2>
                          </AccordionSummary>
                          <AccordionDetails>
                            <div className=" h-full">
                              <StackedBarCO2 co2_stats={blog.co2_stats} />
                            </div>
                          </AccordionDetails>
                        </Accordion>
                      )}
                    </div>
                  </div>
                )}
                  </>
                )}

              </div>
              <div
                className={`z-[100] w-full h-[150px] flex flex-col md:rounded-xl shadow-all overflow-auto bg-white rounded-none justify-between ${
                  !isBlogOpen && "hidden md:flex"
                }`}
              >
                <div className="w-full flex flex-col justify-center pb-3 mt-6">
                  <ScrollShadow
                    orientation="horizontal"
                    className="z-10 relative bottom-2 left-2 max-h-[150px]"
                  >
                    <div className="relative rounded-lg flex flex-row gap-4 w-fit">
                      {blog.chapters.map((chapter: any, index: number) => (
                        <div
                          className="relative w-[150px] h-[80px] rounded-lg"
                          onClick={() => displayChapterContent(chapter.id)}
                        >
                          <img
                            className="rounded-lg w-full h-full object-cover"
                            loading="lazy"
                            alt=""
                            src={
                              chapter.images[0]
                                ? `${
                                    chapter.images[0].image_url ||
                                    chapter.images[0].image_file
                                  }`
                                : "/welcome_img.png"
                            }
                          />
                          <div className="absolute rounded-lg inset-0 bg-black bg-opacity-30 flex items-center justify-center">
                            <h3 className="text-white text-sm font-semibold px-2 text-center">
                              {getLocalizedField(
                                chapter,
                                "title",
                                i18n.language,
                                userIsAuthor
                              ).length > 20
                                ? getLocalizedField(
                                    chapter,
                                    "title",
                                    i18n.language,
                                    userIsAuthor
                                  ).slice(0, 20) + ".."
                                : getLocalizedField(
                                    chapter,
                                    "title",
                                    i18n.language,
                                    userIsAuthor
                                  )}
                            </h3>
                          </div>
                        </div>
                      ))}
                    </div>
                  </ScrollShadow>
                  <div className="flex w-full justify-center">
                    <Pagination
                      total={blog?.chapters.length + 1}
                      page={page}
                      onChange={(e) => pageChange(e)}
                      color="primary"
                    />
                  </div>
                </div>
              </div>
              <div className="md:hidden w-full block fixed top-[60px]">
                <div className="bg-white box-border h-[45px] flex justify-center items-center flex-col border border-solid border-whitesmoke-400 border-l-0 border-r-0 border-b-0">
                  <div className="w-full h-full flex flex-row">
                    <div
                      className={`w-1/2 h-full flex items-center justify-center ${
                        !isBlogOpen ? "bg-primary text-white" : ""
                      }`}
                      onClick={() => handleBlogOpen(false)}
                    >
                      <MapIcon />
                    </div>
                    <div
                      className={`w-1/2 h-full flex items-center justify-center ${
                        isBlogOpen ? "bg-primary text-white" : ""
                      }`}
                      onClick={() => handleBlogOpen(true)}
                    >
                      <ArticleIcon />
                    </div>
                  </div>
                  <div className="w-full flex justify-end">
                    <div className="w-1/2 flex justify-center">
                      {!isBlogOpen && (
                        // @ts-ignore
                        <Tooltip
                          PopperProps={{
                            disablePortal: true,
                          }}
                          onClose={handleTooltipClose}
                          open={tooltipOpen}
                          arrow
                          disableFocusListener
                          disableHoverListener
                          disableTouchListener
                          placement="bottom"
                          title={
                            <p className="p-2 text-md">
                              {t("blog-content-info")}
                            </p>
                          }
                        />
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <div className={`spinner-background ${isLoading ? "" : "hidden"}`}>
          <CircularProgress />
          {t("loading")}
        </div>
      )}
    </div>
  );
};

export default NewBlogView;
