import React, { useEffect, useRef, useState } from 'react';
import { TextField, Select, InputLabel, MenuItem, FormControl, Button, IconButton} from '@mui/material';
import DirectionsIcon from '@mui/icons-material/Directions';
import DeleteIcon from '@mui/icons-material/Delete';
import CircularProgress from '@mui/material/CircularProgress';
import { useTranslation } from 'react-i18next';

import { boatGeometryAPI } from "../../utils/api/api";



const loadGoogleMapsScript = (callback) => {
  const existingScript = document.getElementById('googleMapsScript');
  if (!existingScript) {
    const script = document.createElement('script');
    script.src = `https://maps.googleapis.com/maps/api/js?key=${process.env.REACT_APP_GOOGLE_MAPS_API_TOKEN}&libraries=places`;
    script.async = true; // Make sure the script is loaded asynchronously
    script.defer = true; // Defer script execution until the HTML parser is done
    script.id = 'googleMapsScript';
    document.body.appendChild(script);
    script.onload = () => {
      if (callback) callback();
    };
  } else if (callback) {
    callback();
  }
};



const RouteSearcher = ({setRoutes, setSelectedRouteIndex, origin, setOrigin, destination, setDestination, transportationMethod}) => {
  const { t } = useTranslation();

  const [originAutocomplete, setOriginAutocomplete] = useState(null);
  const [destinationAutocomplete, setDestinationAutocomplete] = useState(null);
  const [isSearching, setIsSearching] = useState(false);

  useEffect(() => {
    loadGoogleMapsScript(() => {
      const originInput = document.getElementById('origin-input');
      const destinationInput = document.getElementById('destination-input');

      const originAuto = new window.google.maps.places.Autocomplete(originInput);
      const destinationAuto = new window.google.maps.places.Autocomplete(destinationInput);

      originAuto.addListener('place_changed', () => {
        const place = originAuto.getPlace();
        if (place.geometry) {
          setOrigin({
            lat: place.geometry.location.lat(),
            lng: place.geometry.location.lng()
          });
        }
      });

      destinationAuto.addListener('place_changed', () => {
        const place = destinationAuto.getPlace();
        if (place.geometry) {
          setDestination({
            lat: place.geometry.location.lat(),
            lng: place.geometry.location.lng()
          });
        }
      });

      setOriginAutocomplete(originAuto);
      setDestinationAutocomplete(destinationAuto);

    });
  }, []);


  useEffect(() => {
    // Only calculate the route when both origin and destination are set
    if (origin && destination) {
      if (transportationMethod==='boat' || transportationMethod==='sail')  {
        setIsSearching(true)
        boatGeometryAPI(origin.lat, origin.lng, destination.lat, destination.lng).then((data) => {
          const routesFeatureCollection = {
            type: "FeatureCollection",
            features: data.features.map(feature => ({
              type: "Feature",
              properties: {
                transportation: { name: transportationMethod }
              },
              geometry: feature.geometry
            }))
          };


            console.log('routesFeatureCollection', routesFeatureCollection)
            const routesLen = routesFeatureCollection.features.length
            setSelectedRouteIndex((transportationMethod==='sail' && routesLen > 1) ? 1 : 0 );

            setIsSearching(false);
            setRoutes(routesFeatureCollection);
        })
        .catch((error) => {
          window.alert("No unique routes found.");

        });
      } else {
      calculateRoute();
    }
  }
  }, [origin, destination]);


  const calculateRoute = () => {
    setSelectedRouteIndex(0);
    if (!originAutocomplete || !destinationAutocomplete) return;
    setIsSearching(true)
    const directionsService = new window.google.maps.DirectionsService();

    const times = [3, 7].map(days => {
      let date = new Date();
      date.setDate(date.getDate() + days);
      return date;
    });


  let routesGeoJSONFeatures = [];

  const routeRequests = times.map(time => ({
    origin: { placeId: originAutocomplete.getPlace().place_id },
    destination: { placeId: destinationAutocomplete.getPlace().place_id },
    travelMode: window.google.maps.TravelMode["TRANSIT"],
    provideRouteAlternatives: true,
    transitOptions: { arrivalTime: time }
  }));

  Promise.all(routeRequests.map(request =>
    new Promise(resolve => directionsService.route(request, (response, status) => {
      if (status === 'OK') {
        resolve(response.routes);
      } else {
        resolve([]); // resolve with an empty array on failure
      }
    }))
  )).then(allRoutes => {
    allRoutes.flat().forEach(route => {
      const overviewPath = route.overview_path;
      const pathCoords = overviewPath.map(location => ({
        lat: location.lat(),
        lng: location.lng()
      }));

      const routeGeoJSON = {
        type: "Feature",
        properties: {
          transportation: {name: {transportationMethod}},
        },
        geometry: {
          type: "LineString",
          coordinates: pathCoords.map(coord => [coord.lng, coord.lat])
        }
      };

      // Check if this geometry is unique before adding
      if (!routesGeoJSONFeatures.some(feature =>
        JSON.stringify(feature.geometry.coordinates) === JSON.stringify(routeGeoJSON.geometry.coordinates))) {
        routesGeoJSONFeatures.push(routeGeoJSON);
      }
    });


      let uniqueRoutes = getMostDistinctRoutes(routesGeoJSONFeatures, 5)

      if (uniqueRoutes.length > 0) {
        const routesFeatureCollection = {
          type: "FeatureCollection",
          features: uniqueRoutes
        };

      setIsSearching(false);
      setRoutes(routesFeatureCollection);
    } else {
      window.alert("No unique routes found.");
    }
  })}

  const getMostDistinctRoutes = (routes, count) => {
    const scores = [];

    routes.forEach((routeA, indexA) => {
      let minDistance = Infinity;

      routes.forEach((routeB, indexB) => {
        if (indexA !== indexB) {
          const distance = calculateRouteDistance(routeA, routeB);
          minDistance = Math.min(minDistance, distance);
        }
      });

      scores.push({
        route: routeA,
        score: minDistance // Higher is better, means more distinct
      });
    });

    // Filter out routes with duplicate scores
    const uniqueScores = scores.filter((value, index, self) =>
      index === self.findIndex((t) => t.score === value.score)
    );

    // Sort by score descending to get most distinct routes
    uniqueScores.sort((a, b) => b.score - a.score);

    // Return only the top 'count' unique routes
    return uniqueScores.slice(0, count).map(entry => entry.route);
  };

  const calculateRouteDistance = (routeA, routeB) => {
    // Simplify the calculation by using a reduced number of points
    let distance = 0;
    const sampleSize = 10; // Adjust sample size as needed for performance
    const pathA = routeA.geometry.coordinates;
    const pathB = routeB.geometry.coordinates;
    const stepA = Math.ceil(pathA.length / sampleSize);
    const stepB = Math.ceil(pathB.length / sampleSize);

    for (let i = 0; i < sampleSize; i++) {
      const indexA = i * stepA < pathA.length ? i * stepA : pathA.length - 1;
      const indexB = i * stepB < pathB.length ? i * stepB : pathB.length - 1;
      const [lngA, latA] = pathA[indexA];
      const [lngB, latB] = pathB[indexB];
      distance += Math.sqrt(Math.pow(lngA - lngB, 2) + Math.pow(latA - latB, 2));
    }

    return distance;
  };

  // In the calculateRoute function, make sure to use the updated getMostDistinctRoutes function




  return (
    <div className='flex flex-col w-[400px] gap-1 justify-center'>
    <div className='flex flex-row gap-0 w-full justify-center'>
      <TextField id="origin-input" placeholder={t('start-location-google')} variant="outlined" size="small" className='flex w-3/5 bg-white rounded-[5px]' />
      </div>


      <div className='flex flex-row gap-3 w-full justify-center'>
      <TextField id="destination-input" placeholder={t('end-location-google')} variant="outlined" size="small" className='flex w-3/5 bg-white rounded-[5px]'/>
        </div>

        {isSearching && (
          <div className='w-full flex justify-center items-center p-5'>
          <CircularProgress/>
          </div>
        )}


    </div>
  );
};

export default RouteSearcher;
