import { FunctionComponent, useState, useEffect } from "react";
import CircularProgress from '@mui/material/CircularProgress';
import { useTranslation } from 'react-i18next';
import EmailIcon from '@mui/icons-material/Email';import { useLocation } from 'react-router-dom';

import Navbar from "../components/main/Navbar";

const Divider = ({ width = "w-64" }) => {
  return (
    <div className={`w-full mb-4`}>
      <div
        className={`h-1 mx-auto bg-orange ${width} opacity-25 my-0 py-0 rounded-t mb-10`}
      ></div>
    </div>
  );
};

const VerifyMail: FunctionComponent = () => {
  const { t } = useTranslation();


  return (
    <div className="w-full relative bg-primary-contrast overflow-hidden flex flex-col items-center justify-start">
    <Navbar />
    <>
    <div className="w-full relative bg-primary-contrast flex flex-col gap-4 items-center justify-center text-center pt-[80px] h-[calc(100vh-80px)] max-w-[400px] font-roboto text-lg">
      <div ><EmailIcon color="primary" className="!text-[80px]"/></div>
      <div>
<h1>{t('account-verification-validation')}</h1>
<Divider/>
<p>{t('account-verification-description')}</p>

      </div>

    </div>
    </>
    </div>
  );
};

export default VerifyMail;
