import React, { useEffect, useRef, useState } from "react";
import ImageGallery from "react-image-gallery";
import * as turf from "@turf/turf";
import mapboxgl from "mapbox-gl";
import CircularProgress from '@mui/material/CircularProgress';
import Skeleton from '@mui/material/Skeleton';


import {getLinePaintTransport} from '../../utils/mapStyles'
import {proxyImageAPI} from '../../utils/api/api'


export function densifyLineString(
  lineString: any,
  intervalMeters: any,
  transportId: any
) {
  const densifiedCoordinates = [];
  lineString.geometry.coordinates.forEach(
    (coord: any, index: any, arr: any) => {
      if (index < arr.length - 1) {
        const start = turf.point(coord);
        const end = turf.point(arr[index + 1]);
        const distance = turf.distance(start, end, { units: "meters" });
        densifiedCoordinates.push(coord);
        if (distance > intervalMeters) {
          const segments = Math.floor(distance / intervalMeters);
          for (let i = 1; i <= segments; i++) {
            const segmentPoint = turf.along(
              turf.lineString([coord, arr[index + 1]]),
              intervalMeters * i,
              { units: "meters" }
            );
            densifiedCoordinates.push(segmentPoint.geometry.coordinates);
          }
        }
      }
    }
  );
  densifiedCoordinates.push(
    lineString.geometry.coordinates[lineString.geometry.coordinates.length - 1]
  );

  // Create a LineString feature including transportId in properties
  return turf.lineString(densifiedCoordinates, { transport: transportId });
}

export function transformChapterImages(chapter: any) {
  if (!chapter || !chapter.images) return null;

  return chapter.images.map((image: any) => ({
    original: image.image_file ? image.image_file : image.image_url,
    preview: image.preview
  }));
}


const ImageItem: React.FC<any> = ({ item, isFullscreen }) => {
  const [imageSrc, setImageSrc] = useState<any>(null);

  useEffect(() => {
    const fetchImage = async () => {
      if (item.original) {
        if (!item.original.includes('slostr')) {
          try {
            const blob = await proxyImageAPI(item.original);
            const objectURL = URL.createObjectURL(blob);
            setImageSrc(objectURL);
          } catch (error) {
            console.error('Error fetching image:', error);
          }
        } else {
          setImageSrc(item.original);
        }
      } else {
        setImageSrc(item.preview);
      }

    };

    fetchImage();


    // Clean up the object URL
    return () => {
      if (imageSrc) {
        URL.revokeObjectURL(imageSrc);
      }
    };
  }, [item.original]);

  return (
    <div className="image-gallery-image flex justify-center items-center">
    {imageSrc ? (
        <img
          src={imageSrc}
          alt={item.description}
          className="overflow-auto object-contain"
          style={{
            maxHeight: isFullscreen ? "100vh" : "200px",
            height: isFullscreen ? "auto" : "200px",
            width: "100%",
            maxWidth: "100vw",
            margin: "auto",
            display: "block",
          }}
        />
      ) : (
        <Skeleton variant="rounded" width={150} height={150} />
      )}
      {/* {item.description && (
        <span className="image-gallery-description" style={{ color: "white" }}>
          {item.description}
        </span>
      )} */}
    </div>
  );
};

export const MyGallery: React.FC<any> = ({ items }) => {
  const [isFullscreen, setIsFullscreen] = useState(false);

  const handleScreenChange = (fullScreen: any) => {
    setIsFullscreen(fullScreen);
  };

  const renderItem = (item: any) => (
    <ImageItem key={item.original} item={item} isFullscreen={isFullscreen} />

  );

  return (
    // <div className="w-full" style={{ height: isFullscreen ? '100vh' : '300px' }}>
    <ImageGallery
      items={items}
      renderItem={renderItem}
      onScreenChange={handleScreenChange}
      useBrowserFullscreen={true}
      showBullets={true}
      showPlayButton={false}
    />
    // </div>
  );
};

// Linear interpolation function
export function lerp(start: any, end: any, t: any) {
  return start * (1 - t) + end * t;
}

export function hideMarkersAndLayers(
  map: any,
  allLayers: any[],
  allMarkers: any[]
) {
  // Hide layers
  // @ts-ignore
  allLayers.forEach((layer) => {
    map.setLayoutProperty(layer, "visibility", "none");
  });
  allMarkers.forEach(
    ([marker, markerName]: [mapboxgl.Marker, string]) =>
      (marker.getElement().style.display = "none")
  ); // To hide without removing
}

export function hideUnrelatedMarkersAndLayers(
  map: any,
  chapterId: any,
  allLayers: any[],
  allMarkers: any[]


) {
  // Hide unrelated layers
  const relatedOpacity = 1;
  const unrelatedOpacity = 0.4;
  const relatedWidth = 4;
  const unrelatedWidth = 2;

  allLayers.forEach((layerId: string) => {
    // Determine if the layer ID includes the specific chapter ID
    // const isRelatedToChapter = layerId.includes(`chapter-${chapterId}`);

    // If not related to the chapter, hide the layer
    // if (!isRelatedToChapter && map.getLayer(layerId)) {
    //   map.setLayoutProperty(layerId, "visibility", "none");
    // }
    // if (isRelatedToChapter && map.getLayer(layerId)) {
    //   map.setLayoutProperty(layerId, "visibility", "visible");
    // }
    const isRelatedToChapter = layerId.includes(`chapter-${chapterId}`);

    // Set opacity based on whether the layer is related to the chapter
    if (map.getLayer(layerId)) {
      map.setPaintProperty(
        layerId,
        "line-opacity",
        isRelatedToChapter ? relatedOpacity : unrelatedOpacity
      );
      map.setPaintProperty(
        layerId,
        "line-width",
        isRelatedToChapter ? relatedWidth : unrelatedWidth
      );
    }
  });
  // });

  // Hide unrelated markers
  allMarkers.forEach(([marker, markerName]: [mapboxgl.Marker, string]) => {
    // Check if markerName includes the specific chapter ID
    const isRelatedToChapter = markerName.includes(`chapter-${chapterId}`);
    // Hide the marker if it is not related to the chapter
    if (!isRelatedToChapter) {
      marker.getElement().style.display = "none";
    } else {
      // Otherwise, make sure the marker is visible
      marker.getElement().style.display = "";
    }
  });
}

export function showAllMarkersAndLayers(
  map: any,
  allLayers: any[],


) {
  // Hide unrelated layers
  const relatedOpacity = 1;
  const relatedWidth = 4;

  allLayers.forEach((layerId: string) => {


    // Set opacity based on whether the layer is related to the chapter
    if (map.getLayer(layerId)) {
      map.setPaintProperty(
        layerId,
        "line-opacity",relatedOpacity
      );
      map.setPaintProperty(
        layerId,
        "line-width",relatedWidth
      );
    }
  });
}

// Function to show markers and layers
export function showMarkersAndLayers(
  map: any,
  allLayers: any[],
  allMarkers: any[],
  markersRef: any
) {
  if (map && map.getStyle()) {
    // The getStyle check ensures the map is loaded
    // Check if the 'completed-path' layer exists
    if (map.getLayer("completed-path")) {
      map.removeLayer("completed-path");
      map.removeSource("completed-path");
      // @ts-ignore
      markersRef.current[0].remove(); // Remove the marker from the map
      markersRef.current.splice(0, 1);
    }

    // Show layers
    allLayers.forEach((layer: any) => {
      map.setLayoutProperty(layer, "visibility", "visible");
    });

    allMarkers.forEach(
      ([marker, markerName]: [mapboxgl.Marker, string]) =>
        (marker.getElement().style.display = "")
    ); // If you hid them
  }
}

export const fitBoundsToFeatures = (map: any, mapFeatures: any) => {
  if (mapFeatures && mapFeatures.features.length > 0) {
    const data = mapFeatures;
    const bbox = turf.bbox(data);
    map.fitBounds(
      [
        [bbox[0], bbox[1]], // southwest coordinates
        [bbox[2], bbox[3]], // northeast coordinates
      ],
      {
        padding: 20, // Adjust padding as needed
      }
    );
  }
};

const sleep = (milliseconds: any) => new Promise((resolve) => setTimeout(resolve, milliseconds));


export const animateFeaturePath = async (
  map: any,
  feature: any,
  index: number,
  completedPath: any,
  markersRef: any,
  setTotalDistance: any,
  lastTransportSame: any,
  zoomSettings: any,
  isAnimatingRef: React.MutableRefObject<boolean>
) => {
  const updateDistanceByMode = (distance: any, mode: any) => {
    setTotalDistance((prevDistances: any) => ({
        ...prevDistances,
        [mode]: prevDistances[mode] + distance
    }));
  };
  const lineString = feature.geometry.coordinates;
  if (
    completedPath.features[0].geometry.coordinates.slice(-1)[0] !==
    lineString[0]
  ) {
    completedPath.features[0].geometry.coordinates.push(
      ...lineString.slice(0, 1)
    );
  }
  const lineDistance = turf.length(turf.lineString(lineString), {
    units: "kilometers",
  });
  let progress = 0;
  let counter = 0;
  let totalDistanceCovered = 0;  // Local distance accumulator
  let prevDistance = 0;  // Local distance accumulator

  const transportationMode = feature.properties.transportation; // Assuming this is how you determine the icon

  // const zoomSettings: any = {
  //   hike: { speed: 0.001, zoom: 16.4 },
  //   bike: { speed: 0.5, zoom: 7.9 },
  //   car: { speed: 1.2, zoom: 7.9 },
  //   train: { speed: 1.2, zoom: 6.4 },
  //   boat: { speed: 1, zoom: 8.4 }
  // };
  const { speed, zoom } = zoomSettings[transportationMode] || { speed: 0.2, zoom: 12.9 };


  const steps = Math.ceil(lineDistance / speed);

  let lastBearing = map.getBearing(); // Get the initial bearing of the map
  // let interpolatedBearing = map.getBearing(); // Get the initial bearing of the map

  return new Promise((resolve) => {
    const animateMarker = async () => {
      if (counter <= steps && isAnimatingRef.current) {
        progress += speed;
        const lastCoord =
          completedPath.features[0].geometry.coordinates.slice(-1)[0];
        const pointAlongPath = turf.along(
          turf.lineString(lineString),
          progress + speed,
          { units: "kilometers" }
        );
        const newCoords = pointAlongPath.geometry.coordinates;
        completedPath.features[0].geometry.coordinates.push(newCoords);

  totalDistanceCovered = turf.distance(turf.point(lastCoord), turf.point(newCoords), { units: 'kilometers' });
  updateDistanceByMode(totalDistanceCovered, transportationMode);




        const centerCoords = [
          lerp(lastCoord[0], newCoords[0], 0.5),
          lerp(lastCoord[1], newCoords[1], 0.5),
        ];
        // Calculate dynamic bearing between lastCoord and newCoords
        let targetBearing = turf.bearing(
          turf.point(lastCoord),
          turf.point(newCoords)
        );

        // Offset the bearing to look from behind
        let lookBackBearing = (targetBearing ) + 180;

        // Optionally, you can interpolate between the current map bearing and the new bearing
        // for a smoother transition. Adjust the interpolation factor as needed.
        let interpolatedBearing =
          lerp(lastBearing, lookBackBearing, 0.001);
        // let interpolatedBearing = (lastBearing+0.1) %360

        if (counter === 0 && index===0) {
          interpolatedBearing = targetBearing + 180
        }

        map.easeTo({
          center: centerCoords,
          zoom: zoom,
          // speed: speed,
          pitch: (transportationMode === 'hike' || transportationMode === 'bike'|| transportationMode === 'run') ? 60 : 40,
          bearing: (transportationMode === 'hike' || transportationMode === 'bike'|| transportationMode === 'run') ? interpolatedBearing : 0,
          essential: true,
          speed: 0.2
        });

        if (counter === 0 && !lastTransportSame) {
          await sleep(500);
        }

        map.getSource("completed-path").setData(completedPath);
      //   map.getSource('point').setData({
      //     type: 'Feature',
      //     geometry: {
      //         type: 'Point',
      //         coordinates: newCoords
      //     }
      // });

        lastBearing = interpolatedBearing;

        if (markersRef.current.length > 0) {
          // @ts-ignore
          const markerElement = markersRef.current[0].getElement();
          markerElement.className = `marker-animation marker icon-${transportationMode}`; // Update the class for dynamic icon change

          // @ts-ignore
          markersRef.current[0].setLngLat(centerCoords);
        } else {
          const el = document.createElement("div");
          el.className = `marker-animation marker icon-${transportationMode}`; // Set class based on transportation mode

          // @ts-ignore
          const marker = new mapboxgl.Marker(el)
            // @ts-ignore
            .setLngLat(newCoords)
            .addTo(map);
          // @ts-ignore
          markersRef.current.push(marker); // Store the marker reference for later updates
        }

        counter++;
        requestAnimationFrame(animateMarker);
      } else {
        // @ts-ignore
        resolve(); // Resolve the promise after the animation completes
      }
    };

    animateMarker();
  });
};



export const animatePathSequentially = async (
  map: any,
  markersRef: any,
  allLayers: any[],
  allMarkers: any[],
  mapFeatures: any,
  setTotalDistance: any,
  zoomSettings: any,
  isAnimatingRef: React.MutableRefObject<boolean>

) => {
  let completedPath: any = {
    type: "FeatureCollection",
    features: [
      {
        type: "Feature",
        properties: {},
        geometry: {
          type: "LineString",
          coordinates: [mapFeatures.features[0].geometry.coordinates[0]],
        },
      },
    ],
  };
  const transportationMode = mapFeatures.features[0].properties.transportation;
  const initialZoom = zoomSettings[transportationMode].zoom

  map.flyTo({
    center: mapFeatures.features[0].geometry.coordinates[0],
    zoom: initialZoom, // Updated zoom level to 12 as requested
    essential: true, // this animation is considered essential with respect to prefers-reduced-motion
  });




//   map.loadImage(
//     'https://img.icons8.com/ios/100/high-speed-train.png',
//     (error: any, image: any) => {
//         if (error) throw error;

//         // Add the image to the map style.
//         map.addImage('cat', image);

//         // Add a data source containing one point feature.
//         map.addSource('point', {
//             'type': 'geojson',
//             'data': {
//                 'type': 'FeatureCollection',
//                 'features': [
//                     {
//                         'type': 'Feature',
//                         'geometry': {
//                             'type': 'Point',
//                             'coordinates': mapFeatures.features[0].geometry.coordinates[0]
//                         }
//                     }
//                 ]
//             }
//         });

//         // Add a layer to use the image to represent the data.
//         map.addLayer({
//             'id': 'points',
//             'type': 'symbol',
//             'source': 'point', // reference the data source
//             'layout': {
//                 'icon-image': 'cat', // reference the image
//                 'icon-size': 0.2
//             }
//         });
//     }
// );


  // Wait for the 'moveend' event to ensure the flyTo operation is complete
  await new Promise((resolve) => map.once("moveend", resolve));
  const sleep = (milliseconds: any) => new Promise((resolve) => setTimeout(resolve, milliseconds));



  map.addSource("completed-path", {
    type: "geojson",
    data: completedPath,
  });

  map.addLayer({
    id: "completed-path",
    type: "line",
    source: "completed-path",
    layout: {},
    paint: getLinePaintTransport(true),
  });

  let lastTransportSame = false
  for (let i = 0; i < mapFeatures.features.length; i++) {
    if (mapFeatures.features[i].geometry.type === "LineString") {
      if (i !== 0 && mapFeatures.features[i].properties.transportation === mapFeatures.features[i-1].properties.transportation) {
        lastTransportSame = true
      }
      await animateFeaturePath(
        map,
        mapFeatures.features[i],
        i,
        completedPath,
        markersRef,
        setTotalDistance,
        lastTransportSame,
        zoomSettings,
        isAnimatingRef
      ); // Wait for each animation to complete
      lastTransportSame = false
    }
  }
  showMarkersAndLayers(map, allLayers, allMarkers, markersRef);
  fitBoundsToFeatures(map, mapFeatures);
};

// export const startAnimation = (
//   map: any,
//   mapFeatures: any,
//   allLayers: any[],
//   allMarkers: any[],
//   markersRef: any,
//   setTotalDistance: any
// ) => {
//   // Ensure there are features to animate
//   if (mapFeatures && mapFeatures.features.length > 0) {
//     // Remove the moveend event listener after the initial animation
//     map.off("moveend", startAnimation);
//     // Start the sequential animation
//     animatePathSequentially(
//       map,
//       markersRef,
//       allLayers,
//       allMarkers,
//       mapFeatures,
//       setTotalDistance
//     ).then(() => {
//       // Fit bounds to features after the animation is completed
//       fitBoundsToFeatures(map, mapFeatures);
//     });
//   }
// };


export const createGeoJSONLine = (coordinates: any) => {
  return {
    type: 'FeatureCollection',
    features: [{
      type: 'Feature',
      properties: {
        id: '1',
        description: 'Route Description'
      },
      geometry: {
        type: 'LineString',
        coordinates: coordinates // Coordinates should be an array of [lng, lat] pairs
      }
    }]
  };
};
