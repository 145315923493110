"use client";

import { useState, useEffect, useContext } from "react";
import { Suspense } from 'react'; // Import Suspense
import { useTranslation } from 'react-i18next';
import { Button, Input, RadioGroup, Radio, Textarea, Popover, PopoverTrigger, PopoverContent, Tabs, Tab, Avatar, Chip, Modal, ModalContent, ModalHeader, useDisclosure, ModalBody, ModalFooter, Select, SelectItem} from "@nextui-org/react";
import {
  ChevronUp,
  MessageSquare,
  ChevronDown,
  Plus,
  Search,
  SmilePlus
} from "lucide-react";
import Snackbar from "@mui/material/Snackbar";
import Fade from "@mui/material/Fade";
import Slide, { SlideProps } from "@mui/material/Slide";

import Navbar from "../components/main/Navbar";
import { AuthContext } from "../components/authentification/AuthContext";
import {
  fetchFeedback,
  createFeedback,
  upvoteFeedback,
  addComment,
  updateFeedbackStatus,
  addReaction,
} from "../utils/api/api";


const typeColors = {
  bug: "bg-red-100",
  design: "bg-blue-100",
  feature: "bg-green-100",
  idea: "bg-pink-100",
};

const statusBadges = {
  active: <Chip color="secondary">Active</Chip>,
  "in-progress": <Chip color="primary">In progress</Chip>,
  completed: <Chip color="success">Completed</Chip>,
};


function SlideTransition(props) {
  return <Slide {...props} direction="up" />;
}

const emojiReactions = [
  { type: "smile", emoji: "😊" },
  { type: "thumb_up", emoji: "👍" },
  { type: "thumb_down", emoji: "👎" },
  { type: "heart", emoji: "❤️" },
];

export function Comment({ comment, handleReaction, currentUser }) {
  const [selectedReaction, setSelectedReaction] = useState(null);
  const [reactionCounts, setReactionCounts] = useState({
    smile: 0,
    thumb_up: 0,
    thumb_down: 0,
    heart: 0,
  });
  useEffect(() => {
    // Initialize reaction counts from the comment's reaction field
    const reactionCountMap = { smile: 0, thumb_up: 0, thumb_down: 0, heart: 0 };

    comment.reactions.forEach((reaction) => {
      reactionCountMap[reaction.reaction_type]++;
      if (reaction.user.username === currentUser.username) {
        setSelectedReaction(reaction.reaction_type); // Set user's current reaction
      }
    });

    setReactionCounts(reactionCountMap);
  }, [comment.reactions, currentUser]);

  const handleEmojiClick = async (reactionType) => {
    let newReactionCounts = { ...reactionCounts };

    if (selectedReaction === reactionType) {
      // User clicked the same reaction again, so remove their reaction
      newReactionCounts[reactionType]--;
      setSelectedReaction(null);
      await handleReaction(comment.id, reactionType, true); // Pass true to indicate removal
    } else {
      // Update the reaction counts accordingly
      if (selectedReaction) {
        newReactionCounts[selectedReaction]--;
      }
      newReactionCounts[reactionType]++;
      setSelectedReaction(reactionType);
      await handleReaction(comment.id, reactionType, false); // Pass false to indicate update/add
    }

    setReactionCounts(newReactionCounts);
  };

  return (
    <div className="group relative flex items-start space-x-2 p-4 bg-white rounded-lg shadow-sm hover:shadow-md transition-shadow duration-200">
          <Avatar className="w-10 h-10 bg-slate-100" showFallback src={comment.user.profile_picture} alt={comment.user.username} name={comment.user.username.charAt(0)} />

      <div className="flex-grow">
        <p className="font-normal italic text-xs">{comment.user.username}</p>
        <p className="text-base text-gray-600 mt-1">{comment.text}</p>

        {/* Reaction counts display */}
        <div className="mt-2 flex space-x-3">
          {emojiReactions.map(
            (reaction) =>
              reactionCounts[reaction.type] > 0 && (
                <div
                  key={reaction.type}
                  className="flex items-center space-x-1"
                >
                  <span className="text-lg">{reaction.emoji}</span>
                  <span className="text-sm">
                    {reactionCounts[reaction.type]}
                  </span>
                </div>
              )
          )}
        </div>
      </div>

      {/* Popover for reacting */}
      <Popover>
        <PopoverTrigger>
          <Button
            isIconOnly
            variant="ghost"
            size="sm"
            className="absolute top-2 right-2 opacity-0 group-hover:opacity-100 transition-opacity duration-200"
          >
            <SmilePlus className="h-4 w-4" />
            <span className="sr-only">Add reaction</span>
          </Button>
        </PopoverTrigger>
        <PopoverContent className="w-auto p-1">
          <div className="flex space-x-1">
            {emojiReactions.map((reaction) => (
              <Button
                isIconOnly
                key={reaction.type}
                variant="ghost"
                size="sm"
                onClick={() => handleEmojiClick(reaction.type)}
                className={`hover:bg-slate-100 transition-colors duration-200 ${
                  selectedReaction === reaction.type ? "bg-gray-200" : ""
                }`}
              >
                <span className="text-lg">{reaction.emoji}</span>
                <span className="sr-only">React with {reaction.type}</span>
              </Button>
            ))}
          </div>
        </PopoverContent>
      </Popover>
    </div>
  );
}

export default function ImprovedFeedbackBoard() {
  const authContext = useContext(AuthContext);

  if (!authContext) {
    // Handle the null context scenario, e.g., return null, redirect, or show an error
    console.error("Authentication context not available");
    return null;
  }
  const { user } = authContext;
  const { t, i18n } = useTranslation();

  const [feedbackList, setFeedbackList] = useState([]);
  const [newFeedback, setNewFeedback] = useState({
    type: "bug",
    title: "",
    description: "",
  });
  const [newComment, setNewComment] = useState("");
  const [expandedId, setExpandedId] = useState(null);
  const [filterType, setFilterType] = useState<any>(new Set([]));
  const [activeTab, setActiveTab] = useState<any>("active");
  const [searchQuery, setSearchQuery] = useState("");
  const {isOpen, onOpen, onOpenChange} = useDisclosure();
  const [alreadyUpvoted, setAlreadyUpvoted] = useState(false);
  const [loading, setLoading] = useState(true);


  // Fetch feedback when the component mounts or filters change
  useEffect(() => {
    const loadDataAndTranslations = async () => {
      try {
        const feedbackData = await fetchFeedback(); // Fetch feedback data from API
        setFeedbackList(feedbackData);
        setLoading(false); // Mark as loaded when data and translations are ready
      } catch (error) {
        console.error("Error loading feedback:", error);
      }
    };

    // Wait for both feedback data and translations to load
    if (i18n.isInitialized) {
      loadDataAndTranslations();
    }
  }, [i18n.isInitialized]);

  if (loading) {
    // Show a loading spinner or placeholder until everything is ready
    return <div></div>;
  }

  const handleSubmit = async (e) => {
    e.preventDefault();
    const createdFeedback = await createFeedback(newFeedback);
    setFeedbackList([...feedbackList, createdFeedback]);
    setNewFeedback({ type: "bug", title: "", description: "" });
    onOpenChange();
  };

  const handleVote = async (id) => {
    try {
      await upvoteFeedback(id);
      setFeedbackList(
        feedbackList.map((item) =>
          item.id === id ? { ...item, votes: item.votes + 1 } : item
        )
      );
      setAlreadyUpvoted(false); // Reset banner if the vote is successful
    } catch (error) {
      if (error.response && error.response.status === 400) {
        // Show banner if user has already upvoted
        setAlreadyUpvoted(true);
      }
    }
  };

  const handleAddComment = async (id) => {
    if (newComment.trim()) {
      const createdComment = await addComment(id, newComment);
      setFeedbackList(
        feedbackList.map((item) =>
          item.id === id
            ? {
                ...item,
                comments: [...item.comments, createdComment],
              }
            : item
        )
      );
      setNewComment("");
    }
  };

  const handleReaction = async (commentId, reactionType) => {
    try {
      await addReaction(commentId, reactionType);
      // Optionally, update the state to reflect the new reaction
    } catch (error) {
      console.error("Error adding reaction:", error);
    }
  };

  const handleStatusChange = async (feedback, newStatus) => {
    const updatedFeedback = { ...feedback, status: newStatus };
    await updateFeedbackStatus(updatedFeedback);
    setFeedbackList(
      feedbackList.map((item) =>
        item.id === feedback.id ? { ...item, status: newStatus } : item
      )
    );
  };

  const filteredFeedback = feedbackList
  .filter((feedback) =>
    feedback.title.toLowerCase().includes(searchQuery.toLowerCase())
  )
  .filter(
    (feedback) => activeTab === "all" || feedback.status === activeTab
  )
  .filter(
    (feedback) =>
      !filterType.currentKey || filterType.currentKey === "all" || feedback.type === filterType.currentKey
  );

const sortedFeedback = [...filteredFeedback].sort(
  (a, b) => b.votes - a.votes
);

console.log('fb', feedbackList, filterType)

  return (
    <>

      <Navbar />
      <div className="max-w-4xl mx-auto p-4 top-[60px] relative">
        <h1 className="text-3xl font-bold mb-6">{t('feedback-board')}</h1>
        <p className="text-lg font-sans text-slate-700 mb-6">
        {t('feedback-board-info')} 😎
        </p>
        <div className="mb-4 flex justify-between items-center">
          <div className="flex items-center space-x-2">
            <div className="w-48">
            <Select
        label={t('filter')}
        size="sm"
        variant="bordered"
        placeholder={t('filter-type')}
        defaultSelectedKeys={["all"]}
        // selectedKeys={filterType}
        className="max-w-xs"
        onSelectionChange={setFilterType}
      >
                  <SelectItem key="all">All</SelectItem>
                  <SelectItem key="bug">{t('feedback-bug')}</SelectItem>
                  <SelectItem key="design">{t('feedback-design')}</SelectItem>
                  <SelectItem key="feature">{t('feedback-feature')}</SelectItem>
                  <SelectItem key="idea">{t('feedback-other')}</SelectItem>
      </Select>
            </div>
            <div className="relative">
              <Input
                type="text"
                size="md"
                placeholder={t('filter-title')}
                value={searchQuery}
                onChange={(e) => setSearchQuery(e.target.value)}
                className="pl-8 h-full"
                startContent={
          <Search color='gray' className="text-black/50 mb-0.5 dark:text-white/90 text-slate-400 pointer-events-none flex-shrink-0" />
        }
              />
            </div>
          </div>
          <Button color="primary" onPress={onOpen}>
                <Plus className="w-4 h-4 mr-2" />
                {t('add-feedback')}
              </Button>
              <Modal isOpen={isOpen} onOpenChange={onOpenChange}>
              <ModalContent>
              <ModalHeader>
              {t('submit-feedback')}

              </ModalHeader>
              <ModalBody>
              <p>
              {t('submit-feedback')}
                </p>
              <form onSubmit={handleSubmit} className="space-y-4">
                <div>
                  <RadioGroup
      label={t('submit-feedback-type')}
      orientation="horizontal"
      value={newFeedback.type}
                    onValueChange={(value) =>
                      setNewFeedback({ ...newFeedback, type: value })
                    }
    >
      <Radio value="bug">{t('feedback-bug')}</Radio>
      <Radio value="design">{t('feedback-design')}</Radio>
      <Radio value="feature">{t('feedback-feature')}</Radio>
      <Radio value="idea">{t('feedback-other')}</Radio>
    </RadioGroup>
                </div>
                <div>
                  <Input
                    id="title"
                    label={t('title')}
                    value={newFeedback.title}
                    onChange={(e) =>
                      setNewFeedback({ ...newFeedback, title: e.target.value })
                    }
                    required
                  />
                </div>
                <div>
                  <Textarea
                  placeholder={t('feedback-description-info')}
                    label="Description"
                    value={newFeedback.description}
                    onChange={(e) =>
                      setNewFeedback({
                        ...newFeedback,
                        description: e.target.value,
                      })
                    }
                    required
                  />
                </div>
                <ModalFooter className="pr-0">
                <Button color="danger" variant="light" onPress={onOpenChange}>
                {t('cancel')}
                </Button>
                <Button color="primary" type="submit">
                {t('submit-feedback')}
                </Button>
              </ModalFooter>
              </form>
              </ModalBody>
            </ModalContent>
          </Modal>
        </div>
        <div className="flex w-full flex-col">

        <Tabs selectedKey={activeTab} onSelectionChange={setActiveTab}>
          <Tab key="active" title='Active'>
            <div className="space-y-2">
              {sortedFeedback.map((feedback) => (
                <div
                  key={feedback.id}
                  className={`border rounded-lg ${typeColors[feedback.type]} ${
                    expandedId === feedback.id && "shadow-lg"
                  }`}
                >
                  <div
                    className="flex md:items-center flex-col gap-3 md:gap-0 md:flex-row items-start md:justify-between p-4 cursor-pointer"
                    onClick={() =>
                      setExpandedId(
                        expandedId === feedback.id ? null : feedback.id
                      )
                    }
                  >
                    <div className="flex flex-row items-center">
                      {/* Voting Button */}
                      <div className="flex flex-col items-center mr-4">
                        <Button
                          isIconOnly
                          color="primary"
                          variant="light"
                          size="sm"
                          className="px-2"
                          onClick={(e) => {
                            e.stopPropagation();
                            handleVote(feedback.id);
                          }}
                        >
                          <ChevronUp className="w-4 h-4" />
                        </Button>
                        <span className="text-sm font-semibold">
                          {feedback.votes}
                        </span>
                      </div>

                      {/* Avatar */}
                      <Avatar  className="w-10 h-10 bg-slate-100" showFallback src={feedback.user.profile_picture} alt={feedback.user.username} name={feedback.user.username.charAt(0)} />

                    </div>

                    {/* Title and Description */}
                    <div className="flex-grow md:w-[calc(100% - 250px)] md:max-w-[70%] max-w-full w-full">
                      <h3 className="text-lg font-semibold truncate">
                        {feedback.title}
                      </h3>
                      <p className="text-sm text-gray-600 truncate">
                        {feedback.description}
                      </p>
                    </div>

                    {/* Status Badge and Comment Count */}
                    <div className="flex items-center min-w-[120px] justify-end space-x-2">
                      {statusBadges[feedback.status]}
                      <div className="flex items-center">
                        <MessageSquare className="w-4 h-4 mr-1" />
                        <span className="text-sm mr-2">
                          {feedback.comments.length}
                        </span>
                      </div>
                      {expandedId === feedback.id ? (
                        <ChevronUp className="w-4 h-4" />
                      ) : (
                        <ChevronDown className="w-4 h-4" />
                      )}
                    </div>
                  </div>
                  {expandedId === feedback.id && (
                    <div className="p-4 border-t bg-white">
                      <p className="mb-4">{feedback.description}</p>
                      {(user.username === "admin" ||
                        user.username === "vfayt99") && (
                        <>
                          {feedback.status === "active" && (
                            <div className="flex flex-row gap-4">
                              <Button
                                onClick={() =>
                                  handleStatusChange(feedback, "in-progress")
                                }
                                className="mb-4"
                                color="secondary"
                              >
                                Mark as In Progress
                              </Button>
                              <Button
                                onClick={() =>
                                  handleStatusChange(feedback.id, "completed")
                                }
                                className="mb-4"
                                color="primary"
                              >
                                Mark as Completed
                              </Button>
                            </div>
                          )}
                        </>
                      )}
                      <h4 className="font-semibold mb-2">{t('feedback-comments')}:</h4>
                      <ul className="space-y-2 mb-4">
                        {feedback.comments.map((comment) => (
                          <Comment
                            comment={comment}
                            handleReaction={handleReaction}
                            currentUser={user}
                          />
                        ))}
                      </ul>
                      <div className="flex space-x-2">
                        <Input
                          value={newComment}
                          onChange={(e) => setNewComment(e.target.value)}
                          placeholder={t('feedback-add-comments')}
                        />
                        <Button onClick={() => handleAddComment(feedback.id)}>
                        {t('add')}
                        </Button>
                      </div>
                    </div>
                  )}
                </div>
              ))}
            </div>
          </Tab>
          <Tab key="in-progress" title='In progress'>
            <div className="space-y-2">
              {sortedFeedback.map((feedback) => (
                <div
                  key={feedback.id}
                  className={`border rounded-lg ${typeColors[feedback.type]}`}
                  onClick={() =>
                    setExpandedId(
                      expandedId === feedback.id ? null : feedback.id
                    )
                  }
                >
                  <div className="flex items-center p-4 justify-between">
                  <Avatar className="w-10 h-10 bg-slate-100" showFallback src={feedback.user.profile_picture} alt={feedback.user.username} name={feedback.user.username.charAt(0)} />
                    <div className="flex-grow md:w-[calc(100% - 250px)] md:max-w-[70%] max-w-full w-full">
                      <h3 className="text-lg font-semibold">
                        {feedback.title}
                      </h3>
                      <p className="text-sm text-gray-600">
                        {feedback.description}
                      </p>
                    </div>
                    <div className="flex items-center min-w-[100px] justify-end space-x-2">
                      {statusBadges[feedback.status]}
                    </div>
                  </div>
                  {expandedId === feedback.id && (
                    <div className="p-4 border-t bg-white">
                      <p className="mb-4">{feedback.description}</p>
                      {(user.username === "admin" ||
                        user.username === "vfayt99") && (
                        <>
                          {feedback.status === "active" && (
                            <div className="flex flex-row gap-4">
                              <Button
                                onClick={() =>
                                  handleStatusChange(feedback.id, "completed")
                                }
                                className="mb-4"
                                color="primary"
                              >
                                Mark as Completed
                              </Button>
                            </div>
                          )}
                        </>
                      )}
                      <h4 className="font-semibold mb-2">{t('feedback-comments')}:</h4>
                      <ul className="space-y-2 mb-4">
                        {feedback.comments.map((comment) => (
                          <Comment
                            comment={comment}
                            handleReaction={handleReaction}
                            currentUser={user}
                          />
                        ))}
                      </ul>
                      <div className="flex space-x-2">
                        <Input
                          value={newComment}
                          onChange={(e) => setNewComment(e.target.value)}
                          placeholder="Add a comment..."
                        />
                        <Button onClick={() => handleAddComment(feedback.id)}>
                        {t('add')}
                        </Button>
                      </div>
                    </div>
                  )}
                </div>
              ))}
            </div>
          </Tab>
          <Tab key="completed" title='Completed'>
            <div className="space-y-2">
              {sortedFeedback.map((feedback) => (
                <div
                  key={feedback.id}
                  className={`border rounded-lg ${typeColors[feedback.type]}`}
                >
                  <div className="flex items-center p-4">
                  <Avatar className="w-10 h-10 bg-slate-100" showFallback src={feedback.user.profile_picture} alt={feedback.user.username} name={feedback.user.username.charAt(0)} />

                    <div className="flex-grow">
                      <h3 className="text-lg font-semibold">
                        {feedback.title}
                      </h3>
                      <p className="text-sm text-gray-600">
                        {feedback.description}
                      </p>
                    </div>
                    <div className="flex items-center  space-x-2">
                      {statusBadges[feedback.status]}
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </Tab>
        </Tabs>
        </div>
        <Snackbar
          anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
          open={alreadyUpvoted}
          onClose={() => setAlreadyUpvoted(false)}
          TransitionComponent={SlideTransition}
          message="You have already upvoted"
          autoHideDuration={3000}
        />
      </div>
      </>
  );
}
