import { FunctionComponent } from "react";
import { Link } from 'react-router-dom';


const Card: FunctionComponent<any> = ({ route }) => {
  return (
    <button className="hover-opacity-button rounded-[15px] w-[392px] bg-primary-contrast shadow-xl flex flex-col items-start justify-start p-3.5 box-border gap-[17px] min-w-[157px] max-w-full text-left text-sm text-secondary-400 font-heading-h3-bold cursor-pointer">
      <img
        className="rounded-[15px] self-stretch h-[183.4px] relative max-w-full overflow-hidden shrink-0 object-cover"
        loading="lazy"
        alt=""
        src={route?.header_image_thumbnail ? `data:image/jpeg;base64,${route.header_image_thumbnail}` : "/welcome_img.png"}
      />
      <div className="self-stretch flex flex-col items-start justify-start gap-[8px]">
        <div className="self-stretch flex flex-col items-start justify-start">
          <div className="self-stretch relative text-base leading-[24px] font-semibold font-text-small text-black overflow-hidden text-ellipsis whitespace-nowrap">
            {route?.title}
          </div>
        </div>
        {/* <div className="self-stretch relative text-xs leading-[20px] font-text-small text-gray">
        {blog?.summary}
        </div> */}
      </div>
      {/* <button className="cursor-pointer [border:none] py-1.5 px-3.5 bg-accent rounded-md overflow-hidden flex flex-row items-center justify-center gap-[6px] whitespace-nowrap hover:bg-dodgerblue-100">
        <div className="relative text-xs leading-[20px] font-text-small text-primary-contrast text-center">
          Read more
        </div>
        <img
          className="h-4 w-4 relative overflow-hidden shrink-0"
          alt=""
          src="/icon.svg"
        />
      </button> */}
    </button>
  );
};

export default Card;
