import { FunctionComponent, useState, useEffect, useContext } from "react";
import CircularProgress from '@mui/material/CircularProgress';
import { useTranslation } from 'react-i18next';
import PersonAddIcon from '@mui/icons-material/PersonAdd';
import { useLocation } from 'react-router-dom';
import { activateEmailAPI } from '../utils/api/api';

import { AuthContext } from '../components/authentification/AuthContext';
import { useNavigate } from "react-router-dom";

import Navbar from "../components/main/Navbar";

const Divider = ({ width = "w-64" }) => {
  return (
    <div className={`w-full mb-4`}>
      <div
        className={`h-1 mx-auto bg-orange ${width} opacity-25 my-0 py-0 rounded-t mb-10`}
      ></div>
    </div>
  );
};

const ActivateMail: FunctionComponent = () => {
  const { t } = useTranslation();
  const [isLoading, setIsLoading] = useState(true);
  const location = useLocation();
  const authContext = useContext(AuthContext);
  const navigate = useNavigate();
  const [success, setSuccess] = useState(false);
  const [error, setError] = useState('');

  useEffect(() => {
    const params = new URLSearchParams(location.search);
    const uid = params.get('uid');
    const token = params.get('token');

    if (uid && token) {
      activateEmailAPI(uid, token)
        .then((response: any) => {
          console.log('Email verified successfully:', response);
          // Handle success - maybe navigate to another page or show a success message
          localStorage.setItem('token', response.token);
        authContext?.setIsAuthenticated(true);
        setSuccess(true);
          setError('');
          setIsLoading(false);

        // Fetch the user data here and set it
        setTimeout(() => {
          navigate("/");
        }, 5000);
        })
        .catch((error: any) => {
          console.error('Error verifying email:', error);
          setSuccess(false);
          setError(t('verification-failed'));
          // Handle error - show an error message
        })
    } else {
      setIsLoading(false);
      setError(t('invalid-verification-link'));
    }
  }, [location]);

  return (
    <div className="w-full relative bg-primary-contrast overflow-hidden flex flex-col items-center justify-start">
    <Navbar />
    <>
    <div className="w-full relative bg-primary-contrast flex flex-col gap-4 items-center justify-center text-center pt-[80px] h-[calc(100vh-80px)] max-w-[400px] font-roboto text-lg">
    {isLoading ? (
          <CircularProgress />
        ) : (
          <div>
            {success ? (
              <>
      <div ><PersonAddIcon color="primary" className="!text-[80px]"/></div>
      <div>
<h1>{t('mail-verified-thanks')}</h1>
<Divider/>
<p>{t('mail-verified-description')}</p>

      </div>
      </>
      ) : (
        <>
          <h1>{t('verification-error')}</h1>
          <Divider />
          <p>{error}</p>
        </>
      )}
    </div>
  )}

    </div>
    </>
    </div>
  );
};

export default ActivateMail;
