import { FunctionComponent } from "react";
import { Link } from 'react-router-dom';
import Card from "./Card";



const Content: FunctionComponent<any> = ({blogs}) => {
  return (
    <div className="flex flex-row flex-wrap items-start justify-center gap-[20px] max-w-full text-sm text-secondary-400 pb-10">
        {blogs && blogs.map((blog: any, index: any) => (
          <Link to={`/${blog.author.username}/${blog.id}`} key={index}>
            <Card
            blog={blog}
            />
            </Link>
        ))}
    </div>
  );
};

export default Content;
