import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { Button, TextField } from "@mui/material";
import heic2any from "heic2any";
import CircularProgress from "@mui/material/CircularProgress";
import AddPhotoAlternateIcon from "@mui/icons-material/AddPhotoAlternate";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import {Image as ImageUI} from "@nextui-org/react";

const ImageUploader: React.FC<any> = ({
  multiple = true,
  pois = false,
  noVideo = false,
  initialImages,
  onImageChange,
  text,
}) => {
  const { t } = useTranslation();
  // const [images, setImages] = useState<any[]>([]);
  const [initialised, setInitialised] = useState<boolean>(false);
  const [hovering, setHovering] = useState<boolean>(false);
  const [imageIsTransforming, setImageIsTransforming] =
    useState<boolean>(false);
  const [images, setImages] = useState<any>([]);

  useEffect(() => {
    if (!multiple && !initialised) {
      const transformedImages = initialImages.map((image: any) => {
        if (typeof image === "string") {
          // If the image is a string, use it for both preview and image_url
          return {
            preview: image,
            image_url: image,
            caption: "",
          };
        } else {
          // If the image is an object, use the existing properties
          return {
            ...image,
            preview: image.image_file
              ? image.image_file
              : image.video_file
              ? image.video_file
              : image.image_url
              ? image.image_url
              : image.video_url,
            image_url: image.image_file
              ? image.image_file
              : image.video_file
              ? image.video_file
              : image.image_url
              ? image.image_url
              : image.video_url,
            type: image.video_file
              ? "video"
              : image.video_url
              ? "video"
              : "image",
            caption: image.caption || "",
            id: image.id || null,
          };
        }
      });
      setImages(transformedImages);
      onImageChange(transformedImages);
    } else {
      setImages(initialImages);
    }
  }, [initialImages]);

  const handleImageChange = async (e: any) => {
    setInitialised(true);
    const files = Array.from(e.target.files);
    setImageIsTransforming(true); // Set loading state
    const imagePromises = files.map((file) => processFile(file));

    const newImages = await Promise.all(imagePromises);
    setImageIsTransforming(false); // Reset loading state
    setImages(multiple ? [...images, ...newImages] : newImages);
    onImageChange(multiple ? [...images, ...newImages] : newImages);
  };

  const processFile = async (file: any) => {
    if (file.type.startsWith("image/")) {
      let blob = file;
      let targetFormat = "image/jpeg"; // Default format
      let quality = 1.0; // Start with the best quality

      // Convert HEIC to JPEG first if necessary
      if (file.type === "image/heic") {
        blob = await heic2any({
          blob: file,
          toType: "image/jpeg",
          quality: 0.8, // Conversion quality for HEIC to JPEG
        });
      }

      // Use canvas to resize and compress the image
      const canvas = document.createElement("canvas");
      const ctx = canvas.getContext("2d");
      const img = new Image();
      img.src = URL.createObjectURL(blob);
      await new Promise((resolve) => (img.onload = resolve));

      // Adjust canvas size based on the image dimensions
      let width = img.width;
      let height = img.height;

      // Set up scaling factor or dimensions to maintain aspect ratio
      const MAX_WIDTH = 1920; // Maximum width resolution
      const MAX_HEIGHT = 1080; // Maximum height resolution

      if (width > MAX_WIDTH || height > MAX_HEIGHT) {
        if (width > height) {
          height *= MAX_WIDTH / width;
          width = MAX_WIDTH;
        } else {
          width *= MAX_HEIGHT / height;
          height = MAX_HEIGHT;
        }
      }

      canvas.width = width;
      canvas.height = height;
      ctx?.drawImage(img, 0, 0, width, height);

      // Check support for WebP and set conversion target format
      if (canvas.toDataURL("image/webp").indexOf("data:image/webp") === 0) {
        targetFormat = "image/webp";
      }

      let finalBlob: any;
      do {
        finalBlob = await new Promise((resolve) =>
          canvas.toBlob(resolve, targetFormat, quality)
        );
        quality -= 0.1; // Decrease quality to reduce size
      } while (finalBlob.size > 500000 && quality > 0.1); // Stop if size is under 500KB or quality too low

      const finalFile = new File([finalBlob], file.name, {
        type: targetFormat,
      });

      return {
        preview: URL.createObjectURL(finalFile),
        file: finalFile,
        caption: "",
      };
    } else if (file.type.startsWith("video/")) {
      return processVideo(file);
    }
  };

  const processVideo = async (file: any) => {
    // Video processing logic, perhaps converting formats or extracting thumbnails...
    const preview = URL.createObjectURL(file);
    return {
      preview,
      file,
      caption: "",
    };
  };

  const handleRemoveImage = (e: any, index: any) => {
    e.preventDefault();
    setImages(multiple ? images.filter((_: any, i: any) => i !== index) : []);
    onImageChange(
      multiple ? images.filter((_: any, i: any) => i !== index) : []
    );
  };

  const handleDrop = async (event: React.DragEvent<HTMLDivElement>) => {
    setInitialised(true);
    event.preventDefault();
    const files = Array.from(event.dataTransfer.files);
    const imagePromises = files.map(async (file: any) => {
      if (file.type === "image/heic") {
        setImageIsTransforming(true); // Assume this sets a loading state
        try {
          const blob: any = await heic2any({
            blob: file,
            toType: "image/jpeg",
            quality: 0.8, // Adjust the quality to a lower value to reduce size
          });

          // Convert the blob to a File object
          const jpegFile = new File([blob], `${file.name}.jpeg`, {
            type: "image/jpeg",
          });

          return {
            preview: URL.createObjectURL(jpegFile),
            file: jpegFile,
            caption: "",
          };
        } catch (error) {
          console.error("Error converting HEIC to JPEG:", error);
          return; // Handle error appropriately
        } finally {
          setImageIsTransforming(false); // Reset loading state
        }
      } else {
        return {
          preview: URL.createObjectURL(file),
          file: file,
          caption: "",
        };
      }
    });

    const newImages = await Promise.all(imagePromises);
    setImageIsTransforming(false);
    const updatedImages = multiple ? [...images, ...newImages] : newImages;
    setImages(updatedImages);
    onImageChange(updatedImages);

    setHovering(false);
  };

  const handleCaptionChange = (index: any, newCaption: string) => {
    const updatedImages = images.map((image: any, i: any) =>
      i === index ? { ...image, caption: newCaption } : image
    );
    setImages(updatedImages);
    onImageChange(updatedImages);
  };

  const onDragEnd = (result: any) => {
    if (!result.destination) return;
    const updatedImages = Array.from(images);
    const [reorderedItem] = updatedImages.splice(result.source.index, 1);
    updatedImages.splice(result.destination.index, 0, reorderedItem);
    setImages(updatedImages);
    onImageChange(updatedImages);
  };

  return (
    <div
      className="w-full box-border"
      onDrop={handleDrop}
      onMouseEnter={() => setHovering(true)}
      onMouseLeave={() => setHovering(false)}
      onDragOverCapture={(e) => e.preventDefault()}
      onDragEnterCapture={() => setHovering(true)}
      onDragLeaveCapture={(e) => {
        // Check if relatedTarget is a Node before calling contains
        if (e.relatedTarget instanceof Node) {
          if (!e.currentTarget.contains(e.relatedTarget)) {
            setHovering(false);
          }
        } else {
          // Handle case where relatedTarget is not a Node (e.g., null or different type)
          setHovering(false);
        }
      }}
    >
      <div className="flex flex-row w-full gap-2 justify-center">
        <div
          className={`${
            multiple
              ? images && images.length > 0
                ? "h-[200px] w-6 bg-whitesmoke-100"
                : "h-[200px] border border-solid border-slate-300"
              : "h-[300px] md:w-[380px]"
          }   box-border flex flex-col items-center justify-center gap-[24px] max-w-full text-smi text-black font-helvetica  border-gray1-400  cursor-pointer  ${
            hovering ? "bg-whitesmoke-200" : ""
          }`}
          style={{
            // backgroundImage: `url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' rx='20' ry='20' stroke='gray' stroke-width='${
            //   hovering ? "0" : "5"
            // }' stroke-dasharray='22' stroke-dashoffset='0' stroke-linecap='square'/%3e%3c/svg%3e")`,
            borderRadius: "12px",
            transition: "all 0.3s ease 0s",
            width: multiple && images && images.length > 0 ? "100px" : "100%",
            minWidth: "40px",
          }}
          onDrop={handleDrop}
          // onDragOver={(e) => e.preventDefault()}
          // onDragEnter={() => setHovering(true)}
          // onDragLeave={() => setHovering(false)}
          onMouseEnter={() => setHovering(true)}
          onMouseLeave={() => setHovering(false)}
          onDragOverCapture={(e) => e.preventDefault()}
          onDragEnterCapture={() => setHovering(true)}
          onDragLeaveCapture={(e) => {
            // Check if relatedTarget is a Node before calling contains
            if (e.relatedTarget instanceof Node) {
              if (!e.currentTarget.contains(e.relatedTarget)) {
                setHovering(false);
              }
            } else {
              // Handle case where relatedTarget is not a Node (e.g., null or different type)
              setHovering(false);
            }
          }}
        >
          <>
            <label
              htmlFor="image-upload"
              className="flex flex-col items-center justify-center h-full w-full hover:cursor-pointer"
            >
              {!multiple && images && images.length > 0 ? (
                <div className="box-border p-3 h-full w-full">
                  <div
                    className={`absolute flex justify-center items-center z-[100] ${
                      imageIsTransforming ? "" : "hidden"
                    }`}
                  >
                    <CircularProgress />
                  </div>
                  <div className="relative w-full h-full rounded-lg">
  <img
    className="w-full h-full object-cover rounded-lg"
    loading="lazy"
    alt=""
    src={`${images[0].preview}`}
  />
  {!multiple && (
  <div className="absolute top-0 left-0 w-full h-full rounded-lg bg-gradient-to-b from-black to-transparent opacity-50"></div>
  )}
</div>
                </div>
              ) : (
                <div className="flex flex-col items-center justify-center gap-3 box-border p-3 md:gap-[24px] h-full w-full">
                  <AddPhotoAlternateIcon
                    color="primary"
                    className="!text-[2rem]"
                  />
                  {images && images.length === 0 && (
                    <span className="relative font-normal text-md text-gray px-5 text-center">
                      {multiple
                        ? noVideo  ? `${t("input-image-info-novid")}` : `${t("input-image-info")}`
                        : text
                        ? text
                        : `${t("input-image-info-single")}`}
                    </span>
                  )}

                  {/* <span className="relative font-normal text-md text-gray">
                  {t("input-image-type")}
                </span> */}
                </div>
              )}
            </label>
            <input
              id="image-upload"
              type="file"
              onChange={handleImageChange}
              accept={`image/jpeg, image/png, image/gif, image/tif, image/tiff, image/heic ${
                multiple ? (pois || noVideo ? "" : ", video/*") : ""
              }`}
              style={{ display: "none" }}
              multiple={multiple}
            />
          </>
        </div>
        {multiple && images && images.length > 0 && (
          <DragDropContext onDragEnd={onDragEnd}>
            <Droppable droppableId="images" direction="horizontal">
              {(provided) => (
                <div
                  className={`relative w-full ${
                    pois ? "h-[225px]" : "h-[265px]"
                  } overflow-x-scroll`}
                  {...provided.droppableProps}
                  ref={provided.innerRef}
                >
                  {imageIsTransforming && (
                    <div className="absolute inset-0 flex justify-center items-center z-10 bg-white bg-opacity-50">
                      <CircularProgress />
                    </div>
                  )}

                  <div className="flex space-x-2 min-w-max">
                    {images && images.length > 0 ? (
                      images.map((media: any, index: any) => (
                        <Draggable
                          key={media.preview}
                          draggableId={media.preview}
                          index={index}
                        >
                          {(provided) => (
                            <div
                              className="flex-none relative w-[200px] h-[200px]"
                              ref={provided.innerRef}
                              {...provided.draggableProps}
                              {...provided.dragHandleProps}
                            >
                              {media.type === "video" ? (
                                <video
                                  controls
                                  className="w-full h-full object-cover rounded-md shadow-sm"
                                >
                                  <source
                                    src={media.preview}
                                    type={
                                      media.file ? media.file.type : "video/mp4"
                                    }
                                  />
                                  Your browser does not support the video tag.
                                </video>
                              ) : (
                                <div
                                  className="w-full h-full bg-center bg-contain rounded-md shadow-sm bg-no-repeat"
                                  style={{
                                    backgroundImage: `url(${media.preview})`,
                                  }}
                                ></div>
                              )}
                              <button
                                className="absolute top-[0] right-[0] w-8 h-8 p-1 bg-slate-800 bg-opacity-[0.8] rounded-full text-white shadow-sm"
                                onClick={(e) => handleRemoveImage(e, index)}
                              >
                                ×
                              </button>
                              {!pois && (
                                <TextField
                                  label={t("image-caption")}
                                  variant="standard"
                                  size="small"
                                  value={media.caption}
                                  onChange={(e: any) =>
                                    handleCaptionChange(index, e.target.value)
                                  }
                                  fullWidth
                                  className="!mt-0 !text-xs"
                                  multiline
                                  margin="normal"
                                  maxRows={2}
                                />
                              )}
                            </div>
                          )}
                        </Draggable>
                      ))
                    ) : (
                      <p className="text-gray-500">No media to display.</p>
                    )}
                    {provided.placeholder}
                  </div>
                </div>
              )}
            </Droppable>
          </DragDropContext>
        )}
      </div>
    </div>
  );
};

export default ImageUploader;
